import { observable, computed, runInAction } from "mobx";
import { OrganisationTemplate, Features, Feature, CardLayout } from "../types";
import { getOrganisation } from "../repo/organization";

class OrganisationStore {
  @observable organisations!: Map<number, OrganisationTemplate>;

  orgIsFeatureEnabled(
    orgId: number,
    feature: Features,
    cardTypeId: number = -1
  ): boolean {
    const org = this.organisations.get(orgId);
    if (!org) {
      return false;
    }

    const foundFeature = org.features.find(
      (f: Feature) => f.feature === feature
    );
    if (cardTypeId > -1) {
      if (
        foundFeature &&
        foundFeature.active &&
        foundFeature.cardTypes &&
        foundFeature.cardTypes.indexOf(cardTypeId) > -1
      ) {
        return true;
      }
      return false;
    } else if (foundFeature && foundFeature.active) {
      return true;
    }
    return false;
  }

  rolesEnabledForOrg(orgId: number): boolean {
    const org = this.organisations.get(orgId);
    for (const card of org.cardTypes) {
      for (const template of card.cardTemplates) {
        for (const attrib of template.attributes) {
          if (attrib.role) {
            return true;
          }
        }
      }
    }
    return false;
  }

  @computed
  get organisationsApplicationEnabled() {
    const orgs = new Map<number, OrganisationTemplate>();

    if (this.organisations && this.organisations.size > 0) {
      for (const org of Array.from(this.organisations.values()).filter(o => {
        return (
          o.cardTypes.filter(
            c =>
              c.cardApplicationEnabled &&
              c.cardTemplates.filter(ct => ct.name === CardLayout.Soknad)
                .length > 0
          ).length > 0
        );
      })) {
        orgs.set(org.orgId, org);
      }
    }
    return orgs;
  }

  assetsEnabledOnOrganisationsCardType(orgId: number, cardTypeId: number) {
    const org = this.organisations.get(orgId);
    return (
      org &&
      org.hasAssets &&
      !org.features.find(
        f =>
          f.feature === Features.CardDoesNotUseAssets &&
          f.active &&
          f.cardTypes.indexOf(cardTypeId) > -1
      )
    );
  }

  @computed
  get organisationsDoorkeyApplicationEnabled() {
    const orgs = new Map<number, OrganisationTemplate>();

    if (this.organisations && this.organisations.size > 0) {
      for (const org of Array.from(this.organisations.values()).filter(
        o => o.doorkeyApplicationTemplate.attributes.length > 0
      )) {
        orgs.set(org.orgId, org);
      }
    }
    return orgs;
  }

  refreshCardTypes = async (orgId: number) => {
    if (!this.organisations.has(orgId)) {
      throw new Error("Organisation not found");
    }
    const oldOrg = this.organisations.get(orgId);
    try {
      const updatedOrg = await getOrganisation(orgId);
      const newOrgTemplate = {
        ...oldOrg,
        cardTypes: updatedOrg.cardTypes
      };
      runInAction("refreshCardTypes() organisationStore", () =>
        this.organisations.set(orgId, newOrgTemplate)
      );
    } catch (e) {
      throw e;
    }
  };
}

export const organisationStore = new OrganisationStore();
