// import * as React from "react";
import { Local } from "./local";
import {
  OrganisationTemplate
  // CardType
} from "../../types";
import { EngRoutes } from "./en-routes";
import { EngShared } from "./en-shared";

export const Eng: Local = {
  language: "en",
  app: {
    constants: {
      SafetyCardText: (
        organisation: OrganisationTemplate,
        currentCardTypeId: number
      ) =>
        currentCardTypeId === -1
          ? "card"
          : organisation &&
            organisation.cardTypes &&
            organisation.cardTypes.find(c => c.cardTypeId === currentCardTypeId)
              .cardNameEn,
      SafetyCardTextNeutral: "card",
      CreatedApplication: (statusSetByName: string) =>
        `Application created by ${statusSetByName}`,
      CreatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Application created by ${statusSetByName} and is awaiting attestation by ${attestorName}`,
      CreatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} created by ${statusSetByName}`,
      UpdatedApplication: (statusSetByName: string) =>
        `Application updated by ${statusSetByName}`,
      UpdatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Application updated by ${statusSetByName} and is awaiting attestation by ${attestorName}`,
      UpdatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} updated by ${statusSetByName}`,
      Issued: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Issued at ${statusSetByOrgEmp} by ${statusSetByName}`,
      IssuedOnBehalf: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Issued on behalf of ${statusSetByOrgEmp} by ${statusSetByName}`,
      OrgApprovedCard: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) => `${cardName} created at ${statusSetByOrgEmp} by ${statusSetByName}`,
      OrgApprovedCardOnBehalf: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) =>
        `${cardName} created on behalf of ${statusSetByOrgEmp} by ${statusSetByName}`,
      OrgApprovedApplication: (
        caseManager: string,
        orgName: string,
        cardName: string
      ) =>
        `${cardName} application processed at ${orgName} by ${caseManager} and is waiting to be issued`,
      ReceiptReceived: "Receipt received",
      DoorkeyReserved: "Key reserved",
      OrgRejected: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Rejected at ${statusSetByOrgEmp} by ${statusSetByName}`,
      GoneStale:
        "User has not taken required action, warning about deletion is sent",
      ApplicationCreated: "Application created",
      EmpApproved: "Application attested",
      EmpNotApproved: "Application not attested",
      EmpRejected: "Application rejected by attestor",
      EmpRejectedView: "Application rejected by attestor - ",
      EmpApprovedView: "Application attested - ",
      AwaitingEmpApproval: "Awaiting attestation",
      Shredded: "Shredded",
      ApprovedUser: "Valid user",
      Invited: "Invited",
      InvalidUser: "User not valid",
      Loading: "Loading data. Please wait",
      NoRowsToShow: "Data set empty",
      NoSafetycardsToShow: `No cards found`,
      Filter: "Filter...",
      Equal: "Equals",
      NotEqual: "Does not equal",
      LessThan: "Less than",
      LessThanOrEqual: "Less than or equals",
      GreaterThan: "Greater than",
      GreaterThanOrEqual: "Greater than or equals",
      InRange: "In range",
      ClearFilter: "Clear filter",
      ApplyFilter: "Apply filter",
      StartsWidth: "Starts with",
      EndsWidth: "Ends with",
      Contains: "Contains",
      NotContains: "Does not contain",
      RowGroupColumnsEmptyMessage: "Drag here to set row groups",
      Groups: "Row groups",
      PivotColumnsEmptyMessage: "Drag here to set column labels",
      Pivots: "Column labels",
      Sum: "Sum",
      Count: "Count",
      Min: "Min",
      Max: "Max",
      Average: "Average",
      PinColumn: "Pin column",
      PinLeft: "Pin left",
      PinRight: "Pin right",
      NoPin: "No pin",
      AutosizeThiscolumn: "Autosize this column",
      AutosizeAllColumns: "Autosize all columns",
      ResetColumns: "Reset columns",
      ToolPanel: "Tool panel",
      PivotMode: "Pivot mode",
      ValueColumnsEmptyMessage: "Drag here to aggregate",
      Values: "Values",
      SearchOoo: "Search...",
      SelectAll: "Select all",
      Copy: "Copy",
      CtrlC: "Ctrl+C",
      CopyWithHeaders: "Copy with headers",
      Paste: "Paste",
      CtrlV: "Ctrl+V",
      Export: "Export",
      CsvExport: "Export Csv",
      ExcelExport: "Export Excel",
      Columns: "Columns",
      DoorKeyCreated: "New Door Key Application",
      DoorKeyReciptRecieved: "Receipt received",
      DoorKeyOrganizationApproved: "Approve",
      DoorKeyOrganizationRejected: "Reject"
    }
  },
  routes: EngRoutes,
  shared: EngShared,
  actions: {
    supportTitle: "Contact support",
    supportEmail: "app-support@verico.com",
    area: {
      personell: "personell",
      rights: "user rights",
      access: "access",
      keys: "keys",
      application: "application",
      template: "template",
      attestor: "attestor",
      company: "company",
      stations: "stations",
      user: "user",
      files: "files",
      pendingTasks: "pending tasks",
      pendingScCount: "pending safety card count",
      pendingSC: "pending safety card",
      employer: "emplyer",
      employers: "employers",
      termsOfService: "terms of service",
      person: "person",
      preferredLanguage: "prefferred language",
      maintenanceMessages: "maintenance messages",
      documents: "documents",
      notifications: "notifications",
      image: "image",
      organization: "organization",
      attributeDataType: "attribute data types",
      applications: "applications",
      assets: "safety card assets",
      accessHistory: "access history"
    },
    error: {
      title: "Error",
      uploadError: (area: string) =>
        `An error has occured while uploading the ${area}.`,
      updateError: (area: string, extraText?: string) =>
        `An error has occured while updating the ${area}${
          extraText ? ` for ${extraText}.` : "."
        }`,
      fetchError: (area: string, extraText?: string) =>
        `An error has occured while fetching the ${area}${
          extraText ? ` for ${extraText}.` : "."
        }`,
      sendError: (area: string) =>
        `An error has occured while uploading the ${area}.`,
      deleteError: (area: string, extraText?: string) =>
        `An error has occured while deleting the ${area}${
          extraText ? ` for ${extraText}.` : "."
        }`,
      approveError: (area: string) =>
        `An error has occured while attesting the ${area}.`,
      acceptError: (area: String) =>
        `An error has occured while accepting the ${area}.`,
      saveError: (area: string) =>
        `An error has occured while saving the ${area}.`,
      inviteError: (area: String) =>
        `An error has occured while inviting the ${area}.`,
      checkoutError: (area: string) =>
        `An error has occured while checking out the ${area}.`,
      changeError: (area: string) =>
        `An error has occured while changing the ${area}.`,
      activateError: (area: string) =>
        `An error has occured while activating the ${area}.`,
      createError: (area: string) =>
        `An error has occured while creating the ${area}.`,
      logoutError: "An error has occured while logging out.",
      unknownError: "An unknown error has occured."
    },
    contactSupport:
      "<p>Please reload page and try again. If the error presists please contact support using <a href='mailto:app-support@verico.com'>this email.</a></p>",
    doorKeys: {
      ErrorDiscardKey: "Could not discard key.",
      ErrorSaveDoorKey: "Could not create new key(s).",
      ErrorReceiveDoorKey: "Could not confirm key as received.",
      ErrorUpdateDoorKey: "Could not update door key data.",
      ErrorReserveDoorKey: "Could not reserve key.",
      ErrorGetDoorKeys: "Could not get door keys.",
      ErrorReturnKey: "Could not register key as returned to store",
      ErrorLostKeyModalMessage: "Could not register key as lost.",
      ErrorHistoryMessage: "Could not get door key history.",
      ErrorSignReturnKeyModalMessage: "Could not confirm key as received.",
      ErrorRecallKeyModalMessage: "Could not recall key.",
      ErrorDeleteKeyModalMessage: "Could not delete key.",
      ErrorFetchingPersonOrgDoorKeys: "Could not get door keys.",
      ErrorFetchingPersonEmployerDoorKeys: "Could not get door keys."
    },
    otherCards: {
      ErrorNetworkOperation: "A network error occured."
    }
  }
};
