// import * as React from "react";
import { Local } from "./local";
import {
  OrganisationTemplate
  //  CardType
} from "../../types";
import { NedRoutes } from "./nl-routes";
import { NedShared } from "./nl-shared";

export const Ned: Local = {
  language: "Nee",
  app: {
    constants: {
      SafetyCardText: (
        organisation: OrganisationTemplate,
        currentCardTypeId: number
      ) => {
        return currentCardTypeId === -1
          ? "kort"
          : organisation &&
              organisation.cardTypes &&
              organisation.cardTypes.find(
                c => c.cardTypeId === currentCardTypeId
              ).cardNameNo;
      },
      SafetyCardTextNeutral: "Kort",
      CreatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} gemaakt door ${statusSetByName}`,
      CreatedApplication: (statusSetByName: string) =>
        `Toepassing gemaakt door ${statusSetByName}`,
      CreatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Toepassing gemaakt door ${statusSetByName} en in afwachting van certificering van ${attestorName}`,
      UpdatedApplication: (statusSetByName: string) =>
        `Toepassing bijgewerkt door ${statusSetByName}`,
      UpdatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Toepassing bijgewerkt door ${statusSetByName} en in afwachting van certificering van ${attestorName}`,
      UpdatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} bijgewerkt door ${statusSetByName}`,
      Issued: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Uitgegeven door ${statusSetByOrgEmp} om ${statusSetByName}`,
      IssuedOnBehalf: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Uitgegeven namens ${statusSetByOrgEmp} om ${statusSetByName}`,
      OrgApprovedCard: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) =>
        `${cardName} gemaakt door ${statusSetByOrgEmp} om ${statusSetByName}`,
      OrgApprovedCardOnBehalf: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) =>
        `${cardName} gemaakt namens ${statusSetByOrgEmp} om ${statusSetByName}`,
      OrgApprovedApplication: (
        caseManager: string,
        orgName: string,
        cardName: string
      ) =>
        `${cardName} verwerkt door ${orgName} bij ${caseManager} en wacht op probleem`,
      ReceiptReceived: "Bevestiging ontvangen",
      DoorkeyReserved: "Sleutel gereserveerd",
      OrgRejected: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Geweigerd door ${statusSetByOrgEmp} om ${statusSetByName}`,
      GoneStale:
        "Gebruiker heeft geen actie ondernomen op aanvraag, waarschuwing voor verwijdering verzonden",
      ApplicationCreated: "Applicatie gemaakt",
      EmpApproved: "Aanvraag gecertificeerd",
      EmpNotApproved: "Aanvraag niet gecertificeerd",
      AwaitingEmpApproval: "In afwachting van certificering",
      EmpRejected: "Aanvraag afgewezen voor certificering",
      EmpRejectedView: "Aanvraag afgewezen voor certificering -",
      EmpApprovedView: "Aanvraag gecertificeerd -",
      Shredded: "Versnipperd",
      ApprovedUser: "Geautoriseerde gebruiker",
      Invited: "Uitgenodigd",
      InvalidUser: "Niet gebruiken",
      Loading: "Gegevens laden. Een ogenblik geduld alstublieft",
      NoRowsToShow: "Geen gegevens",
      NoSafetycardsToShow: "Geen kaarten gevonden",
      Filter: "Filter...",
      Equal: "Is gelijk",
      NotEqual: "Is niet gelijk",
      LessThan: "Minder een",
      LessThanOrEqual: "Kleiner of gelijk",
      GreaterThan: "Groter dan",
      GreaterThanOrEqual: "Groter of gelijk",
      InRange: "Tussen",
      ClearFilter: "Verwijder filter",
      ApplyFilter: "Filter",
      StartsWidth: "Beginnend met",
      EndsWidth: "Eindigt met",
      Contains: "Bevat",
      NotContains: "Bevat geen",
      RowGroupColumnsEmptyMessage: "Sleep hierheen om rijgroepen in te stellen",
      Groups: "Rijgroepen",
      PivotColumnsEmptyMessage: "Sleep hierheen om kolomnamen in te stellen",
      Pivots: "DeNaamkolom",
      Sum: "Som",
      Count: "Nummer",
      Min: "Me",
      Max: "Max",
      Average: "Gemiddelde",
      PinColumn: "Kolom bijvoegen",
      PinLeft: "Bevestig links",
      PinRight: "Rechts bijvoegen",
      NoPin: "Niemand gehecht",
      AutosizeThiscolumn: "Automatisch formaat van deze kolom",
      AutosizeAllColumns: "Automatisch formaat op alle kolommen",
      ResetColumns: "Kolommen resetten",
      ToolPanel: "Gereedschap",
      PivotMode: "Draaimodus",
      ValueColumnsEmptyMessage: "Sleep hierheen om te aggregeren",
      Values: "Waarden",
      SearchOoo: "Zoeken ...",
      SelectAll: "Selecteer alles",
      Copy: "Exemplaar",
      CtrlC: "Ctrl + C",
      CopyWithHeaders: "Kopieer met kolomnaam",
      Paste: "Plakken",
      CtrlV: "Ctrl + V",
      Export: "Export",
      CsvExport: "Csv exporteren",
      ExcelExport: "Excel exporteren",
      Columns: "Kolommen",
      DoorKeyCreated: "Nieuwe applicatie voor sleutel",
      DoorKeyReciptRecieved: "Goedkeuren",
      DoorKeyOrganizationApproved: "Aangenomen",
      DoorKeyOrganizationRejected: "Verworpen"
    }
  },
  routes: NedRoutes,
  shared: NedShared,
  actions: {
    supportTitle: "Neem contact op met ondersteuning",
    supportEmail: "App-support@verico.com",
    area: {
      personell: "Personeel",
      rights: "Gebruikersrechten",
      access: "Adganger",
      keys: "Dørnøkler",
      application: "Toepassing",
      template: "Tijden",
      attestor: "Attestantinfo",
      company: "Partij",
      stations: "Stations",
      user: "Gebruiker",
      files: "Bestanden",
      pendingTasks: "In afwachting van taken",
      pendingScCount: "In afwachting van het aantal beveiligingskaarten",
      pendingSC: "In afwachting van beveiligingskaart",
      employer: "Werkgever",
      employers: "Werkgevers",
      termsOfService: "Servicevoorwaarden",
      person: "Persoon",
      preferredLanguage: "Voorkeurstaal",
      maintenanceMessages: "Onderhoudsberichten",
      documents: "Documenten",
      notifications: "Waarschuwingen",
      image: "Afbeelding",
      organization: "Organisatie",
      attributeDataType: "Kenmerk gegevenstypen",
      applications: "Toepassingen",
      assets: "Beveiligingskaart faciliteit",
      accessHistory: "Adgangshistorikk"
    },
    error: {
      title: "Verkeerd",
      uploadError: (area: string) =>
        `Er is een fout opgetreden bij het uploaden van ${area}. `,
      updateError: (area: string, extraText?: string) =>
        `Er is een fout opgetreden tijdens het bijwerken\n        ${area} ${
          extraText ? `voor ${extraText} .` : ". "
        }`,
      fetchError: (area: string, extraText?: string) =>
        `Er is een fout opgetreden tijdens het laden\n      ${area} ${
          extraText ? `voor ${extraText} .` : ". "
        }`,
      sendError: (area: string) =>
        `Er is een fout opgetreden bij het indienen van ${area}.`,
      deleteError: (area: string, extraText?: string) =>
        `Er is een fout opgetreden tijdens het laden\n      ${area} ${
          extraText ? `voor ${extraText} .` : ". "
        }`,
      approveError: (area: string) =>
        `Er is een fout opgetreden bij het verifiëren van ${area}.`,
      acceptError: (area: string) =>
        `Er is een fout opgetreden bij het accepteren van ${area}.`,
      saveError: (area: string) =>
        `Er is een fout opgetreden bij het opslaan van ${area}.`,
      inviteError: (area: string) =>
        `Er is een fout opgetreden bij het uitnodigen van ${area}.`,
      checkoutError: (area: string) =>
        `Er is een fout opgetreden bij het uitchecken van ${area}.`,
      changeError: (area: string) =>
        `Er is een fout opgetreden bij het wijzigen van ${area}.`,
      activateError: (area: string) =>
        `Er is een fout opgetreden bij het activeren van ${area}.`,
      createError: (area: string) =>
        `Er is een fout opgetreden bij het maken van ${area}.`,
      logoutError: "Er is een fout opgetreden tijdens het uitloggen",
      unknownError: "Er is een onbekende fout opgetreden"
    },
    contactSupport:
      "<p> Laad de pagina opnieuw en probeer het opnieuw. Als de fout zich nog steeds voordoet, neem dan contact op met de ondersteuning via <a href=mailto:app-support@verico.com> deze e-mail. </a> </p>",
    doorKeys: {
      ErrorDiscardKey: "Kan sleutel niet weggooien.",
      ErrorSaveDoorKey: "Maken van nieuwe sleutels is mislukt.",
      ErrorReceiveDoorKey: "Kan de ontvangen sleutel niet bevestigen.",
      ErrorUpdateDoorKey: "Bijwerken van sleutelgegevens is mislukt.",
      ErrorReserveDoorKey: "Kon sleutelgegevens niet reserveren.",
      ErrorGetDoorKeys: "Kan sleutels niet ophalen.",
      ErrorReturnKey:
        "Kan sleutel niet registreren die is opgeslagen in opslag.",
      ErrorLostKeyModalMessage: "Kan de verloren sleutel niet registreren.",
      ErrorHistoryMessage: "Kan sleutelgeschiedenis niet ophalen.",
      ErrorSignReturnKeyModalMessage:
        "Kan de ontvangen sleutel niet bevestigen.",
      ErrorRecallKeyModalMessage: "Kon sleutel niet intrekken.",
      ErrorDeleteKeyModalMessage: "Kan sleutel niet verwijderen.",
      ErrorFetchingPersonOrgDoorKeys: "Kan sleutels niet ophalen.",
      ErrorFetchingPersonEmployerDoorKeys: "Kan sleutels niet ophalen."
    },
    otherCards: {
      ErrorNetworkOperation: "Netwerk Error"
    }
  }
};
