import * as React from "react";
import styles from "./loading-spinner.css";

export function LoadingSpinner(props: {
  background?: string;
  small?: boolean;
  xsmall?: boolean;
}) {
  return (
    <div className={styles.container} style={{ background: props.background }}>
      <i
        className={`fa fa-spinner fa-pulse fa-fw ${
          props.small ? `fa-2x` : props.xsmall ? `fa-1x` : `fa-3x`
        }`}
      />
      <span className="sr-only">Loading...</span>
    </div>
  );
}
