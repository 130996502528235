import { errorStore, localizationStore } from "../stores";
import { GridColumnFilter } from "../types";
import * as repo from "./../repo";

export const Other = {
  async getGridFilters(
    orgId: number,
    gridId: string
  ): Promise<GridColumnFilter[]> {
    try {
      // When no filter is created this will return 404 (it did not find it right?)
      const filters = await repo.getFilterGrids(orgId, gridId);
      return filters;
    } catch (e) {
      return [];
    }
  },
  async saveGridFilter(
    orgId: number,
    gridId: string,
    filterData: GridColumnFilter
  ): Promise<GridColumnFilter> {
    const local = localizationStore.currentLocal.actions;
    return errorStore.wrap(
      () => repo.saveGridFilter(orgId, gridId, filterData),
      local.error.title,
      `${local.error.fetchError(local.area.organization)}${
        local.contactSupport
      }`
    );
  },
  async removeGridFilter(orgId: number, filterId: number): Promise<void> {
    const local = localizationStore.currentLocal.actions;
    return errorStore.wrap(
      () => repo.removeGridFilter(orgId, filterId),
      local.error.title,
      `${local.error.fetchError(local.area.organization)}${
        local.contactSupport
      }`
    );
  }
};
