import { observable, action, computed } from "mobx";
import {
  PersonInfo,
  UserIdentity,
  Employer,
  PreferredLanguage,
  OrganisationTemplate,
  OrgPermittoRights,
  OurPermittoRights
} from "./../types";
import { runInAction } from "mobx";

import {
  getOrgAssetsV2,
  getPersonInfo,
  getUserIdentity,
  logOut,
  getOrganisation,
  getEmployer
} from "./../repo";
import { localizationStore } from "./localization";
import { errorStore } from "./error-store";
import { organisationStore } from "./organisation-store";
import { employerStore } from "./employers-store";
import { localStorageStore } from "./local-storage-store";
import { RightsLookup } from "../data-utillities/rights-utils";

const INVALID_RIGHTS_ID = -1;

export class UserStore {
  @observable
  userInfo!: PersonInfo;
  @observable
  userIdentity!: UserIdentity;

  @computed
  get employerRights(): RightsLookup<OurPermittoRights> {
    return new RightsLookup(this.userIdentity.ourPermittoRights, {
      rights: "",
      approver: false,
      organisations: [],
      accessHistory: false,
      accessView: false,
      admin: false,
      id: INVALID_RIGHTS_ID
    });
  }

  @computed
  get organisationsRights(): RightsLookup<OrgPermittoRights> {
    return new RightsLookup(this.userIdentity.orgPermittoRights, {
      safetyCardRights: "",
      issuer: false,
      notificationSchedules: undefined,
      doorkeyAdmin: false,
      projectAdmin: false,
      assetAdmin: false,
      accessHistory: false,
      accessView: false,
      admin: false,
      id: INVALID_RIGHTS_ID,
      orgInsight: []
    });
  }

  organisationInsightRights(
    parentOrg: OrgPermittoRights
  ): RightsLookup<OrgPermittoRights> {
    const insight = parentOrg.orgInsight ? parentOrg.orgInsight : [];

    return new RightsLookup(insight, {
      safetyCardRights: "",
      issuer: false,
      notificationSchedules: undefined,
      doorkeyAdmin: false,
      projectAdmin: false,
      assetAdmin: false,
      accessHistory: false,
      accessView: false,
      admin: false,
      id: INVALID_RIGHTS_ID,
      orgInsight: []
    });
  }

  async initData(): Promise<Boolean> {
    const userRights: UserIdentity = await getUserIdentity();
    const user = await getPersonInfo(userRights.personnelId);
    runInAction("initData", () => {
      this.userIdentity = userRights;
      this.userInfo = user;
    });
    localizationStore.updateCurrentLocal();
    await this.loadOrganiations(userRights);
    await this.loadEmployers(
      this.mergeArraysAndRemoveDuplicates(
        this.userIdentity.ourPermittoRights.map(p => p.id),
        this.userIdentity.issuePermissions.length > 0
          ? [this.userInfo.organizationNo]
          : []
      )
    );
    return true;
  }

  async realodUserRights() {
    const userIdentity = await getUserIdentity();
    this.userIdentity = userIdentity;
  }

  mergeArraysAndRemoveDuplicates = (ar1: number[], ar2: number[]) => {
    const retVal = ar1.concat(ar2);
    return retVal.filter((item, pos) => retVal.indexOf(item) === pos);
  };

  setUserRights(user: UserIdentity) {
    runInAction("setUserRights", () => {
      userStore.userIdentity = user;
    });
  }

  async logOutUser() {
    try {
      await logOut();
      localStorageStore.clear();
      window.history.replaceState({}, "", "/");
      window.location.reload();
    } catch (e) {
      const local = localizationStore.currentLocal.actions;
      errorStore.reportError({
        text: `${local.error.logoutError}${local.contactSupport}`,
        title: local.error.title,
        error: e
      });
    }
  }

  @action
  async setLanguage(language: PreferredLanguage) {
    this.userIdentity.preferences.preferredLanguage =
      language.preferredLanguage;
  }

  async loadOrganiations(userRights: UserIdentity): Promise<void> {
    try {
      const orgsIds = new Set<number>();
      if (userRights) {
        if (Array.isArray(userRights.organisations)) {
          for (const orgId of userRights.organisations) {
            orgsIds.add(orgId);
          }
        }
        if (Array.isArray(userRights.ourPermittoRights)) {
          for (const ourPermitto of userRights.ourPermittoRights)
            for (const orgId of ourPermitto.organisations) {
              orgsIds.add(orgId);
            }
        }

        if (Array.isArray(userRights.orgPermittoRights)) {
          for (const org of userRights.orgPermittoRights) {
            orgsIds.add(org.id);
          }
        }
      }

      const orgs = new Map<number, OrganisationTemplate>();
      const promises: Promise<OrganisationTemplate>[] = [];
      for (const orgId of orgsIds.values()) {
        const promise = getOrganisation(orgId).then(async o => {
          try {
            const nodes = await getOrgAssetsV2(orgId, orgId, false);
            if (nodes.length > 0) {
              o.hasAssets = true;
            } else {
              o.hasAssets = false;
            }
          } catch (e) {
            o.hasAssets = false;
          }
          return o;
        });
        promises.push(promise);
      }

      const done = await Promise.all(promises);
      for (const o of done) {
        orgs.set(o.orgId, o);
      }

      runInAction("loadOrganiations", () => {
        organisationStore.organisations = orgs;
      });
    } catch (e) {
      const local = localizationStore.currentLocal.actions;
      errorStore.reportError({
        text: `${local.error.fetchError(local.area.organization)}${
          local.contactSupport
        }`,
        title: local.error.title,
        error: e
      });

      runInAction("ACTION: loadOrganiations Error", () => {
        organisationStore.organisations = new Map<
          number,
          OrganisationTemplate
        >();
      });
    }
  }

  private async loadEmployers(employerIds: number[]) {
    try {
      if (employerIds.length > 0) {
        if (employerIds.length === 1 && employerIds[0] === -1) {
          return;
        }

        const employers: Employer[] = [];
        for (const employerId of employerIds) {
          employers.push(await getEmployer(employerId));
        }
        runInAction("loadEmployers", () => {
          employerStore.employers = employers;
        });
      }
    } catch (e) {
      const local = localizationStore.currentLocal.actions;
      errorStore.reportError({
        text: `${local.error.fetchError(local.area.company)}${
          local.contactSupport
        }`,
        title: local.error.title,
        error: e
      });
      employerStore.employers = [];
    }
  }
}

export const userStore = new UserStore();
