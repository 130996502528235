import { observable } from "mobx";
import { Employer } from "./../types";

class EmployersStore {
  @observable employers: Employer[];

  constructor() {
    this.employers = [];
  }
}

export const employerStore = new EmployersStore();
