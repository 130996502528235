import {
  Org,
  IssueList,
  Issue,
  ApprovalList,
  Approval,
  CardsList,
  Type,
  Card,
  PersonnelList,
  Person,
  Access,
  Asset,
  AccessLog,
  ApplicationList,
  AssetList,
  AssetMessages,
  ConstructionProjects,
  Documentation,
  DoorKeys,
  DoorkeyTemplates,
  Templates,
  ExternalCards,
  Employers,
  Splash,
  Profile,
  Rights,
  Application,
  Roles
} from "../consts";

export function GetOrganisationIssueListUrl(orgId: number) {
  return Org + orgId + IssueList;
}

export function GetOrganisationIssueUrl(orgId: number, applicationId: number) {
  return Org + orgId + Issue + applicationId;
}

export function GetOrganisationApprovalListUrl(orgId: number) {
  return Org + orgId + ApprovalList;
}

export function GetOrganisationApprovalUrl(
  orgId: number,
  applicationId: number
) {
  return Org + orgId + Approval + applicationId;
}

export function GetOrgApplicationUrl(orgId: number) {
  return Org + orgId + Application;
}

export function GetOrganisationCardsListUrl(orgId: number, typeId: number) {
  return Org + orgId + CardsList + Type + typeId;
}

export function GetOrganisationCardUrl(
  orgId: number,
  typeId: number,
  cardId: number
): string {
  return Org + orgId + CardsList + Type + typeId + Card + cardId;
}

export function GetOrganisationPersonnelListUrl(orgId: number) {
  return Org + orgId + PersonnelList;
}

export function GetOrganisationPersonUrl(orgId: number, personId: number) {
  return Org + orgId + Person + personId;
}

export function GetOrganisationAssetListUrl(orgId: number) {
  return Org + orgId + AssetList;
}

export function GetOrganisationExternalCardsUrl(orgId: number) {
  return Org + orgId + ExternalCards;
}

export function GetOrganisationAssetUrl(orgId: number, assetId: number) {
  return Org + orgId + Asset + assetId;
}
export function GetOrganisationRightsUrl(orgId: number) {
  return Org + orgId + Rights;
}

export function GetOrganisationRolesUrl(orgId: number) {
  return Org + orgId + Roles;
}

export function GetOrganisationTemplatesUrl(orgId: number) {
  return `${Org}${orgId}${Templates}`;
}

export function GetOrganisationDoorKeyTemplatesUrl(orgId: number) {
  return `${Org}${orgId}${DoorkeyTemplates}`;
}

export function GetOrganisationAssetMessagesUrl(orgId: number) {
  return `${Org}${orgId}${AssetMessages}`;
}

export function GetOrganisationPermittoUrl(orgId: number) {
  return Org + orgId + Splash;
}

export function GetOrganisationProfileUrl(orgId: number) {
  return Org + orgId + Profile;
}

export function GetOrganisationAccessUrl(orgId: number) {
  return Org + orgId + Access;
}

export function GetOrganisationAccessLogUrl(orgId: number) {
  return Org + orgId + AccessLog;
}

export function GetOrganisationEmployersUrl(orgId: number) {
  return Org + orgId + Employers;
}

export function GetOrganisationDoorKeysUrl(orgId: number) {
  return `${Org}${orgId}${DoorKeys}`;
}

export function GetOrganisationDoorKeyApplicationsUrl(orgId: number) {
  return `${Org}${orgId}${DoorKeys}${ApplicationList}`;
}

export function GetOrganisationDoorKeyApplicationViewIdUrl(
  orgId: number,
  doorKeyApplicId: number
) {
  return `${Org}${orgId}${DoorKeys}${ApplicationList}${doorKeyApplicId}`;
}

export function GetOrganisationDocumentationUrl(orgId: number) {
  return `${Org}${orgId}${Documentation}`;
}

export function GetOrganisationConstructionProjectsUrl(orgId: number) {
  return `${Org}${orgId}${ConstructionProjects}`;
}
