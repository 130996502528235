import {
  Org,
  OrganisationIdParameter,
  IssueList,
  Issue,
  ApplicationIdParameter,
  ApprovalList,
  Approval,
  CardsList,
  Type,
  TypeIdParameter,
  Card,
  CardIdParameter,
  PersonnelList,
  Person,
  PersonIdParameter,
  Access,
  Asset,
  AssetIdParameter,
  AccessLog,
  ApplicationList,
  AssetList,
  AssetMessages,
  ConstructionProjects,
  Documentation,
  DoorKeys,
  DoorkeyTemplates,
  Templates,
  ExternalCards,
  Employers,
  Splash,
  Profile,
  Rights,
  Roles
} from "../consts";

export function GetOrganisationIssueListRoute() {
  return Org + OrganisationIdParameter + IssueList;
}

export function GetOrganisationIssueRoute() {
  return Org + OrganisationIdParameter + Issue + ApplicationIdParameter;
}

export function GetOrganisationApprovalListRoute() {
  return Org + OrganisationIdParameter + ApprovalList;
}

export function GetOrganisationApprovalRoute() {
  return Org + OrganisationIdParameter + Approval + ApplicationIdParameter;
}

export function GetOrganisationCardsListRoute() {
  return Org + OrganisationIdParameter + CardsList + Type + TypeIdParameter;
}

export function GetOrganisationCardRoute() {
  return (
    Org +
    OrganisationIdParameter +
    CardsList +
    Type +
    TypeIdParameter +
    Card +
    CardIdParameter
  );
}

export function GetOrganisationPersonnelListRoute() {
  return Org + OrganisationIdParameter + PersonnelList;
}

export function GetOrganisationPersonRoute() {
  return Org + OrganisationIdParameter + Person + PersonIdParameter;
}

export function GetOrganisationAssetListRoute() {
  return Org + OrganisationIdParameter + AssetList;
}

export function GetOrganisationExternalCardsRoute() {
  return Org + OrganisationIdParameter + ExternalCards;
}

export function GetOrganisationTemplateRoute() {
  return `${Org}${OrganisationIdParameter}${Templates}`;
}

export function GetOrganisationDoorkeyTemplateRoute() {
  return `${Org}${OrganisationIdParameter}${DoorkeyTemplates}`;
}

export function GetOrganisationAssetMessagesRoute() {
  return `${Org}${OrganisationIdParameter}${AssetMessages}`;
}

export function GetOrganisationAssetRoute() {
  return Org + OrganisationIdParameter + Asset + AssetIdParameter;
}

export function GetOrganisationEmployersRoute() {
  return Org + OrganisationIdParameter + Employers;
}

export function GetOrganisationRightsRoute() {
  return Org + OrganisationIdParameter + Rights;
}

export function GetOrganisationRolesRoute() {
  return Org + OrganisationIdParameter + Roles;
}

export function GetOrganisationPermittoRoute() {
  return Org + OrganisationIdParameter + Splash;
}

export function GetOrganisationProfileRoute() {
  return Org + OrganisationIdParameter + Profile;
}

export function GetOrganisationAccessRoute() {
  return Org + OrganisationIdParameter + Access;
}

export function GetOrganisationAccessLogRoute() {
  return Org + OrganisationIdParameter + AccessLog;
}

export function GetOrganisationDoorKeysRoute() {
  return `${Org}${OrganisationIdParameter}${DoorKeys}`;
}

export function GetOrganisationDoorKeyApplicationsRoute() {
  return `${Org}${OrganisationIdParameter}${DoorKeys}${ApplicationList}`;
}

export function GetOrganisationDoorKeyApplicationViewRoute() {
  return `${Org}${OrganisationIdParameter}${DoorKeys}${ApplicationList}${ApplicationIdParameter}`;
}

export function GetOrganisationDocumentationRoute() {
  return `${Org}${OrganisationIdParameter}${Documentation}`;
}

export function GetOrganisationConstructionProjectsRoute() {
  return `${Org}${OrganisationIdParameter}${ConstructionProjects}`;
}
