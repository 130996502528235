import * as React from "react";
import { LocalShared } from "./local-shared";
import {
  //  OrganisationTemplate,
  CardType
} from "../../types";

export const SweShared: LocalShared = {
  expertiseList: {
    greenStatus: "OK",
    yellowStatus: "Utgår snart",
    redStatus: "Utgånget",
    unknownStatus: "Okänd",
    missingStatus: "Saknas",
    masterGridColumns: {
      status: "Status",
      type: "Typ",
      expire: "Upphöra",
      count: "# dokument",
      requiredBy: "Som krävs av"
    }
  },
  documentations: {
    documentation: "Dokumentation",
    info: "Det finns ingen fil associerad med den här dokumentationen. Dokumentationen består av ett datum och en referens för en möjlig kurs",
    date: "Gäller från:",
    confirmComment: "Dokumentation referens:",
    description: "Valfri kommentar"
  },
  statuslog: {
    header: `Historie`,
    name: "Namn:",
    number: "Telefon:",
    comment: "Kommentar",
    title: "Tittel:",
    email: "Email",
    validFrom: "Gäller från",
    validTo: "Giltig till",
    assetList: "Anläggningslista",
    cardChanged: (cardName: string) => `${cardName} uppdaterat`,
    oldValue: (val: string) => `Gammalt värde: ${val}`,
    newValue: (val: string) => `Nytt värde: ${val}`,
    assetCount: (val: string) => `Antal anläggningar - ${val}`
  },
  batchUpdate: {
    AlertTitle: "Ett fel inträffade",
    AlertText: "Ett fel inträffade när data skulle skickas",
    Cancel: "Avbryt",
    Done: "Gjort"
  },
  admin: {
    rights: {
      ReadAccess: "Läsåtkomst",
      WriteAccess: "Skrivåtkomst",
      PersonPlaceHolder: "Sök efter person att lägga till",
      Name: "Namn",
      EmptySearch: "Inga träffar",
      RemoveHeader: "Ta bort",
      Live: "Live",
      Daily: "Dagliga",
      Weekly: "Vecka",
      Monthly: "Månatliga"
    }
  },
  asset: {
    popup: {
      EmptyStation: "Ingen på stationen",
      AltImg: "Anläggningsbild",
      NameCol: "Namn",
      AreaCol: "Området",
      EntriesCountCol: "#",
      TableFooter: "Översikt av stationens personal",
      EntryText: "Checkade in människor",
      MissingInfo: "Saknad info",
      ShowInfo: "Visa info",
      HideInfo: "Dölj info"
    },
    userpopup: {
      travelingTo: "Reser till",
      registeredAt: "Registrerad på",
      lastKnownPosition: "Senast kända position :",
      timestamp: "Senast rapporterade position kl",
      username: "Användare",
      station: "station"
    }
  },
  buttons: {
    ConfirmTitle: "Vill du avsluta  inlämningen?",
    ConfirmText: "Ändringar kommer inte att sparas.",
    Back: "Tillbaka",
    changeSplitAlignment: "Anteckningar/karta"
  },
  dialog: {
    Cancel: "Avbryt",
    NoComment: "Inga kommentarer",
    Yes: "Ja",
    No: "Nej",
    Confirm: "Bekräfta",
    InputText: "Kommentar",
    Ok: "Ok",
    DeleteRightRowTitle: "Ta bort rad",
    DeleteRightRowText: "Detta kommer att ta bort rad och rättigheter på den"
  },
  editIndBar: {
    Required: "Obligatoriskt fält"
  },
  employer: {
    Accesses: "Tillgångar",
    Safetycards: (cardName: string) => `Våra ${cardName.toLowerCase()}`,
    Personnel: "Vår personal",
    NewApplication: "Ny ansökan",
    Applications: "Våra ansökningar",
    Profile: "Vår profil",
    UserAccess: "Användaråtkomst",
    OrgPlaceHolder: "Organisationsnummer",
    EmployerSearchPlaceHolder: "Sök efter ett företag",
    EmployerPlaceHolder: "Företagsnamn"
  },
  error: {
    HeaderText: "Nätverksfel.",
    Details: "Visa fel information",
    Retry: "Försök igen"
  },
  templates: {
    card: {
      card: {
        Section: "Avsnitt",
        SubSection: "Undersektion",
        Header: (cardName: string) => `${cardName} - Mall`
      },
      safetyCardAttribute: {
        requiredForCard: "Obligatoriskt värde",
        type: "Typ",
        true: "JA",
        false: "NEJ",
        dependanciesToolTip: "Krav",
        dependancyNotFound: attributeID => {
          return `Den obligatoriska attributet med ID ${attributeID} kunde inte hittas i den aktuella mallen.`;
        },
        required: "Krävs"
      }
    }
  },
  file: {
    handle: {
      DocReqOwner: "Som krävs av",
      ColRequired: "Krävs",
      Expires: "Upphör",
      ColFileName: "Filnamn",
      ColAction: "Åtgärder",
      ColReference: "Referens",
      ColOrg: "Anläggningsägare",
      ColOptComment: "Valfri kommentar",
      ColComment: "Kommentar",
      ColName: "Namn",
      ColDate: "Gäller från",
      ColValidDays: "Giltiga dagar",
      ColDateUploaded: "Uppladdade",
      ColDocumentRequirement: "Dokumentkrav",
      ConfirmDeleteTitle: "Ta bort dokument(ar)",
      ConfirmDeleteText: "Detta tar bort det här dokumentet",
      MyFiles: "Min dokumentation",
      NoFile: "No fil",
      DocumentsNotFound: "Inga dokument hittades",
      Choose: "Välj",
      FileToBigTitle: "Fel",
      FileToBigText: "Filen är för stor",
      DropZoneText: "Dra och släpp ett dokument här för att ladda upp",
      SaveDemands: "Välj en fil och en beskrivning innan du sparar",
      Upload: "Uppladdning",
      AddDocumentation: "Lägg till dokumentation",
      AddNewDocumentationWithoutFile: "Lägg till dokumentation utan fil",
      DeleteFile: "Ta bort fil(ar)",
      CannotDelete:
        "Det går inte att ta bort den här filen eftersom den används i en ansökan",
      ShowFile: "Preview fil",
      DocumentRequirements: "Dokumentkrav",
      RequirementCovered: "Krav som täcks",
      SelectDocumentRequirement: "Välj dokumenttyp",
      SelectDate: "Välj ett startdatum för den giltiga perioden",
      SelectDocument: "Ladda upp dokument för",
      SelectDocumentRequirementHeader: "Ladda upp dokumentation",
      Cancel: "Avbryt",
      Select: "Välj dokumentation",
      SelectNoFile: "Spara dokumentation",
      SelectDatePlaceholder: "Välj datum",
      UploadedBy: "Uppladdad av",
      confirmationComment: "Dokumentations referens",
      confirmationCommentMissing: "Dokumentations referens krävs",
      isUploading: "Uppladdning...",
      requiredBy: "Som krävs av",
      UploadInfoHeader: (reqName: string) => {
        return (
          <React.Fragment>
            Här kan du ladda upp <i>{reqName}.</i>
            <br />
            <br />
          </React.Fragment>
        );
      },
      UploadInfoText: (reqName: string, reqValid: number) => {
        let expiresText = `${reqValid} dagar.`;
        if (reqValid > 365) {
          const years = reqValid / 365;
          const rounded = Math.round(years * 10) / 10;
          expiresText = `${rounded} år.`;
        }

        return (
          <React.Fragment>
            <i>{reqName}</i> är giltig för {expiresText}
            <br />
            Det krävs att du anger det första giltiga datumet nedan (
            <i>detta kan vara det datum då en kurs slutfördes</i>
            ).
          </React.Fragment>
        );
      },
      AllowConfirmationWithoutFileHelpText:
        "Om det inte finns någon fysisk dokumentation, (gemensamma kurser, etc.) är det möjligt att registrera dokumentation utan att ladda upp dokument. Om så är fallet, datum och en referens för kursen måste tillhandahållas.",
      NoFileExistsGridLabel: "--Ingen fil--",
      editImageButton: "Redigera",
      MissingDate: "Datum saknas",
      MissingDocRef: "Dokumentations referens saknas"
    }
  },
  header: {
    Personnel: "Personal",
    ApproveApplication: "Godkänn ansökan",
    IssueApplication: (cardName: string) =>
      `Utfärda ${cardName.toLowerCase()} från ansökan`,
    AdminAsset: "Administrera Anläggning",
    ConfirmUser: "Bekräfta ny Användare",
    AccessHistory: "Tillgångshistorik",
    MyAccessHistory: "Min Tillgångshistorik",
    OurAccessHistory: "Vår Tillgångshistorik",
    Accesses: "Tillgångar",
    MyAccess: "Mina Tillgångar",
    MyCards: (cardName: string) => `Mina ${cardName}`,
    OurCards: (cardName: string) => `Våra ${cardName}`,
    OurPersonnel: "Vår personal",
    OurProflie: "Vår profil",
    OurOtherCards: "Våra HSE-kort",
    OurAccess: "Vår åtkomst karta",
    MyProfile: "Min profil",
    MyApplications: "Mina ansökningar",
    MyApplication: "Min ansökan",
    OurApplications: "Våra applikationer",
    OurApplication: "Vår ansökan",
    IssuedApplications: "Utfärdade ansökningar",
    ConfirmedApplications: "Bekräfta ansökan",
    ApplicationsForApproval: "Ansökningar som väntar på godkännande",
    ApplicaitonsForIssue: (cardName: string) =>
      `${cardName} väntar på att utfärdas`,
    UserRights: "Användarrättigheter - Organisation",
    GroupRights: "Våra användarrättigheter",
    MyPermitto: "Min Permitto",
    ServiceProvider: (empName: string) =>
      empName ? `Tjänstleverantör - ${empName}` : `Tjänsteleverantör`,
    AssetOwner: (orgName: string) => `Anläggningsägare - ${orgName}`,
    Owner: "Anläggningsägare",
    ConfirmApplication: "Godkänn ansökan för",
    Employers: "Leverantörer",
    MyOtherCards: "HSE-kort",
    AdminOtherCards: "HSE-kort",
    DoorkeyApplications: "Nyckel ansökan",
    OurDoorkeyApplications: "Våra Nyckel ansökningar",
    MyDoorkeyApplications: "Mina Nyckel ansökningar",
    Doorkeys: "Nycklar",
    OurDoorkeys: "Våra nycklar",
    MyDoorkeys: "Mina nycklar",
    Documentation: "Dokumentation",
    OurDocumentation: "Vår dokumentation",
    Projects: "Projekt",
    OrganisationTemplates: "Mallar för organisation",
    OrganisationDoorkeyTemplates: "Mallar för organisation",
    OrganisationAssetMessages: "Anläggningsmeddelanden"
  },
  numberLookup: {
    UserExists: (
      firstName: string,
      lastName: string,
      organizationName?: string
    ) => {
      return organizationName
        ? `${firstName} ${lastName} är redan en användare under ${organizationName}`
        : `${firstName} ${lastName} är redan en användare`;
    },
    Invitation: (firstName: string, lastName: string) => {
      return `${firstName} ${lastName} är redan en användare i Permitto, du kan inbjuda användaren nedan.`;
    },
    UserNotFound: "Användaren hittades inte",
    InvalidPhone: "Det angivna telefonnumret är ogiltigt",
    FirstName: "Förnamn",
    LastName: "Efternamn",
    Company: "Företag",
    CreateUser: "Skapa ny användare",
    Invite: "Inbjuda",
    Ok: "Ok",
    NumberPlaceHolder: "Ange mobiltelefonnummer",
    Cancel: "Avbryt",
    SubmitMobileNr: "Ange mobiltelefonnumret som hör till den nya användaren"
  },
  newApplication: {
    Warning: "Varning",
    ConfirmClose: "Är du säker på att du vill avbryta?",
    SafetyCardApplication: (cardName: string) => `${cardName} ansökan`,
    CloseApplicationTitle: "Avbryt ansökan",
    CloseApplicationText:
      "Är du säker på att du vill avbryta och ignorera denna här ansökan?",
    Cancel: "Avbryt",
    DoorkeyApplicationTitle: "Ny nyckel ansökan"
  },
  newCardNoApplic: {
    CreateNewCard: "Skapa ett nytt kort",
    CloseApplicationTitle: "Avbryt skapande",
    CloseApplicationText:
      "Är du säker på att du vill avbryta och ignorera skapande av kortet?",
    Cancel: "Avbryt",
    Warning: "Varning",
    ConfirmClose: "Är du säker på att du vill avbryta?"
  },
  person: {
    FirstName: "Förnamn",
    LastName: "Efternamn",
    Email: "Email",
    Mobile: "Mobil",
    Company: "Företag",
    Back: "Tillbaka",
    Edit: "Redigera",
    EditMode: "Redigeringsläge",
    Save: "Spara",
    Cancel: "Avbryt",
    ChangesMade: {
      text: "Om du fortsätter kommer dina ändringar att gå förlorade",
      title: "Ändringar gjorda. Vill du fortsätta?"
    }
  },
  personDocuments: {
    Header: "",
    ErrorTitle: "Fel",
    ErrorMessage: "Hämtningen av dokumentationen misslyckades",
    NoDocumentsMessage: (companyName: string, cardName: string) =>
      `Denna person har inga ${cardName.toLowerCase()}  anslutna till ${companyName} och dokumentationen är därför inte tillgänglig`
  },
  personList: {
    AlertTitle: "Ett fel inträffade",
    AlertText: "Det gick inte att skicka inbjudningar",
    Cancel: "Avbryt",
    SendInvitations: (numOfInvitations: number) => {
      return numOfInvitations === 1
        ? `Skicka ${numOfInvitations} inbjudan`
        : `Skicka ${numOfInvitations} inbjudningar`;
    },
    Send: (fromOrg: boolean) =>
      fromOrg ? `Skicka SMS till mottagaren` : `Skicka e-post till mottagaren`,
    Invitations: "inbjudningar",
    InvitationsSent: "inbjudningar som skickas",
    Done: "Gjort",
    FirstName: "Förnamn",
    LastName: "Efternamn",
    FullName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    Email: "Email",
    Mobile: "Mobil",
    Company: "Företag",
    Invited: (firstName: string, lastName: string) =>
      `${firstName} ${lastName} inbjuden`,
    InvitedTo: (firstName: string, lastName: string, orgName: string) =>
      `${firstName} ${lastName} inbjuden till ${orgName}`,
    NewPersonHeader: "Skapa ny person",
    Save: "Spara",
    CreateNew: "Skapa nya",
    Invite: (numOfPersons: number) => {
      return numOfPersons === 1
        ? `Inbjud ${numOfPersons} person`
        : `Inbjud ${numOfPersons} persons`;
    },
    Persons: "Följeslagare",
    LastNamePlaceholder: "Filtrera efter efternamn",
    Open: "Öppna",
    SelectAPerson: "Välj en person",
    SelectJustOnePerson: "Välj bara en person",
    SendInvitationsDescription:
      "Är du säker på att du vill bjuda in den valda personalen till organisationen?"
  },
  safetycard: {
    Application: "Ansökan",
    Add: "Add",
    InputPlaceholder: "Inte fylld",
    Shredded: "Strimlad",
    Expired: "Löpt ut",
    Sign: (cardName: string) =>
      `Klicka för att signera ${cardName.toLowerCase()} mottaget`,
    NotSigned: "Inte bekräftat mottaget",
    SafetyCard: (cardName: string) => cardName,
    Card: (cardId: number, cardName: string) => `${cardName}: ${cardId}`,
    Access: "Tilgångslistan",
    CardOwner: (cardName: string) => `${cardName} innehavare`,
    Issued: "Utfärdad",
    Signed: "Bekräftat mottaget",
    ValidFrom: "Gäller från",
    DatePlaceholder: "Välj datum",
    ReceiptReceived: "Bekräftat mottaget",
    ValidTo: "Giltig till",
    UploadDate: "Uppladdningsdatum",
    ValidFromWithColon: "Gäller från:",
    ValidToWithColon: "Giltig till:",
    UnknownDataType: "Okänd datatyp",
    Attestor: (name: string, email: string) => `Attestor: ${name} (${email})`,
    LoadingAssets: "Laddar anläggningar...",
    ChangedBy: "Endrat av ",
    ReadyForIssuing: "Redo for å utfärdas",
    NotFilled: "Inte fylld",
    EmployerName: "Arbetsgivare",
    HeadOfOperations: "Operations manager",
    IssuedOnBehalfOf: (issuerName: string, headOfOperations: string) =>
      `Utfärdas av ${issuerName} på uppdrag av Operations manager ${headOfOperations}`,
    postIssueSections: {
      clickToApprove: "Klicka för att godkänna",
      missingTrainingHeader: "Krav som måste uppfyllas",
      missingTrainingHeaderForAccess: "Krav som måste uppfyllas för åtkomst",
      missingConditionalTrainingHeader: "Krav relaterade till åtkomst",
      requirmentsNeedToBeFulfilled:
        "Detta är krav som måste uppfyllas innan kortet är giltigt",
      requirmentsNeedToBeFulfilledForAsset:
        "Detta är krav som måste uppfyllas för att registrera åtkomst till vissa av anläggningarna på detta kort",
      requirmentsNeedToBeFulfilledThisAsset:
        "Detta är krav som måste uppfyllas för att registrera åtkomst till den här tillgången"
    }
  },
  safetyCardApplication: {
    GivesAccessConflict:
      "Vissa anläggningar i din ansökan har det här fältet som ett krav för tilgång. Om du tar bort det här fältet kommer du inte att få tillgång till denna anläggning.",
    TreeHelpFirst:
      "Här kan du välja vad du vill ansöka om tillgång till. Du kan antingen välja en grupp av anläggningar eller du kan expandera trädet och välj en enskild anläggning.",
    TreeHelpSecond:
      "Du väljer en anläggning genom att markera den i kryssrutan",
    TreeHelpThird:
      "Du kan bläddra i trädet genom att dubbelklicka eller klicka på pilen",
    TreeHelpFourth: "Visa demo",
    TreeAttestorHelpText:
      "Här kan du se vilka åtkomsträttigheter ansökan har ansökt om. Du kan navigera i trädet genom att klicka på pilarna.",
    EqualAsset: "= Tilgång",
    EqualGroup: "= Grupp",
    WhereToApply: "Var vill du ha tillgång?",
    AppliedTo: "Begär tillgång til",
    FileToBigTitle: "Fel",
    FileToBigText: "Filen är för stor",
    Cancel: "Avbryt",
    MyFiles: "Min dokumentation",
    FileOwner: " sina filer",
    ApplicationApproved: "Ansökan bestyrkad",
    CloseWindow: "Du kan nu stänga sidan",
    ApplicationDenied: "Ansökan avvisades",
    WrongNumber: "Telefonnumret måste vara ett 8-siffrigt nummer",
    ApproverMissing: "Du måste ange information om attestor.",
    ValidEmail: "Inte en giltig e-postadress",
    ValidEmailHeader: "Attestors e-postadress är inte giltig",
    CardOwner: "Kortägare",
    ValidFrom: (date: string) => `Gäller från ${date}`,
    SelectDatePlaceholder: "Välj datum",
    ValidTo: "Giltig till",
    Next: "Nästa",
    SearchForLastName: "Filtrera efter efternamn",
    SelectFile: "Vänligen bifoga dokumentation",
    UploadFile: "Ladda upp fil",
    UploadDate: "Uppladdad",
    ChangeFile: "Ändra fil",
    NewFile: "Ny fil",
    OpenFile: "Öppna fil",
    NoFile: "Ingen fil",
    Loading: "Laddar data. Snälla vänta",
    Missing: "Måste fyllas i",
    UnknownDataType: "Okänd datatyp",
    ReadyToSendWithAttestor: (cardName: string) => (
      <span>
        {`Innan ansökningen kan skickas, måste det bestyrkas. Syftet med detta är att bekräfta att den sökande är kvalificerad att inneha ett ${cardName.toLowerCase()}  med de begärda auktorisationer. Attestor behöver inte vara en Permitto-användare.`}
        <br />
        <b>
          {`Attestor är sällan samma person som ${cardName.toLowerCase()} sökande.`}
        </b>
      </span>
    ),
    ReadyToSend:
      "Ansökningen är giltigt, Lägg till en kommentar och klicka på Skicka",
    WillNotBeSetBeforeAttested: (cardName: string) =>
      `Varning: ansökningen skickas inte till utfärdaren ${cardName.toLowerCase()} innan det intygas. Vänligen kontrollera att e-postmeddelandet är korrekt.`,
    FillAttestorInfo: "Vänligen fyll i information om attestor",
    AddPlaceholder: "Lägg till kommentar",
    AttestorNameTitle: "Attestor namn",
    AttestorEmailTitle: "Attestor email",
    AttestorFirstnameTitle: "Attestor förnamn",
    AttestorLastnameTitle: "Attestor efternamn",
    AttestorTitleTitle: "Attestor titel",
    AttestorPhoneTitle: "Attestor telefonerna",
    Back: "Tillbaka",
    Done: "Gjort",
    Send: "Send",
    ApplyForCard: (cardName: string) =>
      `Var vill du ansöka om ett ${cardName.toLowerCase()}?`,
    IssueCard: (cardName: string) =>
      `Var vill du utfärda ett ${cardName.toLowerCase()}?`,
    ApplyForWhichCard: (cardName: string) =>
      `Vilken typ av ${cardName} ansöker du om?`,
    AllMissing: "Vissa fält saknas",
    ForPerson: "Ansökningsinformation",
    AuthorizationReadOnly: "Tillstånd",
    Authorization: (numAssets: number) =>
      numAssets > 0
        ? `Välj behörigheter (auktoriseringar till stationer som valts tidigare är redan markerade)`
        : `Välj behörigheter`,
    ApplicationSummary: "Applikationsinformation",
    Valid: "Giltig:",
    ApplicationForm: "Ansökningsblankett",
    Access: "Tilgångslistan",
    AssetLowerCase: (numAssets: number) => `${numAssets} anläggningar`,
    AuthorizationField: "Behörighet",
    Submit: "Skicka",
    FillInAllMarked: "Fyll i alla fält markerade med *",
    FillInAccessLogg: "Lägg till stationer som du vill ha åtkomst till",
    FillInDates: "Välj två giltiga datum",
    Reject: "Avvisa",
    Approve: "Godkänna",
    AttestorInfo: "Attestor",
    ApplicantInfoIssued: "Information om kortinnehavaren",
    AttestorInfoMessage1: (
      fistName: string,
      lastName: string,
      orgName: string,
      cardName: string
    ) =>
      `${fistName} ${lastName} har skapat en ansökan om ${cardName.toLowerCase()} hos ${orgName}.`,
    AttestorInfoMessage2: (orgName: string) =>
      `${orgName} kräver att alla ansökningar ska bestyrkas innan de lämnas in.`,
    AttestorInfoMessage3: (
      cardName: string
    ) => `Du är vald som attestor för denna ansökan. Syftet med detta steg är att få en bekräftelse från någon som är associerad med den sökande som kan bekräfta att den sökande är kvalificerad att inneha ett ${cardName.toLowerCase()} med de auktoriseringar som ansökan gäller.
      Som attestor ombeds du också att se till att ansökan har fyllts i korrekt och att den nödvändiga dokumentationen är giltig och bifogas ansökan.
      När du intygar denna ansökan kommer din kontaktinformation att läggas till i ansökningen så att administratören kan kontakta dig om några frågor skulle uppstå. Sökanden har registrerat följande information om dig:`,
    AttestorInfoMessage4:
      "Kontrollera att informationen som registrerats om dig är korrekt och gör de nödvändiga ändringarna.",
    Attest: "Bestyrkande",
    AttestApplication: "Bestyrka ansökan",
    StartAttesting: "Börja bestyrkande",
    AttestorMessage1: "Du kan nu välja att intyga eller avvisa ansökningen.",
    AttestorMessage2: (fistName: string, lastName: string) =>
      `Om du avvisar ansökningen kommer det att skickas tillbaka till ${fistName} ${lastName} som kan göra ändringar och återutfärda för bestyrkande.`,
    AttestorMessage3: (
      orgName: string
    ) => `Om du avvisar ansökningen kommer det att skickas tillbaka till ${orgName} för bearbetning.
      I båda fallen kan du lägga till en kommentar i ansökningen.`,
    AttestorConfirmSane: (cardName: string) =>
      `Genom att intyga denna ansökan bekräftar du också att sökanden är kvalificerad att inneha ett ${cardName.toLowerCase()}.`,
    Applicant: "Sökande:",
    Issuer: (cardName: string) => `${cardName} utfärdare`,
    ValidFromDate: "Gäller från:",
    ValidToDate: "Giltig till:",
    Comment: "Kommentar",
    ContinueApplication: "Fortsätta",
    CardNumber: (cardName: string) => `${cardName} nummer`,
    Previewtemplate: (cardName: string) => `Visa ${cardName.toLowerCase()}`,
    ApplyFromExistingCardHeaderText: (cardName: string) =>
      `Välj ${cardName.toLowerCase()} du vill kopiera till denna här ansökan. \nNotera att dokumentationen fortfarande måste fyllas i.`,
    CreateNewSafetyCard: "Tom ansökan",
    CopyExistingSafetyCard: (cardName: string) =>
      `Kopiera befintliga ${cardName.toLowerCase()} i denna här ansökan`,
    FileValidFrom: "Gäller från",
    FileValidTo: "Giltig till",
    FileUploadComment: "Kommentar",
    FileName: "Filnamn",
    ExpireMessage:
      "Varning: Detta dokument löper ut under den period säkerhetskortet har ansökts.",
    Retry: "Igen",
    cardTypeName: (cardTemplate: CardType, createdfromApplic: boolean) =>
      createdfromApplic
        ? `${cardTemplate.cardNameEn} - Skapad från applikationen`
        : cardTemplate.cardNameEn,
    ApplicantInfo: "Sökande information",
    Application: "Ansökan",
    ApplicInfoNoApplic: "Fält från applikationsmall",
    ApplicationAttestorInfo: (cardName: string) =>
      `Under finner du ${cardName}et det söker om och ansökan till det här kortet. Vänligen se igjennom at ansökan ser förnuftig ut.`,
    ApplicantName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    SafetyCard: "Säkerhetskort",
    SafetyCardStatusLog: "Ändringshistorik för ansökan",
    SafetyCardStatusLogNoApplic: (cardTemplate: CardType) =>
      `Historik för ändring av ${cardTemplate.cardNameEn}`,
    AttestorEmailSent: "E-post skickas igen",
    AttestorEmailSentInfo: "E-post har skickas igen till attestor",
    AttestorLink: "Attestorlink",
    CopyToClipboard: "Kopiera till urklipp",
    Status: "Status:",
    Name: "Namn:",
    LastApplicChangeDate: "Senaste ändring:",
    CompleteAttestation: "Slutföra bestyrkande",
    SelfAttesting:
      "Jag vill vara listad som attestor för den här applikationen",
    Reference: "Referens",
    StatusExpired: "Utgått",
    StatusValid: "OK",
    ExpireDate: "Utgångsdatum",
    SelectedDocument: "Valt dokument",
    NoneFileJustRef: "Ingen fil, endast referens",
    DropdownHeader: "Lagrade dokument",
    IssueOnBehalfOfDisabledTooltipText:
      "Anläggningens ägare har inaktiverat möjligheten att söka på andras vägnar"
  },
  doorkeyCardApplication: {
    SendPageText:
      "Din nyckel ansökan är klar att skickas. Om du vill lägga till en kommentar till din ansökan kan detta läggas till nedan.",
    AddPlaceholder: "Lägg till kommentar",
    MissingFeilds: "Du måste ange en kommentar och klicka på skicka.",
    Back: "Tillbaka",
    Done: "Gjort",
    Send: "Send",
    ForPerson: "Ansökningsinformation",
    Next: "Nästa",
    ApplyForCard: "Vid vilken anläggningsägare vill du ansöka om nyckel?",
    ApplicationForm: "Ansökningsblankett",
    ApplicationSummary: "Applikationsinformation",
    Submit: "Skicka",
    Applicant: "Sökande:",
    Issuer: (cardName: string) => `${cardName} utfärdare`,
    doorkeyCardApplicationText: "Ansökan om nyckel"
  },
  cardIssueNoApplication: {
    CardOwner: "Kort innehavare",
    FindByMobile:
      "Ange mobilnumret för den person som kortet ska utfärdas till",
    UserNotFound: (inOrg: boolean) =>
      inOrg
        ? "Användaren hittades i permitto, men i en annan organisation. Du kan bara utfärda kort till användare i din egen organisation."
        : "Användaren hittades inte, fyll i fälten för att skapa en ny användare. OBS: Se till att telefonnumret och e-postadressen är korrekt.",
    UserFound: "Användaren hittades",
    FirstName: "Förnamn:",
    LastName: "Efternamn:",
    Mobil: "Mobil:",
    Email: "Email:",
    Next: "Nästa",
    Applicant: "Sökande:",
    AuthorizationField: "Behörighet",
    Issuer: (cardName: string) => `${cardName} utfärdare`,
    Valid: "Giltig:",
    Submit: "Skicka",
    ApplicationSummary: "Applikationsinformation",
    ApplicationForm: "Ansökningsblankett",
    ReadyToSend:
      "Ansökningen är giltigt. Lägg till en kommentar och klicka på Skicka",
    AddPlaceholder: "Lägg till kommentar",
    Back: "Tillbaka",
    Done: "Gjort",
    Send: "Utfärda",
    Retry: "Igen",
    CreateNewPersonHeader: (
      cardholderFistName: string,
      cardholderLastName: string
    ) => `${cardholderFistName} ${cardholderLastName} 
      kommer med detta att skapas som en ny användare i Permitto`,
    CreateNewPerson: (cardholderFistName: string, cardholderLastName: string) =>
      `${cardholderFistName} ${cardholderLastName} 
        kommer nu att skapas som en ny användare, och du kan sedan fortsätta processen att utfärda kort till dem. Vill du fortsätta?`
  },
  safetyCardAssets: {
    EnterAssetLocation:
      "Klicka på karta för att ställa in koordinaterna för anläggningen",
    EnterAssetName: "Ange ett namn",
    EnterAssetDescription: "Ange en beskrivning",
    Type: "Typ",
    Description: "Beskrivning",
    AddType: (message: string) => `Lägg till ny typ: "${message}"`,
    Cancel: "Avbryt",
    Save: "Spara",
    SelectNode: "Välj nod",
    Add: "Add",
    Delete: "Ta bort",
    Or: (message: string) => `${message} eller `,
    RequireAuthorization: (message: string, cardName: string) =>
      `${message} krävs på ${cardName.toLowerCase()} för att få tillgång till denna station`,
    AccessList: "Åtkomstlistan",
    Access: "Åtkomstlistan",
    SelectAll: "Markera alla",
    SelectNone: "Välj ingen",
    AddAll: "Lägga till alla",
    RemoveAll: "Ta bort alla",
    Asset: "Anläggning",
    AssetNode: "Grupp",
    SelectType: "Välj typ",
    SelectModelType: "Välj modell typ",
    NotAvailable: "Detta val är inte tillgängligt på denna nivå"
  },
  safetyCardList: {
    Kpi: {
      Active: "Aktiva kort",
      Expired: "Förfallna kort",
      ExpiresIn30Days: "Kort giltiga inom 30 dagar",
      RecieptNotRecieved: "Kort som inte erkänns"
    },
    ShredButtonText: "Strimla",
    ShredAndCreateButtonText: "Strimla och skapa nya",
    ShredAndCreateText: (cardName: string) =>
      `Vill du strimla och skapa ett nytt ${cardName.toLowerCase()}, eller bara strimla ${cardName.toLowerCase()}?`,
    ShredAndCreateTitle: (cardName: string) =>
      `Strimla och skapa nya ${cardName.toLowerCase()}?`,
    ShouldIssueTitle: "Kort skapad",
    ShouldIssue: (cardName: string) =>
      `Vill du utfärda ${cardName.toLowerCase()} nu?`,
    RenewedFromCard: (
      cardId: number,
      firstName: string,
      lastName: string,
      cardName: string
    ) =>
      `Förnyas från ${cardName.toLowerCase()} nummer ${cardId} av ${firstName} ${lastName}`,
    NamePlaceholder: "Sök på namn",
    CopyCardHeader: (cardName: string) =>
      `Kopiera ${cardName.toLowerCase()} från: `,
    CardNumberPlaceholder: (cardName: string) =>
      `Sök på ${cardName.toLowerCase()} nummer`,
    LastNamePlaceholder: "Filtrera efter efternamn",
    Proceed: "Fortsätta",
    Warning: "Varning",
    ValidFrom: "Gäller från",
    ValidTo: "Giltig till",
    Missing: "Giltigt från tidigare en giltig till",
    Comment: "Skriv kommentar",
    CardTitle: (cardName: string) => `${cardName} skapat`,
    CardText: (cardName: string) =>
      `${cardName} skapat men inte utfärdat. Utfärdande rättigheter krävs för att utfärda ${cardName.toLowerCase()}`,
    CardWarning: (cardName: string) =>
      `${cardName} skapas inte. Alla obligatoriska attribut anges inte`,
    Save: "Spara",
    Cancel: "Avbryt",
    ErrorCreatingCardShredded: (cardId: number, cardName: string) =>
      `Ett fel har uppstått vid skapandet av ${cardName.toLowerCase()} ${cardId}. ${cardName.toLowerCase()} är strimlad, men ett nytt ${cardName.toLowerCase()} skapades inte.`,
    ErrorTitle: "Fel",
    ErrorShreddingCard: (cardId: number, cardName: string) =>
      `Ett fel har uppstått och ${cardName.toLowerCase()} ${cardId} ble inte strimlad`,
    SetDate: (numCards: number, cardName: string) =>
      numCards === 1
        ? `Välj start-och slutdatum på ${numCards} ${cardName}`
        : numCards === 2
        ? `Välj start-och slutdatum på båda ${cardName}`
        : `Välj start-och slutdatum på alla ${numCards} ${cardName}`,
    SecurityCard: (cardName: string) => cardName,
    TheSecurityCard: (cardName: string) => `${cardName.toLowerCase()}et`,
    Update: "Förnya",
    CardRenewed: (cardName: string) => `${cardName.toLowerCase()} förnyas.`,
    NumCardRenewed: (numCards: number, issued: boolean, cardName: string) =>
      issued
        ? `${numCards} ${cardName.toLowerCase()} förnyas och utfärdas.`
        : `${numCards} ${cardName.toLowerCase()} förnyat, men inte utfärdat.`,
    Done: "Gjort",
    Renew: "Förnya",
    RenewCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? `Förnya ${numCards} ${cardName.toLowerCase()}`
        : `Förnya ${cardName.toLowerCase()}`,
    RenewNotSelected: (cardName: string) =>
      `Välj ${cardName.toLowerCase()} du vill förnya från listan nedan`,
    ReceiptReceived: "Mottaget kvitto",
    IssuedBy: "Utfärdat av",
    Issued: "Utfärdat",
    ShreddedBy: "Strimlad av",
    ShreddedTime: "Tid strimlad",
    CreateNewSecurityCard: (cardName: string) =>
      `Skapa ny ${cardName.toLowerCase()}`,
    CreateNew: "Skapa ny",
    Card: (cardName: string) => cardName,
    Shred: "Strimla",
    ShredCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? `Strimla ${numCards} ${cardName.toLowerCase()}`
        : `Strimla ${cardName.toLowerCase()}`,
    ShredCardsAllTypes: (numCards: number) =>
      numCards > 0 ? `Strimla ${numCards} kort` : `Strimla kort`,
    ShredSelectedCards: (cardName: string) =>
      `Välj ${cardName.toLowerCase()} du vill strimla ur listan nedan`,
    AlreadyShreddedTitle: "Redan strimlad",
    AlreadyShreddedText: (cardName: string) =>
      `Den valda ${cardName.toLocaleLowerCase()} är redan strimlad `,
    AlreadyShreddedTextAllTypes: `Den valda kort är redan strimlad `,
    ShredAlertText: (multipleCards: boolean) =>
      multipleCards
        ? `Detta kommer att strimla de valda korten`
        : `Detta kommer att strimla det valda kortet`,
    ShredAlertTitle: (cardName: string) => `Strimla ${cardName.toLowerCase()}`,
    ShredAlertTitleAllTypes: `Strimla kort`,
    Filter: "Filter...",
    ShowValid: (cardName: string) => `Visa giltiga ${cardName.toLowerCase()}s`,
    ShowShredded: (cardName: string) =>
      `Visa strimla ${cardName.toLowerCase()}`,
    Open: "Öppna",
    NeedToSelectCard: (cardName: string) =>
      `Välj ett ${cardName.toLowerCase()}`,
    NeedToSelectOneCard: (cardName: string) =>
      `Välj bara ett ${cardName.toLowerCase()}`,
    RenewInfoTextWithShred: (cardName: string) =>
      `De valda ${cardName.toLowerCase()} kommer att strimlas och nya skapas.`,
    RenewInfoTextNoShred: (cardName: string) =>
      `De valda ${cardName.toLowerCase()} kommer att förnyas, men de gamla kommer inte att bliva strimlad.`,
    RenewShouldShred: (cardName: string) => `Strimla ${cardName} vid förnyelse`,
    InfoLegendFirst: (cardName: string) =>
      `${cardName.toLowerCase()} markerade med `,
    InfoLegendLast: "har upphört att gälla.",
    InfoLegendShredded: (cardName: string) =>
      `Visar strimlade ${cardName.toLowerCase()}`,
    FilterByAssets: "Filtrera efter tilgång",
    Actions: "Åtgärder",
    ShredNumExpired: (num: number): string =>
      `Strimla alla utgångna kort (${num})`,
    ShredAll: "Strimla alla",
    ShredExpiredInProgress: "Strimling av kort pågår...",
    ShredExpiredDone: "Strimling av kort avslutade"
  },
  safetyCardEdit: {
    EditCard: (cardName: string) => `Redigera ${cardName.toLowerCase()}`,
    SaveCard: (cardName: string) => `Spara ${cardName.toLowerCase()}`,
    Cancel: "Avbryt"
  },
  sidebar: {
    Admin: "Admin",
    AdminAsset: "Administrera anläggning",
    UserControl: "Användarrättigheter",
    Roles: "Roller",
    Templates: "Redigera mallar",
    ConstructionProjects: "Projekt",
    AssetMessages: "Anläggningsmeddelanden",
    DoorKeyTemplates: "Nyckelapplikationsmall"
  },
  signReceipt: {
    PinNotValid: "PIN-kod är inte giltig",
    ErrorRequestingPinTitle: "Fel",
    ErrorRequestingPinText:
      "Det gick inte att bekräfta PIN-koden. Vänligen försök igen eller kontakta supporten",
    ErrorValidatingPinTitle: "Fel",
    ErrorValidatingPinText: "Det gick inte att skicka PIN-kod",
    ConfirmReceivingCard: (cardId: number, orgName: string, cardName: string) =>
      `Jag bekräftar att ha fått ${cardName} med nummer ${cardId} utfärdat av ${orgName}.`,
    ConfirmReceivingCardSign: (
      cardId: number,
      orgName: string,
      cardName: string
    ) =>
      `Jag bekräftar att ha fått ${cardName} med nummer ${cardId} .\nSignera för ${cardName.toLowerCase()} utfärdat av ${orgName}.`,
    ConfirmReceivingDoorKey: "Bekräfta mottagen nyckel",
    GetPinSms: "Få PIN-kod via SMS",
    GetDoorKeyPinSms: "Skicka PIN-kod",
    VerifyDoorKeyPinSms: "Bekräfta",
    WritePin: "Skriv PIN-kod",
    SignSecurityCard: (cardName: string) => `Signera ${cardName.toLowerCase()}`,
    modalHeader: "Kvitton för mottagen nyckel",
    modalHeaderCard: "Kvitton för mottagna kort",
    Close: "Stänga",
    SuccessText: "Kortet är undertecknat",
    DoorkeySuccessText: "Nyckel är undertecknat"
  },
  singleAsset: {
    Error: "Fel",
    ErrorFetchingAsset: "Det gick inte att hämta anläggning",
    MissingAssetAttri: "Vissa attribut för anläggningen saknas",
    ErrorPutStation: "Ett fel har uppstått vid laddning av station",
    ErrorPutImg: "Det gick inte att skicka bilden",
    ErrorPutAsset: "Det gick inte att skicka anläggningsobjekt",
    WrongFileFormat: "Fel filformat",
    DropzoneText: "Klicka eller dra och släpp en fil här för att ladda upp den",
    DeleteImage: "Ta bort bild",
    Warning: "Varning",
    DoYouWantToDeleteImg: "Då raderas bilden",
    Zones: "Zoner",
    AddZone: "Lägg till zon",
    DeleteZone: "Ta bort zon",
    Latitude: "Latitude *",
    SelectOnMap: "Välj på karta",
    ClickToSetCoordinates:
      "Klicka på kartan för att ställa in koordinaterna för denna anläggning",
    Longitude: "Longitud *",
    Name: "Namn *",
    NamePlaceholder: "Sök på namn",
    Missing: "Saknad text",
    ExportId: "Integration ID",
    ExportIdPlaceholder: "Integration ID",
    AccessRequirment: "Tilgångskrav",
    Role: (numRoles: number) =>
      numRoles === 1 ? `${numRoles} roll` : `${numRoles} roller`,
    RequiredRolesForAccess: "En av följande roller krävs för att få åtkomst.",
    LeaderRequirment: "Krav til följeslagare",
    RequiredRolesForLeader:
      "En av följande roller krävs för att vara en följeslagare",
    maxEntries: "Maximalt antal samtidiga incheckningar",
    maxEntriesPlaceholder:
      "Ange antalet samtidiga incheckningar för anläggningar",
    maxEntriesBlock: "Blockera efter maximalt antal samtidiga incheckningar",
    maxEntriesUpdate: "Uppdatera max antal samtidiga incheckningar",
    maxEntryInputWarning: "Ange giltigt nummer som maximalt antal poster",
    infoLabel: "Info"
  },
  singlePerson: {
    personalInformation: "Personuppgifter",
    FirstName: "Förnamn",
    Missing: "Det här fältet kan inte lämnas tomt",
    LastName: "Efternamn",
    Mobile: "Mobil",
    Digits: "Endast siffror är tillåtna",
    Email: "E-post",
    ValidEmail: "Inte en giltig e-postadress",
    Company: "Företag",
    Active: "Aktiva",
    Shredded: "Strimlad",
    SafetyCards: "Säkerhetskort",
    Documents: "Dokument"
  },
  personSafetycards: {
    SafetyCards: (cardName: string) => `${cardName}`,
    SelectToOpen: (cardName: string) =>
      `Välj ett ${cardName.toLowerCase()} för att öppna`,
    Open: "Öppna",
    ShowShredded: (cardName: string) =>
      `Visa strimlade ${cardName.toLowerCase()}`,
    InfoLegendFirst: (cardName: string) =>
      `${cardName.toLowerCase()} markerade med `,
    InfoLegendLast: "har upphört att gälla.",
    ShowValid: (cardName: string) => `Visa giltiga ${cardName.toLowerCase()}`
  },
  currentUser: {
    LoggedIn: "Inloggad som:",
    LogOut: "Logga ut",
    PrivacyPolicy: "Sekretesspolicy"
  },
  columnDefs: {
    BirthDate: "Födelsedatum",
    PermittoId: "Permitto-ID",
    CardNr: "Kortnummer",
    FirstName: "Förnamn",
    LastName: "Efternamn",
    Mobile: "Mobil",
    EmployerName: "Företag",
    ValidFrom: "Gäller från",
    ValidTo: "Giltig till",
    Status: "Status",
    ShreddedBy: "Strimlad av",
    ShreddedTime: "Tid strimlad",
    PersonnelId: "Id",
    Email: "Email",
    Company: "Företag",
    Organisation: "Organisation",
    OrganizationNo: "Organisationsnummer",
    InvitedDate: "Inbjudan e-post skickad",
    AssetName: "Anläggning/projekt",
    AssetArea: "Område",
    AssetOwner: "Anläggningsägare",
    CheckOut: "Utcheckning",
    CheckIn: "Incheckning",
    ExpectedTimeToLeave: "Förväntad utcheckning",
    EntryCount: "Antal personer",
    EntryNames: "Personer",
    EntryNote: "Anmärkning",
    RoleName: "Roll",
    EntryNoteBy: "Anmärkning av",
    CheckoutBy: "Utcheckad av",
    IssuedBy: "Utfärdat av",
    IsApplication: "Är ansökan",
    ReceiptReceived: "Mottaget kvitto",
    Message: "Meddelande",
    InfoType: "Meddelandeformat",
    EventType: "Visa meddelande när",
    ShowMsgFrom: "Visa från datum",
    ShowMsgTo: "Visa till datum",
    EnteredBy: "Anges av",
    OtherCardType: "Korttyp",
    OtherCardTypeDesc: "Kort typ beskrivning",
    ProjectName: "Projektnamn",
    ProjectConnectedToAsset: "Förknippas med växten",
    ProjectReq: "Tilgångskrav",
    ProjectSupplier: "Projekt leverantör",
    ProjectStart: "Start datum",
    ProjectEnd: "Slutdatum",
    ProjectReference: "Integration ID",
    AllVenors: "Alla leverantörer",
    CardHolder: "Kortinnehavaren",
    CardDetails: "Kortuppgifter",
    CardAttribute: "Kortattribut",
    CardHolderDoc: "Kortinnehavarens dokumentation",
    BlockAutoCheckout: "Inaktiverar automatisk utcheckning",
    ProjectNumber: "Projekt nr",
    ProjectLead: "Projektledare",
    ExternalId: "external-id",
    CardType: "Korttyp"
  },
  columnAttachmentDetailDefs: {
    fileId: "Bilaga ID",
    fileName: "Namn",
    attachments: "Bilagor vid registreringen",
    gridEmptytext: "Inga bilagor"
  },
  customDateComponent: {
    clear: "Ta bort filter"
  },
  accessLog: {
    fromDatePickerDate: "Från-datum",
    toDatePickerDate: "Till-datum",
    digitalProjectEntry: "",
    digitalProjectEntryTitle: "",
    digitalProjectEntryAddress: "",
    digitalProjectEntryClient: "",
    enableFilter: "",
    disableFilter: "",
    from: "",
    to: "",
    export: "Export"
  },
  dateValidation: {
    validDates: "Valda datum är inte giltiga",
    beforeToday:
      "De valda datumen är ogiltiga. Från-datum kan inte vara före dagens datum.",
    toBeforeFrom:
      "De valda datumen är ogiltiga. Till-datum måste vara efter från-datum."
  },
  assetMessagesModal: {
    repeats: "Upprepad",
    modalHeader: "Projekt",
    modalSave: "Spara",
    modalClose: "Nära",
    infoDesc:
      "Det här meddelandet visas för den anläggning som valts i trädvyn. Om du väljer en anläggningsgruppa visas meddelandet för alla anläggningar som hör till den gruppen. Du måste ange för vilka datum meddelandet ska vara aktivt. Om till-datum är tom kommer meddelandet att visas tills det raderas. När det är aktivt visas meddelandet automatiskt i Permitto-appen för alla användare som besöker de valda anläggningar (erna).",
    groupIcon: "Grupp",
    assetIcon: "Anläggning",
    selectedAsset: "Vald anläggning",
    selectAsset: "Välj en anläggning",
    messageType: "Meddelandeformat",
    messageText: "Meddelandetext",
    fromDate: "Visa meddelande från",
    toDate: "Visa meddelande till",
    messageEventType: "Visa meddelande när",
    messagePlaceholder: "meddelandetext...",
    datePlaceholder: "Välj ett datum",
    assetMessageDiabledTooltip: (asset: boolean, message: boolean) =>
      !asset
        ? "Anläggning/Grupp är inte inställd"
        : !message
        ? "Meddelandet har inte ställts in"
        : ""
  },
  otherCards: {
    openCard: "Öppna",
    addCard: "Lägg till kort",
    deleteCard: "Ta bort kort",
    filterPlaceHolder: "Sök på efternamn",
    save: "Spara",
    cancel: "Avbryt",
    close: "Nära",
    selectCardType: "Välj korttyp",
    cardType: "Korttyp",
    cardNumber: "Kortnummer",
    cardHolder: "Kortinnehavaren",
    added: "Tillade",
    addFrontImage: "Lägg till front bild",
    addBackImage: "Lägg till bakre bild",
    selectDate: "Välj datum",
    validTo: "Giltig till",
    validFrom: "Giltig från",
    birthDate: "Födelsedatum",
    editImageApplyChanges: "Tillämpa ändringar",
    editImageDiscardChanges: "Kassera ändringar",
    editImageRotateLeft: "Vänster",
    editImageRotateRight: "Höger",
    editImageButton: "Redigera",
    editImageHeading: "Redigera bild",
    editImageZoomMinLabel: "Minimum",
    editImageZoomMaxLabel: "Maximal",
    editImageZoomControlLabel: "Zoom",
    modalEditHeader: "Redigera kort",
    modalNewHeader: "Lägg till nytt kort",
    modalViewHeader: "Kortuppgifter",
    editButtonLabel: "Redigera",
    deleteButtonLabel: "Ta bort",
    noImageFound: "Ingen bild hittades",
    enterCardNumber: "Ange kortnummer",
    cardNumberNotFilled: "Kortnumret är inte ifyllt"
  },
  selectPerson: {
    proceedBtnLabel: "Fortsätta",
    filterPlaceholder: "Efternamn"
  },
  languageSelector: {
    Nor: "Norska",
    Eng: "Engelska",
    Sve: "Svenska",
    Ned: "Dutch"
  },
  updateVersion: {
    preRefreshText: "En ny version är tillgänglig.",
    refreshTextBtn: "Ladda",
    postRefreshText: "om sidan."
  },
  maintenanceMessages: {
    title: "titel",
    author: "Författare",
    message: "Meddelande"
  },
  assetMap: {
    polygon: {
      draw: "Rita en polygon",
      cancel: "Rita en polygon",
      finish: "Finish",
      undo: "Radera sista punkten",
      delete: "Radera",
      deleteDisabled: "Inga lager att ta bort",
      modal: {
        delete: {
          title: "Radera polygon",
          subTitle: "Detta tar bort polygonen"
        }
      },
      tooltip: {
        start: "Klicka för att lägga till punkt till polygon",
        continue: "Klicka för att lägga till ny koordinat",
        stop: "Klicka på den första punkten för att slutföra polygon"
      }
    }
  },
  customToolPanel: {
    title: "Sparade filter",
    saveFilterButton: "Spara filter",
    saveFilterModal: {
      title: "Spara filter",
      subtitle: "Lägg till ett namn",
      inputPlaceholder: "Lägg till ett namn här",
      saveButton: "Spara filter"
    },
    deleteFilterModal: {
      title: "Radera filter",
      subtitle: ""
    }
  },
  jungleMap: {
    firstPara: "Du hittar kursen E-Learning ",
    linkText: "vid den här länken",
    secondPara: "När du har slutfört kursen visas detta här",
    buttonTitle: "Uppdatera",
    footerText:
      "Om du har slutfört kursen E-Learning och den inte visas automatiskt nedan kan du klicka på uppdatera ovan"
  },
  genericWords: {
    from: "från",
    phone: "telefon",
    since: "eftersom",
    change: "byta"
  }
};
