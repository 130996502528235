import { appConfig } from "./../config";
import {
  OtherCard,
  OtherCardType,
  OtherCardTypeAndDates,
  Documentation
} from "./../types";
import { getJson, postJson, getImageBlobUrl } from "./fetches";
import { joinUrlParams, UrlParamArg } from "./helpers";

const PERSONNEL_BASE_URL = appConfig.baseUrl + appConfig.serviceName;

function joinPersonnelUrl(
  personnelId: number,
  endPoint: string,
  urlParams?: UrlParamArg
): string {
  const newEndpoint = endPoint.length > 0 ? `/${endPoint}` : "";
  return `${PERSONNEL_BASE_URL}/personnel/${personnelId}${newEndpoint}${joinUrlParams(
    urlParams
  )}`;
}

export async function getPersonnelCards(
  personnelId: number
): Promise<OtherCard[]> {
  return getJson<OtherCard[]>(
    joinPersonnelUrl(personnelId, "othercards"),
    "getPersonnelCards"
  );
}

export async function getPersonnelCardTypes(
  personnelId: number
): Promise<OtherCardType[]> {
  return getJson<OtherCardType[]>(
    joinPersonnelUrl(personnelId, "othercards/types"),
    "getPersonnelCardTypes"
  );
}

export async function createPersonnelCard(
  personnelId: number,
  card: OtherCardTypeAndDates
): Promise<OtherCard> {
  return postJson<OtherCard>(
    joinPersonnelUrl(personnelId, "othercards"),
    JSON.stringify(card),
    "createPersonnelCard"
  );
}

export async function fetchAttachments(
  personnelId: number
): Promise<Documentation[]> {
  return postJson<Documentation[]>(
    joinPersonnelUrl(personnelId, "attachments/refresh"),
    JSON.stringify({}),
    "lookupAttachments"
  );
}

export async function fetchPhoto(
    personnelId: number,
    photoHash: string
): Promise<string> {
  const url = joinPersonnelUrl(personnelId, `photo?hash=${photoHash}`);
  return getImageBlobUrl(url, "fetchPhoto");
}
