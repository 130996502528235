import * as React from "react";
import { LocalRoutes } from "./local-routes";
import { CardType } from "../../types";

export const EngRoutes: LocalRoutes = {
  auth: {
    inputPIN: (byEmail: boolean) =>
      `Submit PIN received in ${byEmail ? "email" : "SMS"}`,
    sendByEmail: "Send PIN by Email",
    rememberMe: "Remember me",
    regUser: "Register new user",
    login: "Login",
    errorInputPhone: "Input phone number",
    errorSMSSent: "SMS already sent. Try again later",
    noUserFound: "No user found for the given number",
    unknownError: "Unknown error. Try reloading the page",
    errorInputPIN: "Input pin code",
    errorWrongPIN: "Wrong pin code",
    errorMultipleWrongPIN: "Wrong pin code too many times, please try again",
    errorPinMustBeNumber: "Pin code can only contain numbers",
    accessedByPhone: {
      title: "Warning",
      description:
        "This website is not optimized for mobile phones. There is a Permitto mobile application that is available for download.",
      continueWithout: "Continue anyway"
    },
    ssoHelpText: "Or login with",
    ssoNotAvailable: "Single Sign On not available for your employer",
    ssoBackButton: "Back to login"
  },
  attest: {
    alreadyAttestedMessage: (message: string, name: string, datetime: string) =>
      `${message} ${datetime} by ${name}`,
    approvedMessage: "Application was approved",
    rejectMessage: "Application was rejected"
  },
  admin: {
    applicationApprove: {
      Application: "Application",
      ApplicantInfoHeader: "Applicant",
      Approve: "Approve",
      ApproveWithoutAttestation: "Approve without attestation",
      ApprovedWithoutAttestation: "Approved without attestation. Comment: ",
      ChangeAttestor: "Change attestor",
      ApproveCard: (cardName: string) =>
        `Approve ${cardName.toLocaleLowerCase()}`,
      ApproveCardWithoutAttestation: (cardName: string) =>
        `Approve ${cardName.toLocaleLowerCase()} without attestation`,
      Issued: "Issued",
      Reject: "Reject",
      Delete: "Delete",
      DeleteApplicationTitle: "Delete application",
      DeleteApplicationText: "This will permanently delete this application",
      RejectApplication: "Reject application",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} has no attached application. Rejection will shred the ${cardName.toLocaleLowerCase()}.\nComment:`,
      EditApplication: "Edit application",
      ResendAttestor: "Resend",
      GetAttestorLink: "Get attestor link"
    },
    applicationIssue: {
      CardNumber: (cardName: string) => `${cardName} number`,
      ApplicantInfoHeader: "Applicant",
      Approve: "Approve",
      ApproveApplication: "Approve application",
      Dismiss: "Dismiss",
      Issued: "Issue",
      IssuedCard: (cardName: string) => `Issued ${cardName.toLowerCase()}`,
      Reject: "Reject",
      RejectApplication: "Reject application",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} has no attached application. Rejection will shred the ${cardName.toLowerCase()}.\nComment:`,
      EditApplication: "Edit application"
    },
    applicationListForApproval: {
      Open: "Open",
      NoApplication: "No applications ready to be approved",
      ApproveApplications: (numApplications: number) =>
        numApplications === 1
          ? `Approve ${numApplications} application`
          : `Approve ${numApplications} applications`,
      ApproveApplicationsDescription:
        "Are you sure you want to approve the selected applications?",
      ApplicationsApproved: "applications approved",
      DeleteApplications: (numApplications: number) =>
        numApplications === 1
          ? `Delete ${numApplications} application`
          : `Delete ${numApplications} applications`,
      DeleteApplicationsDescription:
        "Are you sure you want to delete the selected applications?",
      ApplicationsDeleted: "applications deleted",
      Comment: "Comment",
      Action: "Action required",
      CardType: "Card type"
    },
    applicationListForIssue: {
      Open: "Open",
      NoApplication: (cardName: string) => `No ${cardName}s ready to be issued`,
      IssueCards: (numCards: number, cardName: string) =>
        numCards === 1
          ? `Issue ${numCards} ${cardName.toLowerCase()}`
          : `Issue ${numCards} ${cardName.toLowerCase()}s`,
      AlertTitle: "An error occured",
      AlertText: (cardName: string) =>
        `An error occured while issuing ${cardName}s`,
      CardsIssued: (cardName: string) => `${cardName.toLowerCase()} issued`,
      NumCardsIssued: (numCards: number, cardName: string) =>
        numCards === 1
          ? `${numCards} ${cardName.toLowerCase()} issued.`
          : `${numCards} ${cardName.toLowerCase()}s issued.`,
      Done: "Done",
      Cancel: "Cancel",
      IssueCardsDescription: (cardName: string) =>
        `Are you sure you want to issue the selected ${cardName.toLowerCase()}s?`,
      Comment: "Comment"
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName} number`,
      EditMode: "Edit mode",
      Save: "Save",
      Cancel: "Cancel",
      Back: "Back",
      Edit: "Edit",
      CanNotEdit: (cardName: string) =>
        `Cannot edit a ${cardName.toLowerCase()} with status received`,
      Empty: "",
      LiveEditWarning:
        "This card has already been issued. The user will therefore receive an email summarizing the changes you make. Do you want to continue?",
      SavingCardInProgress: "Saving card, please wait",
      Shred: "Shred",
      IsShredded: (cardName: string) => `${cardName} is already shredded`,
      AreUSure: "Are you sure?",
      SureToShred: (cardName: string) =>
        `Are you sure you want to shred this ${cardName.toLowerCase()}?`,
      ShredButtonText: "Shred",
      ShredAndCreateButtonText: "Shred and create new",
      ShredAndCreateTitle: (cardName: string) =>
        `Shred ${cardName.toLowerCase()}`,
      CreateNewSecurityCard: (cardName: string) =>
        `Create new ${cardName.toLowerCase()}`,
      ReNewCard: (cardName: string) => `Renew ${cardName.toLowerCase()}`,
      RenewDisable: (cardName: string) => `${cardName} is not shredded`,
      PrintCard: (cardName: string) => `Print ${cardName}`,
      ShredAlertTitle: (cardName: string) => `Shred ${cardName}`,
      ShredAlertText: (cardName: string) => `This will shred the ${cardName}`,
      ShredBeforeRenewTitle: (cardName: string) => `Renew ${cardName}`,
      ShredBeforeRenewText: (cardName: string) =>
        `This will shred the ${cardName} before being renewed`
    },
    splashView: {
      Permissions: "Asset Entries",
      PermHistory: "Asset Entries History",
      SafetyCard: (cardName: string) => cardName,
      Personnel: "Personnel",
      Applications: "Applications Needing Approval",
      Issued: (cardName: string) => `${cardName} to be issued`,
      SysAdm: "System Admin",
      Employers: "Suppliers",
      OtherCards: "HSE Cards",
      DoorKeys: "Door Keys",
      Documentation: "Documentation",
      DoorKeyApplications: "Door Key Applications"
    },
    asset: {
      newModal: {
        header: (parent: string) =>
          parent ? `Create new zone under ${parent}` : `Create new asset`,
        Save: "Save",
        Cancel: "Cancel"
      },
      admin: {
        Back: "Back",
        Edit: "Edit",
        Header: "",
        Save: "Save",
        Cancel: "Cancel"
      },
      adminList: {
        Description: "Description",
        Type: "Type",
        ShowDetails: "Show details",
        CreateNew: "Create new",
        Search: "Search",
        Asset: "Asset",
        AssetNode: "Group",
        Edit: "Edit",
        Delete: "Delete",
        AddChild: "Add child",
        Save: "Save",
        Cancel: "Cancel",
        AddTopNode: "Add top node",
        DeleteAssetAlertTitle: "Delete asset",
        DeleteAssetAlertText: "This will permanently delete asset"
      }
    },
    employerRights: {
      Admin: "Access",
      UserControl: "User access control",
      UserApprover: (cardName: string) =>
        `Confirm ${cardName.toLowerCase()} application`,
      UserApproverInfo: (cardName: string) =>
        `Allows user to attest ${cardName.toLowerCase()} applications.`,
      UserControlInfo:
        "Allows user to edit user rights for this service provider. \nAllows user to check out personnel registered as inside an asset."
    },
    orgRights: {
      HeaderOrgAdmin: (cardName: string) =>
        `Personnel/${cardName.toLowerCase()}`,
      HeaderOrgAdminInfo: (cardName: string) =>
        `Read access = Allows user to read data in the personnel and ${cardName.toLowerCase()} modules.  \nWrite access= Allows user to read and edit data in the personnel and ${cardName.toLowerCase()} modules in addition to approve ${cardName.toLowerCase()} applications.`,
      HeaderOrgAccess: "Access/Access history",
      HeaderUserControl: "User administration",
      HeaderUserControlInfo:
        "Allows user to edit user rights for this organisation.\nWARNING: This is the top level of access in the system and should be granted to a few selected users.",
      HeaderUserIssuer: (cardName: string) => `Issue ${cardName.toLowerCase()}`,
      HeaderDoorkeyAdmin: "Door Key Admin",
      HeaderDoorkeyAdminInfo:
        "Allows user to edit door keys for this organisation.",
      HeaderProjectAdmin: "Project Admin",
      HeaderProjectAdminInfo:
        "Allows user to edit projects for this organisation.",
      HeaderUserIssuerInfo: (cardName: string) =>
        `Allows user to issue ${cardName.toLowerCase()}.`,
      HeaderAccessHistory: "Access history",
      HeaderAccessHistoryInfo:
        "Allows user to see the access history for assets.",
      HeaderAccessView: "Access",
      HeaderAccessViewInfo:
        "Allows user to see all current entries to assets.\nAllows user to check out personnel registered as inside an asset.",
      HeaderApproverSchedule: "Approver Notifications",
      HeaderApproverScheduleInfo: (cardName: string) =>
        `Subscribes the user to notifications relating to new ${cardName.toLowerCase()} applications.`,
      HeaderIssuerSchedule: "Issuer Notifications",
      HeaderIssuerScheduleInfo: (cardName: string) =>
        `Subscribes the user to notifications relating to ${cardName.toLowerCase()}s ready for issuing.`,
      HeaderEntrySchedule: "Entry Notifications",
      HeaderAssetAdminInfo:
        "Allows user to manage assets and asset messages for this asset owner",
      HeaderEntryScheduleInfo:
        'This role allows a user to subscribe to alerts in connection with. check-in and check-out of facilities. A user can specify the days and time intervals they want to be notified. When an in / out registration takes place within this interval, the user will receive an email notifying them of this. In addition, you can choose to be notified when a user has been inside a facility for a given period of time, this is stated in the field "Hours before notification"',
      InfoHeader: "Info: ",
      InfoClose: "Close",
      EditInstuctions: "Double click a cell to edit a permission",
      HeaderExistingUser: "Existing User",
      HeaderExistingUserAlert: "Person already in user list",
      AlertPersonAttriComboTitle: "Entry Already Exists!",
      AlertPersonAttriComboText:
        "This person & attribute combination already exists in the list.",
      AlertAllComboExistsTitle: "Duplicate Entry!",
      AlertAllComboExistsText:
        "All valid person & attribute combination already exists in the list.",
      EditHeaderLabel: "Double click a cell to change attribute",
      HeaderAssetAdmin: "Asset Admin",
      FromTime: "From",
      ToTime: "To",
      HoursBefore: "Hours Before Reminder",
      Monday: "Monday",
      Thuesday: "Thuesday",
      Wednesday: "Wednesday",
      Thursday: "Thursday",
      Friday: "Friday",
      Saturday: "Saturday",
      Sunday: "Sunday",
      InvalidValueTooltip: "Invalid Value",
      SearchForPerson: "Select person"
    },
    roles: {
      columndefs: {
        Role: "Approve Training",
        SafetyCardAttribute: "Safetycard Attribute"
      },
      EditHeaderLabel: "Double click a cell to change attribute",
      SelectAttriPlaceHolder: "Select Attribute",
      ApproveAll: "Approve all"
    },
    employers: {
      columndefs: {
        Address: "Address",
        Country: "Country",
        Contact: "Contact",
        Name: "Name",
        OrganizationNo: "Organization no",
        ContactEmail: "Contact email",
        ContactPhone: "Contact phone",
        NumActiveCards: "Num active cards",
        NumShreddedCards: "Num shredded cards",
        OrgId: "Org id",
        PersonnelCount: "Personnel count"
      },
      UpdateBtn: "Edit selected",
      CreateBtn: "Add new",
      DeleteBtn: "Delete",
      ModalUpdateHeader: "Update",
      ModalCreateHeader: "Create",
      ModalSave: "Ok",
      ModalCancel: "Cancel",
      AlertTitle: "Warning",
      InvalidEmployer: "Form not submitted. Invalid employer.",
      AutoCompleteTitle: "Company",
      AddEmployerHeader: "Add supplier",
      AddEmployer: (emp: string, orgName: string) =>
        `${emp} is not a supplier to ${orgName}. \nDo you want to add ${emp} as a supplier to ${orgName}`,
      EmployerAlreadyFound: "The supplier is already in the list",
      sendMail: "Send e-mail to contact person"
    },
    constructionProjects: {
      delete: "Delete",
      edit: "Edit",
      add: "Add",
      deleteTitle: "Delete project",
      deleteText: "This will permanently delete the project",
      modalHeader: "Project",
      modalSave: "Save",
      modalClose: "Close",
      projectName: "Project Name",
      projectNumber: "Project number",
      projectAddress: "Address",
      constructionClient: "Project owner",
      projectLead: "Project manger",
      namePlaceholder: "Name",
      startDate: "Project Start Date",
      endDate: "Project End Date",
      datePlaceholder: "Date",
      accessCardType: "Card Types for Access",
      lat: "Lat",
      lng: "Lng",
      selectOnMapPlaceholder: "Enter coordinate or click on map",
      clickToSetCoordinates: "Invalid coordinates",
      referencePlaceholder: "Integration ID",
      externalReference: "Integration ID",
      allVendors: "Available for",
      checkAllVendors: "All Vendors",
      checkSomeVendors: "Selected Vendors",
      latLngRequiredMessage: "Longitude and latitude required.",
      latLngValidateMessage: "Add valid longitude and latitude values.",
      latLimitationMessage: "Latitude should between -85 to +85",
      lngLimitationMessage: "Longitude should between -180 to +180",
      projectNameRequiredMessage: "Project name required.",
      ProjectHasAssets: "Project has connected asset(s)",
      Ok: "Ok",
      Clear: "Clear",
      addNewNode: "Add new asset",
      projecPostionSameAsAsset: "Project has same position as asset",
      maxEntries: "Restrict number of entries in asset at the same time",
      maxEntriesPlaceholder: "Enter max entry count for project",
      maxEntriesBlock:
        "Restriction is hard limit (User not able to register if reached)",
      maxEntryInputWarning: "Enter valid number as a max entry count",
      activeProjBtnLbl: "Show active projects",
      finishedProjBtnLbl: "Show finished projects"
    },
    entries: {
      newOperationalMsg: "New Message",
      operationalMsgHeader: "Operational Messages",
      showOneTimeMsg: "Show one time messages",
      showRepeatingMsg: "Show repeating messages",
      regUserBtn: "Register User",
      regTravelBtn: "Register Travel",
      modalHeader: "Register User",
      modalTravelHeader: "Register Travel",
      selectPerson: "Select Authorized Person",
      selectProject: "Select Project",
      selectSource: "Select Assets/Projects",
      selectFollowers: "Add people(s) escorted into asset/project",
      selectAsset: "Select Asset",
      project: "Projects",
      assetTree: "Assets",
      submit: "Check In",
      confirm: "Close",
      emptyPerson: "None Authorized Persons where found!",
      statusText: (name: string) => `Checking in ${name} completed`,
      statusFailText: (name: string) => `Checking in ${name} failed`,
      statusTextTraveling: (name: string) => `${name} is registered on travel`,
      back: "Back",
      proceed: "Proceed",
      choosenPerson: "Selected Person",
      choosenAsset: "Selected Asset",
      projectPlaceholder: "Search in project names",
      followersInputLabel: 'Enter names seperated by comma ","',
      columnDefs: {
        delete: "Delete",
        created: "Created",
        enteredByName: "Created by",
        assetName: "Asset",
        message: "Message",
        repeats: "Repeated"
      },
      searchAsset: "Search for asset",
      warnings: {
        maxAmount: (maxEntryAmount: number) =>
          `Only ${maxEntryAmount} people can be checked in simultaneously on this asset/project.`,
        alreadyIn: (current: number) =>
          `Currently, ${current} have checked in.`,
        alreadyReached: "It's already reached.",
        parentReachedMaxAmount: "Parent asset already exceed the max amount.",
        reachedMaxAmount: "Max entry amount reached",
        note: "Note",
        warning: "Warning"
      },
      selectAssetOwner: "Select asset owner",
      displayingRows: "Displaying",
      of: "of",
      entries: "entries",
      clearFilter: "Reset Filter",
      enterExpectedTimeToLeave: "Enter time",
      time: "Time",
      expectedTimeToLeaveHeader: "Enter expected time to leave",
      addAssetMsgMenuItemText: "Add asset message",
      chkInMenuItemText: "Check in personnel",
      assetMsgDisabledTooltipText: "The asset belongs to another asset owner",
      stdDisabledTooltipText: "Disabled",
      gotoAsset: "Goto asset",
      noAdminAssetRightsTooltipText: "Lacks rights"
    },
    onDuty: {
      onDutyTitle: `On-Duty Officer`,
      noAppointedOnDuty: "No On-Duty Officer designated",
      currentOnDuty: "Current On-Duty Officer",
      seletNewOnDutyOfficer: "Select new On-Duty Officer",
      newOnDuty: "New On-Duty Officer",
      changeOnDutyInfoText: `Appoint a new On-Duty Officer by searching in the field below. If no On-Duty Officer is present at this time save without selecting a new person`
    }
  },
  assetView: {
    FirstName: "First name",
    LastName: "Last name",
    Mobile: "Mobile",
    Visitors: "Visitors",
    NoVisitors: "All stations are empty",
    AssetHeader: "Asset name: ",
    AltImg: "Asset image"
  },
  notification: {
    Notifications: (orgName: string) =>
      orgName ? `Notifications - ${orgName}` : `Notifications`,
    NoNotification: "None",
    NewApplicationsForApproval: (cardName: string) =>
      `Notifications about new ${cardName.toLowerCase()} applications ready for approval`,
    ReadyForIssuing: (cardName: string) =>
      `Notifications about ${cardName.toLowerCase()}s ready to be issued`
  },
  invitation: {
    Reject: "Reject",
    Accept: "Accept",
    FirstName: "First name",
    EmptyFieldNotAllowed: "This field cannot be empty",
    LastName: "Last name",
    Mobile: "Mobile",
    AtLeast: "Phone number must consist of at least 10 digits",
    Email: "Email",
    ValidEmail: "Not a valid email address",
    Proceed: "Proceed",
    Welcome: "Welcome to Permitto",
    LogInAndDownload: "You can now download our app and log in ",
    LogIn: "Log in",
    VerifyData: "Verify your information"
  },
  my: {
    access: {
      NoRegAccess: (cardName: string) =>
        `Your ${cardName.toLowerCase()}s do not grant any access`,
      NoAssets: (cardName: string) =>
        `You have no ${cardName.toLowerCase()}s granting access to assets/projects`
    },
    application: {
      Delete: "Delete application",
      DeleteApplicationTitle: "Delete application",
      DeleteApplicationText: "This will permanently delete this application",
      ChangeAttestor: "Change attestor",
      MissingValuesAttestor: "Please enter data in all required fields",
      Cancel: "Cancel"
    },
    applicationList: {
      ColumnText: "Action required",
      Attestor: "Attestor",
      Open: "Open",
      CardType: "Card type"
    },
    profile: {
      AccessTo: (numStations: number) =>
        numStations === 1
          ? `Access registration allowed on ${numStations} asset`
          : `Access registration allowed on ${numStations} assets`,
      Holds: (numCards: number) =>
        numCards === 1
          ? `Holds ${numCards} valid safety card`
          : `Holds ${numCards} valid safety cards`,
      InfoFieldsHeader: "Info",
      HeaderTitle: "Update profile info",
      HeaderNotificationTitle: "Update notifications",
      HeaderSave: "Save",
      HeaderCancel: "Cancel",
      DialogSuccessText: "Profile info is saved",
      DialogSuccessTitle: "Ok",
      DialogConfirmText: "Are you sure you want to discard the changes?",
      DialogConfirmTitle: "Changes made",
      FooterText:
        "If you wish to change other data than email or phone number, please contact ",
      FooterEmail: "app support.",
      EditNotifications: "Edit notifications",
      EditInfo: "Edit stored info"
    },
    safetycards: {
      ShowShredded: (cardName: string) => `Shredded ${cardName.toLowerCase()}s`,
      NoCardsToShow: (cardName: string) => `No ${cardName.toLowerCase()}s`
    },
    splash: {
      Permissions: "My Access",
      AccessHistory: "My Access History",
      Cards: (cardName: string) => `My ${cardName}s`,
      NewApplication: "New Application",
      Applications: "My Applications",
      Profile: "My Profile",
      OtherCards: "My HSE Cards",
      DoorKeys: "My Door keys",
      NewDoorKeyApplication: "New Door Key Application",
      DoorkeyApplication: "My Door Key Applications",
      ApplicDisableTooltip: employerName =>
        `Your employer ${employerName}</br>
           is not registred as a service provider</br>
           to any asset owners in Permitto.</br>
           You will therefore not be able to apply</br>
           for safety cards or register access to projects.</br>
           Please contact the asset owner you wish to work for</br>
           and tell them to register ${employerName}</br>
           as a service provider in Permitto.</br>
           If you need help with this please email us at</br>
           app-support@verico.com</br>
           and we will help you out.</br>`
    },
    otherCards: {
      AddCard: "Add new card",
      DeleteTitle: "Delete card",
      DeleteText: "This will permanently delete this card"
    }
  },
  noAccess: {
    NoAccess: "User access not permitted",
    NoAccessHeader: "401 No access"
  },
  notFound: {
    PageNotFound: "Page not found",
    PageNotFoundHeader: "404 Not found"
  },
  registerView: {
    registerInfoText:
      "You can register a new user in Permitto here. When this is done, you can apply for and receive safety cards from the asset owners connected to your employer.",
    registerPrivacyPolicyLink: (link: string) => (
      <React.Fragment>
        Information about how your data is handled is described in the
        <a href={link}>
          <i> privacy policy.</i>
        </a>
      </React.Fragment>
    ),
    continue: "Continue",
    phoneAlreadyRegistred: "Phone number is already registered in Permitto",
    createUserFailed: "Could not create user",
    typePhoneNumber:
      "In order to register a user we need your phone number, this will be your identification in Permitto:",
    companyPrompt:
      'Please provide your employer. Do this by searching below or type in the "organisasjonsnummer(Norwegian company ID)."',
    userCreated:
      "You may now download the Permitto app and log in using your phone number",
    gotoPermitto: "Go to Permitto",
    userCreatedHeader: "User created",
    userIsBeingRegistered: "Register new user",
    firstName: "Firstname",
    lastName: "Lastname",
    eMail: "Email",
    registrer: "Register"
  },
  our: {
    rights: {
      HeaderEmployerAdmin: "Access",
      HeaderUserControl: "User control",
      HeaderUserApprove: (cardName: string) => `Attest ${cardName} application`,
      HeaderAccessHistory: "Access history",
      HeaderAccessView: "Access view"
    },
    access: {
      NoPersonnel:
        "No personnel registered in assets/projects you have right to view",
      PersonnelInField: "Personnel in the field",
      menuItemGotoPerson: "Show person",
      menuItemCheckoutPerson: "Checkout of asset",
      menuItemCancelTravel: "Cancel travel",
      menuItemUserArrived: "Report user arrived",
      menuItemCheckoutPersonList: "Send SMS",
      menuItemGotoPersonDisabled: "Missing rigths",
      menuItemCheckoutPersonDisabled: "Missing rights",
      menuItemChangeEscortedPersonnel: "Change escorted persons",
      InfoLegendCheckOutFirst: "Row marked with ",
      InfoLegendCheckOutLast: "has been over 24 hours in the facility.",
      InfoLegendTimeToLeaveFirst: "Row marked with ",
      InfoLegendTimeToLeaveLast: "has exceeded the time to leave.",
      checkoutModalHeader: "Confirm checkout",
      cancelTravelHeader: "Cancel Travel",
      userArrivedHeader: "Report user arrived",
      checkoutModalConfirmBtn: "Confirm",
      checkoutModalCancelBtn: "Cancel",
      checkoutModalSmsText: "Send SMS notification",
      menuItemAssetNote: "Set note",
      setAssetNoteText: "Set asset note",
      escortedModalHeader: "Change escorted persons",
      escortedModalSubmitBtnLbl: "Change"
    },
    applications: {
      Confirm: "Confirm",
      ConfirmedApplications: "Confirmed applications",
      Reject: "Reject",
      RejectApplication: "Reject application",
      Delete: "Delete application",
      DeleteAppTitle: "Delete application",
      DeleteAppText: "This will permanently delete this application",
      ChangeAttestor: "Change attestor",
      MissingValuesAttestor: "Please enter data in all required fields",
      Cancel: "Cancel",
      Application: "Application"
    },
    applicationList: {
      DemandsAction: "Action required",
      Open: "Open",
      Attestor: "Attestor"
    },
    profile: {
      ValidCards: (numCards: number) =>
        numCards === 1
          ? `${numCards} valid safety card`
          : `${numCards} valid safety cards`,
      RegPersonnel: (numPersons: number) => `${numPersons} registered personnel`
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName} number`,
      Back: "Back",
      Shredded: "Shredded",
      CardReceipted: (cardName: string) => `${cardName} is received `,
      EditMode: "Edit mode",
      Save: "Save",
      Cancel: "Cancel",
      ValidFrom: "Valid from",
      ValidTo: "Valid to",
      PrevValid: "Valid from must be earlier then valid to"
    },
    splash: {
      CardName: (cardTemplate: CardType) => cardTemplate.cardNameEn,
      Accesses: "Asset Entries",
      AccessHistory: "Asset Entries History",
      SafetyCard: (cardName: string) => `Our ${cardName.toLowerCase()}s`,
      Personnel: "Our Personnel",
      NewApplication: "New Application",
      Applications: "Our Applications",
      NewCardNoApplic: (cardTemplate: CardType) =>
        `New ${cardTemplate.cardNameEn}`,
      Profile: "Our Profile",
      OtherCards: "Our HSE Cards",
      UserAccess: "User Access",
      DoorKeys: "Our Door Keys",
      Documentation: "Our Documentation",
      NewDoorKeyApplication: "New Door Key Application",
      DoorkeyApplication: "Our Door Key Applications"
    },
    safetyCardList: {
      Issuer: "Issuer",
      Open: "Open",
      Grid: "Grid",
      CardUpperCase: (cardName: string) => cardName
    }
  },
  templates: {
    buttons: {
      newtemplate: "New",
      startedit: "Start edit",
      endedit: "Stop edit",
      savetemplate: "Save",
      saveastemplate: "Save as",
      previewtemplate: "Preview",
      applytemplate: "Apply",
      confirmTitle: "Changes made",
      confirmText: "This will delete all unsaved changes"
    },
    subSection: {
      ModalCancelText: "Cancel"
    },
    dropdowns: {
      cardTypeName: (cardType: CardType) => cardType.cardNameEn,
      selectCardType: "Select card type",
      selectTemplate: "Select template",
      selectDraft: "Select draft",
      blankDraft: "Blank draft",
      createDraft: "Create draft from current template",
      newDraft: "New draft",
      unSaved: "Not saved",
      draftStatus: {
        inUse: "In use",
        draft: "Draft",
        historical: "Historical",
        unknown: "Unknown"
      },
      redeemedAfterIssueTemplateText: "Redeemed after issue"
    },
    attributes: {
      newAttribute: "Choose attribute",
      newAttributePlaceholder: "Search for existing attribute",
      addExistingAttribute: "Add existing attribute",
      setLocationAssetTreeButton: "Set location of asset tree",
      dataType: "Data type",
      lableNo: "Label No",
      lableEn: "Label En",
      hyperlink: "Hyperlink",
      sectionOrder: "Section order",
      sortOrder: "Sort order",
      info: "info",
      selectAttribute: "Select attribute",
      subSectionOrder: "Sub Section Order",
      descriptionEn: "Description En",
      descriptionNo: "Description No",
      SelectType: "Select type",
      requiredValue: "Required value",
      requiredWhenVisible: "Required if visible",
      role: "Role",
      sectionNo: "Section name - NO",
      sectionEn: "Section name - EN",
      subSectionNameNo: "Sub section name - NO",
      subSsectionNameEn: "Sub section name - EN",
      ok: "Ok",
      cancel: "Cancel",
      addNewAttribute: "Add attribute",
      editAttribute: "Edit attribute",
      editSectionName: "Edit section name",
      editSubSection: "Edit sub section",
      selectOperator: "Show if",
      selectAttributes: "Select attribute(s)",
      displayOperandOr: "OR",
      displayOperandNor: "NOT",
      attriExistOnCardTitle: "Warning",
      attriExistOnCardText: (cardType: string) =>
        `This attribute already exists on the safetycard. Since all attributes on the ${cardType} type is presented on the application, adding this attribute will add an dublicate. Do you want to proceed?`,
      associatedAssets: "Associated Assets"
    },
    confirmModal: {
      prompt: "Do you want to save the changes in this draft?",
      ok: "Save",
      cancel: "Discard",
      title: "Changes made",
      deleteAttributeModalTitle: "Delete attribute",
      deleteAttributeModalDescription: "This will delete the attribute"
    },
    applyModal: {
      applyMessageTitle: "Apply template",
      applyMessageText:
        "Are you sure you want to make this template the curent active one?"
    },
    saveAsModal: {
      editTemplateNameTitle: "Edit template name",
      ok: "Save",
      cancel: "Discard",
      editTemplateNamelabel: "Template name",
      templateNameRequiredMessage: "Template name required."
    },
    locationAssetTreeSelectModal: {
      title: "Set location of asset tree",
      defaultCheck: "Default"
    },
    postIssueRule: {
      InvalidatesCard: "Invalidates Card",
      RequiresApproval: "Requires Approval",
      postIssueRuleList:
        "The attribute is already in the list. Do you want to continue?"
    }
  },
  doorKeys: {
    person: {
      GridHeaderText: "Door keys",
      GridEmptyText: "User has no door keys"
    },
    reserve: {
      keepPersonnel: "Reserve to",
      ModalTermsInfo: "Terms for lending",
      ModalReserveText: "Reserve",
      ModalCancelText: "Cancel",
      ModalHeaderText: "Reserve keys",
      ModalStatusComment: "Comment"
    },
    register: {
      SerialNumber: "Serial number",
      SerialNumberStart: "Serial number start",
      NumberOfKeys: "Number of keys",
      ModalHeaderText: "Register new keys",
      ModalCancelText: "Cancel",
      AlertLabelSingular: "Register key",
      AlertLabelPlural: "Register keys",
      AlertOkButtonText: "Confirm",
      AlertCancelButtonText: "Cancel",
      AlertMsg: (num: number) =>
        num === 1
          ? `Are you sure you want to register ${num} new key?`
          : `Are you sure you want to register ${num} new keys?`,
      DoorkeySystemNo: "System number",
      DoorkeyType: "Key type",
      Description: "Description of key",
      StatusComment: "Comment upon creation",
      EnableKeySeries: "Register a series of keys",
      DoorkeyComment: "Key comment",
      DoorkeyDept: "Responsible"
    },

    update: {
      ModalHeaderText: "Update door key",
      ModalCancelText: "Cancel",
      ModalUpdateText: "Update",
      DoorkeySystemNo: "System number",
      SerialNumber: "Serial number",
      DoorkeyType: "Key type",
      Description: "Description of key"
    },
    receive: { ReceivekeyHeader: "Sign for key received" },
    discard: {
      DiscardKeyHeader: "Discard key",
      DiscardKeyMessage: "Are you sure you would like to discard key?",
      DiscardKeyConfirmBoxTitle: "Discard key",
      DiscardKeyConfirmBoxText:
        "This will permanently discard key and cannot be reversed",
      DiscardKeyConfirmBoxConfirmButtonText: "Discard",
      DiscardKeyConfirmBoxCancelButtonText: "Cancel"
    },
    return: {
      ReturnKeyHeader: "Return to storage",
      ReturnKeyMessage:
        "Are you sure you would like to return this door key to store?"
    },
    deleteKey: {
      DeleteKeyHeader: "Delete key",
      WarningText: "This will permanently delete the key",
      ConfirmButtonText: "Delete",
      CancelButtonText: "Cancel"
    },
    application: {
      ReservedLinkMessage: `Doorey is reserved(click to show)`
    },
    doorKeyList: {
      button: {
        DeleteKeyText: "Delete key",
        RegisterKeyText: "Register new key",
        ReserveKeyText: "Reserve key",
        SignForReturnKeyText: "Sign for returned key",
        RecallKeyText: "Recall key",
        LostKeyText: "Lost key",
        ReturnToStoreText: "Return to storage",
        DiscardKeyText: "Discard key",
        ReceiveKeyText: "Receive Key",
        UpdateKeyText: "Edit door key",
        FilterListPlaceholder: "Search for person or employer"
      },
      columnDefs: {
        DoorkeySystemNo: "System number",
        DoorkeyType: "Key type",
        SerialNumber: "Serial number",
        Description: "Description of key",
        StatusDateTime: "Status date",
        DoorkeyStatusName: "Status",
        KeeperFirstName: "First name",
        KeeperLastName: "Last name",
        KeeperHasSCard: "Valid s.card",
        Employer: "Company",
        StatusComment: "Status comment",
        Organization: "Key owner",
        Info: "Info",
        AssetOwner: "Asset owner",
        HolderName: "Key holder",
        ApplicationId: "Application",
        HolderPhone: "Key holder phone",
        Email: "Key holder email",
        IsTraveling: "Travel",
        Note: "Note",
        DoorkeyComment: "Key comment",
        DoorkeyDept: "Responsible"
      },
      lostKey: {
        ModalTitleText: "Mark key as lost",
        ConfirmButtonText: "Lost"
      },
      returnKey: {
        ModalTitleText: "Sign for returned key",
        ConfirmButtonText: "Ok"
      },
      recallKey: {
        ModalTitleText: "Recall Key",
        ConfirmButtonText: "Recall"
      },
      log: {
        columnDefs: {
          ActionPersonName: "Performed by",
          ActionStatusText: "Action",
          KeeperEmployer: "Company",
          KeeperFirstName: "First name",
          KeeperLastName: "Last name",
          StatusComment: "Comment",
          StatusDateTime: "Date"
        }
      }
    },
    doorKeyApplicationsList: {
      approveModal: {
        commentHeaderText: "Comment",
        commentSubHeaderText: "",
        headerText: "Approve door key application",
        subHeaderText: ""
      },
      rejectModal: {
        commentHeaderText: "Comment",
        commentSubHeaderText: "",
        headerText: "Reject application",
        subHeaderText: ""
      },
      shredModal: {
        commentHeaderText: "Comment",
        commentSubHeaderText: "",
        headerText: "Delete application",
        subHeaderText: ""
      },
      doorkeyReserveModal: {
        doorkeyReserveHeaderText: "Reserve Doorkey Now"
      },
      resendModal: {
        commentHeaderText: "Comment",
        commentSubHeaderText: "",
        headerText: "Resend application",
        subHeaderText: ""
      },
      buttons: {
        approve: "Approve",
        reject: "Reject",
        open: "Open",
        shred: "Delete application",
        resend: "Resend",
        back: "Back",
        archive: "Show active applications",
        nonArchive: "Show archived",
        reserve: "Reserve key"
      },
      columnDefs: {
        applicId: "Application Id",
        firstName: "First name",
        lastName: "Last name",
        mobile: "Mobile",
        email: "Email",
        employerName: "Employer",
        organisationName: "Organisation",
        status: "Status",
        actionRequired: "Action required"
      },
      statuses: {
        applicationCreated: "Application created",
        applicationEmployerApproved:
          "Application sent and is awaiting processing",
        applicationEmployerRejected: "Application rejected by service provider",
        applicationOrganizationApproved:
          "Application approved and awaiting doorkey to be reserved",
        applicationOrganizationRejected:
          "Application reject by asset owner. Action required",
        applicationEmployerApprovedORG: "Application awaiting processing",
        applicationOrganizationShredded: "Application shredded",
        applicationOrganizationRejectedORG:
          "Application rejected by asset owner",
        applicationOrganizationReserved: "Key reserved"
      }
    },
    kpiNames: {
      loanedKeys: "Loaned Keys",
      reservedKeys: "Reserved Keys",
      recalledKeys: "Recalled Keys",
      keyOwnersInvalidCard: "Owners with Invalid Cards"
    }
  },
  documentation: {
    documentationList: {
      columnDefs: {
        employerName: "Company",
        firstName: "First name",
        lastName: "Last name",
        hasValidSafetyCard: (cardName: string) =>
          `Valid ${cardName.toLowerCase()}`
      },
      columnDocumentationDetailsDefs: {
        fileName: "File Name",
        documentRequirement: "Document requirement",
        reference: "Reference",
        comment: "Comment",
        uploaded: "Uploaded",
        validFrom: "Valid from",
        expiresFrom: "Expires from",
        uploadedBy: "Uploaded by",
        actions: "Actions",
        gridEmptyText: "No documents"
      },
      lastNamePlaceholder: "Search by last name",
      openButtonText: "Open",
      allDocumentTypes: "All document types",
      editImageButton: "Edit",
      addDocButtonText: "Add documentation",
      upload: {
        uploadButton: "Continue",
        cancelButton: "Cancel",
        uploadDocumentHeader: "Select persons",
        uploadProgressText: "Uploading documentation, please wait"
      }
    }
  },
  assetMessages: {
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    deleteTitle: "Delete message",
    deleteText: "This will permanently delete the message",
    staticEventType: "Always",
    checkInEventType: "Entering asset",
    checkOutEventType: "Leaving asset",
    showExpiredMessages: "Show expired messages",
    htmlType: "HTML",
    informationType: "Plain text"
  }
};
