import styles from "./header.css";
import * as React from "react";
import { observer } from "mobx-react";
import { globalStore } from "../../stores";
import HeaderDivider from "./header-divider";
import { routeShouldHaveSubDivider } from "./header-utils";

interface SubHeaderTextProps {
  location: Location;
}

@observer
export default class SubHeaderText extends React.Component<SubHeaderTextProps> {
  componentWillUnmount() {
    globalStore.setCurrentHeader("");
  }

  render() {
    if (routeShouldHaveSubDivider(this.props.location.pathname)) {
      return (
        <React.Fragment>
          <HeaderDivider />
          <div className={styles.contextTextContainer}>
            <span>
              {globalStore.loadingCurrentHeader
                ? "..."
                : globalStore.currentHeader}
            </span>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
