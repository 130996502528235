import {
  CardsList,
  Card,
  Access,
  AccessLog,
  ApplicationList,
  DoorKeys,
  Splash,
  Profile,
  Application
} from "../consts";

export function IsMyApplicationListUrl(url: string) {
  const regexp = new RegExp(`^${ApplicationList}`);
  return regexp.test(url);
}

export function IsMyApplicationUrl(url: string) {
  return url.startsWith(Application);
}

export function IsMyCardsListUrl(url: string) {
  return url.startsWith(CardsList);
}

export function IsMyCardUrl(url: string) {
  const regexp = new RegExp(`^${Card}+?\\d+`);
  return regexp.test(url);
}

export function IsMyPermittoUrl(url: string) {
  return url.startsWith(Splash);
}

export function IsMyProfileUrl(url: string) {
  return url.startsWith(Profile);
}

export function IsMyCardsUrl(url: string) {
  return url.startsWith(CardsList);
}

export function IsMyAccessUrl(url: string) {
  return url.startsWith(Access);
}

export function IsMyAccessLogUrl(url: string) {
  return url.startsWith(AccessLog);
}

export function IsMyDoorkeyApplicationsUrl(url: string) {
  const regexp = new RegExp(`^${DoorKeys}${ApplicationList}`);
  return regexp.test(url);
}

export function IsMyDoorkeysUrl(url: string) {
  return url.startsWith(DoorKeys);
}
