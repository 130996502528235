import * as React from "react";
import styles from "./edit-indicator-bar.css";

export interface EditIndicatorBarProps {
  header: string;
  close?: () => void;
  save?: () => void;
  saveEnabled?: boolean;
  saveDisabledMessage?: string[];
  saveText?: string;
  cancelText?: string;
  hideSaveButton?: boolean;
  hideCancelButton?: boolean;
  centerHeader?: boolean;
  headerImageUrl?: string;
}

const CLOSEICON = "fa fa-times fa-lg";

export default class EditIndicatorBar extends React.Component<EditIndicatorBarProps> {
  constructor(props: EditIndicatorBarProps) {
    super(props);
  }

  render() {
    const headerStyle = this.props.centerHeader
      ? styles.headerCenter
      : styles.header;
    return (
      <div className={styles.editIndicatorBarContainer}>
        {this.props.headerImageUrl && (
          <img className={styles.headerImage} src={this.props.headerImageUrl} />
        )}
        <p className={headerStyle}>{this.props.header}</p>

        {!this.props.hideCancelButton && (
          <div
            className={styles.buttonClose}
            onClick={() => {
              this.props.close();
            }}
          >
            <span className={CLOSEICON} />
          </div>
        )}

        {!this.props.hideSaveButton && (
          <div
            className={
              this.props.saveEnabled ? styles.button : styles.buttonDisabled
            }
            onClick={() => {
              if (this.props.saveEnabled) {
                this.props.save();
              }
            }}
          >
            {!this.props.saveEnabled &&
            this.props.saveDisabledMessage &&
            this.props.saveDisabledMessage.length > 0 ? (
              <div className={styles.saveDisabledMessageContainer}>
                {this.props.saveDisabledMessage.map((m, index) => (
                  <p className={styles.saveDisabledMessage} key={m + index}>
                    {m}
                  </p>
                ))}
              </div>
            ) : (
              false
            )}
            <p className={styles.text}>{this.props.saveText}</p>
          </div>
        )}
      </div>
    );
  }
}

export interface EditIndicatorFooterProps {
  close?: () => void;
  save?: () => void;
  saveEnabled: boolean;
  closeDisable?: boolean;
  saveDisabledMessage?: string[];
  saveText?: string;
  cancelText?: string;
  hideSaveButton?: boolean;
  hideCancelButton?: boolean;
  absoluteBottom?: boolean;
  style?: React.CSSProperties;
  centerButtons?: boolean;
}

export class EditIndicatorFooter extends React.Component<EditIndicatorFooterProps> {
  constructor(props: EditIndicatorFooterProps) {
    super(props);
  }

  render() {
    return (
      <div
        style={this.props.style}
        className={`${styles.footerContainer} ${
          this.props.absoluteBottom ? styles.absoluteBottom : ""
        } ${this.props.centerButtons ? styles.centerButtons : ""}`}
      >
        {!this.props.hideCancelButton && (
          <div
            tabIndex={0}
            className={
              this.props.closeDisable ? styles.buttonDisabled : styles.buttonRed
            }
            onClick={() => {
              this.props.close();
            }}
            onKeyUp={event => {
              if (event.which === 13) {
                this.props.close();
              }
            }}
          >
            <p className={styles.text}>{this.props.cancelText}</p>
          </div>
        )}

        {!this.props.hideSaveButton && (
          <div
            className={
              this.props.saveEnabled ? styles.buttonBlue : styles.buttonDisabled
            }
            onClick={() => {
              if (this.props.saveEnabled) {
                this.props.save();
              }
            }}
          >
            {!this.props.saveEnabled &&
            this.props.saveDisabledMessage &&
            this.props.saveDisabledMessage.length > 0 ? (
              <div className={styles.saveDisabledMessageContainer}>
                {this.props.saveDisabledMessage.map((m, index) => (
                  <p className={styles.saveDisabledMessage} key={m + index}>
                    {m}
                  </p>
                ))}
              </div>
            ) : (
              false
            )}
            <p className={styles.text}>{this.props.saveText}</p>
          </div>
        )}
      </div>
    );
  }
}
