import * as repo from "./../repo";
import { SafetyCard, Attribute } from "../types";
import { errorStore } from "../stores/index";
import { localizationStore } from "./../stores";

export async function sendApplication(
  application: SafetyCard,
  comment: string,
  selfAttesting: boolean,
  approverEmail: string
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  try {
    const returnValue = await errorStore.wrap(
      async () => {
        const newApplication: SafetyCard = await repo.postSafetyCardApplication(
          application,
          comment,
          selfAttesting,
          approverEmail
        );

        if (application.nodes.length > 0) {
          await repo.putSafetycardAssets(
            newApplication.cardId,
            application.nodes
          );
        }

        return newApplication.cardId;
      },
      local.error.title,
      `${local.error.sendError(local.area.application)}${local.contactSupport}`
    );

    return returnValue;
  } catch (e) {
    return 0;
  }
}

export async function sendUpdatedApplication(
  application: SafetyCard,
  comment: string,
  selfAttesting: boolean
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () => {
      if (!comment) {
        comment = "";
      }

      // Update existing card
      await repo.putSafetycard(application);

      // Update all attribs
      // TODO ONLY UPDATE ATTRIB THAT CHANGED
      const allToUpdate: Attribute[] = [];
      for (const attrib of application.attributes) {
        const toUpdate: Attribute = {
          attributeId: attrib.attributeId,
          value: attrib.value,
          attachmentId: attrib.attachmentId
        };
        allToUpdate.push(toUpdate);
      }

      await repo.patchSafetycardAttribute(application.cardId, allToUpdate);

      // Update assets
      if (application.nodes) {
        await repo.putSafetycardAssets(application.cardId, application.nodes);
      }

      // Mark it as resent
      await repo.putApplicationResend(
        application.personnelId,
        application.cardId,
        comment,
        selfAttesting
      );
      return application.cardId;
    },
    local.error.title,
    `${local.error.sendError(local.area.application)}${local.contactSupport}`
  );
}

export async function rejectApplicationOrganisation(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrganisationRejectApplication(orgId, applicationId, comment),
    local.error.title,
    `${local.error.sendError(local.area.application)}${local.contactSupport}`
  );
}

export async function approveApplicationOrganisation(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrganisationApproveApplication(orgId, applicationId, comment),
    local.error.title,
    `${local.error.sendError(local.area.application)}${local.contactSupport}`
  );
}

export async function getApplications(
  orgId: number,
  cardTypeId: number
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.getOrgSafetyCardsAndApplications(
        orgId,
        false,
        false,
        false,
        cardTypeId
      ),
    local.error.title,
    `${local.error.fetchError(local.area.applications)}${local.contactSupport}`
  );
}

export function getOrgUserApplications(
  orgId: number,
  personnelId: number,
  includeShredded: boolean
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgApplicationsForPerson(orgId, personnelId, includeShredded),
    local.error.title,
    `${local.error.fetchError(local.area.applications)}${local.contactSupport}`
  );
}

export async function getPersonApplications(
  personnelId: number
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonnelApplications(personnelId, false),
    local.error.title,
    `${local.error.fetchError(local.area.applications)}${local.contactSupport}`
  );
}

export async function getEmployerApplications(
  employerId: number,
  cardTypeId: number
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerApplications(employerId, false, true, cardTypeId),
    local.error.title,
    `${local.error.fetchError(local.area.applications)}${local.contactSupport}`
  );
}

export async function issueApplicationOrganisation(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrganisationIssueApplication(orgId, applicationId, comment),
    local.error.title,
    `${local.error.sendError(local.area.application)}${local.contactSupport}`
  );
}

export async function rejectEmployerApplicationWithToken(
  employerId: number,
  applicationId: number,
  comment: string,
  token: string
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.putEmployerRejectApplicationWithToken(
        employerId,
        applicationId,
        comment,
        token
      ),
    local.error.title,
    `${local.error.approveError(local.area.application)}${local.contactSupport}`
  );
}

export async function approveEmployerApplicationWithToken(
  employerId: number,
  applicationId: number,
  comment: string,
  token: string
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.putEmployerApproveApplicationWithToken(
        employerId,
        applicationId,
        comment,
        token
      ),
    local.error.title,
    `${local.error.approveError(local.area.application)}${local.contactSupport}`
  );
}
