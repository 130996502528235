import * as repo from "./../repo";
import { errorStore } from "../stores/index";
import { OtherCard, OtherCardType, OtherCardTypeAndDates } from "../types";
import { localizationStore } from "./../stores";

function stdErrorMsg() {
  const local = localizationStore.currentLocal.actions;
  return {
    title: local.error.title,
    text: `${local.otherCards.ErrorNetworkOperation}${local.contactSupport}`
  };
}

export const OtherCards = {
  async getPersonnelCards(personnelId: number): Promise<OtherCard[]> {
    return await errorStore.catchError(
      repo.getPersonnelCards(personnelId),
      stdErrorMsg()
    );
  },

  async getPersonnelCardTypes(personnelId: number): Promise<OtherCardType[]> {
    return await errorStore.catchError(
      repo.getPersonnelCardTypes(personnelId),
      stdErrorMsg()
    );
  },

  async createPersonnelCard(
    personnelId: number,
    card: OtherCardTypeAndDates
  ): Promise<OtherCard> {
    return await errorStore.catchError(
      repo.createPersonnelCard(personnelId, card),
      stdErrorMsg()
    );
  },

  async deleteCard(othercardsId: number): Promise<void> {
    return await errorStore.catchError(
      repo.deleteOtherCard(othercardsId),
      stdErrorMsg()
    );
  },

  async deleteCardFrontImage(othercardsId: number): Promise<void> {
    return await errorStore.catchError(
      repo.deleteCardFrontImage(othercardsId),
      stdErrorMsg()
    );
  },

  async deleteCardBackImage(othercardsId: number): Promise<void> {
    return await errorStore.catchError(
      repo.deleteCardBackImage(othercardsId),
      stdErrorMsg()
    );
  },

  async updateCard(
    othercardsId: number,
    cardAttributes: OtherCardTypeAndDates
  ): Promise<OtherCard> {
    return await errorStore.catchError(
      repo.updateOtherCard(othercardsId, cardAttributes),
      stdErrorMsg()
    );
  },

  async updateCardFrontImage(
    othercardsId: number,
    imgFile: File
  ): Promise<void> {
    return await errorStore.catchError(
      repo.updateCardFrontImage(othercardsId, imgFile),
      stdErrorMsg()
    );
  },

  async updateCardBackImage(
    othercardsId: number,
    imgFile: File
  ): Promise<void> {
    return await errorStore.catchError(
      repo.updateCardBackImage(othercardsId, imgFile),
      stdErrorMsg()
    );
  },
  async getCardFrontImage(othercardsId: number): Promise<string> {
    return await errorStore.catchError(
      repo.getCardFrontImage(othercardsId),
      stdErrorMsg()
    );
  },

  async getCardBackImage(othercardsId: number): Promise<string> {
    return await errorStore.catchError(
      repo.getCardBackImage(othercardsId),
      stdErrorMsg()
    );
  },

  async getEmployerCards(employerId: number): Promise<OtherCard[]> {
    return await errorStore.catchError(
      repo.getEmpoyerOtherCards(employerId),
      stdErrorMsg()
    );
  },

  async getOrganizationCards(orgId: number): Promise<OtherCard[]> {
    return await errorStore.catchError(
      repo.getOrganisationOtherCards(orgId),
      stdErrorMsg()
    );
  },
  async getOrganizationCardTypes(orgId: number): Promise<OtherCardType[]> {
    return await errorStore.catchError(
      repo.getOrganisationOtherCardTypes(orgId),
      stdErrorMsg()
    );
  }
};
