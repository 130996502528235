import * as L from "leaflet";
import { History } from "history";
import * as moment from "moment";
import { AssetInfo } from "./routes/org-asset-entries/reg-user-modal";

export interface BooleanFlexObject {
  [s: string]: boolean;
}

export interface Attribute {
  attributeId: number;
  value: string;
  attachmentId: number;
  expired?: boolean;
}

export enum AttributeDataType {
  LongText = "LONGTEXT",
  Text = "TEXT",
  Number = "NUMBER",
  DateTime = "DATETIME",
  Time = "TIME",
  Sheet = "SHEET",
  Boolean = "BOOLEAN",
  Blob = "BLOB",
  IdefixRights = "IDEFIX_RIGHTS",
  Html = "HTML",
  Assets = "ASSETS",
  JungleMap = "JUNGLEMAP"
}

export enum AttributeTypesOrderCardandApplic {
  Boolean = "BOOLEAN",
  Text = "TEXT",
  Number = "NUMBER",
  LongText = "LONGTEXT",
  Html = "HTML",
  DateTime = "DATETIME",
  Blob = "BLOB",
  JungleMap = "JUNGLEMAP"
}

export enum OperandTypes {
  NOT = "NOT",
  OR = "OR"
}
export enum AttributesDefaultValues {
  Boolean = "1",
  Text = "",
  Blob = "-1",
  JungleMap = "-1"
}

export enum LocalStorageKeys {
  RememberSms = "rememberSms",
  RememberAlignment = "rememberAlignment",
  AdminDocumentsColumnWidths = "permittoAdminDocumentListColumnWidths",
  OurDocumentsColumnWidths = "permittoOurDocumentListColumnWidths",
  AdminSafetycardsColumnWidths = "permittoAdminSafetycardsColumnWidths",
  OurSafetycardsColumnWidths = "permittoOurSafetycardsColumnWidths"
}

export interface SectionDoneHideState {
  done: boolean;
  hidden: boolean;
}

export interface TreeDataAttributeNode
  extends TreeDataSubSectionNode,
    ApplicationAttribute {
  actualObject: ApplicationAttribute;
}

export interface TreeDataSectionNode {
  title: string;
  subSectionNo: number;
  sectionOrder: number;
  expanded: boolean;
  sortOrder: number;
  type: string;
  children: TreeDataSubSectionNode[];
}

export interface TreeDataSubSectionNode extends TreeDataSectionNode {
  children: TreeDataAttributeNode[];
}

export enum DraftTemplateStatus {
  InUse = 1,
  Draft = 2,
  Historical = 3
}

export enum DraftTemplateType {
  NewTemplate = -1,
  NewAttribute = -1
}

export interface TreeNodeV2 {
  icon: string;
  access: boolean;
  hasChildren?: boolean;
  id: number;
  name: string;
  parentId: number;
  children: TreeNodeV2[];
  isExpanded: boolean;
  selected: boolean;
  isLoading?: boolean;
  originalObject: any;
  checked: boolean;
  allChildrenChecked: boolean;
}

export enum Bool {
  True = "1",
  False = "0"
}

export interface ApplicationBase {
  personnelId: number;
  attributes: Attribute[];
  orgId: number;
  firstName: string;
  lastName: string;
  employerName: string;
  validFromDateTime?: string;
  validToDateTime?: string;
  statusLog?: StatusLog[];
  updateLog?: UpdateLog[];
  organizationNo?: number;
  orgName?: string;
}

export interface PostIssueAttributeMissing {
  attributeId: number;
  postIssueStatus: "Valid" | "PartialValid" | "Invalid";
}

export interface SafetyCard extends CardBase, ApplicationBase {
  cardTypeId: number;
  attestor: Attestor;
  hasApplication: boolean;
  application: boolean;
  responsibleAtOrg: string;
  assets?: Asset[];
  nodes: number[];
  headOfOperations?: string;
  logo?: string;
  mainColor?: string;
  safetyCardFooter?: string;
  secondaryColor?: string;
  postIssueAttribsMissing: PostIssueAttributeMissing[];
}

export interface DoorKeyApplication extends ApplicationBase {
  doorKeyApplicId?: number;
  eMail?: string;
  mobilePhone?: string;
  comment?: string;
  doorkeyInfo?: DoorkeyApplicationDoorkeyInfo;
}

export interface DoorkeyApplicationDoorkeyInfo {
  doorkeyId: number;
  doorkeySerialNo: number;
  keyType: string;
  doorkeySystemNo: string;
}

export interface PersonInfoBasics {
  employerName: string;
  firstName: string;
  lastName: string;
  personnelId: number;
}

export interface PersonInfo extends PersonInfoBasics {
  attributes: Attribute[];
  email?: string;
  organizationNo: number;
  mobilePhone: string;
  tosApproved: string;
  tosInvited: string;
  photoHash?: string;
  preferences: PreferredLanguage;
}

export interface AuthorizedPerson extends PersonInfoBasics {
  email: string;
  mobilePhone: string;
  organizationNo: number;
  orgRelations: number[];
  tosApproved: string;
  tosInvited: string;
  attributes: [
    {
      attributeId: number;
      value: string;
    }
  ];
  checkinInfo: {
    assetId: number;
    assetName: string;
    checkinDateTime: string;
    entryCount: number;
    entryId: number;
    escortedPersonNames: string;
    isTraveling: boolean;
    notificationSentDateTime: string;
  };
}

export interface CheckinPerson {
  entryCount: number;
  entryPersonNames: string[];
  isTraveling: boolean;
  personnelId: number;
  expectedTimeToLeave?: string;
}

export interface ApplicationAttribute {
  attributeId: number;
  dataType: string;
  dataTypeId: number;
  hyperlink: string;
  info: string;
  labelEn: string;
  labelNo: string;
  name: string;
  optional: boolean;
  sectionEn: string;
  sectionNo: string;
  subSectionNameEn?: string;
  subSectionNameNo?: string;
  sectionOrder: number;
  sortOrder: number;
  subSectionNo: number;
  showIf: {
    attributes: number[];
    operand: string;
    optionalWhenShown: boolean;
  };
  descriptionNo: string;
  descriptionEn: string;
  isEditing?: boolean;
  isAttributeOrderChanged?: boolean;
  role?: boolean;
  assetNodeRequirements: number[];
}
export interface templateSectionName {
  sectionNameEn: string;
  sectionNameNo: string;
  newSectionOrder: number;
  sectionOrder: number;
}

export interface TemplateSubSectionOrder {
  newSubSectionNo: number;
  subSectionNo: number;
  sectionOrder: number;
}

export interface PersonellAttributeTemplate {
  attributeId: number;
  dataTypeId: number;
  dataType: string;
  labelEn: string;
  labelNo: string;
  name: string;
  orgid: number;
  sectionEn: string;
  sectionNo: string;
  sectionOrder: number;
  sortOrder: number;
  internalAttribute: boolean;
}

export interface PersonTemplate {
  attributes: PersonellAttributeTemplate[];
}

export enum CardLayout {
  Card = "CARD",
  Soknad = "APPLIC",
  SoknadView = "APPLIC_VIEW"
}

export enum Features {
  PrintEnabled = "print-enabled",
  ApplicContainsCardAttributes = "applic-contains-card-attributes",
  CardLocalized = "cards-localized",
  CardDoesNotUseAssets = "card-does-not-use-assets",
  TravelEnabled = "travel-enabled",
  IssueOnBehalfOnCardText = "issue_on_behalf_of",
  OrgHideEntryTimestamp = "org_hide_entry_timestamp",
  OrgOperationalMessages = "org_operational_messages",
  OrgOnDutyInAppEnabled = "org_on_duty_in_app_enabled",
  IssueOnBehalfOfDisabled = "issue_on_behalf_of_disabled",
  HideHeadOfOperations = "hide_head_of_operations",
  DisableApplicationAccessAutoFill = "disable_application_access_auto_fill",
  HideEmptyFieldsOnCard = "hide_empty_fields_on_card",
  OrgExpectedTimeToLeaveEnabled = "org_expected_time_to_leave_enabled",
  OrgCustomAttestorText = "org_custom_attestor_text",
  ShowAllAssetsInMap = "show-all-assets-in-map"
}

export interface ApplicationTemplate {
  attributes: ApplicationAttribute[];
  name?: CardLayout;
  cardNameEn?: string;
  cardNameNo?: string;
}

export interface Feature {
  active: boolean;
  feature: Features;
  featureValue?: string;
  cardTypes: number[];
}

export interface AttestorTextFeatureValue {
  lang: string;
  text: string;
}

export interface OrganisationTemplate {
  hasAssets: boolean;
  installercardApplicationTemplates: ApplicationTemplate[];
  assetModelPriorities: number[] | null;
  assets: Asset[] | null;
  attribsForCardIssue: [];
  cardNameEn: string;
  cardNameNo: string;
  cardTypes: CardType[];
  desc: string;
  docRequirements: DocumentRequirement[];
  doorkeyApplicationTemplate: ApplicationTemplate;
  doorkeyReceivedMessageEn: string;
  doorkeyReceivedMessageNo: string;
  features: Feature[];
  logo: string;
  mainColor: string;
  name: string;
  orgId: number;
  personTemplate: PersonTemplate;
  //safetyCardTemplates: ApplicationTemplate[];
  screenSetups: ScreenSetup[];
  secondaryColor: string;
}

export interface ScreenSetup {
  screen: Screen;
  setupOrFilter: string;
}

export type Screen = "access" | "";

export interface SetupOrFilter {
  hiddenColumns: string[];
  sortModel: { colId: string; sort: "asc" | "desc" }[];
  filterModel: {
    [colName: string]:
      | TextFilterModel
      | NumberFilterModel
      | DateFilterModel
      | SetFilterModel;
  };
}

interface TextFilterModel {
  filterType: "text";
  type:
    | "equals"
    | "notEqual"
    | "contains"
    | "notContains"
    | "startsWith"
    | "endsWith";
  filter?: string;
}

interface NumberFilterModel {
  filterType: "number";
  type:
    | "equals"
    | "notEqual"
    | "lessThan"
    | "lessThanOrEqual"
    | "greaterThan"
    | "greaterThanOrEqual"
    | "inRange";
  filter?: string;
  filterTo?: string;
}

interface DateFilterModel {
  filterType: "date";
  type: "equals" | "notEqual" | "lessThan" | "greaterThan" | "inRange";
  dateFrom: string;
  dateTo: string;
}

interface SetFilterModel {
  filterType: "set";
  values: string[];
}

export interface PostIssueRule {
  cardTypeId: number;
  invalidatesCard: boolean;
  requiresApproval: boolean;
  approvers: string[];
  attribute: ApplicationAttribute;
}

export interface CardType {
  cardApplicationEnabled: boolean;
  cardNameEn: string;
  cardNameNo: string;
  cardRequiresAttestor: boolean;
  cardTemplates: ApplicationTemplate[];
  cardTypeId: number;
  description: string;
  maxDaysValid: number;
  attributePostIssueRules: PostIssueRule[];
}

export interface Employer {
  country: string;
  address: string;
  contact: string;
  organizationNo: number;
  name: string;

  contactEmail?: string;
  contactPhone?: string;
  numActiveCards?: number;
  numShreddedCards?: number;
  orgId?: number;
  personnelCount?: number;
}

export interface AssetNode {
  accessToNode: boolean;
  asset: Asset;
  description: string;
  modelId: number;
  modelName: string;
  nodeId: number;
  numChildren: number;
  numChildrenAccessible: number;
  parentId: number;
}

export interface AssetModel {
  mayCheckin: boolean;
  modelId: number;
  modelName: string;
  parentId: number;
  treeId: number;
  typeDescription: string;
  typeId: number;
}

export interface Asset {
  assetId: number;
  exportId: string;
  givesAccess: number[];
  lat: number;
  lng: number;
  mayEscort: number[];
  name: string;
  orgId: number;
  parentId: number;
  assetInfo?: AssetInfo[];
  orgName: string;
  polygon: Polygon;
  maxEntries: number;
  blockAfterMaxEntries: boolean;
  editable?: boolean;
  info: string;
}

export interface Polygon {
  polygonId: number;
  bb_nw_lat: number;
  bb_nw_lng: number;
  bb_se_lat: number;
  bb_se_lng: number;
  coordinates: PolygonCoordinate[];
}

export interface PolygonCoordinate {
  lat: number;
  lng: number;
}

export interface FormattedAccessHistory extends AssetEntry {
  [key: string]: any;
}

export interface AssetExtraValues {
  entryId: number;
  labelEn: string;
  labelNo: string;
  value: string;
}

export interface AssetEntry {
  asset: Asset;
  zone: Asset;
  checkInTime: string;
  checkOutTime: string;
  organizationNo: number;
  employerName: string;
  entryCount: number;
  escortedPersonNames: string;
  entryId: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  orgId: number;
  orgName: string;
  personnelId: number;
  expectedTimeToLeave: string;
  timeReceive?: number;
  checkedOutBy: number;
  checkedOutByLastName: string;
  checkedOutFirstName: string;
  checkinAttachments: checkInAttachment[];
  extraValues?: AssetExtraValues[];
  note: string;
  entryNoteByFirstName: string;
  entryNoteByLastName: string;
  noteTimestamp: string;
  travelLogs: TravelLog[];
  isIncomplete?: boolean;
  birthdate?: string;
  hsecardnumber?: string;
  isTraveling: boolean;
}

export interface TravelLog {
  assetEntryId: number;
  lat: number;
  lng: number;
  timestamp: string;
}

export interface checkInAttachment extends AssetEntry {
  attachmentId: number;
  entryId: number;
  fileName: string;
}

export enum SafetyCardLayout {
  Box = 1,
  Line
}

export const EMPTY = "";

export interface PostSafetycardObject {
  cardTypeId: number;
  application: boolean;
  attributes: Attribute[];
  orgId: number;
  responsibleAtOrg: string;
  validFromDateTime: string;
  validToDateTime: string;
}

export interface PostApplicationBase {
  attributes: Attribute[];
  comment: string;
}
export interface PostApplicationObject extends PostApplicationBase {
  application: boolean;
  orgId: number;
  responsibleAtOrg: string;
  validFromDateTime: string;
  validToDateTime: string;
  mailTo: string;
  cardTypeId: number;
}

export interface PostDoorKeyApplication extends PostApplicationBase {
  orgId: number;
  application: boolean;
}

export interface PermittoRights {
  id: number;
  admin: boolean;
  accessHistory: boolean;
  accessView: boolean;
}

export interface OurPermittoRights extends PermittoRights {
  rights: string;
  approver: boolean;
  organisations: number[];
}

export interface OrgPermittoRights extends PermittoRights {
  safetyCardRights: string;
  issuer: boolean;
  notificationSchedules: OrgNotificationSchedules;
  doorkeyAdmin: boolean;
  projectAdmin: boolean;
  assetAdmin: boolean;
  orgInsight: OrgPermittoRights[];
}

export interface UserIdentity {
  personnelId: number;
  allowUpdate: boolean;
  tosApproved: boolean;
  organisations: number[];
  ourPermittoRights: OurPermittoRights[];
  orgPermittoRights: OrgPermittoRights[];
  preferences: PreferredLanguage;
  issuePermissions: IssuePermission[];
}

export interface RwRight {
  hasNone: boolean;
  hasRead: boolean;
  hasWrite: boolean;
  hasReadWrite: boolean;
}

export interface IssuePermission {
  orgId: number;
  cardTypeId: number;
  issueToOwnEmpsOnly: boolean;
}

export interface PreferredLanguage {
  preferredLanguage: string;
}

export class AssetMarker extends L.Marker {
  id: number;
  assetId: number;
  entryCount: number;
  editMode?: boolean;
}

export enum PopupType {
  Table = 1,
  ImgAndTable
}

export interface StatusLog {
  info: string;
  status: string;
  statusDate: string;
  statusSetBy: string;
  statusSetByName: string;
  statusSetByOrgEmp: string;
}

export interface UpdateLog {
  updateId: number;
  updateTime: string;
  updatedBy: number;
  updatedByName: string;
  updateDetails: UpdateLogDetail[];
}

export interface UpdateLogDetail {
  updatedColumn: string;
  updatedAttribute: number;
  newValue: string;
  oldValue: string;
}

export interface Documentation {
  attachmentId: number;
  fileName: string;
  uploadDate: string;
  idDocType: number;
  mayDelete: boolean;
  validFrom: string;
  confirmationComment: string;
  description: string;
  uploadedBy: number;

  uploadedByFirstName?: string;
  uploadedByLastName?: string;

  validTo?: string;
  personnelId?: number;
}

export enum CardStatuses {
  CREATED = "CREATED",
  EMP_REJECTED = "EMP_REJECTED",
  EMP_APPROVED = "EMP_APPROVED",
  ORG_REJECTED = "ORG_REJECTED",
  ORG_APPROVED = "ORG_APPROVED",
  ISSUED = "ISSUED",
  RECEIPT_RECEIVED = "RECEIPT_RECEIVED",
  SHREDDED = "SHREDDED",
  STALE = "STALE_APPL_WARNED"
}

export enum EntryOperationType {
  empEntries = "empEntries",
  orgEntries = "orgEntries"
}

export interface MapEntry {
  assetId: number;
  entryCount: number;
  newEntriesCount: number;
  leavesEntriesCount: number;
  entryId: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  orgId: number;
  orgName: string;
}

export interface MyPendingActions {
  pendingReceipt: number;
  pendingUpdate: number;
}

export interface OurPendingActions {
  pendingApproval: number;
  pendingUpdate: number;
}

export interface OrgPendingActions {
  pendingApproval: number;
  pendingIssuing: number;
}

export interface Relation {
  registeredBy: string;
  registeredTime: string;
  name: string;
  orgId: number;
  personnelId: number;
}

export interface Attestor {
  email: string;
  name: string;
  phone: number;
  title: string;
  valid: boolean;
  timestamp?: string;
  status?: string;
}

export interface DocumentRequirement {
  allowConfirmationWithoutFile: boolean;
  attributeId: number;
  description: string;
  docTypeId: number;
  name: string;
  numDaysValid: number;
  optional: boolean;
  orgName: string;
  autoSelectDisabled: boolean;
  docCategoryId: number;
  docCategoryName: string;
  docCategoryOrg: number;
  defaultCategory: boolean;
}

export const enum MapClickEventSourceType {
  Map = 0,
  NotSet
}

export interface RouteProps {
  match: {
    path: string;
    params: {
      orgId?: string;
      employerId?: string;
      applicationId?: string;
      personId?: string;
      assetId?: string;
      cardId?: string;
      language?: string;
      typeId?: string;
    };
  };
  history: History;
  done?: () => void | Promise<void>;
  location: RouterLocation;
}

export interface RouterLocation {
  key?: string;
  pathname: string;
  search: string;
  hash: string;
  state: {
    [key: string]: any;
  };
}

export interface Notification {
  dayOfMonth: number;
  dayOfWeek: number;
  description: string;
  orgId: number;
  scheduleId: number;
  timeOfDay: string;
}

export interface NotificationSchedules {
  cardExpirationSchedule: number;
  docExpirationSchedule: number;
}

export interface OrgNotificationSchedules {
  approverNotificationSchedule: number;
  issuerNotificationSchedule: number;
  entryNotificationSchedule: EntryNotificationSchedule;
}

export interface EntryNotificationSchedule {
  daysOfWeek: number[];
  fromTime: string;
  toTime: string;
  hoursBeforeReminder: number;
}

export interface Language {
  prefferedLanguage: string;
}

export interface MaintenanceMessage {
  author: string;
  message: string;
  title: string;
}

export interface maintenanceMessageList {
  orgId: number;
  organization: OrganisationTemplate;
  message: MaintenanceMessage[];
}

export interface CardBase {
  cardId: number;
  firstName: string;
  lastName: string;
  eMail?: string;
  employerName: string;
  mobilePhone: string;
  validFromDateTime?: string;
  validToDateTime?: string;
  personnelId: number;
  birthDate: string;
}

export interface OtherCard extends CardBase {
  idOtherCardType: number;
  OtherCardTypeDesc: string;
  OtherCardTypeName: string;
  externalId: string;
  frontImageExists: boolean;
  backImageExists: boolean;
}

export interface ImgUrls {
  frontImgUrl: string;
  backImgUrl: string;
}

export interface ImgFiles {
  frontImgFile: File;
  backImgFile: File;
}

export type CardAndImgUrls = OtherCard & ImgUrls;
export type CardAndImgFiles = OtherCard & ImgFiles;

export interface OtherCardType {
  cardTypeDesc: string;
  cardTypeName: string;
  idOtherCardType: number;
}

export interface OtherCardTypeAndDates {
  idOtherCardType: number;
  externalId: string;
  validFromDateTime: string;
  validToDateTime: string;
  birthDate: string;
}

export interface Option {
  displayName: string;
  value: any;
  isDisabled?: boolean;
  callback?: () => void;
}

export interface TemplateType {
  templateTypeId: number;
  paging: boolean;
  name: string;
  description: string;
}

export interface TemplateDraft {
  name: string;
  statusId: DraftTemplateStatus;
  templateId: number;
  status: string;
  cardTypeId?: number;
  templateTypeId?: number;
  template: ApplicationTemplate;
}

interface DoorKeyBase {
  description: string;
  doorkeySystemNo: string;
  doorkeyType: string;
  serialNumber: number;
  statusComment: string;
}

export interface DoorKeysInfo extends DoorKeyBase {
  statusDateTime: string;
  doorkeyStatusName: string;
  keeperFirstName: string;
  keeperLastName: string;
  keeperHasSCard: string;
  employer: string;
  canBorrow: boolean;
  canDiscard: boolean;
  canDelete: boolean;
  canLose: boolean;
  canRecall: boolean;
  canReceive: boolean;
  canReinstate: boolean;
  canReserve: boolean;
  doorkeyStatusId: number;
  idDoorkey: number;
  keeperPersonnelId: number;
  keeperPhoneNumber: string;
  orgId: number;
  organization: string;
  keyType: string;
  keeperEmail: string;
  doorkeyComment: string;
  doorkeyDept: string;
}

export interface DoorKeyLogs {
  statusDateTime: string;
  actionPersonName: string;
  actionStatusText: string;
  keeperFirstName: string;
  keeperLastName: string;
  keeperEmployer: string;
  statusComment: string;
}

export interface DoorKeyFilters {
  doorkeyId: number;
  keyType: string;
  keeperFirstName: string;
  keeperLastName: string;
  doorkeySystemNo: number;
  doorkeySerialNo: number;
  actionStatus: string;
  statusDateTime: string;
}

export interface DoorKeysCreatePayload extends DoorKeyBase {
  numberOfKeys: number;
  doorkeyComment: string;
  doorkeyDept: string;
}

export interface DoorKeysUpdatePayload extends DoorKeyBase {
  doorKeyId: number;
  doorkeyComment: string;
  doorkeyDept: string;
}

export interface DoorKeysReservePayload {
  personnelId: number;
  reserveDetails: DoorkeyReserveDetails;
}

export interface InputDialogResult {
  success: boolean;
  text: string;
}

export interface DocumentationMasterInfo extends Documentation {
  uploadedBy: number;
  uploadedByFirstName: string;
  uploadedByLastName: string;
}
export interface DocumentationInfo extends PersonInfoBasics {
  documentation: DocumentationMasterInfo[];
}
export interface DocRequirementsInfo {
  docTypeId: number;
  name: string;
  numDaysValid: number;
}

export interface ConstructionProject {
  allVendors: boolean;
  cardForAccess: number;
  endDate: string;
  externalReference: string;
  lat?: number;
  lng?: number;
  name: string;
  orgId: number;
  projectId: number;
  projectVendors: number[];
  startDate: string;
  nodeId: number;
  parentNodeId: number;
  assetId: number;
  projectLeadPersonnelId: number;
  projectNumber: string;
  projectAddress: string;
  constructionClient: string;
  connectedNodes: ConnectedNodes[];
  gotConnectedNodes: boolean;
  maxEntries: number;
  blockAfterMaxEntries: boolean;
  polygon?: Polygon;
}

export interface ConnectedNodes {
  name: string;
  nodeId: number;
  asset?: Asset;
}

export interface AssetMessage {
  assetNode: AssetNode | null;
  created: string;
  enteredBy: number;
  enteredByName: string;
  eventType: number;
  fromDate: string;
  infoId: number;
  infoType: number;
  message: string;
  nodeId: number;
  toDate: string;
  blockAutoCheckout: boolean;
  repeats?: number;
}

export interface KeyValueType {
  key: number;
  value: string;
}

export interface CellRendererFrameworkProps {
  data: { [key: string]: string };
}

export interface ButtonProps {
  title: string;
  callback: () => void;
  tooltip: string;
  disabled: boolean;
  selected?: boolean;
  iconClass?: string;
  color?: string;
}

export interface DropdownCheckOptions {
  id?: number;
  name: string;
  checked: boolean;
  displayName: string;
  isDisabled?: boolean;
  callback?: () => void;
}

export interface DoorkeyApplicationGrid extends DoorKeyApplication {
  status: string;
  action: boolean;
}

export interface DoorkeyReserveApplicationInfo {
  personnelId: number;
  showDoorkeyApplicationModal: (val: boolean) => void;
  doorkeyApplicId: number;
}

export interface DoorkeyUpdateLog extends UpdateLog {
  doorKeyApplicId: number;
}

export interface TimestampKeyVal {
  timestamp: moment.Moment;
  val: JSX.Element;
}
export enum DoorKeyStatuses {
  CREATED = "CREATED",
  EMP_REJECTED = "EMP_REJECTED",
  EMP_APPROVED = "EMP_APPROVED",
  ORG_REJECTED = "ORG_REJECTED",
  ORG_APPROVED = "ORG_APPROVED",
  ISSUED = "ISSUED",
  RECEIPT_RECEIVED = "RECEIPT_RECEIVED",
  SHREDDED = "SHREDDED",
  RESERVED = "RESERVED"
}

export interface InfoField {
  label: string;
  name: string;
  value: string;
  validation?: { onValidate: () => boolean; failMsg: string };
  enabled: boolean;
}

export enum InfoColumn {
  FirstName = "firstName",
  LastName = "lastName",
  Mobile = "mobilePhone",
  Email = "email"
}

export interface PersonValues {
  [InfoColumn.FirstName]: string;
  [InfoColumn.LastName]: string;
  [InfoColumn.Mobile]: string;
  [InfoColumn.Email]: string;
}

export interface DoorkeyReserveDetails {
  doorkeyApplicId?: number;
  comment: string;
}

export enum ApplicContainsCardAttributes {
  False = 0,
  True,
  Undefined
}

export interface ProjectLead {
  personnelId: number;
  orgId: number;
  firstName: string;
  lastName: string;
  employerName: string;
}

export interface GridColumnFilter {
  name: string;
  columns: string;
  filters: string;
  id?: number;
}

export interface GridColumnFilterList {
  gridId: string;
  filters: GridColumnFilter[];
}

export interface PersonnelAttributeRole {
  roleId: number;
  sacaId: number;
  personelId: number;
}

export interface OrgOnDutyPerson {
  personnelId: number;
  orgId: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  dutyStart: string;
}
