import { observable, action } from "mobx";
import Logger from "./../app/view/logger";
import * as stackTrace from "stacktrace-js";
import { PostClientError } from "./../repo/index";
import { appConfig } from "./../config";
import { DialogType, dialog } from "../libs/dialogs/dialogs";
import { RouterLocation } from "../types";

type AppError = {
  title: string;
  text: string;
  error: Error;
};

const MAX_STRING_LENGTH = 4000;

class ErrorStore {
  @observable
  error: AppError | null;

  private logger: Logger;
  public urlStack: LocationStack;

  constructor() {
    this.logger = new Logger();
    this.urlStack = new LocationStack();
  }

  async wrap<T>(
    inner: () => Promise<T>,
    errorTitle: string,
    errorMsg: string
  ): Promise<T> {
    try {
      return await inner();
    } catch (e) {
      this.reportError({
        title: errorTitle,
        text: errorMsg,
        error: e
      });

      throw e;
    }
  }

  async catchError(
    p: Promise<any>,
    error: { title: string; text: string }
  ): Promise<any> {
    return p.catch(e => {
      this.reportError({
        title: error.title,
        text: error.text,
        error: e
      });
    });
  }

  renderDialog = async () => {
    const result = await dialog({
      type: DialogType.Alert,
      label: this.error.title,
      text: this.error.text
    });
    if (result) {
      this.clearErrors();
    }
  };

  @action("setError error-store")
  private setError = (error: AppError): boolean => {
    if (!this.error) {
      this.error = error;
      this.renderDialog();

      return true;
    } else {
      console.warn("ErrorStore.setError(): this.error is already set!");
      return false;
    }
  };

  reportError = async (err: AppError) => {
    try {
      if (appConfig.debug && err.error) {
        // tslint:disable-next-line
        console.error(err.error);
      }

      if (this.setError(err)) {
        console.group(
          "Error",
          appConfig.debug ? " (Debug mode)" : "",
          ": ",
          err.title,
          "\n\t",
          err.text
        );

        if (err.error) {
          let stackframes;

          try {
            stackframes = await stackTrace.fromError(err.error);
          } catch (e) {
            console.warn(
              "ErrorStore.reportError(): Failed parsing stacktrace",
              e
            );
            stackframes = undefined;
          }

          console.group("Error object:");
          console.log(err.error);
          if (stackframes && Array.isArray(stackframes)) {
            console.group("Stacktrace:");
            stackframes.forEach(frame => console.log(frame));
            console.groupEnd();
          }
          console.groupEnd();

          if (!appConfig.debug) {
            if (stackframes) {
              let jsonStackframes;
              try {
                jsonStackframes = JSON.stringify(stackframes);
              } catch (e) {
                console.warn(
                  "ErrorStore.reportError(): Failed converting stackframes to JSON",
                  e
                );
              }
              const logs = this.logger.getLogs();
              await PostClientError(
                window.navigator.userAgent,
                this.trunkStr(logs.httpCalls),
                this.createErrorMsg(err, this.urlStack.entries()),
                this.trunkStr(logs.actions),
                jsonStackframes ? this.trunkStr(jsonStackframes) : ""
              );
            }
          }
        } else {
          console.warn(
            "Exception not an instanse of Error. \n\tStacktrace information can't be parsed. \n\tWrap string exception in new Error()"
          );
        }
        console.groupEnd();
      }
    } catch (e) {
      /*
      console.error("ErrorStore.reportError(): The ironi", e);
      */
    }
  };

  createErrorMsg = (err: AppError, routes: RouterLocation[]) => {
    const msg = {
      url: routes ? routes : [],
      message: err.error.message ? err.error.message : ""
    };

    return this.trunkStr(JSON.stringify(msg));
  };

  trunkStr = str => {
    try {
      if (str.length > MAX_STRING_LENGTH) {
        return str.slice(0, MAX_STRING_LENGTH);
      } else {
        return str;
      }
    } catch (e) {
      console.warn("ErrorStore.reportError(): Failed slicing string!", e);
      return "Failed slicing string";
    }
  };

  @action("clearErrors error-store")
  clearErrors() {
    this.error = null;
  }

  @action("log errors")
  logErrors() {
    // tslint:disable-next-line
    console.log(this.logger.getLogs());
  }
}

class LocationStack {
  static readonly SizeLimit = 5;
  private urlStack: RouterLocation[];

  constructor() {
    this.urlStack = [];
  }

  push = (location: RouterLocation) => {
    if (this.urlStack.length >= LocationStack.SizeLimit) {
      this.urlStack = [...this.urlStack.slice(1), location];
    } else {
      this.urlStack.push(location);
    }
    // console.log(this.urlStack);
  };

  entries = () => {
    return [...this.urlStack];
  };
}

export const errorStore = new ErrorStore();
