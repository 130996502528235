import * as React from "react";
import styles from "./button.css";

import { Tooltip } from "./../../tooltip/tooltip-wrapper";
import { DropdownCheckOptions } from "../../../types";

interface ButtonPanelBtnProps {
  text?: string;
  disabled?: boolean;
  options?: DropdownCheckOptions[];
  callback?: Function;
  selected?: boolean;
  children?: any;
  showLoggedInUserText?: boolean;
  toolTip?: string;
  darkTheme?: boolean;
  color?: string;
  textColor?: string;
  large?: boolean;
  style?: React.CSSProperties;
  iconClass?: string;
  datacy?: string;
  setIndex?: number;
}

export default function Button(props: ButtonPanelBtnProps) {
  let btnStyle;

  if (props.darkTheme) {
    btnStyle = props.selected
      ? styles.buttonPanelButtonSelectedDark
      : styles.buttonPanelButtonDark;
  } else {
    btnStyle = props.selected
      ? styles.buttonPanelButtonSelected
      : styles.buttonPanelButton;
  }

  if (props.disabled) {
    btnStyle += ` ${styles.buttonPanelButtonDisabled}`;
  }

  function buttonPress() {
    {
      !props.disabled && props.callback ? props.callback() : null;
    }
  }

  return (
    <Tooltip text={props.toolTip ? props.toolTip : ""}>
      <div
        tabIndex={props.setIndex ? props.setIndex : 1}
        data-cy={props.datacy}
        key={`button-panel-button-${props.text}`}
        className={`${btnStyle} ${styles.item}`}
        style={{
          background: props.color ? props.color : "",
          height: props.large ? "40px" : "24px",
          color: props.textColor ? props.textColor : "",
          alignItems: "center",
          ...props.style
        }}
        onClick={buttonPress}
        onKeyUp={event => {
          if (event.which === 13) {
            buttonPress();
          }
        }}
      >
        <p className={styles.buttonPanelButtonText}>
          {props.iconClass ? (
            <span className={`${props.iconClass} ${styles.iconContainer}`} />
          ) : (
            ""
          )}
          {props.children ? props.children : props.text}
        </p>
      </div>
    </Tooltip>
  );
}
