import * as React from "react";
import { LocalRoutes } from "./local-routes";
import {
  // OrganisationTemplate,
  CardType
} from "../../types";

export const NedRoutes: LocalRoutes = {
  auth: {
    inputPIN: (byEmail: boolean) =>
      `Voer PIN in van ${byEmail ? "email" : "SMS"} `,
    sendByEmail: "E-mail PIN",
    rememberMe: "Onthoud mij",
    regUser: "Nieuwe gebruiker registreren",
    login: "Aanmelden",
    errorInputPhone: "Voer telefoonnummer in",
    errorSMSSent: "SMS al laat. Probeer het binnenkort opnieuw",
    noUserFound: "Geen gebruiker gevonden voor gegeven nummer",
    unknownError: "Onbekende fout. Probeer de pagina opnieuw te laden",
    errorInputPIN: "Voer PIN in",
    errorWrongPIN: "Onjuiste pincode",
    errorMultipleWrongPIN: "Onjuiste pincode, probeer het opnieuw",
    errorPinMustBeNumber: "Pin mag alleen uit cijfers bestaan",
    accessedByPhone: {
      title: "Waarschuwing",
      description:
        "Deze website is niet aangepast voor mobiele telefoons. Er is een aparte mobiele Permitto-applicatie beschikbaar om te downloaden.",
      continueWithout: "Blijf doorgaan"
    },
    ssoHelpText: "Of log in met",
    ssoNotAvailable: "Single Sign On niet beschikbaar voor uw werkgever",
    ssoBackButton: "Terug naar inloggen"
  },
  attest: {
    alreadyAttestedMessage: (message: string, name: string, datetime: string) =>
      `${message} ${datetime} door ${name}`,
    approvedMessage: "Aanvraag is goedgekeurd",
    rejectMessage: "Aanvraag is afgewezen"
  },
  admin: {
    applicationApprove: {
      Application: "Toepassing",
      ApplicantInfoHeader: "Zoeken",
      Approve: "Goedkeuren",
      ApproveWithoutAttestation: "Goedkeuren zonder attest",
      ApprovedWithoutAttestation:
        "Goedgekeurd zonder certificering. commentaar:",
      ChangeAttestor: "Verander het attest",
      ApproveCard: (cardName: string) =>
        `Goedkeuren ${cardName.toLocaleLowerCase()}`,
      ApproveCardWithoutAttestation: (cardName: string) =>
        `${cardName.toLocaleLowerCase()} goedkeuren zonder attestatie`,
      Issued: "Uitgevende",
      Reject: "Afwijzen",
      Delete: "Delete",
      DeleteApplicationTitle: "Toepassing verwijderen",
      DeleteApplicationText: "Hiermee wordt de toepassing permanent verwijderd",
      RejectApplication: "Aanvraag afwijzen",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} heeft geen bijbehorende toepassing. Weigering vernietigt ${cardName.toLowerCase()}. \\ NComment: `,
      EditApplication: "Applicatie bewerken",
      ResendAttestor: "Stuur email",
      GetAttestorLink: "Krijg certificaatlink"
    },
    applicationIssue: {
      CardNumber: (cardName: string) => `${cardName} nummer`,
      ApplicantInfoHeader: "Zoeken",
      Approve: "Goedkeuren",
      ApproveApplication: "Aanvraag goedkeuren",
      Dismiss: "Undo",
      Issued: "Uitgevende",
      IssuedCard: (cardName: string) => `Emittent ${cardName.toLowerCase()}`,
      Reject: "Afwijzen",
      RejectApplication: "Aanvraag afwijzen",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} heeft geen bijbehorende toepassing. Weigering vernietigt ${cardName.toLowerCase()}. \\ NComment: `,
      EditApplication: "Applicatie bewerken"
    },
    applicationListForApproval: {
      Open: "Open",
      NoApplication: "Geen aanvragen klaar voor goedkeuring",
      ApproveApplications: (numApplications: number) =>
        numApplications === 1
          ? `App ${numApplications} goedkeuren`
          : ` App ${numApplications} goedkeuren`,
      ApproveApplicationsDescription:
        "Weet u zeker dat u geselecteerde toepassingen wilt goedkeuren?",
      ApplicationsApproved: "Applicaties goedgekeurd",
      DeleteApplications: (numApplications: number) =>
        numApplications === 1
          ? `Toepassing ${numApplications} verwijderen`
          : ` Toepassing ${numApplications} verwijderen`,
      DeleteApplicationsDescription:
        "Weet u zeker dat u geselecteerde applicaties wilt verwijderen?",
      ApplicationsDeleted: "Applicaties verwijderd",
      Comment: "Commentaar",
      Action: "Vereist actie",
      CardType: "Kaarttype"
    },
    applicationListForIssue: {
      Open: "Open",
      NoApplication: (cardName: string) =>
        `Geen ${cardName} klaar om uit te geven`,
      IssueCards: (numCards: number, cardName: string) =>
        `Geef ${numCards} ${cardName} af`,
      AlertTitle: "Er is een fout opgetreden",
      AlertText: (cardName: string) =>
        `Er is een fout opgetreden bij het uitgeven van ${cardName.toLowerCase()}`,
      CardsIssued: (cardName: string) => `${cardName} uitgegeven`,
      NumCardsIssued: (numCards: number) => `${numCards} kaart uitgegeven`,
      Done: "Gedaan",
      Cancel: "Annuleren",
      IssueCardsDescription: (cardName: string) =>
        `Weet u zeker dat u de geselecteerde ${cardName.toLowerCase()} wilt uitgeven?`,
      Comment: "Commentaar"
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName} nummer`,
      EditMode: "Edit Mode",
      Save: "Save",
      Cancel: "Annuleren",
      Back: "Terug",
      Edit: "Uitgeven",
      CanNotEdit: (cardName: string) =>
        `Het is niet mogelijk om een ontvangstbewijs ${cardName.toLowerCase()} te bewerken`,
      Empty: "",
      LiveEditWarning:
        "Deze kaart is al uitgegeven. De gebruiker ontvangt daarom een e-mail met een overzicht van de wijzigingen die u aanbrengt. Wil je doorgaan?",
      SavingCardInProgress: "Kaart wordt opgeslagen, even geduld",
      Shred: "Stukje",
      IsShredded: (cardName: string) => `${cardName} is al versnipperd`,
      AreUSure: "Weet je het zeker?",
      SureToShred: (cardName: string) =>
        `Weet u zeker dat u deze ${cardName.toLowerCase()} wilt vernietigen?`,
      ShredButtonText: "Stukje",
      ShredAndCreateButtonText: "Versnipperen en nieuw maken",
      ShredAndCreateTitle: (cardName: string) =>
        `Makuler ${cardName.toLowerCase()}`,
      CreateNewSecurityCard: (cardName: string) =>
        `Maak een nieuwe ${cardName.toLowerCase()}`,
      ReNewCard: (cardName: string) => `Vernieuw ${cardName.toLowerCase()}`,
      RenewDisable: (cardName: string) => `${cardName} wordt niet versnipperd`,
      PrintCard: (cardName: string) => `Print ${cardName.toLowerCase()}`,
      ShredAlertTitle: (cardName: string) =>
        `Makuler ${cardName.toLowerCase()}`,
      ShredAlertText: (cardName: string) =>
        `Dit zal de ${cardName.toLowerCase()} vernietigen`,
      ShredBeforeRenewTitle: (cardName: string) =>
        `Vernieuw ${cardName.toLowerCase()}`,
      ShredBeforeRenewText: (cardName: string) =>
        `Dit zal ${cardName.toLowerCase()} vernietigen voordat het wordt vernieuwd`
    },
    splashView: {
      Permissions: "Adganger",
      PermHistory: "Adgangshistorikk",
      SafetyCard: (cardName: string) => cardName,
      Personnel: "Personeel",
      Applications: "Aanvragen voor goedkeuring",
      Issued: (cardName: string) => `${cardName} klaar om uit te geven`,
      SysAdm: "System management",
      Employers: "Leveranciers",
      OtherCards: "Safety cards",
      DoorKeys: "Sleutels",
      Documentation: "Documentatie",
      DoorKeyApplications: "Belangrijkste applicaties"
    },
    asset: {
      newModal: {
        header: ouder =>
          ouder ? `Maak een nieuwe zone onder ${parent}` : `Create facility`,
        Save: "Save",
        Cancel: "Annuleren"
      },
      admin: {
        Back: "Terug",
        Edit: "Uitgeven",
        Header: "",
        Save: "Save",
        Cancel: "Annuleren"
      },
      adminList: {
        Description: "Beschrijving",
        Type: "Type",
        ShowDetails: "Details weergeven",
        CreateNew: "Maak nieuw",
        Search: "Zoeken",
        Asset: "Constructie",
        AssetNode: "Groep",
        Edit: "Uitgeven",
        Delete: "Delete",
        AddChild: "Toevoegen aan",
        Save: "Save",
        Cancel: "Annuleren",
        AddTopNode: "Topknooppunt toevoegen",
        DeleteAssetAlertTitle: "Systeem verwijderen",
        DeleteAssetAlertText: "Hiermee worden planten permanent gewist"
      }
    },
    employerRights: {
      Admin: "Toegang",
      UserControl: "User management",
      UserApprover: (cardName: string) =>
        `Toepassing ${cardName.toLowerCase()} certificeren`,
      UserApproverInfo: (cardName: string) =>
        `Hiermee kan de gebruiker de toepassing ${cardName.toLowerCase()} certificeren.`,
      UserControlInfo:
        "Hiermee kan de gebruiker gebruikersrechten voor deze provider beheren\nHiermee kan de gebruiker personeel bekijken dat is geregistreerd als binnen een faciliteit."
    },
    orgRights: {
      HeaderOrgAdmin: (cardName: string) =>
        `Personeel / ${cardName.toLowerCase()}`,
      HeaderOrgAdminInfo: (cardName: string) =>
        `Leestoegang: geeft gebruiker toegang om gegevens in personeel / ${cardName.toLowerCase()} modules te lezen. \\ nSchrijftoegang: geeft gebruiker toegang om personeel in te lezen en te schrijven / ${cardName.toLowerCase()} - modules evenals de mogelijkheid om ${cardName.toLowerCase()} applicaties goed te keuren.`,
      HeaderOrgAccess: "Toegang / Adgangshistorikk",
      HeaderUserControl: "User management",
      HeaderUserControlInfo:
        "Hiermee kan de gebruiker gebruikersrechten voor deze site-eigenaar beheren.\nNB! dit is de hoofdrol in het systeem en moet slechts aan een paar mensen worden toegewezen!",
      HeaderUserIssuer: (cardName: string) =>
        `Geef ${cardName.toLowerCase()} uit `,
      HeaderDoorkeyAdmin: "Key management",
      HeaderDoorkeyAdminInfo:
        "Hiermee kan de gebruiker sleutels beheren voor deze site-eigenaar",
      HeaderProjectAdmin: "Project",
      HeaderProjectAdminInfo:
        "Hiermee kan de gebruiker projecten beheren voor deze site-eigenaar",
      HeaderUserIssuerInfo: (cardName: string) =>
        `Hiermee kan de gebruiker ${cardName.toLowerCase()} uitgeven.`,
      HeaderAccessHistory: "Adgangshistorikk",
      HeaderAccessHistoryInfo:
        "Hiermee kan de gebruiker toegangsgeschiedenis voor faciliteiten bekijken",
      HeaderAccessView: "Adganger",
      HeaderAccessViewInfo:
        "Hiermee kan de gebruiker de huidige toegang tot de voorzieningen bekijken.\nHiermee kan de gebruiker personeel bekijken dat is geregistreerd als binnen een faciliteit.",
      HeaderApproverSchedule: "Melden aan goedkeurder",
      HeaderApproverScheduleInfo: (cardName: string) =>
        `Meldingsniveau voor evenementen gerelateerd aan nieuwe ${cardName.toLowerCase()} toepassingen`,
      HeaderIssuerSchedule: "Kennisgever aan uitgever",
      HeaderIssuerScheduleInfo: (cardName: string) =>
        `Meldingsniveau voor ${cardName.toLowerCase()} gereed voor probleem`,
      HeaderEntrySchedule: "Bericht van aankomst",
      InfoHeader: "Info:",
      InfoClose: "Dicht",
      EditInstuctions:
        "Dubbelklik op een cel om een bepaald privilege te bewerken",
      HeaderAssetAdminInfo:
        "Hiermee kan de gebruiker plant- en plantmeldingen voor deze planteneigenaar beheren",
      HeaderEntryScheduleInfo:
        'Met deze rol kan een gebruiker zich abonneren op waarschuwingen in verband met. in- en uitchecken van faciliteiten. Een gebruiker kan de dagen en tijdsintervallen specificeren waarop hij op de hoogte wil worden gebracht. Als er binnen dit interval een in / uit-registratie plaatsvindt, ontvangt de gebruiker hiervan een e-mail. Daarnaast kunt u ervoor kiezen om op de hoogte te worden gesteld wanneer een gebruiker zich gedurende een bepaalde tijd in een faciliteit bevindt, dit wordt vermeld in het veld "Uren voor melding"',
      HeaderExistingUser: "Bestaande gebruiker",
      HeaderExistingUserAlert: "Persoon staat al in gebruikerslijst",
      AlertPersonAttriComboTitle: "Inschrijving bestaat al!",
      AlertPersonAttriComboText:
        "Deze combinatie van persoon en kenmerk bestaat al in de lijst.",
      AlertAllComboExistsTitle: "Dubbele invoer!",
      AlertAllComboExistsText:
        "Alle geldige combinaties van persoon en kenmerk bestaan ​​al in de lijst.",
      EditHeaderLabel: "Dubbelklik op een cel om het kenmerk te wijzigen",
      HeaderAssetAdmin: "Plant management",
      FromTime: "Van",
      ToTime: "Naar",
      HoursBefore: "Uren vóór herinnering",
      Monday: "Maandag",
      Thuesday: "Dinsdag",
      Wednesday: "Woensdag",
      Thursday: "Donderdag",
      Friday: "Vrijdag",
      Saturday: "Zaterdag",
      Sunday: "Zondag",
      InvalidValueTooltip: "Ongeldige waarde",
      SearchForPerson: "Selecteer persoon"
    },
    roles: {
      columndefs: {
        Role: "Opleiding goedkeuren",
        SafetyCardAttribute: "Beveiligingskaartkenmerk"
      },
      EditHeaderLabel: "Dubbelklik op een cel om het kenmerk te wijzigen",
      SelectAttriPlaceHolder: "Selecteer attribuut",
      ApproveAll: "Alles goedkeuren"
    },
    employers: {
      columndefs: {
        Address: "Adres",
        Contact: "Contact",
        Name: "Naam",
        OrganizationNo: "Reg.",
        ContactEmail: "Contact e-mail",
        ContactPhone: "Contact-telefoon",
        NumActiveCards: "Aantal actieve kaarten",
        NumShreddedCards: "Aantal versnipperde kaarten",
        OrgId: "Organisasjonsid",
        PersonnelCount: "Aantal personeelsleden",
        Country: "Land"
      },
      UpdateBtn: "Uitgeven",
      CreateBtn: "Toevoegen aan",
      DeleteBtn: "Delete",
      ModalUpdateHeader: "Uitgeven",
      ModalCreateHeader: "Maak nieuw",
      ModalSave: "Save",
      ModalCancel: "Annuleren",
      AlertTitle: "Waarschuwing",
      InvalidEmployer:
        "Gegevens zijn niet ingediend. Werkgever is niet geldig.",
      AutoCompleteTitle: "Partij",
      AddEmployerHeader: "Leverancier toevoegen",
      AddEmployer: (emp: string, orgName: string) =>
        `${emp} is geen provider voor ${orgName}. \\ nWilt u ${emp} toevoegen als provider voor ${orgName} `,
      EmployerAlreadyFound: "De leverancier staat al in de lijst",
      sendMail: "Stuur een e-mail naar de contactpersoon"
    },
    constructionProjects: {
      delete: "Delete",
      edit: "Uitgeven",
      add: "Voeg project toe",
      deleteTitle: "Project verwijderen",
      deleteText: "Hiermee wordt het project permanent verwijderd",
      modalHeader: "Project",
      modalSave: "Save",
      modalClose: "Dicht",
      projectName: "Project naam",
      projectNumber: "Projectnummer",
      projectAddress: "Adres",
      constructionClient: "Bouwer",
      projectLead: "Projectmanager",
      namePlaceholder: "Naam",
      startDate: "Start dato",
      endDate: "Einddatum",
      datePlaceholder: "Gegeven",
      accessCardType: "Kaarttypen voor toegang",
      lat: "Jaar",
      lng: "Lng",
      selectOnMapPlaceholder: "Typ coördinaten of druk op de kaart",
      clickToSetCoordinates: "Ongeldige coördinaten",
      referencePlaceholder: "Integration ID",
      externalReference: "Integration ID",
      allVendors: "Beschikbaar voor alle leveranciers",
      checkAllVendors: "Alle leveranciers",
      checkSomeVendors: "Geselecteerde leveranciers",
      latLngRequiredMessage: "Breedtegraad en lengtegraad zijn verplicht.",
      latLngValidateMessage:
        "Voeg geldige waarden voor lengte- en breedtegraad toe.",
      latLimitationMessage: "Breedtegraad moet tussen -85 en +85 zijn",
      lngLimitationMessage: "Lengtegraad moet tussen -180 en 180 liggen",
      projectNameRequiredMessage: "Projectnaam vereist.",
      ProjectHasAssets: "Het project heeft betrekking op voorzieningen",
      Ok: "OK",
      Clear: "Afgelegen",
      addNewNode: "Voeg faciliteiten toe",
      projecPostionSameAsAsset: "Projectpositie hetzelfde als asset",
      maxEntries: "Beperk het aantal items in activum tegelijkertijd",
      maxEntriesPlaceholder:
        "Voer het maximum aantal check-ins voor het project in",
      maxEntriesBlock:
        "Beperking is een harde limiet (gebruiker kan zich niet registreren als deze is bereikt)",
      maxEntryInputWarning:
        "Voer een geldig nummer in als een maximum aantal inzendingen",
      activeProjBtnLbl: "Toon actieve projecten",
      finishedProjBtnLbl: "Laat voltooide projecten zien"
    },
    entries: {
      newOperationalMsg: "Nieuw bericht",
      operationalMsgHeader: "Operationele berichten",
      showOneTimeMsg: "Toon eenmalige berichten",
      showRepeatingMsg: "Bekijk terugkerende berichten",
      regUserBtn: "Gebruiker inchecken",
      regTravelBtn: "Registreer reizen",
      modalHeader: "Gebruiker inchecken",
      modalTravelHeader: "Registreer reizen",
      selectPerson: "Kies persoon met toegang",
      selectProject: "Selecteer project",
      selectAsset: "Selecteer plant",
      selectSource: "Selecteer projecten / faciliteiten",
      selectFollowers: "Voeg mensen toe om te begeleiden",
      project: "Projecten",
      assetTree: "Constructie",
      submit: "Gebruiker registreren",
      confirm: "Dicht",
      emptyPerson: "Er zijn geen mensen met toegang gevonden!",
      statusText: (name: string) => `Check-in ${name} voltooid`,
      statusTextTraveling: (name: string) => `${name} is geregistreerd op reis`,
      statusFailText: (name: string) => `Check-in ${name} mislukt`,
      back: "Terug",
      proceed: "Ga je gang",
      choosenAsset: "Geselecteerde plant",
      choosenPerson: "Geselecteerde persoon",
      projectPlaceholder: "Zoeken op projectnaam",
      followersInputLabel:
        'Voer meerdere namen in, gescheiden door komma\'s ","',
      columnDefs: {
        delete: "Verwijderen",
        created: "Gemaakt",
        enteredByName: "Gemaakt door",
        assetName: "Plant",
        message: "Bericht",
        repeats: "Herhaald"
      },
      searchAsset: "Zoeken op plant",
      warnings: {
        maxAmount: (maxEntryAmount: number) =>
          `Slechts ${maxEntryAmount} mensen kunnen tegelijkertijd worden ingecheckt op dit faciliteiten / projecten.`,
        alreadyIn: (current: number) =>
          `er zijn al ${current} mensen op de faciliteit.`,
        alreadyReached: "limiet al bereikt.",
        parentReachedMaxAmount:
          "Ouderfaciliteit overschrijdt al het maximale aantal check-in.",
        reachedMaxAmount: "Maximaal aantal check-ins is bereikt",
        note: "Notitie",
        warning: "Waarschuwing"
      },
      selectAssetOwner: "Selecteer asset owner",
      displayingRows: "Weergeven",
      of: "van",
      entries: "inzendingen",
      clearFilter: "Filter resetten",
      enterExpectedTimeToLeave: "Voer de tijd in",
      time: "Tijd",
      expectedTimeToLeaveHeader: "Voer de verwachte vertrektijd in",
      addAssetMsgMenuItemText: "Faciliteitsmelding toevoegen",
      chkInMenuItemText: "Inchecken personeel",
      assetMsgDisabledTooltipText:
        "De faciliteit is van een andere eigenaar van de faciliteit",
      stdDisabledTooltipText: "Uitgeschakelde",
      gotoAsset: "Ga naar voorzieningen",
      noAdminAssetRightsTooltipText: "Mist rechten"
    },
    onDuty: {
      onDutyTitle: `Officier van dienst`,
      noAppointedOnDuty: "Geen dienstdoende officier aangewezen",
      seletNewOnDutyOfficer: "Selecteer nieuwe dienstdoende officier",
      currentOnDuty: "Huidige dienstdoende officier",
      newOnDuty: "Nieuwe officier",
      changeOnDutyInfoText: `Benoem een nieuwe dienstdoende officier door in het onderstaande veld te zoeken. Als er op dit moment geen dienstdoende officier aanwezig is, sla dan op zonder een nieuwe persoon te selecteren`
    }
  },
  assetView: {
    FirstName: "Voornaam",
    LastName: "Achternaam",
    Mobile: "De auto",
    Visitors: "Bezoekers",
    NoVisitors: "Geen bezoekers op stations",
    AssetHeader: "Naam plant:",
    AltImg: "Station Photo"
  },
  notification: {
    NoNotification: "Geen",
    Notifications: (orgName: string) =>
      orgName ? `Alerts - ${orgName}` : `Alerts`,
    NewApplicationsForApproval: (cardName: string) =>
      `Kennisgeving van nieuwe ${cardName.toLowerCase()} applicaties klaar voor verwerking`,
    ReadyForIssuing: (cardName: string) =>
      `Kennisgeving van ${cardName.toLowerCase()} klaar om uit te geven`
  },
  invitation: {
    Reject: "Niet accepteren",
    Accept: "Aanvaarden",
    FirstName: "Voornaam",
    EmptyFieldNotAllowed: "Dit veld kan niet leeg worden gelaten",
    LastName: "Achternaam",
    Mobile: "De auto",
    AtLeast: "Moet 10 tekens bevatten",
    Email: "E-mail",
    ValidEmail: "Moet een geldig e-mailadres zijn",
    Proceed: "Confirm",
    Welcome: "Welkom als gebruiker in Permitto",
    LogInAndDownload: "U kunt nu de Permitto-app downloaden en inloggen",
    LogIn: "Aanmelden",
    VerifyData: "Controleer of uw gegevens correct zijn."
  },
  my: {
    access: {
      NoRegAccess: (cardName: string) =>
        `Uw ${cardName.toLowerCase()} geeft geen toegang`,
      NoAssets: (cardName: string) =>
        `U hebt geen ${cardName.toLowerCase()} die toegang verleent tot faciliteiten / projecten`
    },
    application: {
      Delete: "Toepassing verwijderen",
      DeleteApplicationTitle: "Toepassing verwijderen",
      DeleteApplicationText: "Hiermee wordt de toepassing permanent verwijderd",
      ChangeAttestor: "Verander het attest",
      MissingValuesAttestor: "U moet alle verplichte velden invullen",
      Cancel: "Annuleren"
    },
    applicationList: {
      ColumnText: "Vereist actie",
      Attestor: "Officieel te bevestigen",
      Open: "Open",
      CardType: "Kaarttype"
    },
    profile: {
      AccessTo: (numStations: number) =>
        numStations === 1
          ? `Toegangsregistratie toegestaan op ${numStations} faciliteiten`
          : ` Toegangsregistratie toegestaan op ${numStations} faciliteiten`,
      Holds: (numCards: number, cardName: string) =>
        numCards === 1
          ? `Houdt ${numCards} geldig ${cardName.toLowerCase()}`
          : `Houdt ${numCards} geldig ${cardName.toLowerCase()}`,
      InfoFieldsHeader: "Informatie",
      HeaderNotificationTitle: "Meldingen bijwerken",
      HeaderTitle: "Update persoonlijke informatie",
      HeaderSave: "Save",
      HeaderCancel: "Annuleren",
      DialogSuccessText: "De informatie wordt opgeslagen",
      DialogSuccessTitle: "OK",
      DialogConfirmText: "Weet u zeker dat u de wijzigingen wilt annuleren?",
      DialogConfirmTitle: "Wijzigingen aangebracht",
      FooterText:
        "Als u andere gegevens dan telefoonnummer en e-mail wilt wijzigen, neemt u contact op met de ondersteuning via e-mail",
      FooterEmail: "Applicatie ondersteuning.",
      EditNotifications: "Meldingen bewerken",
      EditInfo: "Informatie bewerken"
    },
    safetycards: {
      ShowShredded: (cardName: string) => `Shred ${cardName.toLowerCase()}`,
      NoCardsToShow: (cardName: string) => `Geen ${cardName.toLowerCase()}`
    },
    splash: {
      Permissions: "Mijn toegang",
      AccessHistory: "Mijn toegangsgeschiedenis",
      Cards: (cardName: string) => `Mijn ${cardName}`,
      NewApplication: "Nieuwe applicatie",
      Applications: "Mijn toepassingen",
      Profile: "Mijn profiel",
      OtherCards: "Mijn HSE-kaarten",
      DoorKeys: "Mijn sleutels",
      NewDoorKeyApplication: "Nieuwe belangrijke toepassing",
      DoorkeyApplication: "Mijn belangrijkste toepassingen",
      ApplicDisableTooltip: (employerName: string) =>
        `Uw werkgever ${employerName} is niet geregistreerd als provider </br>\n           voor sommige site-eigenaren in Permitto. </br>\n           Daarom kunt u geen beveiligingskaart aanvragen </br>\n           of registreer de toegang tot projecten. </br>\n           Neem contact op met de planteneigenaar voor wie je wilt werken, zodat ze kunnen </br>\n           registreer ${employerName} als leverancier in Permitto. </br>\n           Neem voor meer hulp contact met ons op via e-mail </br>\n           app-support@verico.com en we zullen u verder helpen. </br> `
    },
    otherCards: {
      AddCard: "Nieuwe kaart toevoegen",
      DeleteTitle: "Kaart verwijderen",
      DeleteText: "Hiermee wordt de kaart permanent verwijderd"
    }
  },
  noAccess: {
    NoAccess: "Uw gebruiker heeft geen toegang tot deze pagina",
    NoAccessHeader: "401"
  },
  notFound: {
    PageNotFound: "Deze pagina is niet gevonden",
    PageNotFoundHeader: "404"
  },
  registerView: {
    registerInfoText:
      "Hier kunt u een nieuwe gebruiker in Permitto registreren. Wanneer dit is gebeurd, kunt u beveiligingskaarten aanvragen en ontvangen van de planteigenaars waaraan uw bedrijf is gekoppeld.",
    registerPrivacyPolicyLink: (link: string) => (
      <React.Fragment>
        Informatie over hoe uw gegevens worden verwerkt wordt beschreven in
        Permitto
        <a href={link}>
          <i>privacyverklaring</i>
        </a>
        .
      </React.Fragment>
    ),
    continue: "Telefoontje",
    phoneAlreadyRegistred: "Telefoonnummers zijn al geregistreerd in Permitto",
    createUserFailed: "Maken van gebruiker mislukt",
    typePhoneNumber:
      "Voer uw telefoonnummer in. Dit wordt gebruikt om u te identificeren in Permitto:",
    companyPrompt:
      "Geef aan in welk bedrijf u werkt. U kunt dit doen door te zoeken op naam of door uw organisatienummer in te voeren.",
    userCreated:
      "U kunt nu de Permitto-app downloaden en inloggen met uw telefoonnummer",
    gotoPermitto: "Ga naar Permitto",
    userCreatedHeader: "Gebruiker gemaakt",
    userIsBeingRegistered: " Nieuwe gebruiker registreren",
    firstName: "Voornaam",
    lastName: "Achternaam",
    eMail: "E-mail",
    registrer: "Registreer"
  },
  our: {
    rights: {
      HeaderEmployerAdmin: "Toegang",
      HeaderUserControl: "User management",
      HeaderUserApprove: (cardName: string) =>
        `Toepassing ${cardName.toLowerCase()} certificeren`,
      HeaderAccessHistory: "Adgangshistorikk",
      HeaderAccessView: "Adganger"
    },
    access: {
      NoPersonnel:
        "Geen personeel geregistreerd in faciliteiten / projecten waartoe u toegang hebt",
      PersonnelInField: "Personeel in het veld",
      menuItemGotoPerson: "Laat persoon zien",
      menuItemCheckoutPerson: "Rapporteer persoon uit faciliteit",
      menuItemCancelTravel: "Stop met reizen",
      menuItemUserArrived: "Waarschuw de gebruiker",
      menuItemCheckoutPersonList: "Verstuur sms",
      menuItemGotoPersonDisabled: "Ontbrekende rechten om naar persoon te gaan",
      menuItemCheckoutPersonDisabled:
        "Ontbrekende rechten om persoon uit te checken",
      menuItemChangeEscortedPersonnel: "Verander begeleiders",
      InfoLegendCheckOutFirst: "Rij gemarkeerd met ",
      InfoLegendCheckOutLast: "is al meer dan 24 uur in de faciliteit.",
      InfoLegendTimeToLeaveFirst: "Rij gemarkeerd met ",
      InfoLegendTimeToLeaveLast: "heeft de tijd om te vertrekken overschreden.",
      checkoutModalHeader: "Afrekenen bevestigen",
      cancelTravelHeader: "Stop met reizen",
      userArrivedHeader: "Waarschuw de gebruiker",
      checkoutModalConfirmBtn: "Confirm",
      checkoutModalCancelBtn: "Annuleren",
      checkoutModalSmsText: "Stuur een sms-melding",
      menuItemAssetNote: "Voer een notitie in",
      setAssetNoteText: "Voer een opmerking in over toegang",
      escortedModalHeader: "Verander begeleiders",
      escortedModalSubmitBtnLbl: "Verander"
    },
    applications: {
      Confirm: "Attest",
      ConfirmedApplications: "Certificaten applicatie",
      Reject: "Afwijzen",
      RejectApplication: "Aanvraag afwijzen",
      Delete: "Toepassing verwijderen",
      DeleteAppTitle: "Toepassing verwijderen",
      DeleteAppText: "Hiermee wordt de toepassing permanent verwijderd",
      ChangeAttestor: "Verander het attest",
      MissingValuesAttestor: "Vul alstublieft alle verplichte velden in",
      Cancel: "Annuleren",
      Application: "Toepassing"
    },
    applicationList: {
      DemandsAction: "Vereist actie",
      Open: "Open",
      Attestor: "Officieel te bevestigen"
    },
    profile: {
      ValidCards: (numCards: number, cardName: string) =>
        numCards === 1
          ? `${numCards} geldig ${cardName.toLowerCase()}`
          : `${numCards} geldig ${cardName.toLowerCase()}`,
      RegPersonnel: (numPersons: number) =>
        `${numPersons} geregistreerde personen`
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName} nummer`,
      Back: "Terug",
      Shredded: "Versnipperd",
      CardReceipted: (cardName: string) => `${cardName} is erkend voor`,
      EditMode: "Edit Mode",
      Save: "Save",
      Cancel: "Annuleren",
      ValidFrom: "Geldig vanaf",
      ValidTo: "Geldig voor",
      PrevValid: "Geldig van moet eerder zijn dan Geldig tot"
    },
    splash: {
      CardName: (cardTemplate: CardType) => cardTemplate.cardNameNo,
      Accesses: "Adganger",
      AccessHistory: "Onze toegangsgeschiedenis",
      SafetyCard: (cardName: string) => `Onze ${cardName.toLowerCase()}`,
      Personnel: "Ons personeel",
      NewApplication: "Nieuwe applicatie",
      Applications: "Onze toepassingen",
      NewCardNoApplic: (cardTemplate: CardType) =>
        `Nieuwe ${cardTemplate.cardNameNo}`,
      Profile: "Ons profiel",
      OtherCards: "Onze HSE-kaart",
      UserAccess: "User management",
      DoorKeys: "Onze sleutels",
      Documentation: "Onze documentatie",
      NewDoorKeyApplication: "Nieuwe belangrijke toepassing",
      DoorkeyApplication: "Onze belangrijkste toepassingen"
    },
    safetyCardList: {
      Issuer: "Issuer",
      Open: "Open",
      Grid: "Tafel",
      CardUpperCase: (cardName: string) => `${cardName}`
    }
  },
  templates: {
    buttons: {
      newtemplate: "Nieuw",
      startedit: "Uitgeven",
      endedit: "Stop met bewerken",
      savetemplate: "Save",
      saveastemplate: "Bewaar dat",
      previewtemplate: "Voorbeeld",
      applytemplate: "Gebruik",
      confirmTitle: "Wijzigingen aangebracht",
      confirmText: "Hiermee worden alle niet-opgeslagen wijzigingen verwijderd"
    },
    subSection: {
      ModalCancelText: "Annuleren"
    },
    dropdowns: {
      cardTypeName: (cardType: CardType) => cardType.cardNameNo,
      selectCardType: "Selecteer kaarttype",
      selectTemplate: "Selecteer sjabloon",
      selectDraft: "Kies concept",
      blankDraft: "Lege tijden",
      createDraft: "Maak een schets van de geselecteerde sjabloon",
      newDraft: "Het winkelcentrum",
      unSaved: "Niet opgeslagen",
      draftStatus: {
        inUse: "In gebruik",
        draft: "Tocht",
        historical: "Historisch",
        unknown: "Onbekend"
      },
      redeemedAfterIssueTemplateText: "Ingewisseld na uitgifte"
    },
    attributes: {
      newAttribute: "Selecteer attribuut",
      newAttributePlaceholder: "Zoeken naar bestaande attributen",
      addExistingAttribute: "Bestaande attentie toevoegen",
      setLocationAssetTreeButton: "Geef de positie van plantenbomen op",
      dataType: "Data type",
      lableNo: "Tekst nr",
      lableEn: "Tekst één",
      hyperlink: "Hyperlink",
      sectionOrder: "Sectie volgorde",
      sortOrder: "Sorteernummer",
      info: "Info",
      selectAttribute: "Selecteer attribuut",
      subSectionOrder: "Subsectie volgorde",
      descriptionEn: "Beschrijving One",
      descriptionNo: "Omschrijving nr",
      SelectType: "Kies type",
      requiredValue: "Vereiste waarde",
      requiredWhenVisible: "Vereist indien zichtbaar",
      role: "Rol",
      sectionNo: "Sectienaam - NO",
      sectionEn: "Sectienaam - EN",
      subSectionNameNo: "Subsectie naam - NO",
      subSsectionNameEn: "Subsectie naam - EN",
      ok: "OK",
      cancel: "Annuleren",
      addNewAttribute: "Kenmerk toevoegen",
      editAttribute: "Attribuut bewerken",
      editSectionName: "Bewerk sectienamen",
      editSubSection: "Subsectie bewerken",
      selectOperator: "Toon als",
      selectAttributes: "Selecteer attribuut (en)",
      displayOperandOr: "OF",
      displayOperandNor: "NIET",
      attriExistOnCardTitle: "Waarschuwing",
      attriExistOnCardText: (cardType: string) =>
        `Het kenmerk dat u toevoegt, staat al op de beveiligingskaart. Omdat het volledige ${cardType} in de toepassing wordt weergegeven, wordt dit kenmerk tweemaal aan de gebruiker gepresenteerd. Weet je zeker dat je dit wilt? `,
      associatedAssets: "Aangesloten faciliteiten"
    },
    confirmModal: {
      prompt: "Wilt u de wijzigingen in dit concept opslaan?",
      ok: "Save",
      cancel: "Discard",
      title: "Wijzigingen aangebracht",
      deleteAttributeModalTitle: "Attribuut verwijderen",
      deleteAttributeModalDescription: "Hiermee wordt het kenmerk verwijderd"
    },
    applyModal: {
      applyMessageTitle: "Gebruik sjabloon",
      applyMessageText:
        "Weet u zeker dat u deze sjabloon wilt instellen als de huidige actieve sjabloon?"
    },
    saveAsModal: {
      editTemplateNameTitle: "Sjabloonnaam bewerken",
      ok: "Save",
      cancel: "Negeren",
      editTemplateNamelabel: "Sjabloonnaam bewerken",
      templateNameRequiredMessage: "Sjabloonnaam vereist."
    },
    locationAssetTreeSelectModal: {
      title: "Geef de positie van plantenbomen op",
      defaultCheck: "Standaard"
    },
    postIssueRule: {
      InvalidatesCard: "Maakt de kaart ongeldig",
      RequiresApproval: "Vereist goedkeuring",
      postIssueRuleList: "Het attribuut staat al in de lijst. Wil je doorgaan?"
    }
  },
  doorKeys: {
    person: {
      GridHeaderText: "Sleutels",
      GridEmptyText: "Gebruiker heeft geen sleutels geregistreerd"
    },
    reserve: {
      keepPersonnel: "Reserveer voor",
      ModalTermsInfo: "Leningvoorwaarden",
      ModalReserveText: "Boek",
      ModalCancelText: "Annuleren",
      ModalHeaderText: "Reserve sleutels",
      ModalStatusComment: "Commentaar"
    },
    register: {
      SerialNumber: "Serienummer",
      SerialNumberStart: "Serienummer start",
      NumberOfKeys: "Aantal sleutels",
      ModalHeaderText: "Registreer nieuwe sleutels",
      ModalCancelText: "Annuleren",
      AlertLabelSingular: "Registersleutel",
      AlertLabelPlural: "Sleutels registreren",
      AlertOkButtonText: "Confirm",
      AlertCancelButtonText: "Annuleren",
      AlertMsg: (num: number) =>
        num === 1
          ? `Weet u zeker dat u ${num} nieuwe sleutel wilt registreren?`
          : `Weet u zeker dat u ${num} nieuwe sleutels wilt registreren? `,
      DoorkeySystemNo: "Systemnummer",
      DoorkeyType: "Key-type",
      Description: "Sleutel beschrijving",
      StatusComment: "Commentaar bij creatie",
      EnableKeySeries: "Registreer een nieuwe sleutelserie",
      DoorkeyComment: "Sleutel opmerking",
      DoorkeyDept: "Verantwoordelijk"
    },
    update: {
      ModalHeaderText: "Update belangrijke gegevens",
      ModalCancelText: "Annuleren",
      ModalUpdateText: "Bijwerken",
      DoorkeySystemNo: "Systemnummer",
      SerialNumber: "Serienummer",
      DoorkeyType: "Key-type",
      Description: "Sleutel beschrijving"
    },
    receive: {
      ReceivekeyHeader: "Bevestigingssleutel ontvangen"
    },
    discard: {
      DiscardKeyHeader: "Dozen sleutel",
      DiscardKeyMessage: "Weet u zeker dat u de sleutel wilt weggooien?",
      DiscardKeyConfirmBoxTitle: "Gooi de sleutel weg",
      DiscardKeyConfirmBoxText:
        "Hiermee wordt de sleutel permanent weggegooid en kan deze niet worden teruggedraaid",
      DiscardKeyConfirmBoxConfirmButtonText: "Dozen",
      DiscardKeyConfirmBoxCancelButtonText: "Annuleren"
    },
    return: {
      ReturnKeyHeader: "Ga terug naar de voorraad",
      ReturnKeyMessage:
        "Weet u zeker dat u de sleutel wilt terugzetten naar de opslag?"
    },
    deleteKey: {
      DeleteKeyHeader: "Sleutel verwijderen",
      WarningText: "Hiermee wordt de sleutel permanent verwijderd",
      ConfirmButtonText: "Delete",
      CancelButtonText: "Annuleren"
    },
    application: {
      ReservedLinkMessage: `Sleutel gereserveerd(klik om te bekijken)`
    },
    doorKeyList: {
      button: {
        DeleteKeyText: "Sleutel verwijderen",
        RegisterKeyText: "Registreer nieuwe sleutel",
        ReserveKeyText: "Reserve sleutel",
        SignForReturnKeyText: "Bevestigingssleutel ontvangen",
        RecallKeyText: "Terugroepsleutel",
        LostKeyText: "Sleutel verloren",
        ReturnToStoreText: "Ga terug naar de voorraad",
        DiscardKeyText: "Dozen sleutel",
        ReceiveKeyText: "Bevestigingssleutel ontvangen",
        UpdateKeyText: "Sleutel bewerken",
        FilterListPlaceholder: "Zoeken naar persoon of werkgever"
      },
      columnDefs: {
        DoorkeySystemNo: "Systemnummer",
        DoorkeyType: "Key-type",
        SerialNumber: "Serienummer",
        Description: "Sleutel beschrijving",
        StatusDateTime: "Status gegeven",
        DoorkeyStatusName: "Staat",
        KeeperFirstName: "Voornaam",
        KeeperLastName: "Achternaam",
        KeeperHasSCard: "Geldige kaart",
        Employer: "Partij",
        StatusComment: "Status reactie",
        Organization: "Key eigenaar",
        Info: "Info",
        AssetOwner: "Constructie eigenaar",
        HolderName: "Sleutelhouder",
        ApplicationId: "Toepassing",
        HolderPhone: "Sleutelhouder Tel.",
        Email: "Sleutelhouder email",
        IsTraveling: "Reizen",
        Note: "Noot",
        DoorkeyComment: "Sleutel opmerking",
        DoorkeyDept: "Verantwoordelijk"
      },
      lostKey: {
        ModalTitleText: "Sleutel verloren",
        ConfirmButtonText: "Verloren"
      },
      log: {
        columnDefs: {
          ActionPersonName: "Uitgevoerd door",
          ActionStatusText: "Handling",
          KeeperEmployer: "Partij",
          KeeperFirstName: "Voornaam",
          KeeperLastName: "Achternaam",
          StatusComment: "Commentaar",
          StatusDateTime: "Gegeven"
        }
      },
      returnKey: {
        ModalTitleText: "Bevestigingssleutel ontvangen",
        ConfirmButtonText: "Ontvangen"
      },
      recallKey: {
        ModalTitleText: "Terugroepsleutel",
        ConfirmButtonText: "Herroeping"
      }
    },
    doorKeyApplicationsList: {
      approveModal: {
        commentHeaderText: "Commentaar",
        commentSubHeaderText: "",
        headerText: "Goedkeuring van de belangrijkste toepassing",
        subHeaderText: ""
      },
      rejectModal: {
        commentHeaderText: "Commentaar",
        commentSubHeaderText: "",
        headerText: "Afgewezen aanvraag",
        subHeaderText: ""
      },
      doorkeyReserveModal: {
        doorkeyReserveHeaderText: "Reserve sleutel nu"
      },
      shredModal: {
        commentHeaderText: "Commentaar",
        commentSubHeaderText: "",
        headerText: "Toepassing verwijderen",
        subHeaderText: ""
      },
      resendModal: {
        commentHeaderText: "Commentaar",
        commentSubHeaderText: "",
        headerText: "Toepassing verwijderen",
        subHeaderText: ""
      },
      buttons: {
        approve: "Goedkeuren",
        reject: "Afwijzen",
        open: "Open",
        resend: "Opnieuw verzenden",
        back: "Terug",
        archive: "Bekijk actieve applicaties",
        nonArchive: "Bekijk archief",
        reserve: "Reserve sleutel",
        shred: "Toepassing verwijderen"
      },
      columnDefs: {
        applicId: "Applicatie ID",
        firstName: "Voornaam",
        lastName: "Achternaam",
        mobile: "De auto",
        email: "E-mail",
        employerName: "Partij",
        organisationName: "Organisatie",
        status: "Staat",
        actionRequired: "Actie vereist"
      },
      statuses: {
        applicationCreated: "Applicatie gemaakt",
        applicationEmployerApproved: "Aanvraag verzonden en in behandeling",
        applicationEmployerRejected: "Aanvraag afgewezen door leverancier",
        applicationOrganizationApproved:
          "Aanvraag goedgekeurd, wachtend op gereserveerde sleutel",
        applicationOrganizationRejected: "Aanvraag afgewezen. Actie vereist",
        applicationEmployerApprovedORG: "Toepassing gereed voor verwerking",
        applicationOrganizationShredded: "Applicatie verwijderd",
        applicationOrganizationRejectedORG:
          "Aanvraag afgewezen door de planteneigenaar",
        applicationOrganizationReserved: "Sleutel gereserveerd"
      }
    },
    kpiNames: {
      loanedKeys: "Uitgeleende sleutels",
      reservedKeys: "Gereserveerde sleutels",
      recalledKeys: "Opgeroepen sleutels",
      keyOwnersInvalidCard: "Eigenaars zonder geldige kaarten"
    }
  },
  documentation: {
    documentationList: {
      columnDefs: {
        employerName: "Partij",
        firstName: "Voornaam",
        lastName: "Achternaam",
        hasValidSafetyCard: (cardName: string) =>
          `Geldig ${cardName.toLowerCase()}`
      },
      columnDocumentationDetailsDefs: {
        fileName: "Bestandsnaam",
        documentRequirement: "Dokumentkrav",
        reference: "Referentie",
        comment: "Commentaar",
        uploaded: "Geüploade",
        validFrom: "Geldig vanaf",
        expiresFrom: "Vervalt",
        uploadedBy: "Geupload door",
        actions: "Acties",
        gridEmptyText: "Geen documenten"
      },
      lastNamePlaceholder: "Zoeken op achternaam",
      openButtonText: "Open",
      allDocumentTypes: "Alle documentsoorten",
      editImageButton: "Uitgeven",
      addDocButtonText: "Documentatie toevoegen",
      upload: {
        uploadButton: "Voortzetten",
        cancelButton: "Annuleren",
        uploadDocumentHeader: "Selecteer mensen",
        uploadProgressText:
          "Documentatie uploaden. Een ogenblik geduld alstublieft"
      }
    }
  },
  assetMessages: {
    add: "Toevoegen aan",
    edit: "Uitgeven",
    delete: "Delete",
    deleteTitle: "Verwijder bericht",
    deleteText: "Hiermee wordt het bericht permanent verwijderd",
    staticEventType: "Altijd",
    checkInEventType: "Bij aankomst faciliteiten",
    checkOutEventType: "Wanneer de plant wordt verlaten",
    showExpiredMessages: "Bekijk verlopen berichten",
    htmlType: "HTML",
    informationType: "Eenvoudige tekst"
  }
};
