import { appConfig } from "./../config";
import {
  PersonInfo,
  SafetyCard,
  UserIdentity,
  AssetNode,
  AssetModel,
  AssetEntry,
  OrgPendingActions,
  Employer,
  OrgPermittoRights,
  Asset,
  Notification,
  OrgNotificationSchedules,
  OtherCard,
  DoorKeysInfo,
  DoorKeysCreatePayload,
  DoorKeyLogs,
  DoorKeysUpdatePayload,
  DocumentationInfo,
  TemplateType,
  TemplateDraft,
  ConstructionProject,
  AssetMessage,
  CardType,
  DoorKeyApplication,
  DoorkeyReserveDetails,
  OrganisationTemplate,
  ApplicationTemplate,
  ApplicationAttribute,
  DoorKeyFilters,
  PostIssueRule,
  ProjectLead,
  GridColumnFilter,
  PersonnelAttributeRole,
  OrgOnDutyPerson
} from "./../types";
import {
  getJson,
  postJson,
  putOrPatchJson,
  deleteCall,
  postEmpty,
  getJsonOrVoid
} from "./fetches";
import { getAccessLogDateStr, joinUrlParams, UrlParamArg } from "./helpers";

const ORG_BASE_URL = appConfig.baseUrl + appConfig.serviceName;
const DOOR_KEYS = "doorkeys";

function joinOrgPersUrl(
  orgId: number,
  personnelId: number,
  endPoint: string,
  urlParams?: UrlParamArg
): string {
  const newEndpoint = endPoint.length > 0 ? `/${endPoint}` : "";
  return `${ORG_BASE_URL}/organisation/${orgId}/personnel/${personnelId}${newEndpoint}${joinUrlParams(
    urlParams
  )}`;
}

function joinOrgUrl(
  orgId: number,
  endPoint: string,
  urlParams?: UrlParamArg
): string {
  const newEndpoint = endPoint.length > 0 ? `/${endPoint}` : "";
  return `${ORG_BASE_URL}/organisation/${orgId}${newEndpoint}${joinUrlParams(
    urlParams
  )}`;
}

export async function getOrganisationOtherCards(
  orgId: number
): Promise<OtherCard[]> {
  return getJson<OtherCard[]>(
    joinOrgUrl(orgId, "othercards"),
    "getOrganisationAccessLog"
  );
}

export async function getOrganisationOtherCardTypes(
  orgId: number
): Promise<OtherCard[]> {
  return getJson<OtherCard[]>(
    joinOrgUrl(orgId, "othercards/types"),
    "getOrganisationAccessLog"
  );
}

function joinDoorKeyUrl(
  doorKeyId: number,
  endPoint: string,
  keepPersonnelId?: number
): string {
  const newEndpoint = endPoint.length > 0 ? `/${endPoint}` : "";
  const url = `${ORG_BASE_URL}/doorkeys/${doorKeyId}${newEndpoint}`;
  return keepPersonnelId !== null && keepPersonnelId !== undefined
    ? `${url}/${keepPersonnelId}`
    : url;
}

export async function getOrganisationAccessLog(
  orgId: number,
  fromDate: Date,
  toDate: Date
): Promise<AssetEntry[]> {
  return getJson<AssetEntry[]>(
    joinOrgUrl(orgId, "accesslog", getAccessLogDateStr(fromDate, toDate)),
    "getOrganisationAccessLog"
  );
}

export async function getOrganisationProjectAccessLog(
  orgId: number,
  fromDate: Date,
  toDate: Date
): Promise<AssetEntry[]> {
  return getJson<AssetEntry[]>(
    joinOrgUrl(
      orgId,
      "projects/accesslog",
      getAccessLogDateStr(fromDate, toDate)
    ),
    "getOrganisationAccessLog"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/safetycards/pending GET
export async function getOrganizationSafetycardsPendingCount(
  orgId: number
): Promise<OrgPendingActions> {
  return await getJson<OrgPendingActions>(
    joinOrgUrl(orgId, "safetycards/pending"),
    "getOrganizationSafetycardsPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/doorkeys/applications/pending GET
export async function getOrganizationDoorkeyApplicPendingCount(
  orgId: number
): Promise<number> {
  return await getJson<number>(
    joinOrgUrl(orgId, "doorkeys/applications/pending"),
    "getOrganizationDoorkeyApplicPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/rights GET
export async function getOrganisationRights(
  orgId: number
): Promise<UserIdentity[]> {
  return await getJson<UserIdentity[]>(
    joinOrgUrl(orgId, "rights"),
    "getOrganisationRights"
  );
}

export async function getOrgProjectLeadRights(
  orgId: number
): Promise<ProjectLead[]> {
  return await getJson<ProjectLead[]>(
    joinOrgUrl(orgId, "projects/rights"),
    "getOrganisationProjectRights"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/relation PUT
export async function postOrgPersonRelation(
  orgId: number,
  personnelId: number
): Promise<void> {
  return await postEmpty(
    joinOrgPersUrl(orgId, personnelId, "relation"),
    "postInvitation"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/invite?sendSMS={SENDSMS}&needTos={needTOS} PUT
export async function putInviteExistingUser(
  orgId: number,
  personnelId: number
): Promise<void> {
  return await putOrPatchJson(
    joinOrgPersUrl(orgId, personnelId, "invite"),
    "PUT",
    "",
    "postInvitation"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/tosinvitation?sendSMS={SENDSMS} POST
export async function postInvitePerson(
  orgId: number,
  person: PersonInfo,
  sendSms: boolean
): Promise<PersonInfo> {
  const url = joinOrgUrl(orgId, "personnel/tosinvitation", {
    name: "sendSMS",
    value: sendSms.toString()
  });
  const payload = JSON.stringify({
    email: person.email,
    firstName: person.firstName,
    lastName: person.lastName,
    mobilePhone: person.mobilePhone,
    organizationNo: person.organizationNo,
    attributes: person.attributes
  });

  return await postJson<PersonInfo>(url, payload, "postInvitePerson");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/rights/access POST
export async function postOrganisationRightsAccess(
  orgId: number,
  personnelId: number,
  rights: OrgPermittoRights
): Promise<OrgPermittoRights> {
  const url = joinOrgPersUrl(orgId, personnelId, "rights/access");
  const payload = JSON.stringify(rights);

  return postJson<OrgPermittoRights>(
    url,
    payload,
    "postOrganisationRightsAccess"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/rights/admin POST
export async function postOrganisationRightsAdmin(
  orgId: number,
  personnelId: number,
  rights: OrgPermittoRights
): Promise<OrgPermittoRights> {
  const payload = JSON.stringify(rights);
  return postJson<OrgPermittoRights>(
    joinOrgPersUrl(orgId, personnelId, "rights/admin"),
    payload,
    "postOrganisationRightsAdmin"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/rights DELETE
export async function deleteOrganisationRights(
  orgId: number,
  personnelId: number
): Promise<void> {
  return deleteCall(
    joinOrgPersUrl(orgId, personnelId, "rights"),
    "deleteOrganisationRights"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/assets/node/{NODEID} DELETE
export async function deleteAssetNode(orgId: number, nodeId: number) {
  return await deleteCall(
    joinOrgUrl(orgId, `assets/node/${nodeId}`),
    "deleteAssetNode"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/assets/{ASSETID} DELETE
export async function deleteAsset(orgId: number, nodeId: number) {
  return await deleteCall(joinOrgUrl(orgId, `assets/${nodeId}`), "deleteAsset");
}

export async function deleteEmployer(orgId: number, employerId: number) {
  return await deleteCall(
    joinOrgUrl(orgId, `employer/${employerId}`),
    "deleteEmployer"
  );
}
//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/safetycards?issued={ISSUED}&includeShredded={INCLUDESHREDDED}&includeAssets={INCLUDEASSETS}&nodeIds={nodeIds} GET
export async function getOrgSafetyCardsAndApplications(
  orgId: number,
  includeShredded: boolean,
  inlcudeAssets: boolean,
  issued: boolean,
  cardTypeId: number,
  nodeIds?: number[]
): Promise<SafetyCard[]> {
  const params = [
    { name: "issued", value: issued.toString() },
    { name: "includeShredded", value: includeShredded.toString() },
    { name: "cardTypeId", value: cardTypeId.toString() }
  ];
  if (nodeIds && nodeIds.length > 0) {
    params.push({ name: "nodeIds", value: JSON.stringify(nodeIds) });
  }

  return await getJson<SafetyCard[]>(
    joinOrgUrl(orgId, "safetycards", params),
    "getOrgSafetyCardsAndApplications"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{orgId}/personnel/{id}/safetycards/applications?includeShredded={includeShredded}
export async function getOrgApplicationsForPerson(
  orgId: number,
  personnelId: number,
  includeShredded: boolean
): Promise<SafetyCard[]> {
  return await getJson<SafetyCard[]>(
    joinOrgUrl(orgId, `personnel/${personnelId}/safetycards/applications`, [
      { name: "includeShredded", value: includeShredded.toString() }
    ]),
    "getOrgApplicationsForPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel GET
export async function getOrgPersonnel(orgId: number): Promise<PersonInfo[]> {
  const data = await getJson<PersonInfo[]>(
    joinOrgUrl(orgId, "personnel"),
    "getPersons"
  );
  return data;
  // return data.slice(0, 150);
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/on-duty  GET
export async function getOnDutyOfficer(
  orgId: number
): Promise<OrgOnDutyPerson> {
  const data = await getJsonOrVoid<OrgOnDutyPerson | null>(
    joinOrgUrl(orgId, "on-duty"),
    "getOnDutyOfficer"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/v5/organisation/{ORGID}/on-duty/{personnelId}  POST
export async function setOnDutyOfficer(
  orgId: number,
  personnelId: number
): Promise<void> {
  await postEmpty(
    joinOrgUrl(orgId, `on-duty/${personnelId}`),
    "setOnDutyOfficer"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{personnelID}?useTlf={USETLF} GET
export async function getPersonInOrg(
  personnelId: number,
  orgId: number
): Promise<PersonInfo> {
  return await getJson<PersonInfo>(
    joinOrgPersUrl(orgId, personnelId, ""),
    "getPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}?includeAssets={includeAssets} GET
export async function getOrganisation(
  orgId: number
): Promise<OrganisationTemplate> {
  return await getJson<OrganisationTemplate>(
    joinOrgUrl(orgId, ""),
    "getOrganisation"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}?includeAssets={includeAssets} GET
export async function getOrganisationWithToken(
  orgId: number,
  token: string
): Promise<OrganisationTemplate> {
  const url = joinOrgUrl(orgId, "");

  return await getJson<OrganisationTemplate>(url, "getOrganisationWithToken", {
    tokenKey: token
  });
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/safetycards/applications/{APPLICATIONID}/approve
export async function putOrganisationApproveApplication(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `safetycards/applications/${applicationId}/approve`),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrganisationApproveApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/safetycards/applications/{APPLICATIONID}/issue
export async function putOrganisationIssueApplication(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `safetycards/applications/${applicationId}/issue`),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrganisationIssueApplication"
  );
}
//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/safetycards/applications/{APPLICATIONID}/reject
export async function putOrganisationRejectApplication(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `safetycards/applications/${applicationId}/reject`),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrganisationRejectApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/assets/model GET
export function getOrgAssetsModelV2(assetId: number): Promise<AssetModel[]> {
  const url = `${ORG_BASE_URL}/organisation/${assetId}/assets/model`;
  return getJson(url, "getOrgAssetsModelV2");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/assets GET
export async function getOrgAssetsV2(
  orgId: number,
  assetId: number,
  includeProjects: boolean,
  token?: string
): Promise<AssetNode[]> {
  const url = joinOrgUrl(orgId, "assets/nodes", [
    { name: "parentId", value: assetId.toString() },
    { name: "includeProjects", value: includeProjects.toString() }
  ]);
  return await getJson<AssetNode[]>(url, "getOrgAssetsV2", {
    tokenKey: token
  });
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/assets/node POST
export async function postAssetsNodeV2(
  orgId: number,
  assetNode: AssetNode
): Promise<AssetNode> {
  return await postJson<AssetNode>(
    joinOrgUrl(orgId, "assets/node"),
    JSON.stringify(assetNode),
    "postAssetsNodeV2"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/assets POST
export async function postAsset(orgId: number, asset: Asset): Promise<Asset> {
  return await postJson<Asset>(
    joinOrgUrl(orgId, "assets/"),
    JSON.stringify(asset),
    "postAsset"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/assets/entries GET
export async function getOrgAssetEntries(orgId: number): Promise<AssetEntry[]> {
  return await getJson<AssetEntry[]>(
    joinOrgUrl(orgId, "assets/entries"),
    "getOrgAssetEntries"
  );
}

export async function getOrgAssets(orgId: number): Promise<Asset[]> {
  return await getJson<Asset[]>(joinOrgUrl(orgId, "assets"), "getOrgAssets");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/assets/entries GET
export async function getOrgProjectEntries(
  orgId: number
): Promise<AssetEntry[]> {
  return await getJson<AssetEntry[]>(
    joinOrgUrl(orgId, "projects/entries"),
    "getOrgAssetEntries"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/employers GET
export async function getOrgEmployers(orgId: number): Promise<Employer[]> {
  return await getJson<Employer[]>(
    joinOrgUrl(orgId, "employers"),
    "getOrgEmployers"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/employer POST
export async function postOrgEmployer(
  orgId: number,
  employer: Employer,
  empName: string,
  country: string,
  sendMail
): Promise<Employer> {
  const url = joinOrgUrl(orgId, "employer", [
    { name: "employerName", value: empName },
    { name: "country", value: country },
    { name: "sendMail", value: sendMail }
  ]);
  const payload = JSON.stringify(employer);
  return await postJson<Employer>(url, payload, "postOrgEmployer");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/employer PUT
export async function putOrgEmployer(
  orgId: number,
  employer: Employer
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, "employer"),
    "PUT",
    JSON.stringify(employer),
    "putOrgEmployer"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/schedules/updatenotification GET
export async function getNotificationValues(
  orgId: number
): Promise<Notification[]> {
  return await getJson<Notification[]>(
    joinOrgUrl(orgId, "schedules/updatenotification"),
    "getNotificationValues"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/notifications PUT
export async function setOrgNotifications(
  orgId: number,
  personnelId: number,
  values: OrgNotificationSchedules
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `personnel/${personnelId}/notifications`),
    "PUT",
    JSON.stringify(values),
    "putOrgEmployer"
  );
}
//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/personnel/{ID}/safetycards?includeShredded={INCLUDESHREDDED}
export async function getOrgSafetyCardsForPerson(
  orgId: number,
  personnelId: number,
  includeShredded: boolean
): Promise<SafetyCard[]> {
  return await getJson<SafetyCard[]>(
    joinOrgUrl(orgId, `personnel/${personnelId}/safetycards`, [
      { name: "includeShredded", value: includeShredded.toString() }
    ]),
    "getOrgSafetyCardsForPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/cards/types GET
export async function getOrgCardTypeList(orgId: number): Promise<CardType[]> {
  const data = await getJson<CardType[]>(
    joinOrgUrl(orgId, `cards/types`),
    "getOrgCardTypeList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/cards/{cardTypeId}/templates GET
export async function getOrgTemplateList(
  orgId: number,
  cardTypeId: number
): Promise<TemplateType[]> {
  const data = await getJson<TemplateType[]>(
    joinOrgUrl(orgId, `cards/${cardTypeId}/templates`),
    "getOrgTemplateList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/cards/{cardTypeId}/templates GET
export async function getOrgDoorekyApplicTemplateList(
  orgId: number
): Promise<ApplicationTemplate[]> {
  const data = await getJson<ApplicationTemplate[]>(
    joinOrgUrl(orgId, `doorkey/templates`),
    "getOrgDoorekyApplicTemplateList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/cards/{cardTypeId}/templates/{templateId} GET
export async function getOrgTemplateDraftList(
  orgId: number,
  cardTypeId: number,
  templateId: number
): Promise<TemplateDraft[]> {
  const data = await getJson<TemplateDraft[]>(
    joinOrgUrl(orgId, `cards/${cardTypeId}/templates/${templateId}`),
    "getOrgTemplateDraftList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/doorkey/templates GET
export async function getOrgDoorKeyTemplateDraftList( //TODO refactor after api is done
  orgId: number
): Promise<any[]> {
  const data = await getJson<TemplateDraft[]>(
    joinOrgUrl(orgId, `doorkey/templates`),
    "getOrgDoorKeyTemplateDraftList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token//organisation/{ORGID}/cards/templates/{TEMPLATEID}/activate PUT
export async function putOrgActiveTemplate(
  orgId: number,
  templateId: number
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `cards/templates/${templateId}/activate`),
    "PUT",
    "",
    "putOrgActiveTemplate"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token//organisation/{ORGID}/doorkey/template/{TEMPLATEID}/activate PUT
export async function putOrgActiveDoorkeyApplicTemplate(
  orgId: number,
  templateId: number
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `doorkey/template/${templateId}/activate`),
    "PUT",
    "",
    "putOrgActiveDoorkeyApplicTemplate"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token//organisation/{ORGID}/cards/templates/{TEMPLATEID} PUT
export async function putOrgTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  return await postJson<TemplateDraft>(
    joinOrgUrl(orgId, `cards/templates/${template.templateId}`),
    JSON.stringify(template),
    "putOrgTemplate",
    "PUT"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token//organisation/{ORGID}/doorkey/template/{TEMPLATEID} PUT
export async function putOrgDoorkeyApplicTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  return await postJson<TemplateDraft>(
    joinOrgUrl(orgId, `doorkey/template/${template.templateId}`),
    JSON.stringify(template),
    "putOrgDoorkeyApplicTemplate",
    "PUT"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token//organisation/{ORGID}/cards/templates/ POST
export async function postOrgTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  const url = joinOrgUrl(orgId, `cards/templates`);
  return await postJson<TemplateDraft>(
    url,
    JSON.stringify(template),
    "postOrgTemplate"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/doorkey/template/ POST
export async function postOrgDoorkeyApplicTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  const url = joinOrgUrl(orgId, `doorkey/template`);
  return await postJson<TemplateDraft>(
    url,
    JSON.stringify(template),
    "postOrgDoorkeyApplicTemplate"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{orgId}/templates/attributes GET
export async function getOrgAttributeList(
  orgId: number
): Promise<ApplicationAttribute[]> {
  const data = await getJson<ApplicationAttribute[]>(
    joinOrgUrl(orgId, `templates/attributes`),
    "getOrgAttributeList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{orgId}/templates/attributes GET
export async function getDoorkeyOrgAttributeList(
  orgId: number
): Promise<ApplicationAttribute[]> {
  const data = await getJson<ApplicationAttribute[]>(
    joinOrgUrl(orgId, `doorkey/templates/attributes`),
    "getDoorkeyOrgAttributeList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{ID}/doorkeys
export async function getOrgDoorkeysForPerson(
  orgId: number,
  personnelId: number
): Promise<DoorKeysInfo[]> {
  return await getJson<DoorKeysInfo[]>(
    joinOrgUrl(orgId, `personnel/${personnelId}/doorkeys`),
    "getOrgDoorkeysForPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/doorkeys GET
export async function getOrgDoorKeys(orgId: number): Promise<DoorKeysInfo[]> {
  const data = await getJson<DoorKeysInfo[]>(
    joinOrgUrl(orgId, "doorkeys"),
    "getOrgDoorKeys"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/doorkeys/applications GET
export async function getOrgDoorKeyApplicationList(
  orgId: number,
  archived: boolean
): Promise<DoorKeyApplication[]> {
  let url = joinOrgUrl(orgId, `doorkeys/applications`);
  url = `${url}?issued=${archived}`;

  const data = await getJson<DoorKeyApplication[]>(
    url,
    "getOrgDoorKeyApplicationList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/doorkeys/search?name={NAME}&limit={ROWCOUNT} GET
export async function getOrganisationDoorKeysFilterByName(
  orgId: number,
  name: string,
  rowCount: number
): Promise<DoorKeyFilters[]> {
  return await getJson<DoorKeyFilters[]>(
    joinOrgUrl(orgId, "doorkeys/search", [
      { name: "name", value: name },
      { name: "limit", value: rowCount.toString() }
    ]),
    "getOrganisationDoorKeysFilterByName"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/doorkeys/applications/{APPLICATIONID}/approve
export async function putOrganisationApproveDoorKeyApplication(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `doorkeys/applications/${applicationId}/approve`),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrganisationApproveDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/organisation/{ORGID}/doorkeys/applications/{APPLICATIONID}/reject
export async function putOrganisationRejectDoorKeyApplication(
  orgId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinOrgUrl(orgId, `doorkeys/applications/${applicationId}/reject`),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrganisationRejectDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/doorkeys POST
export async function postRegisterKey(
  orgId: number,
  registerInfo: DoorKeysCreatePayload
): Promise<void> {
  const url = joinOrgUrl(orgId, "doorkeys");
  const payload = JSON.stringify(registerInfo);
  return await postJson<void>(url, payload, "postRegisterKey");
}

//http://192.168.100.13:8085/permitto//rest/V3Internal.svc/token/doorkeys/{DOORKEYID} PUT
export async function putRegisterKey(
  doorKeysUpdatePayload: DoorKeysUpdatePayload
): Promise<void> {
  const url = `${ORG_BASE_URL}/${DOOR_KEYS}/${doorKeysUpdatePayload.doorKeyId}`;
  const res = await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(doorKeysUpdatePayload),
    "updateDoorKeys"
  );
  return res;
}

//http://192.168.100.13:8085/permitto//rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/discarded PUT
export async function putDiscardKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "status/discarded");
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "discardRegisterKey"
  );
}

export async function reserveDoorKey(
  doorKeyId: number,
  payload: DoorkeyReserveDetails,
  keepPersonnelId: number
): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "status/reserved", keepPersonnelId);
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(payload),
    "reserveRegisterKey"
  );
}
//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/reinstated PUT
export async function putReturnKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "status/reinstated");
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "reinstated"
  );
}

//http://192.168.100.13:8085/permitto//rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/lost PUT
export async function putOrgLostDoorKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "status/lost");
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrgLostDoorKey"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{DOORKEYID}/log GET
export async function getOrgDoorKeysLogsById(
  doorKeyId: number
): Promise<DoorKeyLogs[]> {
  const data = await getJson<DoorKeyLogs[]>(
    joinDoorKeyUrl(doorKeyId, "log"),
    "getOrgDoorKeysLogsById"
  );
  return data;
}

//http://192.168.100.13:8085/permitto//rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/received PUT
export async function putOrgSignReturnKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "status/received");
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrgSignReturnKey"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/{doorkeyId}/status/recalled PUT
export async function putOrgRecallDoorKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "status/recalled");
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putOrgRecallDoorKey"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/{doorkeyId} DELETE
export async function deleteOrgDoorKey(doorKeyId: number): Promise<void> {
  const url = joinDoorKeyUrl(doorKeyId, "");
  return await deleteCall(url, "deleteOrgDoorKey");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/documentation GET
export async function getOrgDocumentation(
  orgId: number
): Promise<DocumentationInfo[]> {
  const data = await getJson<DocumentationInfo[]>(
    joinOrgUrl(orgId, "personnel/documentation"),
    "getOrgDocumentation"
  );
  return data;
}

export async function getOrgProjects(
  orgId: number
): Promise<ConstructionProject[]> {
  const data = await getJson<ConstructionProject[]>(
    joinOrgUrl(orgId, "projects"),
    "getOrgProjects"
  );
  for (const p of data) {
    p.gotConnectedNodes = p.connectedNodes
      ? p.connectedNodes.length > 0
      : false;
  }

  return data;
}

export async function getOrgAssetMessages(
  orgId: number
): Promise<AssetMessage[]> {
  const data = await getJson<AssetMessage[]>(
    joinOrgUrl(orgId, "messages"),
    "getOrgAssetMessages"
  );
  return data;
}

export async function getOrgOperationalMessages(
  orgId: number
): Promise<AssetMessage[]> {
  const data = await getJson<AssetMessage[]>(
    joinOrgUrl(orgId, "messages/operational"),
    "getOrgOperationalMessages"
  );
  return data;
}

export async function getOrgProjectMessages(
  orgId: number
): Promise<AssetMessage[]> {
  const data = await getJson<AssetMessage[]>(
    joinOrgUrl(orgId, "project/messages"),
    "getOrgProjectMessages"
  );
  return data;
}

export async function createOrgProject(
  orgId: number,
  project: ConstructionProject
): Promise<ConstructionProject> {
  const payload = JSON.stringify({
    ...project,
    connectedNodeIds: project.connectedNodes.map(node => node.nodeId)
  });
  const data = await postJson<ConstructionProject>(
    joinOrgUrl(orgId, "projects"),
    payload,
    "getOrgProjects"
  );
  return data;
}

export async function updateOrgProject(
  orgId: number,
  project: ConstructionProject
): Promise<void> {
  const payload = JSON.stringify(project);
  const results = await putOrPatchJson(
    joinOrgUrl(orgId, "projects"),
    "PUT",
    payload,
    "getOrgProjects"
  );
  return results;
}

export async function deleteOrgProject(
  orgId: number,
  projectId: number
): Promise<void> {
  const results = await deleteCall(
    joinOrgUrl(orgId, `projects/${projectId}`),
    "getOrgProjects"
  );
  return results;
}

export async function getOrgProjectVendors(
  orgId: number,
  projectId: number
): Promise<number[]> {
  const data = await getJson<number[]>(
    joinOrgUrl(orgId, `projects/${projectId}/vendors`),
    "getOrgProjectVendors"
  );
  return data;
}

export async function updateOrgProjectVendor(
  orgId: number,
  projectId: number,
  vendors: number[]
): Promise<void> {
  const payload = JSON.stringify(vendors);
  const results = await putOrPatchJson(
    joinOrgUrl(orgId, `projects/${projectId}/vendors`),
    "PUT",
    payload,
    "updateOrgProjectVendor"
  );
  return results;
}

export async function updateOrgProjectAssets(
  orgId: number,
  projectId: number,
  assetNodes: number[]
): Promise<void> {
  const payload = JSON.stringify(assetNodes);
  return await putOrPatchJson(
    joinOrgUrl(orgId, `projects/${projectId}/assets`),
    "PUT",
    payload,
    "updateOrgProjectAssets"
  );
}

export async function addOrgAssetMessage(
  orgId: number,
  assetMessage: AssetMessage
): Promise<AssetMessage> {
  const payload = JSON.stringify([assetMessage]);
  const result = await postJson<AssetMessage>(
    joinOrgUrl(orgId, "messages"),
    payload,
    "addOrgAssetMessage"
  );
  return result;
}

export async function searchOrgAssets(
  orgId: number,
  query: string
): Promise<Asset[]> {
  const data = await getJson<Asset[]>(
    joinOrgUrl(orgId, `assets/search?query=${query}`),
    "searchOrgAssets"
  );
  return data;
}

export async function postPostIssueRule(
  orgId: number,
  postIssueRule: PostIssueRule
): Promise<PostIssueRule> {
  const payload = JSON.stringify([postIssueRule]);
  const result = await postJson<PostIssueRule>(
    joinOrgUrl(orgId, "cards/post-issue-rules"),
    payload,
    "postPostIssueRule"
  );
  return result;
}

export async function deletePostIssueRule(
  orgId: number,
  postIssueRule: PostIssueRule
): Promise<void> {
  const jsonPayload = JSON.stringify([
    {
      cardTypeId: postIssueRule.cardTypeId,
      attributeId: postIssueRule.attribute.attributeId
    }
  ]);
  return deleteCall(
    joinOrgUrl(orgId, "cards/post-issue-rules"),
    "deletePostIssueRule",
    jsonPayload
  );
}

export async function getFilterGrids(
  orgId: number,
  gridId: string
): Promise<GridColumnFilter[]> {
  return await getJson<GridColumnFilter[]>(
    joinOrgUrl(orgId, `grid/${gridId}`),
    "getFilterGrids"
  );
}

export async function saveGridFilter(
  orgId: number,
  gridId: string,
  filterData: GridColumnFilter
): Promise<GridColumnFilter> {
  const payload = JSON.stringify(filterData);
  const result = await postJson<GridColumnFilter>(
    joinOrgUrl(orgId, `grid/${gridId}`),
    payload,
    "saveGridFilter"
  );
  return result;
}

export async function removeGridFilter(
  orgId: number,
  filterId: number
): Promise<void> {
  return deleteCall(
    joinOrgUrl(orgId, `filter/${filterId}`),
    "deleteOrganisationRights"
  );
}

export async function getAssetStatus(assetId: number): Promise<number> {
  const url = `${ORG_BASE_URL}/assets/${assetId}/entry/status`;
  return getJson(url, "getAssetStatus");
}

export async function getPersonnelRoles(
  orgId: number
): Promise<PersonnelAttributeRole[]> {
  return await getJson<PersonnelAttributeRole[]>(
    joinOrgUrl(orgId, `roles`),
    "getPersonnelRoles"
  );
}

export async function updatePersonnelRoles(
  orgId: number,
  personnelId: number,
  roles: PersonnelAttributeRole[]
): Promise<number> {
  const payload = JSON.stringify(roles);
  const result = await postJson<number>(
    joinOrgUrl(orgId, `personnel/${personnelId}/roles/admin`),
    payload,
    "updatePersonnelRoles"
  );
  return result;
}
