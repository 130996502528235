export function AdminAccessLog() {
  return import("../routes/admin-access-log/admin-access-log");
}

export function AdminApplicationApprove() {
  return import(
    "../routes/admin-application-approve/view/admin-application-approve"
  );
}

export function AdminApplicationIssue() {
  return import(
    "../routes/admin-application-issue/view/admin-application-issue"
  );
}

export function AdminApplicationListForApproval() {
  return import(
    "../routes/admin-application-list-for-approval/view/admin-application-list-for-approval"
  );
}

export function AdminApplicationListForIssue() {
  return import(
    "../routes/admin-application-list-for-issue/view/admin-application-list-for-issue"
  );
}

export function AdminEmployerRights() {
  return import("../routes/admin-employer-rights/view/admin-employer-rights");
}

export function AdminOrgRights() {
  return import("../routes/admin-org-rights/view/admin-org-rights");
}

export function AdminOrgRoles() {
  return import("../routes/admin-org-roles/admin-org-roles");
}

export function AdminPerson() {
  return import("../routes/admin-person/view/admin-person");
}

export function AdminPersonList() {
  return import("../routes/admin-person-list/view/admin-person-list");
}

export function AdminSafetycard() {
  return import("../routes/admin-safetycard/view/admin-safetycard");
}

export function AdminSafetycardList() {
  return import("../routes/admin-safetycard-list/view/admin-safetycard-list");
}

export function AdminEmpoyers() {
  return import("../routes/admin-employers/view/admin-employers");
}

export function AdminSplashView() {
  return import("../routes/admin-splash-view/admin-splash-view");
}

export function AdminDoorKeysList() {
  return import("../routes/admin-doorkeys-list/admin-doorkeys-list");
}

export function AdminDoorKeyApplicationsList() {
  return import(
    "../routes/admin-doorkey-applications-list/admin-doorkey-applications-list"
  );
}

export function AdminDoorKeyApplicationView() {
  return import(
    "../routes/admin-doorkey-applications-view/admin-doorkey-applications-view"
  );
}

export function AdminDocumentationList() {
  return import("../routes/admin-documentation-list/admin-documentation-list");
}

export function AssetAdminList() {
  return import("../routes/asset-admin-list/view/asset-admin-list-v2");
}

export function AdminOtherCardsList() {
  return import("../routes/admin-cards/view/admin-cards");
}

export function AdminTemplates() {
  return import("../routes/admin-templates/admin-templates");
}

export function AdminDoorKeyTemplates() {
  return import("../routes/admin-doorkey-templates/admin-doorkey-templates");
}

export function AdminAssetMessages() {
  return import("../routes/admin-asset-messages/admin-asset-messages");
}

export function Invitation() {
  return import("../routes/invitation/view/invitation");
}

export function Tos() {
  return import("../routes/tos/tos");
}

export function TosView() {
  return import("../routes/tos/tos-view");
}

export function UpdateInfo() {
  return import("../routes/update-info/update-info");
}

export function AttestApplication() {
  return import("../routes/attest-application/view/attest-application");
}

export function MyAccess() {
  return import("../routes/my-access/view/my-access");
}

export function MyAccessLog() {
  return import("../routes/my-access-log/my-access-log");
}

export function MyApplication() {
  return import("../routes/my-application/view/my-application");
}

export function MyApplicationList() {
  return import("../routes/my-application-list/view/my-application-list");
}

export function MyProfile() {
  return import("../routes/my-profile/view/my-profile");
}

export function MyExternalCards() {
  return import("../routes/my-cards/view/my-cards");
}

export function MyCards() {
  return import("../routes/my-safetycards/view/my-safetycards");
}

export function MySafetycard() {
  return import("../routes/my-safetycard/view/safetycard");
}

export function MySplashView() {
  return import("../routes/my-splash-view/my-splash-view");
}

export function MyDoorKeys() {
  return import("../routes/my-doorkeys-list/my-doorkeys-list");
}

export function MyDoorkeyApplication() {
  return import(
    "../routes/my-doorkey-applications-list/my-doorkey-applications-list"
  );
}

export function MyDoorKeyApplicationView() {
  return import(
    "../routes/my-doorkey-applications-view/my-doorkey-applications-view"
  );
}

export function NoAccess() {
  return import("../routes/no-access/no-access");
}

export function NotFound() {
  return import("../routes/not-found/not-found");
}

export function OrgAssetEntries() {
  return import("../routes/org-asset-entries/org-asset-entries");
}

export function OurAccess() {
  return import("../routes/our-access/our-access");
}

export function OurAccessLog() {
  return import("../routes/our-access-log/our-access-log");
}

export function OurApplication() {
  return import("../routes/our-application/view/our-application");
}

export function OurApplicationList() {
  return import("../routes/our-application-list/view/our-application-list");
}

export function OurPerson() {
  return import("../routes/our-person/view/our-person");
}

export function OurPersonList() {
  return import("../routes/our-person-list/view/our-person-list");
}

export function OurProfile() {
  return import("../routes/our-profile/view/our-profile");
}

export function OurExternalCards() {
  return import("../routes/our-cards/view/our-cards");
}

export function OurSafetycard() {
  return import("../routes/our-safetycard/view/safetycard");
}

export function OurSafetycardList() {
  return import("../routes/our-safetycard-list/view/our-safetycard-list");
}

export function OurSplashView() {
  return import("../routes/our-splash-view/our-splash-view");
}

export function OurDoorKeys() {
  return import("../routes/our-doorkeys-list/our-doorkeys-list");
}

export function OurDoorkeyApplication() {
  return import(
    "../routes/our-doorkey-applications-list/our-doorkey-applications-list"
  );
}

export function OurDoorKeyApplicationView() {
  return import(
    "../routes/our-doorkey-applications-view/our-doorkey-applications-view"
  );
}

export function OurDocumentation() {
  return import("../routes/our-documentation-list/our-documentation-list");
}

export function SideBar() {
  return import("../shared/sidebar/view/sidebar");
}

export function Auth() {
  return import("../routes/auth/auth");
}
export function AuthSSONotAvailable() {
  return import("../routes/sso-not-available/sso-not-available");
}

export function RegisterUser() {
  return import("../routes/register/register-view");
}

export function AdminConstructionProjects() {
  return import(
    "../routes/admin-construction-projects/admin-construction-projects"
  );
}
