import * as repo from "./../repo";
import { DoorKeyApplication, Attribute } from "../types";
import { errorStore } from "../stores/index";
import { localizationStore } from "./../stores";

export async function sendApplication(
  doorkey: DoorKeyApplication,
  comment: string
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () => {
      const newApplication: DoorKeyApplication = await repo.postDoorKeyApplication(
        doorkey,
        comment
      );

      return newApplication.doorKeyApplicId;
    },
    local.error.title,
    `${local.error.sendError(local.area.application)}${local.contactSupport}`
  );
}

export async function sendUpdatedApplication(
  application: DoorKeyApplication,
  comment: string
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () => {
      await repo.putDoorKeyApplication(application);
      const allToUpdate: Attribute[] = [];
      for (const attrib of application.attributes) {
        const toUpdate: Attribute = {
          attributeId: attrib.attributeId,
          value: attrib.value,
          attachmentId: attrib.attachmentId
        };
        allToUpdate.push(toUpdate);
      }

      await repo.putDoorKeyApplicationResend(
        application.personnelId,
        application.doorKeyApplicId,
        comment
      );
      return application.doorKeyApplicId;
    },
    local.error.title,
    `${local.error.sendError(local.area.application)}${local.contactSupport}`
  );
}
