import {
  UserIdentity,
  OurPermittoRights,
  OrganisationTemplate,
  OrgPermittoRights,
  RwRight
} from "../types";
import { userStore, organisationStore } from "../stores";

/**
 * An employer have a set of connected orgs- these are organisations that the employer knows about in the system
 * These can be found in the _OurPermittoRights.organisations_ property.
 * This method find and returns OrgTemplates for these known organisations
 * @param employerId
 * @returns List of OrgTemplates
 */
export function getOurConnectedOrgs(
  employerId: number
): OrganisationTemplate[] {
  const currentOrgRights = userStore.userIdentity.ourPermittoRights.find(
    (rights: OurPermittoRights) => rights.id === employerId
  );
  const allOrgs = Array.from(organisationStore.organisations.values());
  const organisations =
    allOrgs && currentOrgRights
      ? allOrgs.filter(
          (org: OrganisationTemplate) =>
            currentOrgRights.organisations.indexOf(org.orgId) > -1
        )
      : [];
  return organisations;
}

/**
 * A user har as set of organisations they knows about in the system.
 * These organisations is connected to the user either by the users employer or by admin roles the user has in the system.
 * These may be found in the _UserIdentity.organisations_ list.
 * * This method find and returns OrgTemplates for these known organisations
 * @returns List of OrgTemplates
 */
export function getMyConnectedOrgs(): OrganisationTemplate[] {
  const allOrgs = Array.from(organisationStore.organisations.values());
  const organisations =
    allOrgs && userStore.userIdentity.organisations
      ? allOrgs.filter(
          (org: OrganisationTemplate) =>
            userStore.userIdentity.organisations.indexOf(org.orgId) > -1
        )
      : [];
  return organisations;
}

export function getNewRightsObject(
  personnelId: number,
  orgId: number,
  employerId: number
): UserIdentity {
  const newRights: UserIdentity = {
    issuePermissions: [],
    personnelId: personnelId,
    allowUpdate: false,
    tosApproved: false,
    organisations: [],
    preferences: { preferredLanguage: "no" },
    orgPermittoRights: [
      {
        id: orgId,
        safetyCardRights: "",
        admin: false,
        issuer: false,
        accessHistory: false,
        accessView: false,
        notificationSchedules: {
          approverNotificationSchedule: -1,
          issuerNotificationSchedule: -1,
          entryNotificationSchedule: {
            daysOfWeek: [],
            fromTime: "",
            toTime: "",
            hoursBeforeReminder: 0
          }
        },
        doorkeyAdmin: false,
        projectAdmin: false,
        assetAdmin: false,
        orgInsight: []
      }
    ],
    ourPermittoRights: [
      {
        id: employerId,
        organisations: [],
        rights: "",
        admin: false,
        approver: false,
        accessHistory: false,
        accessView: false
      }
    ]
  };

  return newRights;
}

type Rights = OrgPermittoRights | OurPermittoRights;
export class RightsLookup<T extends Rights> {
  private rightsMap: Map<number, T>;
  private defaultValue: T;

  constructor(data: Array<T>, defaultValue: T) {
    this.rightsMap = new Map();
    this.defaultValue = defaultValue;
    data.forEach(d => {
      this.rightsMap.set(d.id, d);
    });
  }

  get(
    key: number
  ): Readonly<{
    rights: Readonly<T>;
    rwRights: Readonly<RwRight>;
    exists: boolean;
  }> {
    const rights = this.rightsMap.get(key) || this.defaultValue;
    const rw = this.getRw(rights);
    return {
      rights,
      rwRights: {
        hasNone: rw === "",
        hasRead: rw.includes("R"),
        hasWrite: rw.includes("W"),
        hasReadWrite: rw.includes("R") && rw.includes("W")
      },
      exists: rights.id !== this.defaultValue.id
    };
  }

  private getRw(rights: Rights): string {
    if ("safetyCardRights" in rights) return rights.safetyCardRights;
    if ("rights" in rights) return rights.rights;
    throw new Error("Invalid Object in collection");
  }
}
