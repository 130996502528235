export default function filterArrayf<T>(
  seachWord: string,
  list: T[],
  getQueryField: (item: T) => string
) {
  return (list ?? []).filter(t => {
    if (seachWord && seachWord.length === 0) {
      return true;
    }

    const field: string = getQueryField(t);
    return field.toLowerCase().indexOf(seachWord.toLowerCase()) > -1;
  });
}
