import { MyPendingActions } from "../types";
import * as repo from "./../repo";
import { errorStore } from "../stores/index";
import { localizationStore } from "./../stores";

export function getPersonSafetycardsPendingCount(
  personnelId: number
): Promise<MyPendingActions> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonSafetycardsPendingCount(personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.pendingSC)}${local.contactSupport}`
  );
}

export function getPersonDoorkeysPendingCount(
  personnelId: number
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonDoorkeysPendingCount(personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.pendingSC)}${local.contactSupport}`
  );
}

export function getPersonDoorkeysApplicPendingCount(
  personnelId: number
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonDoorkeysApplicPendingCount(personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.pendingSC)}${local.contactSupport}`
  );
}
