import { TOKEN_NAME } from "./../app/view/const";

interface TokenContainer {
  tokenKey: string;
}

export class HTTPError extends Error {
  httpStatusCode: number;
  httpStatusText: string;

  constructor(httpStatusCode: number, httpStatusText: string, ...params) {
    super(...params);

    this.httpStatusCode = httpStatusCode;
    this.httpStatusText = httpStatusText;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }

    //Typescript target=ES5 hack
    Object.setPrototypeOf(this, HTTPError.prototype);
  }
}

export async function postJson<T>(
  url: string,
  jsonPayload: string,
  caller: string,
  actionType?: string
) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const payload: RequestInit = {
    credentials: "same-origin",
    method: actionType ? actionType : "post",
    headers: headers,
    body: jsonPayload
  };
  const response = await fetch(url, payload);
  if (response.ok) {
    //Testing for empty response
    const data = await response.text();
    if (data) {
      return <T>JSON.parse(data);
    } else {
      return null;
    }
  } else {
    throw new Error(
      `Url=${url} returned non-ok status: ${response.status}. Status text:${
        response.statusText
      }; caller=${caller}, payload=${JSON.stringify(payload)}`
    );
  }
}

export async function postEmpty(url: string, caller: string): Promise<void> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: headers
  });

  if (response.ok && 200 <= response.status && response.status < 300) {
    return;
  } else {
    throw new Error(
      `Url=${url} returned non-ok status: ${response.status}. Status text:${response.statusText}; Caller=${caller}`
    );
  }
}

export async function putEmpty(url: string, caller: string): Promise<void> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "PUT",
    headers: headers
  });

  if (response.ok && 200 <= response.status && response.status < 300) {
    return;
  } else {
    throw new Error(
      `Url=${url} returned non-ok status: ${response.status}. Status text:${response.statusText}; Caller=${caller}`
    );
  }
}

export async function getJson<T>(
  url: string,
  caller: string,
  token?: TokenContainer
): Promise<T> {
  const headers = new Headers();
  if (token && token.tokenKey) {
    headers.append(TOKEN_NAME, token.tokenKey);
  }

  const response = await fetch(url, {
    credentials: "same-origin",
    headers: headers
  });
  if (response.ok) {
    const responseBody: Promise<T> = response.json();
    return responseBody;
  } else {
    throw new HTTPError(
      response.status,
      response.statusText,
      `Url=${url} returned non-ok status: ${response.status}. Status text:${response.statusText}, caller=${caller}`
    );
  }
}

export async function getJsonOrVoid<T>(
  url: string,
  caller: string,
  token?: TokenContainer
): Promise<T> {
  const headers = new Headers();
  if (token && token.tokenKey) {
    headers.append(TOKEN_NAME, token.tokenKey);
  }

  const response = await fetch(url, {
    credentials: "same-origin",
    headers: headers
  });
  if (response.ok) {
    try {
      const bodyContent = await response.body.getReader().read();
      if (bodyContent.value) {
        const bodyStr = new TextDecoder().decode(bodyContent.value);
        return JSON.parse(bodyStr);
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  } else {
    throw new HTTPError(
      response.status,
      response.statusText,
      `Url=${url} returned non-ok status: ${response.status}. Status text:${response.statusText}, caller=${caller}`
    );
  }
}

export async function getRequestVoid(
  url: string,
  caller: string,
  token?: string
) {
  const headers = new Headers();
  if (token) {
    headers.append(TOKEN_NAME, token);
  }

  const response = await fetch(url, {
    credentials: "same-origin",
    headers: headers
  });
  if (response.ok) {
    return;
  } else {
    throw new Error(
      `Url=${url} returned non-ok status: ${response.status}. Status text:${response.statusText}, caller=${caller}`
    );
  }
}

export async function putOrPatchJson(
  url: string,
  method: "PUT" | "PATCH",
  json: string,
  caller: string,
  token?: string
): Promise<void> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (token) {
    headers.append(TOKEN_NAME, token);
  }
  let response;
  try {
    response = await fetch(url, {
      credentials: "same-origin",
      method: method,
      headers: headers,
      body: json
    });
  } catch (e) {
    //Network error
    throw {
      message: `Network Error\n  url: ${url} \n  method: ${method}\n  headers: ${headers}\n  json: ${json}; caller=${caller}`,
      originalException: e
    };
  }
  if (!response.ok) {
    throw new Error(
      `Wrong reponse: ${response.status};
        statusText:${response.statusText}; Caller=${caller}, payload=${json}`
    );
  }
}

export async function deleteCall(
  url: string,
  caller: string,
  jsonPayload: string = ""
): Promise<void> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const fetchBaseOptions = {
    credentials: "same-origin",
    method: "DELETE",
    headers: headers
  };
  const fetchOptions =
    jsonPayload !== ""
      ? { ...fetchBaseOptions, body: jsonPayload }
      : fetchBaseOptions;
  const response = await fetch(url, fetchOptions as any);
  if (response.status !== 200 && response.status !== 201) {
    throw new Error(`Wrong reponse - ${response.status}`);
  }
  return;
}

export async function deleteJson<T>(
  url: string,
  payload: string,
  caller: string
): Promise<T> {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const response = await fetch(url, {
    credentials: "same-origin",
    method: "DELETE",
    headers: headers,
    body: payload
  });

  if (response.status !== 200 && response.status !== 201) {
    throw new Error(`Wrong reponse - ${response.status}`);
  }

  if (response.ok) {
    const data = await response.text();
    return data ? <T>JSON.parse(data) : null;
  }
}

export async function uploadFile(
  url: string,
  method: "PUT" | "POST",
  file: File,
  caller: string
): Promise<void> {
  const formData = new FormData();
  formData.append(file.name, file, file.name);

  const response = await fetch(url, {
    credentials: "same-origin",
    method: method,
    body: formData
  });
  if (!response.ok) {
    throw new Error(
      `Wrong reponse: ${response.status};
            statusText:${response.statusText}; Caller=${caller}`
    );
  }
}

export async function getImageBlobUrl(
    url: string,
    caller: string
): Promise<string> {
  const headers = new Headers();
  headers.append("Content-Type", "image/png");
  const response = await fetch(url, {
    credentials: "same-origin",
    headers: headers
  });
  if (response.ok) {
    const responseBody: Blob = await response.blob();
    const responseUrl = URL.createObjectURL(responseBody);
    return responseUrl;
  } else {
    throw new HTTPError(
        response.status,
        response.statusText,
        `Url=${url} returned non-ok status: ${response.status}. Status text:${response.statusText}; Caller:${caller}`
    );
  }
}
