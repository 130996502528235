import "url-search-params-polyfill";
import styles from "./app.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./app";
import { isObservable, isObservableArray } from "mobx";

const domElement = document.getElementById("content");

//TODO Remove this for mobx version 5 or later (when using proxies)
const { isArray } = Array;

Array.isArray = (arg: any): arg is any[] => {
  if (isObservable(arg)) {
    console.warn(`Array.isArray(), dosn't work for mobx version 4`);
    return isObservableArray(arg);
  } else {
    return isArray(arg);
  }
};

const render = Component =>
  ReactDOM.render(
    <div className={styles.mountContainer}>
      <Component />
    </div>,
    domElement
  );

render(App);
