import * as repo from "./../repo";
import {
  AssetModel,
  Asset,
  AssetNode,
  CheckinPerson,
  ApplicationAttribute
} from "../types";
import { errorStore } from "../stores/index";
import { localizationStore } from "./../stores";

export function getGivesAccess(nodeIds: number[]): Promise<number[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getGivesAccess(nodeIds),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getUserAssetNodesAll(userId: number): Promise<AssetNode[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getUserAssetNodesAll(userId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getUserAssetsAll(userId: number): Promise<Asset[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getUserAssets(userId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getUserAssetNodes(
  userId: number,
  parentId: number
): Promise<AssetNode[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getUserAssetNodes(userId, parentId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getOrgAssetNodes(
  orgId: number,
  parentId: number,
  includeProjects: boolean,
  token?: string
): Promise<AssetNode[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgAssetsV2(orgId, parentId, includeProjects, token),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function deleteAssetNode(
  orgId: number,
  parentId: number
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteAssetNode(orgId, parentId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function deleteAsset(orgId: number, parentId: number): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteAsset(orgId, parentId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getSafetycardAssetNodes(
  cardId: number,
  parentId: number,
  token?: string
): Promise<AssetNode[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getSafetycardAssetNodes(cardId, parentId, token),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getSafetycardAssetNodesChecked(cardId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getSafetycardAssetNodesChecked(cardId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getNodesPath(orgId: number, nodeIds: number[]) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getNodesPath(orgId, nodeIds),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function postAssetsNode(
  orgId: number,
  node: AssetNode
): Promise<AssetNode> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postAssetsNodeV2(orgId, node),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function postAsset(asset: Asset): Promise<Asset> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postAsset(asset),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function putAsset(assetId: number, asset: Asset): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putAsset(assetId, asset),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function postImage(image: File, assetId: number): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.saveAssetImage(assetId, image),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function putAssetNode(
  nodeId: number,
  assetNode: AssetNode
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putAssetNode(nodeId, assetNode),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getOrgAssetsModel(assetId: number): Promise<AssetModel[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgAssetsModelV2(assetId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getSafetycardAssets(cardId: number): Promise<Asset[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getSafetycardAssets(cardId),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function checkoutEntry(
  assetId: number,
  entryId: number,
  sendSms: boolean
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.checkoutOrgEntry(assetId, entryId, sendSms),
    local.error.title,
    `${local.error.checkoutError(local.area.user)}${local.contactSupport}`
  );
}

export function sendSMS(orgId: number, recipients: number[], message: string) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.sendSMS(orgId, recipients, message),
    local.error.title,
    `${local.error.checkoutError(local.area.user)}${local.contactSupport}`
  );
}

export function setNote(entryId: number, note: string) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.setNote(entryId, note),
    local.error.title,
    `${local.error.fetchError(local.area.user)}${local.contactSupport}`
  );
}

export function getAuthorizedPersons(assetId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getAuthorizedPersons(assetId),
    local.error.title,
    `${local.error.fetchError(local.area.user)}${local.contactSupport}`
  );
}

export function checkinPerson(assetId: number, chkIn: CheckinPerson) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.checkinPerson(assetId, chkIn),
    local.error.title,
    `${local.error.fetchError(local.area.user)}${local.contactSupport}`
  );
}

export function changeEscortedPersonnel(
  assetId: number,
  entryId: number,
  escortedPersonnel: string[]
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.changeEscortedPersonnel(assetId, entryId, escortedPersonnel),
    local.error.title,
    `${local.error.fetchError(local.area.user)}${local.contactSupport}`
  );
}

export function getCheckInAttachment(
  entryId: number,
  attachmentId: number
): string {
  return repo.getCheckInAttachment(entryId, attachmentId);
}

export function search(orgId: number, query: string) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.searchOrgAssets(orgId, query),
    local.error.title,
    `${local.error.fetchError(local.area.user)}${local.contactSupport}`
  );
}

export function getRequirmentList(
  cardTypeId: number,
  nodeIds: number[]
): Promise<ApplicationAttribute[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getNodeAttributeReqForSubNodes(cardTypeId, nodeIds),
    local.error.title,
    `${local.error.fetchError(local.area.stations)}${local.contactSupport}`
  );
}

export function getAssetStatus(assetId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getAssetStatus(assetId),
    local.error.title,
    `${local.error.fetchError(local.area.user)}${local.contactSupport}`
  );
}
