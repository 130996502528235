import {
  CardsList,
  Card,
  Access,
  AccessLog,
  ApplicationList,
  DoorKeys,
  ExternalCards,
  Splash,
  Profile,
  Application
} from "../consts";

export function GetMyApplicationListUrl() {
  return ApplicationList;
}

export function GetMyApplicationUrl(applicationId: number) {
  return Application + applicationId;
}

export function GetMyCardsListUrl() {
  return CardsList;
}

export function GetMyCardUrl(cardId: number) {
  return Card + cardId;
}

export function GetMyPermittoUrl() {
  return Splash;
}

export function GetMyProfileUrl() {
  return Profile;
}

export function GetMyExternalCardsUrl() {
  return ExternalCards;
}

export function GetMyDoorKeysUrl() {
  return DoorKeys;
}

export function GetMyAccessUrl() {
  return Access;
}

export function GetMyAccessLogUrl() {
  return AccessLog;
}

export function GetMyDoorKeyApplicationViewIdUrl(doorKeyApplicId: number) {
  return `${DoorKeys}${ApplicationList}${doorKeyApplicId}`;
}

export function GetMyDoorKeyApplicationsUrl() {
  return `${DoorKeys}${ApplicationList}`;
}
