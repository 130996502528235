import * as React from "react";
import { LocalShared } from "./local-shared";
import {
  // OrganisationTemplate
  CardType
} from "../../types";

export const NorShared: LocalShared = {
  expertiseList: {
    greenStatus: "OK",
    yellowStatus: "Utløper snart",
    redStatus: "Utløpt",
    unknownStatus: "Ukjent",
    missingStatus: "Mangler",
    masterGridColumns: {
      status: "Status",
      type: "Type",
      expire: "Gyldig til",
      count: "# dokumenter",
      requiredBy: "Påkrevd av"
    }
  },
  documentations: {
    documentation: "Dokumentasjon",
    info: "Det forekommer ingen fil i forbindelse med denne dokumentasjonen. Dokumentasjonen består av en dato og referanse for et eventuelt kurs",
    date: "Gyldig fra:",
    confirmComment: "Dokumentasjonsreferanse:",
    description: "Valgfri kommentar:"
  },
  statuslog: {
    header: `Historikk`,
    name: "Navn: ",
    number: "Telefonnummer: ",
    comment: "Kommentar: ",
    title: "Tittel: ",
    email: "Epost: ",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    assetList: "Anleggsliste",
    cardChanged: (cardName: string) => `${cardName} endret`,
    oldValue: (val: string) => `Gammel verdi: ${val}`,
    newValue: (val: string) => `Ny verdi: ${val}`,
    assetCount: (val: string) => `Antall anlegg - ${val}`
  },
  batchUpdate: {
    AlertTitle: "En feil oppsto",
    AlertText: "En feil oppsto under innsending",
    Cancel: "Avbryt",
    Done: "Ferdig"
  },
  admin: {
    rights: {
      ReadAccess: "Lesetilgang",
      WriteAccess: "Skrivetilgang",
      PersonPlaceHolder: "Søk etter person for å legge til",
      Name: "Navn",
      EmptySearch: "Ingen treff",
      RemoveHeader: "Fjern",
      Live: "Live",
      Daily: "Daglig",
      Weekly: "Ukentlig",
      Monthly: "Måntlig"
    }
  },
  asset: {
    popup: {
      EmptyStation: "Ingen i stasjonen",
      AltImg: "Stasjonsbilde",
      NameCol: "Navn",
      AreaCol: "Område",
      EntriesCountCol: "#",
      TableFooter: "Oversikt over personell i stasjon",
      EntryText: "Innsjekkede personer",
      MissingInfo: "Mangler info",
      ShowInfo: "Vis info",
      HideInfo: "Skjul info"
    },
    userpopup: {
      travelingTo: "Reiser til",
      registeredAt: "Registrert på",
      lastKnownPosition: "Posisjon",
      timestamp: "Tidspunkt",
      username: "Bruker",
      station: "stasjon"
    }
  },
  buttons: {
    ConfirmTitle: "Vil du avslutte innsendingen?",
    ConfirmText: "Endringer vil ikke bli lagret.",
    Back: "Tilbake",
    changeSplitAlignment: "Adganger/kart"
  },
  dialog: {
    Cancel: "Avbryt",
    NoComment: "Ingen kommentar",
    Yes: "Ja",
    No: "Nei",
    Confirm: "Fortsett",
    InputText: "Kommentar",
    Ok: "Ok",
    DeleteRightRowTitle: "Slett rad",
    DeleteRightRowText: "Dette vil slette radene og rettighetene på den"
  },
  editIndBar: { Required: "Du må fylle ut" },
  employer: {
    Accesses: "Adganger",
    Safetycards: (cardName: string) => `Våre ${cardName.toLowerCase()}`,
    Personnel: "Vårt personell",
    NewApplication: "Ny søknad",
    Applications: "Våre søknader",
    Profile: "Vår profil",
    UserAccess: "Brukerstyring",
    OrgPlaceHolder: "Organisasjonsnr.",
    EmployerSearchPlaceHolder: "Søk på bedrift",
    EmployerPlaceHolder: "Bedriftsnavn"
  },
  personDocuments: {
    Header: "Dokumenter",
    ErrorTitle: "Feil",
    ErrorMessage: "Kunne ikke hente inn dokument",
    NoDocumentsMessage: (companyName: string, cardName: string) =>
      `Denne personen har ingen ${cardName.toLowerCase()} knyttet til ${companyName} og dokumentasjon er derfor ikke tilgjengelig`
  },
  error: {
    HeaderText: "Nettverksfeil.",
    Details: "Vis detaljert feilmelding",
    Retry: "Prøv på ny"
  },
  templates: {
    card: {
      card: {
        Section: "Seksjon",
        SubSection: "Underseksjon",
        Header: (cardName: string) => `${cardName} mal`
      },
      safetyCardAttribute: {
        requiredForCard: `Påkrevd verdi`,
        type: "Type",
        true: "JA",
        false: "NEI",
        dependanciesToolTip: "Krav",
        dependancyNotFound: attributeID => {
          return `Den påkrevde attributen med ID ${attributeID} ble ikke funnet i valgt mal.`;
        },
        required: "Påkrevd"
      }
    }
  },
  file: {
    handle: {
      DocReqOwner: "Påkrevd av",
      ColRequired: "Påkrevd",
      Expires: "Utløper",
      ColFileName: "Filnavn",
      ColAction: "Handlinger",
      ColReference: "Referanse",
      ColOrg: "Anleggseier",
      ColOptComment: "Valgfri kommentar",
      ColComment: "Kommentar",
      ColName: "Navn",
      ColDate: "Gyldig fra",
      ColValidDays: "Dager gyldig",
      ColDateUploaded: "Lastet opp",
      ColDocumentRequirement: "Dokumentkrav",
      ConfirmDeleteTitle: "Slett dokument(er)",
      ConfirmDeleteText: "Dette vil slette dokument(ene)",
      MyFiles: "Min dokumentasjon",
      NoFile: "Ingen fil",
      DocumentsNotFound: "Ingen dokumenter funnet",
      Choose: "Velg",
      FileToBigTitle: "En feil har oppstått",
      FileToBigText: "Filen er for stor",
      DropZoneText: "Slipp et dokument her for å laste det opp",
      SaveDemands: "Du må velge en fil og en beskrivelse for å lagre",
      Upload: "Last opp",
      AddDocumentation: "Legg til dokumentasjon",
      AddNewDocumentationWithoutFile: "Legg til dokumentasjon uten fil",
      DeleteFile: "Slett fil(er)",
      CannotDelete:
        "Kan ikke slette fil(er) da den/de er brukt i en sikkerhetskortsøknad",
      ShowFile: "Vis fil",
      DocumentRequirements: "Dokumentkrav",
      RequirementCovered: "Krav dekket",
      SelectDocumentRequirement: "Velg dokumenttype",
      SelectDate: "Sett en start-dato for gyldighetsperioden",
      SelectDocument: "Last opp dokument til ",
      SelectDocumentRequirementHeader: "Last opp dokumentasjon",
      Cancel: "Avbryt",
      Select: "Velg dokumentasjon",
      SelectNoFile: "Lagre dokumentasjon",
      SelectDatePlaceholder: "Velg dato",
      UploadedBy: "Opplastet av",
      confirmationComment: "Dokumentasjonsreferanse",
      confirmationCommentMissing: "Dokumentasjonsreferanse er påkrevd",
      isUploading: "Laster opp...",
      requiredBy: "Påkrevd av",
      UploadInfoHeader: (reqName: string) => {
        return (
          <React.Fragment>
            Her kan du laste opp <i>{reqName}.</i>
            <br />
            <br />
          </React.Fragment>
        );
      },
      UploadInfoText: (reqName: string, reqValid: number) => {
        let expiresText = `${reqValid} dager.`;
        if (reqValid > 365) {
          const years = reqValid / 365;
          const rounded = Math.round(years * 10) / 10;
          expiresText = `${rounded} år.`;
        }

        return (
          <React.Fragment>
            <i>{reqName}</i> har en gyldighet på {expiresText} <br />
            Det kreves derfor at du setter første gyldige dato for dokumentet (
            <i>dette kan f.eks være datoen et kurs ble gjennomført</i>
            ).
          </React.Fragment>
        );
      },
      AllowConfirmationWithoutFileHelpText:
        "Dersom det ikke forekommer fysisk dokumentasjon (felleskurs o.l) er det mulig å registrere dokumentasjon uten å laste opp dokument. Dersom dette er tilfelle må det oppgis dato og en referanse for kurset.",
      NoFileExistsGridLabel: "-- Ingen fil --",
      editImageButton: "Redigere",
      MissingDate: "Dato mangler",
      MissingDocRef: "Mangler dokumentasjonsreferanse"
    }
  },
  header: {
    Personnel: "Personell",
    ApproveApplication: "Godkjenne Søknad",
    IssueApplication: (cardName: string) =>
      `Utstede ${cardName.toLowerCase()} fra Søknad`,
    AdminAsset: "Administrer Anlegg",
    ConfirmUser: "Bekreft ny Bruker",
    AccessHistory: "Adgangshistorikk",
    MyAccessHistory: "Min Adgangshistorikk",
    OurAccessHistory: "Vår Adgangshistorikk",
    Accesses: "Adganger",
    MyAccess: "Mine Adganger",
    MyCards: (cardName: string) => `Mine ${cardName.toLowerCase()}`,
    OurCards: (cardName: string) => `Våre ${cardName.toLowerCase()}`,
    OurPersonnel: "Vårt Personell",
    OurProflie: "Vår Profil",
    OurOtherCards: "Våre HMS-kort",
    OurAccess: "Våre adganger",
    MyProfile: "Min Profil",
    MyApplications: "Mine Søknader",
    MyApplication: "Min Søknad",
    OurApplications: "Våre Søknader",
    OurApplication: "Vår søknad",
    IssuedApplications: "Utsted Søknad",
    ConfirmedApplications: "Godkjenn Søknad",
    ApplicationsForApproval: "Søknader til Godkjennelse",
    ApplicaitonsForIssue: (cardName: string) => `${cardName} til Utstedelse`,
    UserRights: "Brukerrettigheter - Organisasjon",
    GroupRights: "Våre Brukerrettigheter",
    MyPermitto: "Min Permitto",
    ServiceProvider: (empName: string) =>
      empName ? `Leverandør - ${empName}` : `Leverandør`,
    AssetOwner: (orgName: string) => `Anleggseier - ${orgName}`,
    Owner: "Anleggseier",
    ConfirmApplication: "Attester Søknad for",
    Employers: "Leverandører",
    MyOtherCards: "HMS-kort",
    AdminOtherCards: "HMS-kort",
    DoorkeyApplications: "Nøkkelsøknader",
    OurDoorkeyApplications: "Våre Nøkkelsøknader",
    MyDoorkeyApplications: "Mine Nøkkelsøknader",
    Doorkeys: "Nøkler",
    OurDoorkeys: "Våre nøkler",
    MyDoorkeys: "Mine nøkler",
    Documentation: "Dokumentasjon",
    OurDocumentation: "Vår dokumentasjon",
    Projects: "Prosjekter",
    OrganisationTemplates: "Rediger maler",
    OrganisationDoorkeyTemplates: "Rediger maler",
    OrganisationAssetMessages: "Anleggsmeldinger"
  },
  numberLookup: {
    UserExists: (
      firstName: string,
      lastName: string,
      organizationName?: string
    ) => {
      return organizationName
        ? `${firstName} ${lastName} er allerede bruker under ${organizationName}`
        : `${firstName} ${lastName} er allerede bruker`;
    },
    Invitation: (firstName: string, lastName: string) => {
      return `${firstName} ${lastName} er allerede bruker i Permitto, du kan invitere vedkommende under.`;
    },
    UserNotFound: "Bruker ikke funnet",
    InvalidPhone: "Telefonnummer er ikke gyldig",
    FirstName: "Fornavn",
    LastName: "Etternavn",
    Company: "Selskap",
    CreateUser: "Opprett ny bruker",
    Invite: "Inviter",
    Ok: "Ok",
    NumberPlaceHolder: "Fyll in mobilnummer",
    Cancel: "Avbryt",
    SubmitMobileNr: "Skriv inn mobilnummeret til den nye brukeren"
  },
  newApplication: {
    Warning: "Advarsel",
    ConfirmClose: "Er du sikker på at du vil avbryte?",
    SafetyCardApplication: (cardName: string) =>
      `Søknad om ${cardName.toLowerCase()}`,
    CloseApplicationTitle: "Avbryt søknad",
    CloseApplicationText:
      "Er du sikker på at du vil avbryte og forkaste søknaden?",
    Cancel: "Avbryt",
    DoorkeyApplicationTitle: "Ny søknad om nøkkel"
  },
  newCardNoApplic: {
    CreateNewCard: "Opprett nytt kort",
    CloseApplicationTitle: "Avbryt opprettelse av kort",
    CloseApplicationText:
      "Er du sikker på at du vil avbryte opprettelsen av nytt kort?",
    Cancel: "Avbryt",
    Warning: "Advarsel",
    ConfirmClose: "Er du sikker på at du vil avbryte?"
  },
  person: {
    FirstName: "Fornavn",
    LastName: "Etternavn",
    Email: "Epost",
    Mobile: "Mobil",
    Company: "Selskap",
    Back: "Tilbake",
    Edit: "Rediger",
    EditMode: "Redigeringsmodus",
    Save: "Lagre",
    Cancel: "Avbryt",
    ChangesMade: {
      title: "Endringer gjort. Vil du fortsette?",
      text: "Hvis du fortsetter, vil endringene gå tapt"
    }
  },
  personList: {
    AlertTitle: "En feil oppsto",
    AlertText: "En feil oppsto under sending av invitasjoner",
    Cancel: "Avbryt",
    SendInvitations: (numOfInvitations: number) => {
      return numOfInvitations === 1
        ? `Send ${numOfInvitations} invitasjon`
        : `Send ${numOfInvitations} invitasjoner`;
    },
    Send: (fromOrg: boolean) =>
      fromOrg ? `Send SMS til mottaker` : `Send Epost til mottaker`,
    Invitations: "invitasjoner",
    InvitationsSent: "invitasjoner sendt",
    Done: "Ferdig",
    FirstName: "Fornavn",
    LastName: "Etternavn",
    FullName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    Email: "Epost",
    Mobile: "Mobil",
    Company: "Selskap",
    Invited: (firstName: string, lastName: string) =>
      `${firstName} ${lastName} invitert`,
    InvitedTo: (firstName: string, lastName: string, orgName: string) =>
      `${firstName} ${lastName} invitert til ${orgName}`,
    NewPersonHeader: "Opprett ny person",
    Save: "Opprett",
    CreateNew: "Opprett ny",
    Invite: (numOfPersons: number) => {
      return numOfPersons === 1
        ? `Inviter ${numOfPersons} person`
        : `Inviter ${numOfPersons} personer`;
    },
    Persons: "personer",
    LastNamePlaceholder: "Søk på etternavn",
    Open: "Åpne",
    SelectAPerson: "Du må velge en person",
    SelectJustOnePerson: "Du må velge kun 1 person",
    SendInvitationsDescription:
      "Er du sikker på at du vil invitere de valgte personene til organisasjonen?"
  },
  safetycard: {
    Application: "Søknad",
    Add: "Legg til",
    InputPlaceholder: "Ikke fylt ut",
    Shredded: "Makulert",
    Expired: "Utløpt",
    Sign: (cardName: string) => `Klikk for å kvittere ${cardName} mottatt`,
    NotSigned: "Ikke kvittert mottatt",
    SafetyCard: (cardName: string) => cardName,
    Card: (cardId: number, cardName: string) => `${cardName}: ${cardId}`,
    Access: "Adgangsliste",
    LoadingAssets: "Laster anlegg...",
    CardOwner: (cardName: string) => `${cardName}innehaver`,
    Issued: "Utstedt",
    Signed: "Kvittert mottatt",
    ValidFrom: "Gyldig fra",
    DatePlaceholder: "Velg dato",
    ReceiptReceived: "Kvittering mottatt",
    ValidTo: "Gyldig til",
    UploadDate: "Opplastingsdato",
    ValidFromWithColon: "Gyldig fra: ",
    ValidToWithColon: "Gyldig til: ",
    UnknownDataType: "Ukjent datatype",
    Attestor: (name: string, email: string) => `Attestant: ${name} (${email})`,
    ChangedBy: "Endret av ",
    ReadyForIssuing: "Klar for utstedelse",
    NotFilled: "Ikke utfylt",
    EmployerName: "Arbeidsgiver",
    HeadOfOperations: "Driftsleder",
    IssuedOnBehalfOf: (issuerName: string, headOfOperations: string) =>
      `Utstedt av ${issuerName} på vegne av driftsleder ${headOfOperations}`,
    postIssueSections: {
      clickToApprove: "Klikk for å godkjenne",
      missingTrainingHeader: "Krav som må innfris",
      missingTrainingHeaderForAccess: "Krav som må innfris for adgang",
      missingConditionalTrainingHeader: "Krav knyttet til adgang",
      requirmentsNeedToBeFulfilled:
        "Dette er krav som må innfris før kortet er gyldig",
      requirmentsNeedToBeFulfilledForAsset:
        "Dette er krav som må innfris for å få adgang til enkelte av anleggene på dette kortet",
      requirmentsNeedToBeFulfilledThisAsset:
        "Dette er krav som må innfris for å kunne registrere adgang på dette anlegget"
    }
  },
  doorkeyCardApplication: {
    SendPageText:
      "Din nøkkelsøknad er klart til å bli sendt.Hvis du ønsker å legge ved en kommentar så kan det gjøres under.",
    AddPlaceholder: "Legg ved eventuell kommentar til søknaden",
    MissingFeilds: "Du må legge inn en kommentar og klikk send.",
    Back: "Tilbake",
    Done: "Ferdig",
    Send: "Send",
    ForPerson: "Velg personen du ønsker å søke på vegne av",
    Next: "Neste",
    ApplyForCard: "Hvilke anleggseier ønsker du å søke om nøkkel hos?",
    ApplicationForm: "Søknadsskjema",
    ApplicationSummary: "Søknadsinfo",
    Submit: "Innsending",
    Applicant: "Søker:",
    Issuer: (cardName: string) => `${cardName}utsteder`,
    doorkeyCardApplicationText: "Søknad om nøkkel"
  },
  cardIssueNoApplication: {
    CardOwner: "Kortholder",
    FindByMobile:
      "Skriv inn mobilnummeret til den personen kortet skal utstedes til",
    UserNotFound: (inOrg: boolean) =>
      inOrg
        ? "Bruker funnet i permitto, men i en annen organisasjon. Du kan kun utstede kort til brukere i egen organisasjon."
        : "Bruker ikke funnet, fyll inn feltene for å opprette en ny bruker. NB: Pass på at telefonnummer og epost er riktig.",
    UserFound: "Bruker funnet",
    FirstName: "First name:",
    LastName: "Last name:",
    Mobil: "Mobile:",
    Email: "Email:",
    Next: "Neste",
    Applicant: "Kortholder:",
    AuthorizationField: "Bemyndigelser",
    Valid: "Gyldighet:",
    Issuer: (cardName: string) => `${cardName}utsteder`,
    Submit: "Utstedelse",
    ApplicationSummary: "Kortinformasjon",
    ApplicationForm: "Ufylling av kort",
    ReadyToSend:
      "Kortet er klart til å utstedes. Fyll inn en eventuell kommentar og klikk utsted.",
    AddPlaceholder: "Legg ved eventuell kommentar til utstedelsen",
    Back: "Tilbake",
    Done: "Ferdig",
    Send: "Utsted",
    Retry: "Utsted på nytt",
    CreateNewPersonHeader: (
      cardholderFistName: string,
      cardholderLastName: string
    ) =>
      `${cardholderFistName} ${cardholderLastName} vil med dette opprettes som en ny bruker i Permitto`,
    CreateNewPerson: (cardholderFistName: string, cardholderLastName: string) =>
      `${cardholderFistName} ${cardholderLastName} vil bli opprettet som ny bruker, og du kan deretter fortsette prosessen med å utstede kort til vedkommende. Vil du forsette?`
  },
  safetyCardApplication: {
    GivesAccessConflict: `Noen av anleggene du søker adgang i har dette krysset som krav til adgang. Fjerner du dette krysset vil du ikke kunne få adgang til anlegget.`,
    TreeHelpFirst:
      'Her kan du velge hva du ønsker å søke adgang til.\nDu kan enten velge en "gruppe" med anlegg, eller du kan bla i treet for å velge et enkelt anlegg.\nAdgang til en gruppe gir adgang til alt underliggende.',
    TreeHelpSecond:
      "Du velger et anlegg ved å 'markere det' ved å huke av avhukningsboksen.",
    TreeHelpThird: "Du blar i treet ved å trykke på pilen.",
    TreeHelpFourth: "Vis demo",
    TreeAttestorHelpText:
      "Her kan du se hvilke adganger det er søkt om. Du kan navigere i treet ved å trykke på pilene",
    EqualAsset: " = Anlegg",
    EqualGroup: " = Gruppe",
    WhereToApply: "Hvor søker du adgang?",
    AppliedTo: "Det søkes adgang til",
    FileToBigTitle: "En feil har oppstått",
    FileToBigText: "Filen er for stor",
    Cancel: "Avbryt",
    MyFiles: "Mine filer",
    FileOwner: " sine filer",
    ApplicationApproved: "Søknaden ble attestert",
    ApplicationDenied: "Søknaden ble avvist",
    WrongNumber: "Telefonnummer må inneholde 8 tall",
    ApproverMissing: "Du må fylle ut all informasjon om attestant",
    ValidEmail: "Du må fylle ut en gyldig epost-adresse i attestant feltet",
    ValidEmailHeader: "Attestantens epost-adresse er ikke gyldig",
    CardOwner: "Søker",
    ValidFrom: (date: string) => `Gyldig fra ${date}`,
    SelectDatePlaceholder: "Velg dato",
    ValidTo: "Gyldig til",
    Next: "Neste",
    SearchForLastName: "Søk etter etternavn",
    SelectFile: "Legg ved dokumentasjon",
    UploadFile: "Last opp dokument",
    UploadDate: "Opplastet",
    ChangeFile: "Endre dokument",
    NewFile: "Nytt dokument",
    OpenFile: "Åpne dokument",
    NoFile: "Ingen dokumenter",
    Loading: "Laster..",
    Missing: "Må fylles ut",
    UnknownDataType: "Ukjent datatype",
    ReadyToSendWithAttestor: (cardName: string) => (
      <span>
        {`Før søknaden sendes må den attesteres. Formålet med dette steget er å
            innhente en bekreftelse fra en person som kjenner til søker og som kan
            bekrefte at søker er skikket og kvalifisert til å inneha
            ${cardName.toLowerCase()} med de bemyndigelsene det søkes om. Attestanten kan være oppdragsgiver, søkers overordnede eller annet personell som kan gå god for søker. 
            Attestanten bes også om å sjekke at søknaden
            er riktig fylt ut og at påkrevet dokumentasjon er gyldig og lagt ved
            søknaden. Attestanten trenger ikke å være bruker i Permitto.`}

        <br />
        <b>
          {`Attestant er sjelden samme person som det søkes ${cardName.toLowerCase()} til.`}
        </b>
      </span>
    ),
    WillNotBeSetBeforeAttested: (cardName: string) =>
      `NB. Søknaden vil ikke bli sendt til ${cardName.toLowerCase()}utsteder før den er attestert. Verifiser derfor at epost til attestant er korrekt.`,
    FillAttestorInfo: "Vennligst fyll inn informasjon om attestant",
    SelfAttesting: "Jeg ønsker å oppføres som attestant på denne søknaden",
    ReadyToSend:
      "Søknaden er klar til å sendes. Fyll inn en eventuell kommentar og klikk send.",
    AddPlaceholder: "Legg ved eventuell kommentar til søknaden",
    AttestorNameTitle: "Navn",
    AttestorEmailTitle: "Attestant epost",
    AttestorFirstnameTitle: "Attestant fornavn",
    AttestorLastnameTitle: "Attestant etternavn",
    AttestorTitleTitle: "Attestant tittel",
    AttestorPhoneTitle: "Attestant telefon",
    Back: "Tilbake",
    Done: "Ferdig",
    Send: "Send",
    ApplyForCard: (cardName: string) =>
      `Hvor vil du søke ${cardName.toLowerCase()}?`,
    IssueCard: (cardName: string) =>
      `Hvor vil du utstede ${cardName.toLowerCase()}?`,
    ApplyForWhichCard: (cardName: string) =>
      `Hvilket type ${cardName} søker du om?`,
    AllMissing: "Du må fylle ut alle feltene",
    ForPerson: "Velg personen du ønsker å søke på vegne av",
    AuthorizationReadOnly: "Det søkes om følgende bemyndigelser",
    Authorization: (numAssets: number) =>
      numAssets > 0
        ? `Velg bemyndigelser du ønsker å søke (bemyndigelser for adgang til stasjoner du har valgt tidligere, er fylt ut automatisk)`
        : `Velg bemyndigelser du ønsker å søke`,
    ApplicationSummary: "Søknadsinfo",
    Valid: "Gyldighet:",
    ApplicationForm: "Søknadsskjema",
    Access: "Adgang",
    AssetLowerCase: (numAssets: number) => `${numAssets} anlegg`,
    AuthorizationField: "Bemyndigelser",
    Submit: "Innsending",
    FillInAllMarked: "Fyll ut alle feltene markert med *",
    FillInAccessLogg: "Velg anlegg du ønsker å søke om adgang til",
    FillInDates: "Fyll ut 2 gyldige datoer",
    Reject: "Avvis",
    Approve: "Attester",
    AttestorInfo: "Attestant",
    AttestorInfoMessage1: (
      fistName: string,
      lastName: string,
      orgName: string,
      cardName: string
    ) =>
      `${fistName} ${lastName} har opprettet en søknad om ${cardName.toLowerCase()} hos ${orgName}.`,
    AttestorInfoMessage2: (orgName: string) =>
      `${orgName} krever at alle søknader skal attesteres før de sendes.`,
    AttestorInfoMessage3: (cardName: string) =>
      `Du har blitt utpekt som attestant for denne søknaden. ` +
      `Formålet med dette steget er å innhente en bekreftelse fra en person som kjenner søker og som kan bekrefte at søker er skikket og kvalifisert til å inneha ${cardName.toLowerCase()} med de bemyndigelsene det søkes om. ` +
      `Som attestant bes du også om å sjekke at søknaden er riktig fylt ut og at påkrevet dokumentasjon er gyldig og lagt ved søknaden. ` +
      `Ved å attestere vil din kontaktinfo bli lagret på søknaden slik at behandler har mulighet til å kontakte deg om det skulle være spørsmål. ` +
      `\nFølgende informasjon er registrert på deg av søker:`,
    AttestorInfoMessage4: `Vennligst verifiser at informasjonen registrert på deg er korrekt, eventuelt gjør nødvendige endringer.`,
    Attest: "Attestering",
    StartAttesting: "Start attestering",
    AttestorMessage1: "Du kan nå velge å attestere eller avvise søknaden.",
    AttestorMessage2: (fistName: string, lastName: string) =>
      `Dersom du avviser søknaden, blir denne sendt tilbake til ${fistName} ${lastName}, som kan gjøre endringer og sende på ny.`,
    AttestorMessage3: (
      orgName: string
    ) => `Dersom du velger Attester, blir søknaden sendt til ${orgName} for behandling.
      I begge tilfeller kan du legge til en eventuell kommentar som vil følge søknaden.`,
    AttestorConfirmSane: (cardName: string) =>
      `Ved å attestere bekrefter du at søker er skikket og kvalifisert til å inneha ${cardName.toLowerCase()} med de bemyndigelsene det søkes om.`,
    Applicant: "Søker:",
    Issuer: (cardName: string) => `${cardName}utsteder:`,
    ValidFromDate: "Gyldig fra:",
    ValidToDate: "Gyldig til:",
    Comment: "Kommentar",
    ContinueApplication: "Fortsett",
    CardNumber: (cardName: string) => `${cardName}nummer`,
    Previewtemplate: (cardName: string) => `Vis ${cardName.toLowerCase()}`,
    ApplyFromExistingCardHeaderText: (cardName: string) =>
      `Velg ${cardName.toLowerCase()} du ønsker å kopiere til denne søknaden. \nMerk deg at krav til dokumentasjon fortsatt må fylles ut.`,
    CreateNewSafetyCard: "Blank søknad",
    CopyExistingSafetyCard: (cardName: string) =>
      `Kopier eksisterende ${cardName.toLowerCase()} til denne søknaden`,
    FileValidFrom: "Gyldig fra",
    FileValidTo: "Gyldig til",
    FileUploadComment: "Kommentar",
    FileName: "Filnavn",
    ExpireMessage:
      "Advarsel: Dette dokumentet er ikke gyldig hele perioden det er søkt om sikkerhetskort for",
    Retry: "Send på nytt",
    cardTypeName: (cardTemplate: CardType, createdfromApplic: boolean) =>
      createdfromApplic
        ? `${cardTemplate.cardNameNo} - Opprettet ved søknad`
        : cardTemplate.cardNameNo,
    ApplicantInfo: "Informasjon om søker",
    ApplicantInfoIssued: "Informasjon om kortinnehaver",
    ApplicantName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    Application: "Søknad",
    SafetyCard: "Sikkerhetskort",
    SafetyCardStatusLog: "Søknadens endringshistorikk",
    SafetyCardStatusLogNoApplic: (cardTemplate: CardType) =>
      `${cardTemplate.cardNameNo}ets endringshistorikk`,
    AttestorEmailSent: "Epost sendt på ny",
    AttestorEmailSentInfo: "Epost er blitt sendt på ny til attestanten",
    AttestorLink: "Attestorlink",
    CopyToClipboard: "Kopier til utklippstavle",
    Status: "Status: ",
    Name: "Navn: ",
    LastApplicChangeDate: "Siste endring: ",
    ApplicationAttestorInfo: (cardName: string) =>
      `Under finner du ${cardName}et det søkes om og søknaden knyttet til dette. Vennligst se igjennom at søknaden ser fornuftig ut.`,
    AttestApplication: "Attester søknad",
    CloseWindow: "Du kan nå lukke siden",
    CompleteAttestation: "Fullfør attestering",
    ApplicInfoNoApplic: "Felt fra søknadsmal",
    Reference: "Referanse",
    StatusExpired: "Utløpt",
    StatusValid: "OK",
    ExpireDate: "Utløpsdato",
    SelectedDocument: "Valgt dokument",
    NoneFileJustRef: "Ingen fil, kun referanse",
    DropdownHeader: "Lagrede dokumenter",
    IssueOnBehalfOfDisabledTooltipText:
      "Anleggseier har deaktivert mulighet til å søke på vegne av andre"
  },
  safetyCardAssets: {
    EnterAssetLocation: "Trykk i kartet for å sette koordinater for anlegget",
    EnterAssetName: "Fyll ut navn",
    EnterAssetDescription: "Fyll ut beskrivelsen",
    Type: "Type",
    Description: "Beskrivelse",
    AddType: (message: string) => `Legg til ny type: "${message}"`,
    Cancel: "Avbryt",
    Save: "Lagre",
    SelectNode: "Velg node",
    Add: "Legg til",
    Delete: "Slett",
    Or: (message: string) => `${message} eller `,
    RequireAuthorization: (message: string, cardName: string) =>
      `Det kreves ${message} på ${cardName.toLowerCase()} for å få adgang til denne stasjonen`,
    AccessList: "Adgangsliste",
    Access: "Adgangsoversikt",
    SelectAll: "Velg alle",
    SelectNone: "Velg ingen",
    AddAll: "Legg til alle",
    RemoveAll: "Fjern alle",
    Asset: "Anlegg",
    AssetNode: "Gruppe",
    SelectType: "Velg type",
    SelectModelType: "Velg modelltype",
    NotAvailable: "Dette valget er ikke mulig på dette nivået."
  },
  safetyCardList: {
    Kpi: {
      Active: "Aktive kort",
      Expired: "Utgåtte kort",
      ExpiresIn30Days: "Utløper innen 30 dager",
      RecieptNotRecieved: "Kort som ikke er kvittert"
    },
    ShredButtonText: "Makuler",
    ShredAndCreateButtonText: "Makuler og opprett ny",
    ShredAndCreateText: (cardName: string) =>
      `Vil du makulere og opprette nytt ${cardName.toLowerCase()}, eller bare makulere?`,
    ShredAndCreateTitle: (cardName: string) =>
      `Makuler ${cardName.toLowerCase()}`,
    ShouldIssueTitle: "Kort opprettet",
    ShouldIssue: (cardName: string) =>
      `Vil du utstede ${cardName.toLowerCase()}(ene) nå?`,
    RenewedFromCard: (
      cardId: number,
      firstName: string,
      lastName: string,
      cardName: string
    ) =>
      `Fornyet fra ${cardName.toLowerCase()} nr. ${cardId} av ${firstName} ${lastName}`,
    CopyCardHeader: (cardName: string) =>
      `Kopier ${cardName.toLowerCase()} fra: `,
    CardNumberPlaceholder: (cardName: string) =>
      `Søk etter ${cardName.toLowerCase()}nummer`,
    NamePlaceholder: "Søk etter navn",
    LastNamePlaceholder: "Søk etter etternavn",
    Proceed: "Velg og gå videre",
    Warning: "Advarsel",
    ValidFrom: "Gyldig fra",
    ValidTo: "Gyldig til",
    Missing: "Gyldig fra tidligere en Gyldig Til",
    Comment: "Fyll inn eventuell kommentar til utstedelse",
    CardTitle: (cardName: string) => `${cardName} opprettet`,
    CardText: (cardName: string) =>
      `${cardName} er opprettet, men ikke utstedet. Dette må gjøres av en person med utsteder-rettigheter.`,
    CardWarning: (cardName: string) =>
      `${cardName} ble ikke sendt inn. Påkrevde attributter er ikke utfylt på ${cardName.toLowerCase()}`,
    Save: "Lagre",
    Cancel: "Avbryt",
    ErrorCreatingCardShredded: (cardId: number, cardName: string) =>
      `En feil har oppstått under opprettelse av ${cardName.toLowerCase()} nummer ${cardId}. ${cardName.toLowerCase()} har blitt makulert, men nytt ${
        cardName.toLowerCase
      } er ikke opprettet.`,
    ErrorTitle: "En feil har oppstått",
    ErrorShreddingCard: (cardId: number, cardName: string) =>
      `En feil har oppstått under makulering av ${cardName.toLowerCase()} nummer ${cardId}`,
    SetDate: (numCards: number, cardName: string) =>
      numCards === 1
        ? `Sett fra og til dato på ${numCards} ${cardName.toLowerCase()}`
        : numCards === 2
        ? `Sett fra og til dato på begge ${cardName.toLowerCase()}ene`
        : `Sett fra og til dato på alle ${numCards} ${cardName.toLowerCase()}`,
    SecurityCard: (cardName: string) => cardName,
    TheSecurityCard: (cardName: string) => `${cardName.toLowerCase()}et`,
    Update: "Oppdater",
    CardRenewed: (cardName: string) => `${cardName.toLowerCase()} fornyet`,
    NumCardRenewed: (numCards: number, issued: boolean, cardName: string) =>
      issued
        ? `${numCards} ${cardName.toLowerCase()} fornyet og utstedet.`
        : `${numCards} ${cardName.toLowerCase()} fornyet, men ikke utstedet`,
    Done: "Ferdig",
    Renew: "Forny",
    RenewCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? `Forny ${numCards} ${cardName.toLowerCase()}`
        : `Forny ${cardName.toLowerCase()}`,
    RenewNotSelected: (cardName: string) =>
      `Velg ${cardName.toLowerCase()} du ønsker å fornye i listen under`,
    ReceiptReceived: "Kvittert mottatt",
    IssuedBy: "Utstedt av",
    Issued: "Utstedt",
    ShreddedBy: "Makulert av",
    ShreddedTime: "Makulert tidspunkt",
    CreateNewSecurityCard: (cardName: string) =>
      `Opprett nytt ${cardName.toLowerCase()}`,
    CreateNew: "Opprett ny",
    Card: (cardName: string) => cardName,
    ShredCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? `Makuler ${numCards} ${cardName.toLowerCase()}`
        : `Makuler ${cardName.toLowerCase()}`,
    ShredCardsAllTypes: (numCards: number) =>
      numCards > 0 ? `Makuler ${numCards} kort` : `Makuler kort`,
    Shred: "Makuler",
    ShredSelectedCards: (cardName: string) =>
      `Velg ${cardName.toLowerCase()} du ønsker å makulere i listen under`,
    AlreadyShreddedTitle: "Allerede makulert",

    AlreadyShreddedText: (cardName: string) =>
      `De valgte ${cardName.toLowerCase()}ene er allerede makulert`,
    AlreadyShreddedTextAllTypes: `De valgte kortene er allerede makulert`,
    ShredAlertTitle: (cardName: string) => `Makuler ${cardName.toLowerCase()}`,
    ShredAlertTitleAllTypes: `Makuler kort`,
    ShredAlertText: (multipleCards: boolean) =>
      multipleCards
        ? `Dette vil makulere de valgte kortene`
        : `Dette vil makulere det valgte kortet`,
    Filter: "Vis/skjul kolonner",
    ShowValid: (cardName: string) => `Vis gyldige ${cardName}`,
    ShowShredded: (cardName: string) => `Vis makulerte ${cardName}`,
    Open: "Åpne",
    NeedToSelectCard: (cardName: string) =>
      `Du må velge et ${cardName.toLowerCase()}`,
    NeedToSelectOneCard: (cardName: string) =>
      `Du må velge kun ett ${cardName.toLowerCase()}`,
    RenewInfoTextWithShred: (cardName: string) =>
      `De valgte ${cardName.toLowerCase()}ene vil bli makulert og nye opprettet.`,
    RenewInfoTextNoShred: (cardName: string) =>
      `De valgte ${cardName.toLowerCase()}ene vil bli fornyet, men de gamle blir ikke makulert`,
    RenewShouldShred: (cardName: string) => `Makuler ${cardName} ved fornyelse`,
    InfoLegendFirst: (cardName: string) => `${cardName} markert med `,
    InfoLegendLast: "er utløpt",
    InfoLegendShredded: (cardName: string) =>
      `Viser makulerte ${cardName.toLowerCase()}`,
    FilterByAssets: "Filtrer på adgang",
    Actions: "Valg",
    ShredNumExpired: (num: number): string =>
      `Makuler alle utgåtte kort (${num})`,
    ShredAll: "Makuler alle",
    ShredExpiredInProgress: "Makulering av kort pågår...",
    ShredExpiredDone: "Makulering av kort fullført"
  },
  safetyCardEdit: {
    EditCard: (cardName: string) => `Editer ${cardName.toLowerCase()}`,
    SaveCard: (cardName: string) => `Lagre ${cardName.toLowerCase()}`,
    Cancel: "Avbryt"
  },
  sidebar: {
    Admin: "Admin",
    AdminAsset: "Administrer anlegg",
    UserControl: "Brukerstyring",
    Roles: "Roller",
    Templates: "Rediger kortmaler",
    ConstructionProjects: "Prosjekter",
    AssetMessages: "Anleggsmeldinger",
    DoorKeyTemplates: "Nøkkelsøknads-mal"
  },
  signReceipt: {
    PinNotValid: "Feil pinkode",
    ErrorRequestingPinTitle: "Advarsel",
    ErrorRequestingPinText:
      "Kunne ikke bekrefte pin. Vennligst prøv igjen eller kontakt support",
    ErrorValidatingPinTitle: "En feil har oppstått",
    ErrorValidatingPinText: "En feil har oppstått under sending av pin-kode.",
    ConfirmReceivingCard: (cardId: number, orgName: string, cardName: string) =>
      `Jeg bekrefter herved å ha mottatt ${cardName} med nr ${cardId} utstedt av ${orgName}.`,
    ConfirmReceivingCardSign: (
      cardId: number,
      orgName: string,
      cardName: string
    ) =>
      `Jeg bekrefter herved å ha mottatt ${cardName} med nr ${cardId}.\nKvitter for ${cardName.toLowerCase()} utstedt av ${orgName}.`,
    ConfirmReceivingDoorKey: "Bekreft nøkkel mottatt",
    GetPinSms: "Send pin på SMS",
    GetDoorKeyPinSms: "Send pin",
    VerifyDoorKeyPinSms: "Bekreft",
    WritePin: "Skriv pin-kode her",
    SignSecurityCard: (cardName: string) =>
      `Kvitter for ${cardName.toLowerCase()}`,
    Close: "Lukk",
    SuccessText: "Kortet er signert",
    DoorkeySuccessText: "Nøkkel er signert",
    modalHeader: "Kvitter for nøkkel",
    modalHeaderCard: "Kvitter for kort"
  },
  singleAsset: {
    Error: "Feil",
    ErrorFetchingAsset: "En feil oppsto ved innlasting av anlegg",
    MissingAssetAttri: "Ikke alle felter på stasjon er fylt ut riktig.",
    ErrorPutStation: "En feil har oppstått under opplasting av stasjon.",
    ErrorPutImg: "En feil har oppstått under opplasting av bilde.",
    ErrorPutAsset: "En feil har oppstått under opplasting av anlegg.",
    WrongFileFormat: "Filen har ikke riktig filformat",
    DropzoneText: "Klikk her, eller slipp et bilde her for å laste det opp",
    DeleteImage: "Slett bilde",
    Warning: "Slett bilde",
    DoYouWantToDeleteImg: "Dette vil slette bildet",
    Zones: "Soner",
    AddZone: "Legg til sone",
    DeleteZone: "Slett valgt sone",
    Latitude: "Breddegrad *",
    SelectOnMap: "Velg i kart",
    ClickToSetCoordinates:
      "Trykk i kartet for å sette koordinater for anlegget",
    Longitude: "Lengdegrad *",
    Name: "Navn *",
    NamePlaceholder: "Fyll inn navn",
    Missing: "Dette feltet kan ikke stå tomt",
    ExportId: "Integration ID",
    ExportIdPlaceholder: "Integration ID",
    AccessRequirment: "Adgangskrav",
    Role: (numRoles: number) =>
      numRoles === 1 ? `${numRoles} rolle` : `${numRoles} roller`,
    RequiredRolesForAccess: "En av følgende roller kreves for å få adgang.",
    LeaderRequirment: "Ledsagerkrav",
    RequiredRolesForLeader:
      "En av følgende roller kreves for å kunne være ledsager.",
    maxEntries: "Maks antall samtidige innsjekkinger",
    maxEntriesPlaceholder: "Angi antall personell lov i anlegg om gangen",
    maxEntriesBlock:
      "Begrensning er en endelig grense (brukere får ikke lov til å registrere seg hvis den er nådd)",
    maxEntriesUpdate:
      "Begrens antall brukere som kan registrere seg i anlegg samtidig",
    maxEntryInputWarning: "Angi gyldig nummer som maksimalt antall oppføringer",
    infoLabel: "Info"
  },
  singlePerson: {
    personalInformation: "Personopplysninger",
    FirstName: "Fornavn",
    Missing: "Dette feltet kan ikke stå tomt",
    LastName: "Etternavn",
    Mobile: "Mobil",
    Digits: "Verdien må være et nummer",
    Email: "Epost",
    ValidEmail: "Verdien må være en gyldig e-post adresse",
    Company: "Selskap",
    Active: "Aktive",
    Shredded: "Makulerte",
    SafetyCards: "Sikkerhetskort",
    Documents: "Dokumenter"
  },
  personSafetycards: {
    Open: "Åpne",
    SafetyCards: (cardName: string) => cardName,
    SelectToOpen: (cardName: string) =>
      `Velg ${cardName.toLowerCase()} for å åpne`,
    ShowShredded: (cardName: string) =>
      `Vis makulerte ${cardName.toLowerCase()}`,
    InfoLegendFirst: (cardName: string) => `${cardName} markert med `,
    InfoLegendLast: "har gått ut.",
    ShowValid: (cardName: string) => `Vis gyldige ${cardName.toLowerCase()}`
  },
  currentUser: {
    LoggedIn: "Logget inn som:",
    LogOut: "Logg ut",
    PrivacyPolicy: "Personvernerklæring"
  },
  columnDefs: {
    BirthDate: "Fødselsdato",
    PermittoId: "Permitto ID",
    CardNr: "Kortnr",
    FirstName: "Fornavn",
    LastName: "Etternavn",
    Mobile: "Mobil",
    EmployerName: "Bedrift",
    ValidFrom: "Gyldig fra",
    ValidTo: "Gyldig til",
    Status: "Status",
    ShreddedBy: "Makulert av",
    ShreddedTime: "Makulert tidspunkt",
    PersonnelId: "Id",
    Email: "Epost",
    Company: "Selskap",
    Organisation: "Organisasjon",
    OrganizationNo: "Organisasjonsnr.",
    InvitedDate: "Invitasjonsmail sendt",
    AssetName: "Anlegg/prosjekt",
    AssetArea: "Område",
    AssetOwner: "Anleggseier",
    CheckOut: "Utsjekk",
    CheckIn: "Innsjekk",
    ExpectedTimeToLeave: "Forventet utsjekk",
    EntryCount: "Antall personer",
    EntryNames: "Ledsager",
    EntryNote: "Notat",
    RoleName: "Rolle",
    EntryNoteBy: "Notat av",
    CheckoutBy: "Utsjekket av",
    IssuedBy: "Utstedt av",
    IsApplication: "Er søknad",
    ReceiptReceived: "Kvittert mottatt",
    Message: "Melding",
    InfoType: "Meldingsformat",
    EventType: "Vises",
    ShowMsgFrom: "Vises fra dato",
    ShowMsgTo: "Vises til dato",
    EnteredBy: "Registrert av",
    OtherCardType: `Kort type`,
    OtherCardTypeDesc: `Kort type beskrivelse`,
    ProjectName: "Prosjektnavn",
    ProjectConnectedToAsset: "Knytte til anlegg",
    ProjectReq: "Adgangskrav",
    ProjectSupplier: "Tilgjengelig for leverandør(er)",
    ProjectStart: "Fra dato",
    ProjectEnd: "Til dato",
    ProjectReference: "Integration ID",
    AllVenors: "Alle leverandører",
    CardHolder: "Kortinnehaver",
    CardDetails: "Kortdetaljer",
    CardAttribute: "Kortattributter",
    CardHolderDoc: "Kortholder dokumentasjon",
    BlockAutoCheckout: "Deaktiverer automatisk utsjekking",
    ProjectLead: "Prosjektleder",
    ProjectNumber: "Prosjektnr.",
    ExternalId: "external-id",
    CardType: "Korttype"
  },
  columnAttachmentDetailDefs: {
    fileId: "Vedlegg ID",
    fileName: "Navn",
    attachments: "Vedlegg ved registering",
    gridEmptytext: "Ingen vedlegg"
  },
  customDateComponent: { clear: "Fjern filter" },
  accessLog: {
    fromDatePickerDate: "Fra dato",
    toDatePickerDate: "Til dato",
    digitalProjectEntry: "Elektronisk mannskapsliste",
    digitalProjectEntryTitle: "Elektronisk mannskapsliste",
    digitalProjectEntryAddress: "Adresse",
    digitalProjectEntryClient: "Byggherre",
    enableFilter: "filter by project",
    disableFilter: "reset project filter",
    from: "fra",
    to: "til",
    export: "Eksporter"
  },
  dateValidation: {
    validDates: "Valgte datoer er ikke gyldige.",
    beforeToday:
      "Valgte datoer er ikke gyldige. Fra-dato kan ikke være før dagens dato.",
    toBeforeFrom:
      "Valgte datoer er ikke gyldige. Til-dato kan ikke være før fra-dato."
  },
  assetMessagesModal: {
    repeats: "Gjentas",
    modalHeader: "Anleggsmelding",
    modalSave: "Lagre",
    modalClose: "Lukk",
    infoDesc: `Meldingen vises for det anlegget du velger i treet. Hvis du
      legger meldingen på en gruppe, vil den vises på alle anlegg
      under gruppen. Du velger når meldingen skal vises. Den vil da
      automatisk vises i Permitto-appen til alle som besøker
      anlegget. Du kan selv bestemme til- og fra-dato for visning av en melding. Hvis til-dato er blank, vil meldingen vises til den blir slettet.`,
    groupIcon: "Gruppe",
    assetIcon: "Anlegg",
    selectedAsset: "Valgt anlegg",
    selectAsset: "Velg et anlegg",
    messageType: "Meldingsformat",
    messageText: "Melding",
    fromDate: "Vises fra og med dato",
    toDate: "Vises til og med dato",
    messageEventType: "Når skal meldingen vises",
    messagePlaceholder: "Fyll inn meldingstekst",
    datePlaceholder: "Velg dato",
    assetMessageDiabledTooltip: (asset: boolean, message: boolean) =>
      !asset
        ? "Anlegg/Gruppe er ikke valgt"
        : !message
        ? "Melding er ikke satt"
        : ""
  },
  otherCards: {
    openCard: "Åpne",
    addCard: "Legg til kort",
    deleteCard: "Slett kort",
    filterPlaceHolder: "Søk på etternavn",
    save: "Lagre",
    cancel: "Avbryt",
    close: "Lukk",
    selectCardType: "Velg korttype",
    cardType: "Korttype",
    cardNumber: "Kortnummer",
    cardHolder: "Kortholder",
    added: "Lagt til",
    addFrontImage: "Legg til forside",
    addBackImage: "Legg til bakside",
    selectDate: "Velg dato",
    validTo: "Gyldig til",
    validFrom: "Gyldig fra",
    birthDate: "Fødselsdato",
    editImageApplyChanges: "Bruk endringer",
    editImageDiscardChanges: "Forkaste endringer",
    editImageRotateLeft: "Venstre",
    editImageRotateRight: "Høyre",
    editImageButton: "Redigere",
    editImageHeading: "Rediger bilde",
    editImageZoomMinLabel: "Minimum",
    editImageZoomMaxLabel: "Maksimum",
    editImageZoomControlLabel: "Zoom",
    modalEditHeader: "Editer kort",
    modalNewHeader: "Legg inn nytt kort",
    modalViewHeader: "Kortdetaljer",
    editButtonLabel: "Rediger",
    deleteButtonLabel: "Slett",
    noImageFound: "Ingen bilde funnet",
    enterCardNumber: "Fyll in kortnummer",
    cardNumberNotFilled: "Kortnummer ikke utfylt"
  },
  selectPerson: {
    proceedBtnLabel: "Fortsett",
    filterPlaceholder: "Etternavn"
  },
  languageSelector: {
    Nor: "Norsk",
    Eng: "Engelsk",
    Sve: "Svensk",
    Ned: "Nederlandsk"
  },
  updateVersion: {
    preRefreshText: "En ny versjon er tilgjengelig.",
    refreshTextBtn: "Last",
    postRefreshText: "siden på nytt"
  },
  maintenanceMessages: {
    title: "tittel",
    author: "forfatter",
    message: "beskjed"
  },
  assetMap: {
    polygon: {
      draw: "Tegn en polygon",
      cancel: "Avbryt",
      finish: "Fullfør",
      undo: "Slett siste punkt",
      delete: "Slett",
      deleteDisabled: "Ingen lag å slette",
      modal: {
        delete: {
          title: "Slett polygon",
          subTitle: "Dette vil slette polygonet. Vil du fortsette?"
        }
      },
      tooltip: {
        start: "Klikk for å legge til punkt på polygon",
        continue: "Klikk for å legge til nytt koordinat",
        stop: "Klikk på det første punktet for å fullføre polygon"
      }
    }
  },
  customToolPanel: {
    title: "Lagrede filtre",
    saveFilterButton: "Lagre som nytt filter",
    saveFilterModal: {
      title: "Lagre filter",
      subtitle: "Fyll inn navn på filter",
      inputPlaceholder: "Vennligst fyll inn navn",
      saveButton: "Lagre filter"
    },
    deleteFilterModal: {
      title: "Slett filter",
      subtitle: ""
    }
  },
  jungleMap: {
    firstPara: "Du finner E-Læringskurset ",
    linkText: "på denne linken",
    secondPara:
      "Når du har fullført kurset vil det automatisk dukke opp under.",
    buttonTitle: "Oppfrisk",
    footerText: `Hvis du har fullført E-Læringskurset og det ikke dukker opp automatisk, kan du klikke "Oppfrisk"`
  },
  genericWords: {
    since: "siden",
    phone: "telefon",
    from: "fra",
    change: "endre"
  }
};
