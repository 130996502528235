export const CardIdParameter = ":cardId";
export const OrganisationIdParameter = ":orgId";
export const EmployerIdParameter = ":employerId";
export const PersonIdParameter = ":personId";
export const ApplicationIdParameter = ":applicationId";
export const AssetIdParameter = ":assetId";
export const LanguageParameter = ":language";
export const TypeIdParameter = ":typeId";

export const CardsList = "/cards/";
export const Card = "/card/";
export const PersonnelList = "/personnel/";
export const Person = "/person/";
export const AssetList = "/asset-list/";
export const Asset = "/asset/";
export const Access = "/access/";
export const Profile = "/profile/";
export const ExternalCards = "/external-cards/";
export const ApplicationList = "/applications/";
export const Application = "/application/";
export const Rights = "/rights/";
export const Roles = "/roles/";
export const AccessLog = "/access-log/";
export const Invite = "/invitation/";
export const Tos = "/privacy-policy";
export const UpdateInfo = "/update-info/";
export const Attest = "/attest/";
export const Auth = "/auth/";
export const SSONotAvilable = "/auth/sso-not-available/";
export const Employers = "/employers/";
export const Templates = "/templates";
export const DoorkeyTemplates = "/doorkey-templates";

export const ApprovalList = "/approval-list/";
export const Approval = "/approval/";
export const IssueList = "/issue-list/";
export const Issue = "/issue/";
export const RegisterUser = "/new-user/";
export const DoorKeys = "/doorkeys";
export const Documentation = "/documentation/";
export const ConstructionProjects = "/construction-projects/";
export const AssetMessages = "/messages/";

export const Splash = "/splash/";
export const Our = "/our/";
export const Org = "/organisation/";
export const Type = "type/";
export const NotFound = "/not-found";
