import * as moment from "moment";
import * as consts from "./../../app/view/const";
import { appConfig } from "./../../config";
import { ValueFormatterParams } from "ag-grid-community";

/**
 * Checks if dates is valid for a NEW safetycard.
 * This means:
 * - FROM date is same of after TODAY
 * - TO date is same of after FROM
 * @param fromIsoDate From date in ISO format
 * @param toIsoDate To date in ISO fromat
 */
export function isNewDatesRangeValid(
  fromIsoDate: string,
  toIsoDate: string,
  maxValidIsoDate: string = ""
): boolean {
  const momentFrom = moment(fromIsoDate).startOf("day");
  const momentTo = moment(toIsoDate).startOf("day");

  if (maxValidIsoDate) {
    return (
      momentTo.isSameOrAfter(momentFrom) &&
      momentTo.isSameOrBefore(moment(maxValidIsoDate))
    );
  }

  const cond = momentTo.isSameOrAfter(momentFrom);

  return cond;
}

export function isDatesInOrder(fromIsoDate: string, toIsoDate: string) {
  const momentFrom = moment(fromIsoDate).startOf("day");
  const momentTo = moment(toIsoDate).startOf("day");

  return momentTo.isSameOrAfter(momentFrom);
}

export function getMiddayIsoString(date: moment.Moment): string {
  return date
    .hour(12)
    .utc()
    .hour(12)
    .minutes(0)
    .second(0)
    .millisecond(0)
    .toISOString();
}

export function isDateAfterStartOfDay(isoDate: string): boolean {
  const now = moment().startOf("day");
  const momentToCheck = moment(isoDate).startOf("day");
  return momentToCheck.isSameOrAfter(now);
}

export function DateFormater(isoDate: string): any {
  return moment(isoDate)
    .locale(appConfig.locale)
    .format(consts.dateFormat);
}

export function DateTimeFormater(isoDateTime: string) {
  return moment(isoDateTime)
    .locale(appConfig.locale)
    .format(consts.dateTimeFormat);
}

export function Now(): moment.Moment {
  return moment().locale(appConfig.locale);
}

export function NowIsoString(): string {
  return Now().toISOString();
}

export function NowIsoStringAddYear(year: number): string {
  return moment()
    .locale(appConfig.locale)
    .add(year, "year")
    .toISOString();
}

export function StartOfDay(): string {
  return moment()
    .locale(appConfig.locale)
    .startOf("day")
    .toISOString();
}

export function EndOfDay(): moment.Moment {
  return moment()
    .locale(appConfig.locale)
    .endOf("day");
}

export function ConvertToStartOfDay(date: string): string {
  return moment(date)
    .locale(appConfig.locale)
    .startOf("day")
    .toISOString();
}

export function ConvertToEndOfDay(date: string): string {
  return moment(date)
    .locale(appConfig.locale)
    .endOf("day")
    .toISOString();
}

export function getLocale(): string {
  return appConfig.locale;
}

export function getDateFormat() {
  return consts.dateFormat;
}

export function formatGridDate(d: ValueFormatterParams) {
  if (d.value) {
    return DateFormater(d.value);
  } else {
    return "";
  }
}

export function formatGridDateTime(d: ValueFormatterParams) {
  if (d.value) {
    return DateTimeFormater(d.value);
  } else {
    return "";
  }
}

export function formatGridDateOnly(d: ValueFormatterParams) {
  if (d.value) {
    return DateFormater(d.value);
  } else {
    return "";
  }
}

export function getMaxDate(dates: Date[]) {
  if (dates && dates.length > 0) {
    return moment.max(dates.map(d => moment(d)));
  } else {
    return "";
  }
}

export function weekdays(lang: string): string[] {
  const eng = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  switch (lang) {
    case "en":
    case "en-GB":
    case "en-US":
      return eng;
    case "no":
    case "nb":
      return [
        "mandag",
        "tirsdag",
        "onsdag",
        "torsdag",
        "fredag",
        "lørdag",
        "søndag"
      ];
    case "se":
      return [
        "måndag",
        "tisdag",
        "onsdag",
        "torsdag",
        "fredag",
        "​​lördag",
        "söndag"
      ];
    case "nl":
      return [
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag",
        "Zondag"
      ];
    default:
      return eng;
  }
}
