import { UserStore } from "../../stores/user-store";
// import { OrgRightsStore } from "../../routes/admin-org-rights/state/admin-org-rights";
import { OrganisationTemplate } from "./../../types";
import * as Routes from "./../view/route-paths";

export default function hasRight(
  userStore: UserStore,
  path: string,
  orgs: Map<number, OrganisationTemplate>,
  orgIdOrEmployerId: number = -1
): boolean {
  const whoAmI = userStore.userIdentity;
  switch (path) {
    /*
      OUR PERMITO    
    */
    case Routes.GetOurRightsRoute():
    case Routes.GetOurDoorKeysRoute(): {
      const { rights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rights.admin;
    }
    case Routes.GetOurApplicationRoute():
    case Routes.GetOurDoorKeyApplicationViewRoute():
    case Routes.GetOurDoorKeyApplicationsRoute():
    case Routes.GetOurDocumentationRoute():
    case Routes.GetOurApplicationListRoute(): {
      const { rwRights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rwRights.hasReadWrite;
    }
    case Routes.GetOurCardRoute():
    case Routes.GetOurCardsListRoute(): {
      const { rwRights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rwRights.hasRead || whoAmI.issuePermissions.length > 0;
    }
    case Routes.GetOurPersonnelListRoute(): {
      const { rwRights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rwRights.hasRead || whoAmI.issuePermissions.length > 0;
    }
    case Routes.GetOurPersonRoute():
    case Routes.GetOurExternalCardsRoute():
    case Routes.GetOurProfileRoute(): {
      const { rwRights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rwRights.hasRead;
    }
    case Routes.GetOurPermittoRoute(): {
      const { exists } = userStore.employerRights.get(orgIdOrEmployerId);
      return exists || whoAmI.issuePermissions.length > 0;
    }
    case Routes.GetOurAccessRoute(): {
      const { rights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rights.accessView;
    }
    case Routes.GetOurAccessLogRoute(): {
      const { rights } = userStore.employerRights.get(orgIdOrEmployerId);
      return rights.accessHistory;
    }
    /*
      ORGANISATION
    */
    case Routes.GetOrganisationPermittoRoute(): {
      const { exists } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return exists;
    }
    case Routes.GetOrganisationIssueListRoute():
    case Routes.GetOrganisationIssueRoute(): {
      const { rights, rwRights } = userStore.organisationsRights.get(
        orgIdOrEmployerId
      );
      return rights.issuer || rwRights.hasReadWrite;
    }
    // Org approve visible
    case Routes.GetOrganisationApprovalListRoute():
    case Routes.GetOrganisationApprovalRoute(): {
      const { rights, rwRights } = userStore.organisationsRights.get(
        orgIdOrEmployerId
      );
      const org = orgs.get(orgIdOrEmployerId);
      return (
        (rwRights.hasReadWrite || rights.issuer) &&
        org &&
        org.cardTypes.filter(a => a.cardApplicationEnabled).length > 0
      );
    }
    // Org ADMIN
    case Routes.GetOrganisationRightsRoute():
    case Routes.GetOrganisationRolesRoute():
    case Routes.GetOrganisationDoorkeyTemplateRoute():
    case Routes.GetOrganisationTemplateRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.admin;
    }
    case Routes.GetOrganisationAssetMessagesRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.assetAdmin || rights.admin || rights.projectAdmin;
    }
    case Routes.GetOrganisationAssetListRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.assetAdmin || rights.admin;
    }
    case Routes.GetOrganisationExternalCardsRoute(): {
      const { rights, rwRights } = userStore.organisationsRights.get(
        orgIdOrEmployerId
      );
      return rights.projectAdmin || rwRights.hasReadWrite;
    }
    case Routes.GetOrganisationEmployersRoute(): {
      const { rights, rwRights } = userStore.organisationsRights.get(
        orgIdOrEmployerId
      );
      return rights.admin || rights.projectAdmin || rwRights.hasReadWrite;
    }
    case Routes.GetOrganisationConstructionProjectsRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.projectAdmin;
    }
    // ORG Visible
    case Routes.GetOrganisationCardsListRoute():
    case Routes.GetOrganisationCardRoute():
    case Routes.GetOrganisationPersonnelListRoute():
    case Routes.GetOrganisationPersonRoute(): {
      const { rwRights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rwRights.hasRead;
    }
    case Routes.GetOrganisationDoorKeysRoute():
    case Routes.GetOrganisationDoorKeyApplicationsRoute():
    case Routes.GetOrganisationDoorKeyApplicationViewRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.doorkeyAdmin;
    }
    case Routes.GetOrganisationDocumentationRoute(): {
      const { rights, rwRights } = userStore.organisationsRights.get(
        orgIdOrEmployerId
      );
      return rwRights.hasReadWrite || rights.issuer;
    }
    // Org access Visible
    case Routes.GetOrganisationAccessRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.accessView || rights.projectAdmin;
    }
    case Routes.GetOrganisationAccessLogRoute(): {
      const { rights } = userStore.organisationsRights.get(orgIdOrEmployerId);
      return rights.accessHistory || rights.projectAdmin;
    }

    /*
      MY PERMITTO
    */
    // My Permitto visible
    case Routes.GetUpdateInfoRoute():
      return whoAmI && whoAmI.allowUpdate;
    case Routes.GetMyPermittoRoute():
    case Routes.GetMyAccessRoute():
    case Routes.GetMyCardsListRoute():
    case Routes.GetMyCardRoute():
    case Routes.GetMyApplicationRoute():
    case Routes.GetMyAccessLogRoute():
    case Routes.GetMyProfileRoute():
    case Routes.GetMyExternalCardsRoute():
    case Routes.GetMyDoorKeyApplicationViewRoute():
    case Routes.GetMyDoorKeyApplicationsRoute():
    case Routes.GetMyDoorKeysRoute():
      return whoAmI ? true : false;
    case Routes.GetMyApplicationListRoute(): {
      return (
        whoAmI &&
        Array.from(orgs)
          .map(org => {
            return { orgId: org[0], org: org[1] };
          })
          .filter(
            o =>
              o.org.cardTypes.filter(c => c.cardApplicationEnabled).length > 0
          ).length > 0
      );
    }
    /* Generic routes */

    case Routes.GetInviteRoute():
    case Routes.GetAttestRoute():
      return true;
    default:
    // console.log(path + " not found!");
  }
}
