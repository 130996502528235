// import * as React from "react";
import { Local } from "./local";
import {
  OrganisationTemplate
  // CardType
} from "../../types";
import { SweRoutes } from "./se-routes";
import { SweShared } from "./se-shared";

export const Swe: Local = {
  language: "se",
  app: {
    constants: {
      SafetyCardText: (
        organisation: OrganisationTemplate,
        currentCardTypeId: number
      ) =>
        currentCardTypeId === -1
          ? "kort"
          : organisation &&
            organisation.cardTypes &&
            organisation.cardTypes.find(c => c.cardTypeId === currentCardTypeId)
              .cardNameNo,
      SafetyCardTextNeutral: "kort",
      CreatedApplication: (statusSetByName: string) =>
        `Ansökan skapad av ${statusSetByName}`,
      CreatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Ansökan skapad av ${statusSetByName} och väntar på bestyrkande av ${attestorName} `,
      CreatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} skapad av ${statusSetByName}`,
      UpdatedApplication: (statusSetByName: string) =>
        `Ansökan uppdaterat av ${statusSetByName}`,
      UpdatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Ansökan uppdaterat av ${statusSetByName} och väntar på bestyrkande av ${attestorName} `,
      UpdatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} uppdaterat av ${statusSetByName}`,
      Issued: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Utfärdad vid ${statusSetByOrgEmp} av ${statusSetByName} `,
      IssuedOnBehalf: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Utfärdas på uppdrag av ${statusSetByOrgEmp} ved ${statusSetByName}`,
      OrgApprovedCard: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) => `${cardName} skapas vid ${statusSetByOrgEmp} av ${statusSetByName}`,
      OrgApprovedApplication: (
        caseManager: string,
        orgName: string,
        cardName: string
      ) =>
        `${cardName} ansökan som bearbetas på ${orgName} av ${caseManager} och väntar på att utfärdas`,
      OrgApprovedCardOnBehalf: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) =>
        `${cardName} kapad på uppdrag av ${statusSetByOrgEmp} ved ${statusSetByName}`,
      ReceiptReceived: "Mottaget kvitto",
      DoorkeyReserved: "Nyckel reserverad",
      OrgRejected: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Avvisad vid ${statusSetByOrgEmp} av ${statusSetByName} `,
      GoneStale:
        "Användaren har inte vidtagit nödvändiga åtgärder, varning om borttagning skickas",
      ApplicationCreated: "Ansökan har skapats",
      EmpApproved: "Ansökan bestyrkas",
      EmpNotApproved: "Ansökan inte bestyrkas",
      AwaitingEmpApproval: "Väntar på bestyrkande",
      EmpRejected: "Ansökan avvisad av attestor",
      EmpRejectedView: "Ansökan avvisad av attestor-",
      EmpApprovedView: "Ansökan bestyrkas-",
      Shredded: "Strimlad",
      ApprovedUser: "Giltig användar",
      Invited: "Inbjuden",
      InvalidUser: "Användaren är inte giltig",
      Loading: "Laddar data. Snälla vänta",
      NoRowsToShow: "Data uppsättningen är tom",
      NoSafetycardsToShow: "Inga kort hittades",
      Filter: "Filter...",
      Equal: "Motsvarar",
      NotEqual: "Inte är lika med",
      LessThan: "Mindre än",
      LessThanOrEqual: "Mindre än eller lika med",
      GreaterThan: "Större än",
      GreaterThanOrEqual: "Större än eller lika med",
      InRange: "Inom räckhåll",
      ClearFilter: "Rensa filter",
      ApplyFilter: "Använd filter",
      StartsWidth: "Börjar med",
      EndsWidth: "Avslutas med",
      Contains: "Innehåller",
      NotContains: "Innehåller inte",
      RowGroupColumnsEmptyMessage: "Dra här för att ställa in radgrupper",
      Groups: "Radgrupper",
      PivotColumnsEmptyMessage: "Dra här om du vill ange kolumnetiketter",
      Pivots: "Kolumnetiketter",
      Sum: "Summan",
      Count: "Räkna",
      Min: "Min",
      Max: "Max",
      Average: "Genomsnittliga",
      PinColumn: "Fäst kolumn",
      PinLeft: "Fäst vänster",
      PinRight: "Fäst höger",
      NoPin: "Ingen PIN-kod",
      AutosizeThiscolumn: "AutoSize den här kolumnen",
      AutosizeAllColumns: "AutoSize alla kolumner",
      ResetColumns: "Återställ kolumner",
      ToolPanel: "Verktygspanelen",
      PivotMode: "Pivot-läge",
      ValueColumnsEmptyMessage: "Dra här för att aggregera",
      Values: "Värden",
      SearchOoo: "Sök...",
      SelectAll: "Markera alla",
      Copy: "Kopiera",
      CtrlC: "CTRL + C",
      CopyWithHeaders: "Kopiera med rubriker",
      Paste: "Klistra in",
      CtrlV: "CTRL + V",
      Export: "Exportera",
      CsvExport: "Exportera CSV-",
      ExcelExport: "Exportera Excel",
      Columns: "Kolumner",
      DoorKeyCreated: "Ny dörr nyckel applikation",
      DoorKeyReciptRecieved: "Mottaget kvitto",
      DoorKeyOrganizationApproved: "Godkänna",
      DoorKeyOrganizationRejected: "Avvisa"
    }
  },
  routes: SweRoutes,
  shared: SweShared,
  actions: {
    supportTitle: "Kontakta supporten",
    supportEmail: "app-support@verico.com",
    area: {
      personell: "personal",
      rights: "användarrättigheter",
      access: "tillgång",
      keys: "nycklar",
      application: "ansökan",
      template: "mall",
      attestor: "attestor",
      company: "företag",
      stations: "stationer",
      user: "användaren",
      files: "filer",
      pendingTasks: "väntande uppgifter",
      pendingScCount: "väntande säkerhetskort räkning",
      pendingSC: "väntande säkerhetskort",
      employer: "arbetsgivare",
      employers: "arbetsgivare",
      termsOfService: "terms of service",
      person: "person",
      preferredLanguage: "företrädesvis språk",
      maintenanceMessages: "underhålls meddelanden",
      documents: "dokument",
      notifications: "anmälningar",
      image: "bild",
      organization: "organisation",
      attributeDataType: "attributdatatyper",
      applications: "ansökningar",
      assets: "säkerhetskort anläggningar",
      accessHistory: "Tilgångshistorik"
    },
    error: {
      title: "Varning",
      uploadError: (area: string) =>
        `Ett fel har uppstått vid uppladdning av ${area}.`,
      updateError: (area: string, extraText?: string) =>
        `Ett fel uppstod vid uppdatering av ${area}${
          extraText ? ` för ${extraText}.` : "."
        }`,
      fetchError: (area: string, extraText?: string) =>
        `Ett fel uppstod vid hämtning av ${area}${
          extraText ? ` för ${extraText}.` : "."
        }`,
      sendError: (area: string) =>
        `Ett fel har uppstått vid uppladdning av ${area}. .`,
      deleteError: (area: string, extraText?: string) =>
        `Ett fel har uppstått vid radering av ${area}${
          extraText ? ` för ${extraText}.` : "."
        }`,
      approveError: (area: string) =>
        `Ett fel har uppstått vid bestyrkande av ${area}.`,
      acceptError: (area: String) =>
        `Ett fel har uppstått vid accepterande av ${area}.`,
      saveError: (area: string) => `Ett fel uppstod när ${area} skulle sparas.`,
      inviteError: (area: String) =>
        `Ett fel har uppstått vid inbjudan till ${area}.`,
      checkoutError: (area: string) =>
        `Ett fel har uppstått när du checkar ut av ${area}.`,
      changeError: (area: string) =>
        `Ett fel har uppstått vid ändring av ${area}.`,
      activateError: (area: string) =>
        `Ett fel har uppstått vid aktivering av ${area}.`,
      createError: (area: string) =>
        `ett fel uppstod när ${area} skulle skapas.`,
      logoutError: "Ett fel uppstod när du loggar ut.",
      unknownError: "Okänt fel. Prova att ladda om sidan"
    },
    contactSupport:
      "<p> vänligen ladda om sidan och försök igen. Om felet presister vänligen kontakta supporten med hjälp av <a href='mailto:app-support@verico.com'> det här e-postmeddelandet. </a> </p>",
    doorKeys: {
      ErrorDiscardKey: "Det gick inte att kassera nyckeln.",
      ErrorSaveDoorKey: "Det gick inte att skapa nya nycklar.",
      ErrorReceiveDoorKey: "Det gick inte att bekräfta nyckeln som mottagen.",
      ErrorUpdateDoorKey: "Det gick inte att uppdatera data för nyckeln.",
      ErrorReserveDoorKey: "Det gick inte att reservera nyckeln.",
      ErrorGetDoorKeys: "Kunde inte henta nycklar.",
      ErrorReturnKey:
        "Det gick inte att registrera nyckeln som returnerades till lager",
      ErrorLostKeyModalMessage:
        "Det gick inte att registrera nyckeln som förlorad.",
      ErrorHistoryMessage: "Kunde inte få nyckel historia.",
      ErrorSignReturnKeyModalMessage:
        "Det gick inte att bekräfta nyckeln som mottagen.",
      ErrorRecallKeyModalMessage: "Det gick inte att återkalla nyckeln.",
      ErrorDeleteKeyModalMessage: "Det gick inte att ta bort nyckeln.",
      ErrorFetchingPersonOrgDoorKeys: "Kunde inte henta nycklar.",
      ErrorFetchingPersonEmployerDoorKeys: "Kunde inte henta nycklar."
    },
    otherCards: {
      ErrorNetworkOperation: "Ett nätverksfel inträffade."
    }
  }
};
