import {
  ApplicationIdParameter,
  CardsList,
  Card,
  CardIdParameter,
  Access,
  AccessLog,
  ApplicationList,
  DoorKeys,
  ExternalCards,
  Splash,
  Profile,
  Application
} from "../consts";

export function GetMyApplicationListRoute() {
  return ApplicationList;
}

export function GetMyApplicationRoute() {
  return Application + ApplicationIdParameter;
}

export function GetMyCardsListRoute() {
  return CardsList;
}

export function GetMyCardRoute() {
  return Card + CardIdParameter;
}

export function GetMyPermittoRoute() {
  return Splash;
}

export function GetMyProfileRoute() {
  return Profile;
}

export function GetMyExternalCardsRoute() {
  return ExternalCards;
}

export function GetMyAccessRoute() {
  return Access;
}

export function GetMyAccessLogRoute() {
  return AccessLog;
}

export function GetMyDoorKeyApplicationViewRoute() {
  return `${DoorKeys}${ApplicationList}${ApplicationIdParameter}`;
}

export function GetMyDoorKeyApplicationsRoute() {
  return `${DoorKeys}${ApplicationList}`;
}

export function GetMyDoorKeysRoute() {
  return DoorKeys;
}
