import {
  //multiPartPost,
  putOrPatchJson,
  deleteCall,
  uploadFile
} from "./fetches";
import { appConfig } from "./../config";
import { joinUrlParams, UrlParamArg } from "./helpers";
import { OtherCardTypeAndDates } from "./../types";

const OTHER_CARDS_BASE_URL = appConfig.baseUrl + appConfig.serviceName;

function joinOtherCardsUrl(
  othercardsId: number,
  endPoint: string,
  urlParams?: UrlParamArg
): string {
  const newEndpoint = endPoint.length > 0 ? `/${endPoint}` : "";
  return `${OTHER_CARDS_BASE_URL}/othercards/${othercardsId}${newEndpoint}${joinUrlParams(
    urlParams
  )}`;
}

export async function deleteOtherCard(othercardsId: number) {
  return await deleteCall(
    joinOtherCardsUrl(othercardsId, ""),
    "deleteOtherCardImage"
  );
}

export async function deleteCardFrontImage(othercardsId: number) {
  return await deleteCall(
    joinOtherCardsUrl(othercardsId, "front"),
    "deleteCardFrontImage"
  );
}

export async function deleteCardBackImage(othercardsId: number) {
  return await deleteCall(
    joinOtherCardsUrl(othercardsId, "back"),
    "deleteCardBackImage"
  );
}

export async function updateOtherCard(
  othercardsId: number,
  cardAttributes: OtherCardTypeAndDates
) {
  return await putOrPatchJson(
    joinOtherCardsUrl(othercardsId, ""),
    "PUT",
    JSON.stringify(cardAttributes),
    "updateOtherCard"
  );
}

export async function updateCardFrontImage(
  othercardsId: number,
  imgFile: File
) {
  return await uploadFile(
    joinOtherCardsUrl(othercardsId, "front"),
    "PUT",
    imgFile,
    "updateCardFrontImage"
  );
}

export async function updateCardBackImage(othercardsId: number, imgFile: File) {
  return await uploadFile(
    joinOtherCardsUrl(othercardsId, "back"),
    "PUT",
    imgFile,
    "updateCardBackImage"
  );
}

export async function getCardFrontImage(othercardsId: number) {
  return joinOtherCardsUrl(othercardsId, "front");
}

export async function getCardBackImage(othercardsId: number) {
  return joinOtherCardsUrl(othercardsId, "back");
}
