import { LocalStorageKeys } from "../types";

class LocalStorageStore {
  setItem(key: LocalStorageKeys, val: any) {
    localStorage.setItem(key, JSON.stringify(val));
  }

  getItem(key: LocalStorageKeys) {
    return JSON.parse(localStorage.getItem(key));
  }

  removeItem(key: LocalStorageKeys) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export const localStorageStore = new LocalStorageStore();
