import {
  PersonInfo,
  PreferredLanguage,
  Notification,
  DoorKeysInfo,
  DoorKeyApplication
} from "../types";

import * as repo from "./../repo";
import { errorStore } from "../stores/index";

import { localizationStore } from "./../stores";
import { Country } from "../libs/employer-bronnoysund-autocomplete/view/employer-bronnoysund-autocomplete";

export async function lookupPerson(phone: string): Promise<PersonInfo | null> {
  /*
    This lookup is a bit different, as it will 'fail' with a 404 most of the time
    Therefor we accept the return of null value.
  */
  try {
    return await repo.getLookupPerson(phone);
  } catch {
    return null;
  }
}

export async function personExists(phone: string): Promise<boolean> {
  let result;
  try {
    result = await repo.personExists(phone);
    if (result === 1) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export function postNewUser(
  person: PersonInfo,
  sendEmail: boolean,
  country: Country
): Promise<PersonInfo> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postPersonnel(person, sendEmail, country.toString()),
    local.error.title,
    `${local.error.createError(local.area.person)}${local.contactSupport}`
  );
}

export async function getPersonInOrg(
  personnelId: number,
  orgId: number
): Promise<PersonInfo> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonInOrg(personnelId, orgId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export async function getPersonNoOrg(personnelId: number): Promise<PersonInfo> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonNoOrg(personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export function putPerson(person: PersonInfo): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putPersonnel(person),
    local.error.title,
    `${local.error.updateError(local.area.personell)}${local.contactSupport}`
  );
}

export function patchPersonAttributes(
  personnelId: number,
  attributes: { attributeId: number; value: string }[]
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.patchPersonnelAttribute(personnelId, attributes),
    local.error.title,
    `${local.error.updateError(local.area.personell)}${local.contactSupport}`
  );
}

export function postInvitePerson(
  orgId: number,
  person: PersonInfo,
  sendSms: boolean,
  country: string
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postInvitePerson(orgId, person, sendSms, country.toString()),
    local.error.title,
    `${local.error.createError(local.area.person)}${local.contactSupport}`
  );
}

export function personAcceptTos(personnelId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putAcceptTos(personnelId),
    local.error.title,
    `${local.error.saveError(local.area.termsOfService)}${local.contactSupport}`
  );
}

export function getNotificationValuesCardExpiration(): Promise<Notification[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getNotificationValuesCardexpiration(),
    local.error.title,
    `${local.error.fetchError(local.area.notifications)}${local.contactSupport}`
  );
}

export function getNotificationValuesDocExpiration(): Promise<Notification[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getNotificationValuesDocExpiration(),
    local.error.title,
    `${local.error.fetchError(local.area.notifications)}${local.contactSupport}`
  );
}

export function setPreferredLanguage(
  personnelId: number,
  language: PreferredLanguage
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.setPreferredLanguage(personnelId, language),
    local.error.title,
    `${local.error.saveError(local.area.preferredLanguage)}${
      local.contactSupport
    }`
  );
}

export function getMyDoorkeyList(personnelId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getMyDoorKeysList(personnelId),
    local.error.title,
    `${local.doorKeys.ErrorGetDoorKeys}${local.contactSupport}`
  );
}

export function getEmployerDoorkeysForPerson(
  personnelId: number
): Promise<DoorKeysInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerDoorkeysForPerson(personnelId),
    local.error.title,
    `${local.doorKeys.ErrorFetchingPersonEmployerDoorKeys}${local.contactSupport}`
  );
}

export function getMyDoorKeyApplicationList(
  orgId: number
): Promise<DoorKeyApplication[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getMyDoorKeyApplicationList(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.keys, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function getMyDoorKeyApplication(
  applicId: number
): Promise<DoorKeyApplication> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getMyDoorKeyApplication(applicId),
    local.error.title,
    `${local.error.fetchError(local.area.keys, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function putDoorKeyApplicationResend(
  personnelId: number,
  applicationId: number,
  comment: string
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putDoorKeyApplicationResend(personnelId, applicationId, comment),
    local.error.title,
    `${local.error.updateError(local.area.notifications)}${
      local.contactSupport
    }`
  );
}
