import {
  Our,
  CardsList,
  Type,
  Card,
  PersonnelList,
  Person,
  Access,
  AccessLog,
  ApplicationList,
  Documentation,
  DoorKeys,
  Splash,
  Profile,
  Rights,
  Application
} from "../consts";

export function IsOurApplicationListUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${ApplicationList}`);
  return regexp.test(url);
}

export function IsOurApplicationUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Application}`);
  return regexp.test(url);
}

export function IsOurCardListUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${CardsList}+${Type}+?\\d`);
  return regexp.test(url);
}

export function IsOurCardUrl(url: string) {
  const regexp = new RegExp(
    `^${Our}+?\\d+${CardsList}+${Type}+?\\d+${Card}+?\\d+`
  );
  return regexp.test(url);
}

export function IsOurPersonnelListUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${PersonnelList}`);
  return regexp.test(url);
}

export function IsOurPersonUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Person}+?\\d+`);
  return regexp.test(url);
}

export function IsOurRightsUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Rights}`);
  return regexp.test(url);
}

export function IsOurPermittoUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Splash}`);
  return regexp.test(url);
}

export function IsOurProfileUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Profile}`);
  return regexp.test(url);
}

export function IsOurExternalCardsUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${CardsList}`);
  return regexp.test(url);
}

export function IsOurAccessUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Access}`);
  return regexp.test(url);
}

export function IsOurAccessLogUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${AccessLog}`);
  return regexp.test(url);
}

export function IsOurDoorkeysUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${DoorKeys}`);
  return regexp.test(url);
}

export function IsOurDoorkeyApplicationsUrl(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${DoorKeys}${ApplicationList}`);
  return regexp.test(url);
}

export function IsOurDocumentation(url: string) {
  const regexp = new RegExp(`^${Our}+?\\d+${Documentation}`);
  return regexp.test(url);
}
