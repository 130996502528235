import * as React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import styles from "./current-user.css";
import { withRouter } from "react-router-dom";
import * as Routes from "./../../app/view/route-paths";
import { userStore, localizationStore } from "./../../stores";
import { RouteProps, PreferredLanguage } from "../../types";
import * as dalc from "./../../dalc";
import { getPreferredLanguage } from "../../stores/localization";
import { LanguageSelector } from "../../../src/libs/language-selector/language-selector";

@observer
class CurrentUserView extends React.Component<RouteProps> {
  @observable isPanelOpen: boolean;
  blueSetIcon: string = "res/blue/Permitto-icon-RGB-settings.svg";
  whiteSetIcon: string = "res/Permitto-icon-RGB-settings.svg";

  constructor(props: RouteProps) {
    super(props);
    this.isPanelOpen = false;
  }

  @action("toogleIsOpen current-user")
  toogleIsOpen = () => {
    this.isPanelOpen = !this.isPanelOpen;
  };

  setLanguage(language: PreferredLanguage) {
    userStore.setLanguage(language);
    localizationStore.updateCurrentLocal();
    dalc.person.setPreferredLanguage(userStore.userInfo.personnelId, language);
  }

  switchContext = e => {
    this.props.history.push(e.target.value);
  };

  _renderPersonInfo(iconSrc: string) {
    return (
      <div className={styles.personInfo}>
        <div className={styles.nameAndLogo}>
          <p className={styles.nameText}>
            {userStore.userInfo
              ? `${userStore.userInfo.firstName} ${userStore.userInfo.lastName} - ${userStore.userInfo.employerName}`
              : ""}
          </p>
          <img
            className={styles.loggedInLogo}
            src={"res/Permitto-icon-RGB-settings.svg"}
          />
        </div>
        <div className={styles.userInfoContainer}>
          <i
            style={{ color: "darkgray" }}
            className="fa fa-envelope-o"
            aria-hidden="true"
          />
          <p className={styles.emailText}>
            {userStore.userInfo ? userStore.userInfo.email : ""}
          </p>
        </div>
        <div className={styles.userInfoContainer}>
          <i
            style={{ color: "darkgray" }}
            className="fa fa-phone"
            aria-hidden="true"
          />
          <p className={styles.emailText}>
            {userStore.userInfo ? userStore.userInfo.mobilePhone : ""}
          </p>
        </div>
      </div>
    );
  }

  render() {
    const iconSrc = this.whiteSetIcon;
    const loggInTextClass = styles.whiteText;
    const local = localizationStore.currentLocal;
    return (
      <div className={styles.container}>
        {userStore.userInfo ? (
          <p className={`${styles.userNameText} ${loggInTextClass}`}>
            <span className={styles.loginText}>
              {local.shared.currentUser.LoggedIn}
            </span>
            <em>
              {`${userStore.userInfo.firstName} ${userStore.userInfo.lastName}`}
            </em>
          </p>
        ) : (
          false
        )}
        <div className={styles.dropdownContainer}>
          <div
            className={
              styles.dropdownButtonOpen +
              (this.isPanelOpen ? ` ${styles.hide}` : "")
            }
            onClick={this.toogleIsOpen}
          >
            <img className={styles.loggedInLogo} src={iconSrc} />
          </div>
          <div
            className={`${styles.dropdownButtonClose} ${
              this.isPanelOpen ? "" : ` ${styles.hide}`
            }`}
            onClick={this.toogleIsOpen}
          >
            <img className={styles.loggedInLogo} src={iconSrc} />
          </div>

          <div
            className={`${styles.dropdownPanelArrow} ${
              this.isPanelOpen ? "" : ` ${styles.hide}`
            }`}
          />
          <div
            className={`${styles.dropdownPanel} ${
              this.isPanelOpen ? "" : ` ${styles.hide}`
            }`}
          >
            {this._renderPersonInfo(this.blueSetIcon)}
            <hr className={styles.divider} />
            <LanguageSelector
              onSelect={lang => {
                this.setLanguage({ preferredLanguage: lang });
              }}
              defaultLanguage={getPreferredLanguage()}
              labelCustomClass={styles.listItemFont}
            />
            <hr className={styles.divider} />
            <a
              href={Routes.GetTosRoute()}
              className={`${styles.tosStyle} ${styles.button}`}
              target="_blank"
            >
              {local.shared.currentUser.PrivacyPolicy}
            </a>

            <hr className={styles.divider} />
            <a
              className={`${styles.tosStyle} ${styles.button}`}
              href={`mailto:${local.actions.supportEmail}`}
            >
              {local.actions.supportTitle}
            </a>
            <hr className={styles.divider} />
            <div
              className={styles.buttonContainer}
              onClick={() => {
                userStore.logOutUser();
              }}
            >
              <div className={styles.button}>
                <img
                  className={styles.buttonIcon}
                  src={"res/Permitto-icon-RGB-Logout-blue.svg"}
                />
                <div className={styles.listItemFont}>
                  {local.shared.currentUser.LogOut}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.overlay} ${
              this.isPanelOpen ? "" : ` ${styles.hide}`
            }`}
            onClick={(e: any) => this.toogleIsOpen()}
          />
        </div>
      </div>
    );
  }
}

export const CurrentUser = withRouter(CurrentUserView);
