import { appConfig } from "./../config";
import {
  PersonInfo,
  SafetyCard,
  Employer,
  UserIdentity,
  Asset,
  AssetEntry,
  OurPendingActions,
  OurPermittoRights,
  OtherCard,
  DoorKeysInfo,
  DocumentationInfo,
  Documentation,
  DoorKeyApplication
} from "./../types";
import { getJson, postJson, putOrPatchJson, deleteCall } from "./fetches";
import { joinUrlParams, getAccessLogDateStr, UrlParamArg } from "./helpers";

const EMPLOYER_BASE_URL = appConfig.baseUrl + appConfig.serviceName;

function joinEmployerUrl(
  employerId: number,
  endPoint: string,
  urlParams?: UrlParamArg
): string {
  const newEndpoint = endPoint.length > 0 ? `/${endPoint}` : "";
  return `${EMPLOYER_BASE_URL}/employers/${employerId}${newEndpoint}${joinUrlParams(
    urlParams
  )}`;
}

export async function getEmpoyerAccessLog(
  organizationNo: number,
  fromDate: Date,
  toDate: Date
): Promise<AssetEntry[]> {
  return getJson<AssetEntry[]>(
    joinEmployerUrl(
      organizationNo,
      "accesslog",
      getAccessLogDateStr(fromDate, toDate)
    ),
    "getEmpoyerAccessLog"
  );
}

export async function getEmpoyerOtherCards(
  employerId: number
): Promise<OtherCard[]> {
  return getJson<OtherCard[]>(
    joinEmployerUrl(employerId, "othercards"),
    "getEmpoyerOtherCards"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/safetycards/pending GET
export async function getEmpSafetycardsPendingCount(
  employerId: number
): Promise<OurPendingActions> {
  return await getJson<OurPendingActions>(
    joinEmployerUrl(employerId, "safetycards/pending"),
    "getEmpSafetycardsPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/doorkeys/applications/pending GET
export async function getEmpDoorkeyApplicPendingCount(
  employerId: number
): Promise<number> {
  return await getJson<number>(
    joinEmployerUrl(employerId, "doorkeys/applications/pending"),
    "getEmpDoorkeyApplicPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/rights GET
export async function getEmployerRights(
  employerId: number
): Promise<UserIdentity[]> {
  return await getJson<UserIdentity[]>(
    joinEmployerUrl(employerId, "rights"),
    "getOrganisationRights"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/personnel/{ID}/rights/admin POST
export async function postEmployerRightsAdmin(
  employerId: number,
  personnelId: number,
  rights: OurPermittoRights
): Promise<OurPermittoRights> {
  return await postJson<OurPermittoRights>(
    joinEmployerUrl(employerId, `personnel/${personnelId}/rights/admin`),
    JSON.stringify(rights),
    "postEmployerRightsAdmin"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/personnel/{ID}/rights/admin DELETE
export async function deleteEmployerRights(
  employerId: number,
  personnelId: number
) {
  return await deleteCall(
    joinEmployerUrl(employerId, `personnel/${personnelId}/rights/admin`),
    "deleteEmployerRights"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/person/{PERSONID}/documentation GET
export async function getEmployerPersonnelDocumentation(
  employerId: number,
  personnelId: number
) {
  return await getJson<Documentation[]>(
    joinEmployerUrl(employerId, `person/${personnelId}/documentation`),
    "getEmployeePersonnelDocumentation"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/employers/{ORGID}/doorkeys/applications GET
export async function getEmployerDoorKeyApplicationList(
  employerId: number
): Promise<DoorKeyApplication[]> {
  const data = await getJson<DoorKeyApplication[]>(
    joinEmployerUrl(employerId, `doorkeys/applications?issued=false`),
    "getEmployerDoorKeyApplicationList"
  );
  return data;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/safetycards/applications/{APPLICATIONID}/approve
export async function putEmployerApproveApplication(
  employerId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinEmployerUrl(
      employerId,
      `safetycards/applications/${applicationId}/approve`
    ),

    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putEmployerApproveApplication"
  );
}

export async function putEmployerApproveApplicationWithToken(
  employerId: number,
  applicationId: number,
  comment: string,
  token: string
): Promise<void> {
  return await putOrPatchJson(
    joinEmployerUrl(
      employerId,
      `safetycards/applications/${applicationId}/approve`
    ),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putEmployerApproveApplication",
    token
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/safetycards/applications/{APPLICATIONID}/reject
export async function putEmployerRejectApplication(
  employerId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    joinEmployerUrl(
      employerId,
      `safetycards/applications/${applicationId}/reject`
    ),

    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putEmployerRejectApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/safetycards/applications/{APPLICATIONID}/reject
export async function putEmployerRejectApplicationWithToken(
  employerId: number,
  applicationId: number,
  comment: string,
  token: string
): Promise<void> {
  return await putOrPatchJson(
    joinEmployerUrl(
      employerId,
      `safetycards/applications/${applicationId}/reject`
    ),
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putEmployerRejectApplication",
    token
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}GET
export async function getEmployer(employerId: number): Promise<Employer> {
  return await getJson<Employer>(
    joinEmployerUrl(employerId, ""),
    "getEmployer"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/assets GET
export async function getEmployerAssets(employerId: number): Promise<Asset[]> {
  return await getJson<Asset[]>(
    joinEmployerUrl(employerId, "assets"),
    "getEmployerAssets"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/personnel GET
export async function getEmployerPersonnel(
  employerId: number
): Promise<PersonInfo[]> {
  return await getJson<PersonInfo[]>(
    joinEmployerUrl(employerId, "personnel"),

    "getEmployerPersonnel"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/asset/entries GET
export async function getEmployerAssetsEnteries(
  employerId: number
): Promise<AssetEntry[]> {
  return await getJson<AssetEntry[]>(
    joinEmployerUrl(employerId, "assets/entries"),
    "getEmployerAssetsEnteries"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/safetycards?includeShredded={INCLUDESHREDDED}&includeAssets={includeAssets} GET
export async function getEmployerSafetycards(
  employerId: number,
  includeShredded: boolean,
  cardTypeId: number
): Promise<SafetyCard[]> {
  return await getJson<SafetyCard[]>(
    joinEmployerUrl(employerId, "safetycards", [
      { name: "includeShredded", value: includeShredded.toString() },
      { name: "cardTypeId", value: cardTypeId.toString() }
    ]),
    "getEmployerSafetycards"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/safetycards/applications?includeShredded={INCLUDESHREDDED}&includeAssets={INCLUDEASSETS} GET
export async function getEmployerApplications(
  employerId: number,
  includeShredded: boolean,
  includeAssets: boolean,
  cardTypeId: number
): Promise<SafetyCard[]> {
  return await getJson<SafetyCard[]>(
    joinEmployerUrl(employerId, "safetycards/applications", [
      { name: "includeShredded", value: includeShredded.toString() },
      { name: "includeAssets", value: includeAssets.toString() },
      { name: "cardTypeId", value: cardTypeId.toString() }
    ]),
    "getEmployerApplications"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID} PUT
export function putEmployer(employer: Employer) {
  const json = JSON.stringify({
    address: employer.address,
    contact: employer.contact,
    name: employer.name
  });
  putOrPatchJson(
    joinEmployerUrl(employer.organizationNo, ""),
    "PUT",
    json,
    "updateEmployer"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{EMPLOYERID}/asset/entries GET
export async function getEmployerDoorKeys(
  employerId: number
): Promise<DoorKeysInfo[]> {
  return await getJson<DoorKeysInfo[]>(
    joinEmployerUrl(employerId, "doorkeys"),
    "getEmployerDoorKeys"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/employers/{employerId}/personnel/documentation GET
export async function getEmployerDocumentation(
  employerId: number
): Promise<DocumentationInfo[]> {
  const data = await getJson<DocumentationInfo[]>(
    joinEmployerUrl(employerId, "personnel/documentation"),
    "getEmployerDocumentation"
  );
  return data;
}
