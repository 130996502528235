import { spy } from "mobx";
import * as fetchIntercept from "fetch-intercept";

export interface logs {
  actions: string;
  httpCalls: string;
}

export interface HTTPCalls {
  url: string;
  body: string;
  headers: string;
  status: string;
  statusText: string;
  type: string;
}

export default class Logger {
  logCount = 10;
  actions: any[] = [];
  httpCalls: HTTPCalls[] = [];
  currentState: any = {};
  reactions: any[] = [];
  computations: any[] = [];

  constructor() {
    fetchIntercept.register({
      request: (url, config) => {
        this.httpCalls.length - 1 === this.logCount
          ? this.httpCalls.shift() &&
            this.httpCalls.push({
              url: url,
              body: "",
              headers: "",
              status: "",
              statusText: "",
              type: ""
            })
          : this.httpCalls.push({
              url: url,
              body: "",
              headers: "",
              status: "",
              statusText: "",
              type: ""
            });

        return [url, config];
      },
      requestError: error => {
        this.httpCalls[this.httpCalls.length - 1].body = error.body;
        this.httpCalls[this.httpCalls.length - 1].headers = error.headers;
        this.httpCalls[this.httpCalls.length - 1].status = error.status;
        this.httpCalls[this.httpCalls.length - 1].statusText = error.statusText;
        this.httpCalls[this.httpCalls.length - 1].type = error.type;
        return Promise.reject(error);
      },

      response: response => {
        const {
          body,
          headers,
          status,
          statusText,
          type
        } = (response as any) as HTTPCalls;
        this.httpCalls[this.httpCalls.length - 1].body = body;
        this.httpCalls[this.httpCalls.length - 1].headers = headers;
        this.httpCalls[this.httpCalls.length - 1].status = status;
        this.httpCalls[this.httpCalls.length - 1].statusText = statusText;
        this.httpCalls[this.httpCalls.length - 1].type = type;
        return response;
      },

      responseError: error => {
        this.httpCalls[this.httpCalls.length - 1].body = error.body;
        this.httpCalls[this.httpCalls.length - 1].headers = error.headers;
        this.httpCalls[this.httpCalls.length - 1].status = error.status;
        this.httpCalls[this.httpCalls.length - 1].statusText = error.statusText;
        this.httpCalls[this.httpCalls.length - 1].type = error.type;
        return Promise.reject(error);
      }
    });

    spy(event => {
      if (event.type === "action") {
        this.actions.length === this.logCount
          ? this.actions.shift() && this.actions.push(event)
          : this.actions.push(event);
      } else if (event.type === "reaction") {
        this.reactions.length === this.logCount
          ? this.reactions.shift() && this.reactions.push(event)
          : this.reactions.push(event);
      } else if (event.type === "compute") {
        this.computations.length === this.logCount
          ? this.computations.shift() && this.computations.push(event)
          : this.computations.push(event);
      }
    });
  }

  getLogs(): logs {
    return {
      actions: JSON.stringify(this.actions.map(a => a.name)),
      httpCalls: JSON.stringify(this.httpCalls.map(h => `${h.url} ${h.status}`))
    };
  }
}
