import * as repo from "../repo";
import { Documentation } from "../types";
import { errorStore } from "../stores/index";
import { localizationStore } from "../stores";

export async function getDocumentationInfo(
  personnelId: number,
  documentationId: number,
  token?: string
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getDocumentationInfo(personnelId, documentationId, token),
    local.error.title,
    `${local.error.fetchError(local.area.files)}${local.contactSupport}`
  );
}

/**
 * This fetches ALL of users doucmentation.
 * SHOULD ONLY BE USED IN MY AND OUR -- NOT SAFE IN ORG - use getPersonDocumentationInOrgs insetad
 *
 * @param personnelId
 */
export async function getPersonDocumentation(
  personnelId: number
): Promise<Documentation[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getDocumentations(personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.files)}${local.contactSupport}`
  );
}

/**
 * Fetches users docs in org.
 * SHOULD BE USED IN ORG
 * @param orgId
 * @param personnelId
 */
export async function getPersonDocumentationInOrgs(
  orgId: number,
  personnelId: number
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonDocumentationInOrg(orgId, personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.files)}${local.contactSupport}`
  );
}

export function getDocumentationUrl(
  personnelId: number,
  documentationId: number,
  token?: string
): string {
  return repo.getDocumentationUrl(personnelId, documentationId, token);
}

export async function uploadDocumentation(
  file: File,
  idDocType: number,
  validFrom: string,
  personnelId: number,
  description: string,
  confirmationComment: string
): Promise<Documentation> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () => {
      const data = await repo.postDocumentationMetadata(
        idDocType,
        validFrom,
        personnelId,
        description,
        confirmationComment
      );
      let f;
      if (file) {
        f = file;
        data.fileName = file.name;

        data.uploadDate = new Date().toString();
        if (data) {
          await repo.postDocumentation(f, personnelId, data.attachmentId);
          return data;
        } else {
          return null;
        }
      }
      return data;
    },
    local.error.title,
    `${local.error.sendError(local.area.files)}${local.contactSupport}`
  );
}

export async function deleteDocumentation(
  personnelId: number,
  documentationId: number
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteDocumentation(personnelId, documentationId),
    local.error.title,
    `${local.error.deleteError(local.area.files)}${local.contactSupport}`
  );
}

export async function uploadDocumentationFile(
  personId: number,
  documentationId: number,
  imgFile: File
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postDocumentation(imgFile, personId, documentationId),
    local.error.title,
    `${local.error.uploadError(local.area.image)}${local.contactSupport}`
  );
}

/**
 * Fetches user attachments
 * @param personnelId
 */
export async function fetchAttachments(personnelId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.fetchAttachments(personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.files)}${local.contactSupport}`
  );
}
