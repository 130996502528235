import * as React from "react";
import { LocalRoutes } from "./local-routes";
import {
  // OrganisationTemplate
  CardType
} from "../../types";

export const SweRoutes: LocalRoutes = {
  auth: {
    inputPIN: (byEmail: boolean) =>
      `Skicka PIN mottagna i ${byEmail ? "email" : "SMS"}`,
    sendByEmail: "Skicka PIN-kod via e-post",
    rememberMe: "Kom ihåg mig",
    regUser: "Registrera ny användare",
    login: "Logga in",
    errorInputPhone: "Telefonnummer för inmatning",
    errorSMSSent: "SMS som redan skickats. Försök igen senare",
    noUserFound: "Ingen användare hittades för angivet nummer",
    unknownError: "Okänt fel. Prova att ladda om sidan",
    errorInputPIN: "Ange PIN-kod",
    errorWrongPIN: "Fel PIN-kod",
    errorMultipleWrongPIN: "Fel PIN-kod för många gånger, vänligen försök igen",
    errorPinMustBeNumber: "PIN-kod kan bara innehålla siffror",
    accessedByPhone: {
      title: "Varning",
      description:
        "Denna webbplats är inte optimerad för mobiltelefoner. Det finns en Permitto mobil applikation som är tillgänglig för nedladdning.",
      continueWithout: "Fortsätt ändå"
    },
    ssoHelpText: "Eller logga in med",
    ssoNotAvailable: "Single Sign On inte tillgängligt för din arbetsgivare",
    ssoBackButton: "Tillbaka till inloggning"
  },
  attest: {
    alreadyAttestedMessage: (message: string, name: string, datetime: string) =>
      `${message} ${datetime} av ${name}`,
    approvedMessage: "Ansökan godkändes",
    rejectMessage: "Ansökan avvisades"
  },
  admin: {
    applicationApprove: {
      Application: "Ansökan",
      ApplicantInfoHeader: "Sökande",
      Approve: "Godkänna",
      ApproveWithoutAttestation: "Godkänn utan bestyrkande",
      ApprovedWithoutAttestation: "Godkänd utan bestyrkande. Kommentar:",
      ChangeAttestor: "Ändra attestor",
      ApproveCard: (cardName: string) =>
        `Godkänn ${cardName.toLocaleLowerCase()}`,
      ApproveCardWithoutAttestation: (cardName: string) =>
        `Godkänn ${cardName.toLocaleLowerCase()} utan bestyrkande`,
      Issued: "Utfärdad",
      Reject: "Avvisa",
      Delete: "Ta bort",
      DeleteApplicationTitle: "Ta bort ansökan",
      DeleteApplicationText: "Detta tar permanent bort den här ansökan",
      RejectApplication: "Avvisa ansökan",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} har inget bifogat ansökan. Avslag kommer att strimla ${cardName.toLocaleLowerCase()}.\nkommentar:`,
      EditApplication: "Redigera ansökan",
      ResendAttestor: "Skicka",
      GetAttestorLink: "Få attestorlink"
    },
    applicationIssue: {
      CardNumber: (cardName: string) => `${cardName} nummer`,
      ApplicantInfoHeader: "Sökande",
      Approve: "Godkänna",
      ApproveApplication: "Godkänn ansökan",
      Dismiss: "Avfärda",
      Issued: "Utfärda",
      IssuedCard: (cardName: string) => `Utfärdat ${cardName.toLowerCase()}`,
      Reject: "Avvisa",
      RejectApplication: "Avvisa ansökan",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} har inget bifogat ansökan. Avslag kommer att strimla ${cardName.toLowerCase()}.\nKommentar:`,
      EditApplication: "Redigera ansökan"
    },
    applicationListForApproval: {
      Open: "Öppna",
      NoApplication: "Inga ansökningar redo att godkännas",
      ApproveApplications: (numApplications: number) =>
        numApplications === 1
          ? `Godkänn ${numApplications} ansökan`
          : `Godkänn ${numApplications} ansökan`,
      ApproveApplicationsDescription:
        "Är du säker på att du vill godkänna de valda ansökningar?",
      ApplicationsApproved: "godkända ansökningar",
      DeleteApplications: (numApplications: number) =>
        numApplications === 1
          ? `Ta bort ${numApplications} ansökan`
          : `Ta bort ${numApplications} ansökan`,
      DeleteApplicationsDescription:
        "Är du säker på att du vill ta bort valda ansökningar?",
      ApplicationsDeleted: "ansökan som tas bort",
      Comment: "Kommentar",
      Action: "Åtgärd krävs",
      CardType: "Korttyp"
    },
    applicationListForIssue: {
      Open: "Öppna",
      NoApplication: (cardName: string) =>
        `Inga ${cardName} redo att godkännas`,
      IssueCards: (numCards: number, cardName: string) =>
        `Utfärda ${numCards} ${cardName.toLowerCase()}`,
      AlertTitle: "Ett fel inträffade",
      AlertText: (cardName: string) =>
        `Ett fel inträffade vid utfärdande av ${cardName}`,
      CardsIssued: (cardName: string) => `${cardName.toLowerCase()} utfärdat`,
      NumCardsIssued: (numCards: number, cardName: string) =>
        `${numCards} ${cardName.toLowerCase()} utfärdat.`,
      Done: "Gjort",
      Cancel: "Avbryt",
      IssueCardsDescription: (cardName: string) =>
        `Är du säker på att du vill utfärda det markerade ${cardName.toLowerCase()}?`,
      Comment: "Kommentar"
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName} nummer`,
      EditMode: "Redigeringsläge",
      Save: "Spara",
      Cancel: "Avbryt",
      Back: "Tillbaka",
      Edit: "Redigera",
      LiveEditWarning:
        "Detta kort har redan utfärdats. Användaren får därför ett e-postmeddelande som sammanfattar de ändringar du gör. Vill du fortsätta?",
      SavingCardInProgress: "Spara kort, vänta",
      CanNotEdit: (cardName: string) =>
        `Kan inte redigera ett ${cardName.toLowerCase()} med status mottaget`,
      Empty: "",
      Shred: "Strimla",
      IsShredded: (cardName: string) => `${cardName} är redan strimlad`,
      AreUSure: "Är du säker?",
      SureToShred: (cardName: string) =>
        `Är du säker på att du vill strimla detta ${cardName.toLowerCase()}?`,
      ShredButtonText: "Strimla",
      ShredAndCreateButtonText: "Strimla och skapa nya",
      ShredAndCreateTitle: (cardName: string) =>
        `Strimla ${cardName.toLowerCase()}`,
      CreateNewSecurityCard: (cardName: string) =>
        `Skapa ett nytt  ${cardName.toLowerCase()}`,
      ReNewCard: (cardName: string) => `Förnya ${cardName.toLowerCase()}`,
      RenewDisable: (cardName: string) => `${cardName} är inte strimlad`,
      PrintCard: (cardName: string) => `Skriv ut ${cardName}`,
      ShredAlertTitle: (cardName: string) => `Strimla ${cardName}`,
      ShredAlertText: (cardName: string) =>
        `Detta kommer att strimla ${cardName}`,
      ShredBeforeRenewTitle: (cardName: string) => `Förnya ${cardName}`,
      ShredBeforeRenewText: (cardName: string) =>
        `Detta kommer att strimla ${cardName} innan det förnyas`
    },
    splashView: {
      Permissions: "Tillgångar",
      PermHistory: "Historik över tillgångar",
      SafetyCard: (cardName: string) => cardName,
      Personnel: "Personal",
      Applications: "Ansökningar som behöver godkännas",
      Issued: (cardName: string) => `${cardName} som ska utfärdas`,
      SysAdm: "System admin",
      Employers: "Leverantörer",
      OtherCards: "HSE-kort",
      DoorKeys: "Nycklar",
      Documentation: "Dokumentation",
      DoorKeyApplications: "Dörr nyckel applikationer"
    },
    asset: {
      newModal: {
        header: (parent: string) =>
          parent ? `Skapa ny zon under ${parent}` : `Skapa anläggning`,
        Save: "Spara",
        Cancel: "Avbryt"
      },
      admin: {
        Back: "Tillbaka",
        Edit: "Redigera",
        Header: "",
        Save: "Spara",
        Cancel: "Avbryt"
      },
      adminList: {
        Description: "Beskrivning",
        Type: "Typ",
        ShowDetails: "Visa detaljer",
        CreateNew: "Skapa nytt",
        Search: "Sök",
        Asset: "Anläggning",
        AssetNode: "Grupp",
        Edit: "Redigera",
        Delete: "Ta bort",
        AddChild: "Lägg till underordnad",
        Save: "Spara",
        Cancel: "Avbryt",
        AddTopNode: "Lägg till toppnoden",
        DeleteAssetAlertTitle: "Ta bort anläggning",
        DeleteAssetAlertText: "Detta tar bort anläggning permanent"
      }
    },
    employerRights: {
      Admin: "Tillgång",
      UserControl: "Användarrättigheter",
      UserApprover: (cardName: string) =>
        `Intyga ${cardName.toLowerCase()} ansökan`,
      UserApproverInfo: (cardName: string) =>
        `Tillåter användaren att intyga ${cardName.toLowerCase()} ansökan.`,
      UserControlInfo:
        "Tillåter användaren att redigera användarrättigheter för den här tjänstprovidern. Tillåter användaren att checka ut personal som registrerats som inne i en anläggning."
    },
    orgRights: {
      HeaderOrgAdmin: (cardName: string) =>
        `Personal/${cardName.toLowerCase()}`,
      HeaderOrgAdminInfo: (cardName: string) =>
        `Läsbehörighet = Tillåter användaren att läsa data i personalen och ${cardName.toLowerCase()} moduler. \nSkriv åtkomst = tillåter användaren att läsa och redigera data i personalen och ${cardName.toLowerCase()} moduler förutom att godkänna ${cardName.toLowerCase()} ansökan.`,
      HeaderOrgAccess: "Åtkomst/åtkomsthistorik",
      HeaderUserControl: "Användaradministration",
      HeaderUserControlInfo:
        "Tillåter användaren att redigera användarrättigheter för den här organisationen. Varning: Detta är den översta åtkomstnivån i systemet och bör beviljas till några utvalda användare.",
      HeaderUserIssuer: (cardName: string) =>
        `Utfärda ${cardName.toLowerCase()}`,
      HeaderDoorkeyAdmin: "Nyckelhantering",
      HeaderDoorkeyAdminInfo:
        "Tillåter användaren att redigera nycklar för den här organisationen.",
      HeaderProjectAdmin: "Projektadministratör",
      HeaderProjectAdminInfo:
        "Tillåter användaren att redigera projekt för den här organisationen.",
      HeaderUserIssuerInfo: (cardName: string) =>
        `Tillåter användaren att utfärda ${cardName.toLowerCase()}.`,
      HeaderAccessHistory: "Åtkomst-historia",
      HeaderAccessHistoryInfo:
        "Tillåter användaren att se åtkomst historiken för anläggningar.",
      HeaderAccessView: "Tillgångar",
      HeaderAccessViewInfo:
        "Tillåter användaren att se alla aktuella poster till anläggningen. Tillåter användaren att checka ut personal som registrerats som inne i en tillgång.",
      HeaderApproverSchedule: "Anmälningar til godkännaren",
      HeaderApproverScheduleInfo: (cardName: string) =>
        `Prenumererar användaren på aviseringar om nya ${cardName.toLowerCase()} ansökan.`,
      HeaderIssuerSchedule: "Issuer Notifications",
      HeaderIssuerScheduleInfo: (cardName: string) =>
        `Prenumererar användaren på aviseringar om ${cardName.toLowerCase()} klar för utfärdande.`,
      HeaderEntrySchedule: "Meddelande om ankomst",
      HeaderAssetAdminInfo:
        "Tillåter användare att hantera anläggnings- och anläggningsaviseringar för denna anläggningsägare",
      HeaderEntryScheduleInfo:
        'Denna roll tillåter en användare att prenumerera på varningar i samband med. in- och utcheckning av faciliteter. En användare kan ange de dagar och tidsintervall som de vill få meddelande om. När en in / ut-registrering sker inom detta intervall kommer användaren att få ett e-postmeddelande om detta. Dessutom kan du välja att bli meddelad när en användare har varit inne i en anläggning under en viss tidsperiod, detta anges i fältet "Timmar före avisering"',
      InfoHeader: "Information om:",
      InfoClose: "Nära",
      EditInstuctions: "Dubbelklicka på en cell för att redigera en behörighet",
      HeaderExistingUser: "Användaren finns redan",
      HeaderExistingUserAlert: "Användaren finns redan i listan",
      AlertPersonAttriComboText:
        "Denna kombination av person och attribut finns redan i listan.",
      AlertPersonAttriComboTitle: "Anmälan finns redan!",
      AlertAllComboExistsTitle: "Dubblettpost!",
      AlertAllComboExistsText:
        "All giltig kombination av person och attribut finns redan i listan.",
      EditHeaderLabel: "Dubbelklicka på en cell för att ändra attribut",
      HeaderAssetAdmin: "Tillgångsadministratör",
      FromTime: "Från",
      ToTime: "Till",
      HoursBefore: "Timmar före påminnelse",
      Monday: "Måndag",
      Thuesday: "Tisdag",
      Wednesday: "Onsdag",
      Thursday: "Torsdag",
      Friday: "Fredag",
      Saturday: "Lördag",
      Sunday: "Söndag",
      InvalidValueTooltip: "Ogiltigt värde",
      SearchForPerson: "Välj person"
    },
    roles: {
      columndefs: {
        Role: "Godkänna utbildning",
        SafetyCardAttribute: "Säkerhetskortattribut"
      },
      EditHeaderLabel: "Dubbelklicka på en cell för att ändra attribut",
      SelectAttriPlaceHolder: "Välj attribut",
      ApproveAll: "Godkänn alla"
    },
    employers: {
      columndefs: {
        Address: "Adress",
        Country: "Land",
        Contact: "Kontakta",
        Name: "Namn",
        OrganizationNo: "Organisationsnummer",
        ContactEmail: "Kontakta e-post",
        ContactPhone: "Kontakta telefon",
        NumActiveCards: "Antal aktiva kort",
        NumShreddedCards: "Antal strimlad kort",
        OrgId: "Org-ID",
        PersonnelCount: "Antal anställda"
      },
      UpdateBtn: "Redigera markerade",
      CreateBtn: "Lägga till nya",
      DeleteBtn: "Radera",
      ModalUpdateHeader: "Uppdatering",
      ModalCreateHeader: "Skapa",
      ModalSave: "Spara",
      ModalCancel: "Avbryt",
      AlertTitle: "Ett fel inträffade",
      InvalidEmployer: "Formuläret har inte skickats in. Ogiltig arbetsgivare.",
      AutoCompleteTitle: "Företag",
      AddEmployerHeader: "Lägg till leverantör",
      AddEmployer: (emp: string, orgName: string) =>
        `${emp} är inte en leverantör till ${orgName}. \nvill du lägga till ${emp} som leverantör till ${orgName}`,
      EmployerAlreadyFound: "Företaget finns redan på listan",
      sendMail: "Skicka E-post til kontakt"
    },
    constructionProjects: {
      delete: "Ta bort",
      edit: "Redigera",
      add: "Lägg till",
      deleteTitle: "Ta bort projekt",
      deleteText: "Detta tar permanent bort projektet",
      modalHeader: "Projekt",
      modalSave: "Spara",
      modalClose: "Stänga",
      projectName: "Projektnamn",
      namePlaceholder: "Namn",
      projectNumber: "Projekt nr",
      projectAddress: "Adress",
      constructionClient: "Byggare",
      projectLead: "Projektledare",
      startDate: "Projektets start datum",
      endDate: "Projektets slutdatum",
      datePlaceholder: "Datum",
      accessCardType: "Korttyper för åtkomst",
      lat: "Lat",
      lng: "Lng",
      selectOnMapPlaceholder: "Ange koordinat eller klicka på karta",
      clickToSetCoordinates: "Ogiltiga koordinater",
      referencePlaceholder: "Integration ID",
      externalReference: "Integration ID",
      allVendors: "Tillgängliga för",
      checkAllVendors: "Alla leverantörer",
      checkSomeVendors: "Valda leverantörer",
      latLngRequiredMessage: "Longitud och latitud krävs.",
      latLngValidateMessage: "Lägg till giltiga longitud-och latitudvärden.",
      latLimitationMessage: "Latitud måste vara mellan -85 och + 85",
      lngLimitationMessage: "Longitud måste vara mellan -180 och + 180",
      projectNameRequiredMessage: "Projektnamn krävs.",
      ProjectHasAssets: "Projektet är kopplat till anläggningar",
      Ok: "Ok",
      Clear: "Ta bort",
      addNewNode: "Lägg till faciliteter",
      projecPostionSameAsAsset: "Projektposition samma som anlegg",
      maxEntries: "Begränsa antalet poster i tillgången samtidigt",
      maxEntriesPlaceholder:
        "Ange det maximala antalet incheckningar för projektet",
      maxEntriesBlock:
        "Begränsning är hård gräns (Användaren kan inte registrera sig om den nås)",
      maxEntryInputWarning: "Ange giltigt nummer som maximalt antal poster",
      activeProjBtnLbl: "Visa aktiva projekt",
      finishedProjBtnLbl: "Visa färdiga projekt"
    },
    entries: {
      newOperationalMsg: "Nytt meddelande",
      operationalMsgHeader: "Operativa meddelanden",
      showOneTimeMsg: "Visa engångsmeddelanden",
      showRepeatingMsg: "Visa återkommande meddelanden",
      regUserBtn: "Registrera användare",
      regTravelBtn: "Registrera resor",
      modalHeader: "Logga ut användare",
      modalTravelHeader: "Registrera resor",
      selectPerson: "Välj person med tillgång",
      selectProject: "Välj projekt",
      selectAsset: "Välj anläggning",
      selectSource: "Välj anläggning/projekt",
      selectFollowers: "Lägg folk att följa med",
      project: "Projekt",
      assetTree: "anläggning",
      submit: "Registrera användare",
      confirm: "Stänga",
      emptyPerson: "Inga personer med tillgång hittades!",
      statusText: (name: string) => `Incheckning till ${name} avslutade`,
      statusTextTraveling: (name: string) => `${name} är registrerad på resa`,
      statusFailText: (name: string) => `Incheckning till ${name} misslyckades`,
      back: "Tillbaka",
      proceed: "Gå vidare",
      choosenAsset: "Välj anläggning",
      choosenPerson: "Välj person",
      projectPlaceholder: "Sök efter projektnamn",
      followersInputLabel: 'Ange flera namn separerade med komma ","',
      columnDefs: {
        delete: "Ta bort",
        created: "Skapad",
        enteredByName: "Skapad av",
        assetName: "Anläggning",
        message: "Meddelande",
        repeats: "Upprepad"
      },
      searchAsset: "Sök efter anläggning",
      warnings: {
        maxAmount: (maxEntryAmount: number) =>
          `Endast ${maxEntryAmount} personer kan checkas in samtidigt på den här tillgången / projektet.`,
        alreadyIn: (current: number) =>
          `För närvarande har ${current} checkat in.`,
        alreadyReached: "gränsen har redan uppnåtts.",
        parentReachedMaxAmount:
          "Föräldrafaciliteten överstiger redan maximalt incheckningsnummer.",
        reachedMaxAmount: "Maximalt antal incheckningar har nåtts",
        note: "Notera",
        warning: "Varning"
      },
      selectAssetOwner: "Välj anläggningsägare",
      displayingRows: "Visar",
      of: "av",
      entries: "poster",
      clearFilter: "Återställ filtret",
      enterExpectedTimeToLeave: "Ange tid",
      time: "Tid",
      expectedTimeToLeaveHeader: "Ange förväntad tid att lämna",
      addAssetMsgMenuItemText: "Lägg till anläggningsavisering",
      chkInMenuItemText: "Checka in personal",
      assetMsgDisabledTooltipText:
        "Anläggningen tillhör en annan anläggningsägare",
      stdDisabledTooltipText: "Inaktiverat",
      gotoAsset: "Gå till anläggning",
      noAdminAssetRightsTooltipText: "Saknar rättigheter"
    },
    onDuty: {
      onDutyTitle: `Ansvarig vakt`,
      noAppointedOnDuty: "Ingen ansvarig vakt utsedd",
      currentOnDuty: "Nuvarande ansvarig vakt",
      seletNewOnDutyOfficer: "Välj ny ansvarlig vakt",
      newOnDuty: "Ny ansvarig vakt",
      changeOnDutyInfoText: `Utse en ny ansvarig vakt genom att söka i fältet nedan. Om det inte är det
      en ansvarig vakt vid denna tidpunkt trycker du på "Spara" utan att välja ny.`
    }
  },
  assetView: {
    FirstName: "Förnamn",
    LastName: "Efternamn",
    Mobile: "Mobil",
    Visitors: "Besökare",
    NoVisitors: "Alla stationer är tomma",
    AssetHeader: "Namn på anläggningen:",
    AltImg: "Anläggningens bild"
  },
  notification: {
    NoNotification: "Ingen",
    Notifications: (orgName: string) =>
      orgName ? `Anmälningar - ${orgName}` : `Anmälningar`,
    NewApplicationsForApproval: (cardName: string) =>
      `Anmälningar om nya ${cardName.toLowerCase()} ansökan som är klara för godkännande`,
    ReadyForIssuing: (cardName: string) =>
      `Anmälningar om ${cardName.toLowerCase()} klar att utfärdas`
  },
  invitation: {
    Reject: "Avvisa",
    Accept: "Acceptera",
    FirstName: "Förnamn",
    EmptyFieldNotAllowed: "Det här fältet får inte vara tomt",
    LastName: "Efternamn",
    Mobile: "Mobil",
    AtLeast: "Telefonnumret måste bestå av minst 8 siffror",
    Email: "Email",
    ValidEmail: "Inte en giltig e-postadress",
    Proceed: "Fortsätta",
    Welcome: "Välkommen till Permitto",
    LogInAndDownload: "Nu kan du ladda ner vår app och logga in",
    LogIn: "Logga in",
    VerifyData: "Verifiera din information"
  },
  my: {
    access: {
      NoRegAccess: (cardName: string) =>
        `Dina ${cardName.toLowerCase()} beviljar inte någon åtkomst`,
      NoAssets: (cardName: string) =>
        `Du har ingen ${cardName.toLowerCase()} som beviljar åtkomst till anläggning/projekt`
    },
    application: {
      Delete: "Ta bort",
      DeleteApplicationTitle: "Ta bort ansökan",
      DeleteApplicationText: "Detta tar permanent bort den här ansökan",
      ChangeAttestor: "Ändra attestor",
      MissingValuesAttestor: "Vänligen ange data i alla obligatoriska fält",
      Cancel: "Avbryt"
    },
    applicationList: {
      ColumnText: "Åtgärd krävs",
      Attestor: "Attestor",
      Open: "Öppna",
      CardType: "Korttyp"
    },
    profile: {
      AccessTo: (numStations: number) =>
        numStations === 1
          ? `Åtkomst registrering tillåten på ${numStations} anläggning`
          : `Åtkomst registrering tillåten på ${numStations} anläggningar`,
      Holds: (numCards: number) =>
        numCards === 1
          ? `Besitter ${numCards} giltigt säkerhetskort`
          : `Besitter ${numCards} giltiga säkerhetskort`,
      InfoFieldsHeader: "Information om",
      HeaderNotificationTitle: "Uppdatera anmälningar",
      HeaderTitle: "Uppdatera personuppgifter",
      HeaderSave: "Spara",
      HeaderCancel: "Avbryt",
      DialogSuccessText: "Personuppgifter sparas",
      DialogSuccessTitle: "Ok",
      DialogConfirmText: "Är du säker på att du vill ignorera ändringarna?",
      DialogConfirmTitle: "Ändringar gjorda",
      FooterText:
        "Om du vill ändra e-postadress eller telefonnummer, vänligen kontakta support via e-post",
      FooterEmail: "app support.",
      EditNotifications: "Redigera anmälningar",
      EditInfo: "Redigera lagrad information"
    },
    safetycards: {
      ShowShredded: (cardName: string) => `Strimlade ${cardName.toLowerCase()}`,
      NoCardsToShow: (cardName: string) => `Inga ${cardName.toLowerCase()}`
    },
    splash: {
      Permissions: "Mina Tillgångar",
      AccessHistory: "Min Tilgångshistorik",
      Cards: (cardName: string) => `Mina ${cardName}`,
      NewApplication: "Ny ansökan",
      Applications: "Mina ansökningar",
      Profile: "Min profil",
      OtherCards: "Mina HSE-kort",
      DoorKeys: "Mina nycklar",
      NewDoorKeyApplication: "Ny nyckel ansökan",
      DoorkeyApplication: "Mina nyckel ansökningar",
      ApplicDisableTooltip: employerName =>
        `Din arbetsgivare ${employerName}</br> är inte registrerad som tjänsteleverantör</br> till någon anläggningsägare i Permitto.</br> du kommer därför inte att kunna ansöka</br> för säkerhetskort eller registrera tillgång till projekt.</br> kontakta ägaren till anläggningen du vill arbeta för</br> och berätta för dem att registrera ${employerName}</br> som tjänsteleverantör i Permitto.</br> om du behöver hjälp med detta vänligen maila oss  på</br> app-support@verico.com</br> och vi hjälper dig.</br>`
    },
    otherCards: {
      AddCard: "Lägg till nytt kort",
      DeleteTitle: "Ta bort kort",
      DeleteText: "Detta tar permanent bort det här kortet"
    }
  },
  noAccess: {
    NoAccess: "Användaråtkomst tillåts inte",
    NoAccessHeader: "401 Ingen åtkomst"
  },
  notFound: {
    PageNotFound: "Sidan hittades inte",
    PageNotFoundHeader: "404 Hittades inte"
  },
  registerView: {
    registerInfoText:
      "Du kan registrera en ny användare i Permitto här. När detta är gjort kan du ansöka om och ta emot säkerhetskort från anläggningsägarna som är anslutna till din arbetsgivare.",
    registerPrivacyPolicyLink: (link: string) => (
      <React.Fragment>
        Information om hur dina data hanteras beskrivs i
        <a href={link}>
          <i> sekretesspolicy.</i>
        </a>
      </React.Fragment>
    ),
    continue: "Fortsätta",
    phoneAlreadyRegistred: "Telefonnumret är redan registrerat i Permitto",
    createUserFailed: "Det gick inte att skapa användar",
    typePhoneNumber:
      "För att registrera en användare behöver vi ditt telefonnummer, detta kommer att vara din identifikation i Permitto:",
    companyPrompt:
      'Ange din arbetsgivare. Gör detta genom att söka nedan eller Skriv in "organisasjonsnummer (norska företags-ID)."',
    userCreated:
      "Du kan nu ladda ner Permitto-appen och logga in med ditt telefonnummer",
    gotoPermitto: "Gå till Permitto",
    userCreatedHeader: "Användare skapad",
    userIsBeingRegistered: "Registrera ny användare",
    firstName: "Förnamn",
    lastName: "Efternamn",
    eMail: "Email",
    registrer: "Register"
  },
  our: {
    rights: {
      HeaderEmployerAdmin: "Tillgång",
      HeaderUserControl: "Användaradministration",
      HeaderUserApprove: (cardName: string) => `Bestyrka ${cardName} ansökan`,
      HeaderAccessHistory: "Tillgånghistorik",
      HeaderAccessView: "Tillgångar"
    },
    access: {
      NoPersonnel:
        "Ingen personal registrerad i anläggningar/projekt som du har rätt att visa",
      PersonnelInField: "Personal på fältet",
      menuItemGotoPerson: "Visa person",
      menuItemCheckoutPerson: "Logga peronen ut från anläggningen",
      menuItemCancelTravel: "Sluta resa",
      menuItemUserArrived: "Meddela användaren anlände",
      menuItemCheckoutPersonList: "Skicka SMS",
      menuItemGotoPersonDisabled: "Saknar rättigheter for att gå till personen",
      menuItemCheckoutPersonDisabled:
        "Saknar rättigheter for att logga ut personen ",
      menuItemChangeEscortedPersonnel: "Byt medföljande personer",
      InfoLegendCheckOutFirst: "Rad markerad med ",
      InfoLegendCheckOutLast: "har varit över 24 timmar i anläggningen.",
      InfoLegendTimeToLeaveFirst: "Rad markerad med ",
      InfoLegendTimeToLeaveLast: "har överskidit utcheckingstiden.",
      checkoutModalHeader: "Bekräfta utloggning",
      cancelTravelHeader: "Sluta resa",
      userArrivedHeader: "Meddela användaren anlände",
      checkoutModalConfirmBtn: "Bekräfta",
      checkoutModalCancelBtn: "Avbryt",
      checkoutModalSmsText: "Skicka SMS-anmälan",
      menuItemAssetNote: "Ange en antecknign",
      setAssetNoteText: "Ange en anteckning på tillgång",
      escortedModalHeader: "Byt medföljande personer",
      escortedModalSubmitBtnLbl: "Byt"
    },
    applications: {
      Confirm: "Bestyrka",
      ConfirmedApplications: "Bestyrka ansökningar",
      Reject: "Avvisa",
      RejectApplication: "Avvisa ansökan",
      Delete: "Ta bort",
      DeleteAppTitle: "Ta bort ansökan",
      DeleteAppText: "Detta tar permanent bort den här ansökan",
      ChangeAttestor: "Ändra attestor",
      MissingValuesAttestor: "Vänligen ange data i alla obligatoriska fält",
      Cancel: "Avbryt",
      Application: "Ansökan"
    },
    applicationList: {
      DemandsAction: "Åtgärd krävs",
      Open: "Öppna",
      Attestor: "Attestor"
    },
    profile: {
      ValidCards: (numCards: number) =>
        numCards === 1
          ? `${numCards} giltig säkerhetskort`
          : `${numCards} giltiga säkerhetskort`,
      RegPersonnel: (numPersons: number) =>
        `${numPersons} registrerade personer`
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName} nummer`,
      Back: "Tillbaka",
      Shredded: "Strimlad",
      CardReceipted: (cardName: string) => `${cardName} är signerad mottagen `,
      EditMode: "Redigeringsläge",
      Save: "Spara",
      Cancel: "Avbryt",
      ValidFrom: "Gäller från",
      ValidTo: "Giltig till",
      PrevValid: "Giltig från måste vara tidigare än giltig till"
    },
    splash: {
      CardName: (cardTemplate: CardType) => cardTemplate.cardNameEn,
      Accesses: "Tillgångar",
      AccessHistory: "Min Tillgångshistorik",
      SafetyCard: (cardName: string) => `Våra ${cardName.toLowerCase()}`,
      Personnel: "Våra Personal",
      NewApplication: "Ny Ansökan",
      Applications: "Våra Ansökningar",
      NewCardNoApplic: (cardTemplate: CardType) =>
        `Nytt ${cardTemplate.cardNameEn}`,
      Profile: "Vår Profil",
      OtherCards: "Våra HSE-kort",
      UserAccess: "Användaråtkomst",
      DoorKeys: "Våra Nycklar",
      Documentation: "Vår Dokumentation",
      NewDoorKeyApplication: "Ny nyckel ansökan",
      DoorkeyApplication: "Våra nyckel ansökningar"
    },
    safetyCardList: {
      Issuer: "Emittenten",
      Open: "Öppna",
      Grid: "Rutnät",
      CardUpperCase: (cardName: string) => cardName
    }
  },
  templates: {
    buttons: {
      newtemplate: "Ny",
      startedit: "Starta redigera",
      endedit: "Sluta redigera",
      savetemplate: "Spara",
      saveastemplate: "Spara som",
      previewtemplate: "Förhandsgranska",
      applytemplate: "Tillämpa",
      confirmTitle: "Ändringar som gjorts",
      confirmText: "Alla osparade ändringar raderas"
    },
    subSection: {
      ModalCancelText: "Avbryt"
    },
    dropdowns: {
      cardTypeName: (cardType: CardType) => cardType.cardNameEn,
      selectCardType: "Välj korttyp",
      selectTemplate: "Välj mall",
      selectDraft: "Välj utkast",
      blankDraft: "Tomt utkast",
      createDraft: "Skapa utkast från aktuell mall",
      newDraft: "Nytt utkast",
      unSaved: "Inte sparat",
      draftStatus: {
        inUse: "I bruk",
        draft: "Utkast",
        historical: "Historiska",
        unknown: "Okänd"
      },
      redeemedAfterIssueTemplateText: "Löst in efter utfärdande"
    },
    attributes: {
      newAttribute: "Välj attribut",
      newAttributePlaceholder: "Sök efter befintligt attribut",
      addExistingAttribute: "Lägg till befintligt attribut",
      setLocationAssetTreeButton: "Ange platsen på anläggningsträd",
      dataType: "Datatyp",
      lableNo: "Text No",
      lableEn: "Text En",
      hyperlink: "Hyperlänk",
      sectionOrder: "Avsnitt ordning",
      sortOrder: "Sortering nummer",
      info: "Info",
      selectAttribute: "Välj attribut",
      subSectionOrder: "Underavdelningsorder",
      descriptionEn: "Beskrivning En",
      descriptionNo: "Beskrivning No",
      SelectType: "Välj typ",
      requiredValue: "Obligatoriskt värde",
      requiredWhenVisible: "Krävs om det är synligt",
      role: "Roll",
      sectionNo: "Avsnittsnamn - NO",
      sectionEn: "Avsnittsnamn - EN",
      subSectionNameNo: "Underavsnittsnamn - NO",
      subSsectionNameEn: "Underavsnittsnamn - EN",
      ok: "Ok",
      cancel: "Avbryt",
      addNewAttribute: "Lägg till attribut",
      editAttribute: "Redigera attribut",
      editSectionName: "Redigera avsnittsnamn",
      editSubSection: "Redigera delavsnitt",
      selectOperator: "Visa om",
      selectAttributes: "Välj attribut",
      displayOperandOr: "Eller",
      displayOperandNor: "Inte",
      attriExistOnCardTitle: "Varning",
      attriExistOnCardText: (cardType: string) =>
        `Det här attributet finns redan på säkerhetskort. Eftersom alla attribut på ${cardType}-typen visas i ansökan läggs ett dublicate till med det här attributet. Vill du fortsätta?`,
      associatedAssets: "Anslutna anläggningar"
    },
    confirmModal: {
      prompt: "Vill du spara ändringarna i utkastet?",
      ok: "Ok",
      cancel: "Avbryt",
      title: "Ändringar gjorda",
      deleteAttributeModalTitle: "Ta bort attribut",
      deleteAttributeModalDescription: "Detta tar bort attributet"
    },
    applyModal: {
      applyMessageTitle: "Använd mall",
      applyMessageText:
        "Är du säker på att du vill göra den här mallen till nuvarande aktiva?"
    },
    saveAsModal: {
      editTemplateNameTitle: "Redigera mallnamn",
      ok: "Okej",
      cancel: "Avbryt",
      editTemplateNamelabel: "Mallnamn",
      templateNameRequiredMessage: "Mallnamn krävs."
    },
    locationAssetTreeSelectModal: {
      title: "Angi platsen för växtträd",
      defaultCheck: "Standard"
    },
    postIssueRule: {
      InvalidatesCard: "Ogiltigt kort",
      RequiresApproval: "Kräver godkännande",
      postIssueRuleList: "Attributet finns redan i listan. Vill du fortsätta?"
    }
  },
  doorKeys: {
    person: {
      GridHeaderText: "Nycklar",
      GridEmptyText: "Användaren har inga nycklar"
    },
    reserve: {
      keepPersonnel: "Reservea för",
      ModalTermsInfo: "Villkor för utlåning",
      ModalReserveText: "Reservera",
      ModalCancelText: "Avbryt",
      ModalHeaderText: "Reservera nycklar",
      ModalStatusComment: "Kommentar"
    },
    register: {
      SerialNumber: "Serienummer",
      SerialNumberStart: "Serienummer start",
      NumberOfKeys: "Antal nycklar",
      ModalHeaderText: "Registrera nya nycklar",
      ModalCancelText: "Avbryt",
      AlertLabelSingular: "Registrera nyckel",
      AlertLabelPlural: "Registrera nycklar",
      AlertOkButtonText: "Bekräfta",
      AlertCancelButtonText: "Avbryt",
      AlertMsg: (num: number) =>
        num === 1
          ? `Är du säker på att du vill registrera ${num} ny nyckel?`
          : `Är du säker på att du vill registrera ${num} nya nycklar?`,
      DoorkeySystemNo: "System nummer",
      DoorkeyType: "Nyckeltyp",
      Description: "Beskrivning",
      StatusComment: "Kommentar vid skapandet",
      EnableKeySeries: "Registrera en serie nycklar",
      DoorkeyComment: "Nyckelkommentar",
      DoorkeyDept: "Ansvarig"
    },
    update: {
      ModalHeaderText: "Uppdatera nyckeldata",
      ModalCancelText: "Avbryt",
      ModalUpdateText: "Uppdatering",
      DoorkeySystemNo: "System nummer",
      SerialNumber: "Serienummer",
      DoorkeyType: "Nyckeltyp",
      Description: "Beskrivning"
    },
    receive: {
      ReceivekeyHeader: "Bekräfta nyckel mottagen"
    },
    discard: {
      DiscardKeyHeader: "Kasser nyckeln",
      DiscardKeyMessage: "Är du säker på att du skulle vilja kassera nyckeln?",
      DiscardKeyConfirmBoxTitle: "Kassera nyckel",
      DiscardKeyConfirmBoxText:
        "Den här vilja permanent kassera nyckel och kan inte ångras",
      DiscardKeyConfirmBoxConfirmButtonText: "Kassera",
      DiscardKeyConfirmBoxCancelButtonText: "Avbryt"
    },
    return: {
      ReturnKeyHeader: "Återvänder till lagring",
      ReturnKeyMessage:
        "Är du säker på att du vill returnera denna dörr nyckel til lagret?"
    },
    deleteKey: {
      DeleteKeyHeader: "Ta bort nyckeln",
      WarningText: "Detta kommer att ta bort nyckeln permanent",
      ConfirmButtonText: "Ta bort",
      CancelButtonText: "Avbryt"
    },
    application: {
      ReservedLinkMessage: `Nyckel er reserverad(klicka för att se)`
    },
    doorKeyList: {
      button: {
        DeleteKeyText: "Ta bort nyckeln",
        RegisterKeyText: "Registrera ny nyckel",
        ReserveKeyText: "Reservera nyckel",
        SignForReturnKeyText: "Bekräfta nyckeln mottagen",
        RecallKeyText: "Återkalla nyckel",
        LostKeyText: "Förlorad nyckel",
        ReturnToStoreText: "Återgå till lagring",
        DiscardKeyText: "Kassera nyckel",
        ReceiveKeyText: "Bekräfta nyckeln mottagen",
        UpdateKeyText: "Redigera nyckel",
        FilterListPlaceholder: "Sök efter person eller arbetsgivare"
      },
      columnDefs: {
        DoorkeySystemNo: "System nummer",
        DoorkeyType: "Nyckeltyp",
        SerialNumber: "Serienummer",
        Description: "Beskrivning av nyckel",
        StatusDateTime: "Rapportdatum",
        DoorkeyStatusName: "Status datum",
        KeeperFirstName: "Förnamn",
        KeeperLastName: "Efternamn",
        KeeperHasSCard: "Giltigt s. kort",
        Employer: "Företag",
        StatusComment: "Kommentar",
        Organization: "Nyckel ägare",
        Info: "Information om",
        AssetOwner: "Anläggningsägare",
        HolderName: "Nyckelhållare",
        ApplicationId: "Ansökan",
        HolderPhone: "Nyckelhållare telefon",
        Email: "Nyckelhållare email",
        IsTraveling: "Resa",
        Note: "Notera",
        DoorkeyComment: "Nyckelkommentar",
        DoorkeyDept: "Ansvarig"
      },
      lostKey: {
        ModalTitleText: "Markera nyckel som förlorad",
        ConfirmButtonText: "Förlorad"
      },
      log: {
        columnDefs: {
          ActionPersonName: "Utförs av",
          ActionStatusText: "Åtgärd",
          KeeperEmployer: "Företag",
          KeeperFirstName: "Förnamn",
          KeeperLastName: "Efternamn",
          StatusComment: "Kommentar",
          StatusDateTime: "Datum"
        }
      },
      returnKey: {
        ModalTitleText: "Bekräfta nyckel mottagen",
        ConfirmButtonText: "Mottagen"
      },
      recallKey: {
        ModalTitleText: "Återkalla nyckel",
        ConfirmButtonText: "Återkalla"
      }
    },
    doorKeyApplicationsList: {
      approveModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Godkänn nyckel ansökan",
        subHeaderText: ""
      },
      rejectModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Förkastad ansökan",
        subHeaderText: ""
      },
      doorkeyReserveModal: {
        doorkeyReserveHeaderText: "Reservera nyckel nu"
      },
      shredModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Ta bort ansökan",
        subHeaderText: ""
      },
      resendModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Ta bort ansökan",
        subHeaderText: ""
      },
      buttons: {
        approve: "Godkänna",
        reject: "Avvisa",
        open: "Öppna",
        resend: "Skicka",
        back: "Tillbaka",
        archive: "Visa aktiva ansökningar",
        nonArchive: "Visa arkiverade",
        reserve: "Reservera nyckel",
        shred: "Ta bort ansökan"
      },
      columnDefs: {
        applicId: "Ansökan-ID",
        firstName: "Förnamn",
        lastName: "Efternamn",
        mobile: "Mobil",
        email: "Email",
        employerName: "Arbetsgivare",
        organisationName: "Organisation",
        status: "Status",
        actionRequired: "Åtgärd krävs"
      },
      statuses: {
        applicationCreated: "Ansökan skapad",
        applicationEmployerApproved:
          "Ansökan skickas och väntar på bearbetning",
        applicationEmployerRejected: "Ansökan avvisad av tjänsteleverantör",
        applicationOrganizationApproved:
          "Ansökan godkänts, väntar på att få en nyckel reserverad",
        applicationOrganizationRejected:
          "Ansökan  avslogs av anläggningsägare. Åtgärd krävs",
        applicationEmployerApprovedORG: "Ansökan väntar på bearbetning",
        applicationOrganizationShredded: "Ansökan raderade",
        applicationOrganizationRejectedORG:
          "Ansökan avvisad av anläggningsägare",
        applicationOrganizationReserved: "Nyckel reserverad"
      }
    },
    kpiNames: {
      loanedKeys: "Långt nycklar",
      reservedKeys: "Reserverade nycklar",
      recalledKeys: "Återkallade nycklar",
      keyOwnersInvalidCard: "Ägare utan giltiga kort"
    }
  },
  documentation: {
    documentationList: {
      columnDefs: {
        employerName: "Arbetsgivare",
        firstName: "Förnamn",
        lastName: "Efternamn",
        hasValidSafetyCard: (cardName: string) =>
          `Giltig ${cardName.toLowerCase()}`
      },
      columnDocumentationDetailsDefs: {
        fileName: "Filnamn",
        documentRequirement: "Dokumentkrav",
        reference: "Referens",
        comment: "Kommentar",
        uploaded: "Uppladdade",
        validFrom: "Gäller från",
        expiresFrom: "Löper ut",
        uploadedBy: "Uppladdad av",
        actions: "Åtgärder",
        gridEmptyText: "Inga dokument"
      },
      lastNamePlaceholder: "Sök på efternamn",
      openButtonText: "Öppna",
      editImageButton: "Redigera",
      allDocumentTypes: "Alla dokumenttyper",
      addDocButtonText: "Lägg till dokumentation",
      upload: {
        uploadButton: "Fortsätta",
        cancelButton: "Avbryt",
        uploadDocumentHeader: "Välj personer",
        uploadProgressText: "Laddar upp dokumentation, vänligen vänta"
      }
    }
  },
  assetMessages: {
    add: "Lägg till",
    edit: "Redigera",
    delete: "Ta bort",
    deleteTitle: "Ta boer meddelande",
    deleteText: "Detta kommer att ta bort meddelandet permanent",
    staticEventType: "Alltid",
    checkInEventType: "Vid ankomsten till anläggningen",
    checkOutEventType: "Vid avresa från anläggningen",
    showExpiredMessages: "Visa utgångna meddelanden",
    htmlType: "HTML",
    informationType: "Enkel text"
  }
};
