import * as React from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";

interface SuccessAnimationProps {
  success: boolean;
  halfSize?: boolean;
}

import "./success-animation.scss";

@observer
export class SuccessAnimation extends React.Component<SuccessAnimationProps> {
  @computed
  get circleClassNames() {
    return `${
      this.props.halfSize
        ? "success-animation-circle-loader-half-size"
        : "success-animation-circle-loader"
    } ${this.props.success ? "success-animation-load-complete" : ""}`;
  }

  @computed
  get checkMarkClassNames() {
    return `${
      this.props.halfSize
        ? "success-animation-checkmark-half"
        : "success-animation-checkmark"
    } draw`;
  }

  @computed
  get displayStyle() {
    return { display: this.props.success ? "block" : "none" };
  }

  render() {
    return (
      <div className={this.circleClassNames} style={this.displayStyle}>
        <div className={this.checkMarkClassNames} style={this.displayStyle} />
      </div>
    );
  }
}
