import styles from "./employer-bronnoysund-autocomplete.css";
import * as React from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { Employer } from "../../../types";
import { SuccessAnimation } from "../../success-animation/success-animation";
import { LoadingSpinner } from "./../../loading-spinner/loading-spinner";
import {
  getBronnoysundOrg,
  getBronnoysundOrgSearchResult
} from "../../../repo";
import { localizationStore } from "../../../stores/index";
import { PortalPanel } from "../../portal-panel/portal-panel";

interface EmployerBronnoysundAutocompleteProps {
  employerSelected: (emp: Employer) => Promise<boolean>;
  currentOrganizationNumber: number;
  currentEmployerName: string;
  readonly?: boolean;
  country: Country;
  setCountry: (country: Country) => void;
  getOrgEmployers: () => Promise<Employer[]>;
}

export enum Country {
  Norway = "no",
  Sweden = "se",
  England = "en"
}

@observer
export default class EmployerBronnoysundAutocomplete extends React.Component<
  EmployerBronnoysundAutocompleteProps
> {
  @observable loading: boolean;
  @observable searchOrgNo: string;
  @observable searchName: string;
  currentEmployer: Employer;
  @observable suggestions: Employer[];
  @observable showDropdown: boolean;
  @observable highlighted: Employer;
  pos: { x: string; y: string } = { x: "0", y: "0" };
  allCurrentEmps: Employer[];

  constructor(props: EmployerBronnoysundAutocompleteProps) {
    super(props);
    this.searchName = "";
    this.searchOrgNo = "";
    this.initData();
  }

  async initData() {
    if (this.props.getOrgEmployers) {
      this.setIsLoading(true);
      const emps = await this.props.getOrgEmployers();
      this.allCurrentEmps = emps;
      this.setIsLoading(false);
    }
  }

  componentDidMount() {
    if (this.props.currentOrganizationNumber > 0) {
      this._orgNumberEntered(this.props.currentOrganizationNumber.toString());
    }
  }

  setPos(pos: { x: string; y: string }) {
    this.pos = pos;
  }

  @action("setIsLoading")
  setIsLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @action("setSearchOrgNo employer-bronnoysund-autocomplete")
  setSearchOrgNo(orgnumber: string) {
    this.searchOrgNo = orgnumber;
  }

  @action("setSearchName employer-bronnoysund-autocomplete")
  setSearchName(name: string) {
    this.searchName = name;
  }

  @action("setCurrentEmployer employer-bronnoysund-autocomplete")
  async setCurrentEmployer(emp: Employer) {
    this.currentEmployer = emp;
    if (emp) {
      const shouldUpdate = await this.props.employerSelected(emp);
      if (shouldUpdate) {
        this.setSearchName(emp.name);
        this.setSearchOrgNo(
          emp.organizationNo ? emp.organizationNo.toString() : null
        );
      }
    }
  }

  @action("setSuggestions employer-bronnoysund-autocomplete")
  setSuggestions(suggestions: Employer[]) {
    this.suggestions = suggestions;
    if (this.suggestions && suggestions.length === 0) {
      this.setShowDropdown(false);
    }
  }

  @action("setShowDropdown employer-bronnoysund-autocomplete")
  setShowDropdown(showDropdown: boolean) {
    this.showDropdown = showDropdown;
  }

  @action("setHighlighted employer-bronnoysund-autocomplete")
  setHighlighted(emp: Employer) {
    this.highlighted = emp;
  }

  onClickOutside = () => {
    this.setShowDropdown(false);
  };

  setCountry(country: Country) {
    if (this.props.readonly) {
      return;
    }
    this.props.setCountry(country);
    this.setSuggestions([]);
    this.setSearchName("");
    this.setSearchOrgNo("");
    this.setCurrentEmployer(null);
  }

  async _orgNumberEntered(orgNumber: string) {
    this.setCurrentEmployer(null);
    this.setSearchOrgNo(orgNumber);
    if (this.props.country === Country.Norway) {
      if (orgNumber.toString().length === 9) {
        this.setShowDropdown(true);
        const org = await getBronnoysundOrg(Number(orgNumber));
        if (org) {
          this.setCurrentEmployer(org);
          this.setSuggestions([]);
        } else this.setShowDropdown(false);
      }
    } else {
      const foundEmp = this.allCurrentEmps
        ? this.allCurrentEmps.find(
            a =>
              a.country === this.props.country &&
              a.organizationNo === (orgNumber ? parseInt(orgNumber) : null)
          )
        : null;

      this.setCurrentEmployer({
        address: "",
        contact: "",
        organizationNo: orgNumber ? parseInt(orgNumber) : null,
        name: foundEmp
          ? foundEmp.name
          : this.searchName
          ? this.searchName
          : this.props.currentEmployerName,
        country: this.props.country
      });
    }
  }

  async _orgNameEntered(orgName: string) {
    this.setCurrentEmployer(null);
    if (this.props.country === Country.Norway) {
      this.setSearchName(orgName);
      if (orgName.length > 1) {
        this.setShowDropdown(true);
        const results = await getBronnoysundOrgSearchResult(orgName);
        this.setSuggestions(results);
      } else {
        this.setShowDropdown(false);
      }
    } else {
      if (orgName.length > 1) {
        this.setShowDropdown(true);
        const results = this.allCurrentEmps.filter(
          a => a.country === this.props.country && a.name.indexOf(orgName) > -1
        );
        this.setSuggestions(results);
      } else {
        this.setShowDropdown(false);
      }
      this.setCurrentEmployer({
        address: "",
        contact: "",
        organizationNo: this.searchOrgNo ? parseInt(this.searchOrgNo) : null,
        name: orgName,
        country: this.props.country
      });
    }
  }

  getCountries() {
    const flagStyle = this.props.readonly
      ? styles.countryReadonly
      : styles.country;
    return (
      <div className={styles.countries}>
        <div
          className={flagStyle}
          onClick={() => {
            this.setCountry(Country.Norway);
          }}
        >
          <img
            className={
              this.props.country === Country.Norway
                ? styles.selectedIcon
                : styles.icon
            }
            src={"res/no.svg"}
          />
        </div>
        <div
          className={flagStyle}
          onClick={() => {
            this.setCountry(Country.Sweden);
          }}
        >
          <img
            className={
              this.props.country === Country.Sweden
                ? styles.selectedIcon
                : styles.icon
            }
            src={"res/se.svg"}
          />
        </div>
        <div
          className={flagStyle}
          onClick={() => {
            this.setCountry(Country.England);
          }}
        >
          <img
            className={
              this.props.country === Country.England
                ? styles.selectedIcon
                : styles.icon
            }
            src={"res/gb.svg"}
          />
        </div>
      </div>
    );
  }

  isCurrentEmployerCorrectlyFilled() {
    if (this.props.country === Country.Norway) {
      return (
        this.currentEmployer &&
        this.currentEmployer.organizationNo &&
        this.currentEmployer.name &&
        this.currentEmployer.name.length > 0 &&
        this.currentEmployer.organizationNo.toString().length === 9
      );
    } else if (this.props.country === Country.Sweden) {
      return (
        this.currentEmployer &&
        this.currentEmployer.organizationNo &&
        this.currentEmployer.name &&
        this.currentEmployer.name.length > 0 &&
        this.currentEmployer.organizationNo.toString().length === 10
      );
    } else {
      return (
        this.currentEmployer &&
        this.currentEmployer.organizationNo &&
        this.currentEmployer.name &&
        this.currentEmployer.name.length > 0 &&
        this.currentEmployer.organizationNo > 0
      );
    }
  }

  render() {
    if (this.loading) {
      return <LoadingSpinner />;
    }
    return (
      <div
        className={styles.autocompleteContainer}
        onFocus={(e: any) => {
          if (
            this.suggestions &&
            this.suggestions.length > 0 &&
            this.props.country === Country.Norway
          ) {
            this.setShowDropdown(true);
          }
        }}
        onKeyDown={e => {
          if (
            this.showDropdown &&
            this.suggestions &&
            this.suggestions.length > 0
          ) {
            if (e.key === "Enter") {
              if (this.highlighted) {
                this.setCurrentEmployer(this.highlighted);
                this.setShowDropdown(false);
              }
            } else if (e.key === "ArrowDown") {
              if (!this.highlighted) {
                this.setHighlighted(this.suggestions[0]);
              } else if (
                this.suggestions.indexOf(this.highlighted) <
                this.suggestions.length - 1
              ) {
                this.setHighlighted(
                  this.suggestions[
                    this.suggestions.indexOf(this.highlighted) + 1
                  ]
                );
              }
            } else if (e.key === "ArrowUp") {
              if (!this.highlighted) {
                this.setHighlighted(this.suggestions[this.suggestions.length]);
              } else if (this.suggestions.indexOf(this.highlighted) > 0) {
                this.setHighlighted(
                  this.suggestions[
                    this.suggestions.indexOf(this.highlighted) - 1
                  ]
                );
              }
            }
          }
        }}
      >
        <div className={styles.inputFormsContainer}>
          <div className={styles.selectedEmployer}>
            <p className={styles.selectedEmployerText}>
              {this.currentEmployer && this.currentEmployer.name}
            </p>
            {this.props.setCountry && this.getCountries()}
          </div>
          <input
            ref={e => {
              if (e) {
                this.setPos({
                  x: `${e.parentElement.offsetLeft}px`,
                  y: `${e.parentElement.offsetTop + 51}px`
                });
              }
            }}
            className={
              this.currentEmployer ? styles.nameValid : styles.nameInvalid
            }
            type="text"
            placeholder={
              this.props.country === Country.Norway
                ? localizationStore.currentLocal.shared.employer
                    .EmployerSearchPlaceHolder
                : localizationStore.currentLocal.shared.employer
                    .EmployerPlaceHolder
            }
            value={this.searchName}
            onChange={e => this._orgNameEntered(e.target.value)}
            disabled={this.props.readonly}
          />
          <input
            className={
              this.currentEmployer
                ? styles.orgNumberValid
                : styles.orgNumberInvalid
            }
            type="number"
            placeholder={
              localizationStore.currentLocal.shared.employer.OrgPlaceHolder
            }
            value={this.searchOrgNo}
            onChange={e => this._orgNumberEntered(e.target.value)}
            disabled={this.props.readonly}
          />
        </div>

        {this.showDropdown && this.suggestions && this.suggestions.length > 0 && (
          <PortalPanel
            offsetX={-290}
            offsetY={0}
            onClickOutside={this.onClickOutside}
          >
            <div
              className={styles.dropDownStyle}
              style={{
                top: this.pos.y,
                left: this.pos.x
              }}
            >
              {this.suggestions.map((emp: Employer) => {
                return (
                  <div
                    key={`employer-autocomplete-${emp.organizationNo}`}
                    className={
                      this.highlighted === emp
                        ? styles.autocompleteEntryHighlighted
                        : styles.autocompleteEntry
                    }
                    onMouseDown={() => {
                      setTimeout(() => {
                        this.setCurrentEmployer(emp);
                      }, 100);
                      this.setSuggestions([]);
                    }}
                  >
                    <div className={styles.autocompleteEntryText}>
                      {emp.name}
                    </div>
                    <div className={styles.autocompleteEntryAddress}>
                      {emp.address ? emp.address : ""}
                    </div>
                  </div>
                );
              })}
            </div>
          </PortalPanel>
        )}

        {this.showDropdown &&
          this.suggestions &&
          this.suggestions.length === 0 && (
            <PortalPanel offsetX={-290} offsetY={0} onClickOutside={() => null}>
              <div
                className={styles.dropDownStyle}
                style={{
                  top: this.pos.y,
                  left: this.pos.x
                }}
              >
                <div className={styles.loadingSpinnerContainer}>
                  <div>
                    <i className="fa fa-spinner fa-pulse fa-2x" />
                  </div>
                </div>
              </div>
            </PortalPanel>
          )}
        <div className={styles.successContainer}>
          <SuccessAnimation
            halfSize={true}
            success={this.isCurrentEmployerCorrectlyFilled()}
          />
        </div>
      </div>
    );
  }
}
