import * as React from "react";
import { LocalShared } from "./local-shared";
import { CardType } from "../../types";

export const EngShared: LocalShared = {
  expertiseList: {
    greenStatus: "OK",
    yellowStatus: "Expires Soon",
    redStatus: "Expired",
    unknownStatus: "Unknown",
    missingStatus: "Missing",
    masterGridColumns: {
      status: "Status",
      type: "Type",
      expire: "Expires",
      count: "# Documents",
      requiredBy: "Required by"
    }
  },
  documentations: {
    documentation: "Documentation",
    info: "There is no file attached to this documentation. The documentation is a date and a reference for a course",
    date: "Valid from:",
    confirmComment: "Documentation reference:",
    description: "Optional comment:"
  },
  statuslog: {
    header: `History`,
    name: "Name: ",
    number: "Phone: ",
    comment: "Comment: ",
    title: "Title: ",
    email: "Email: ",
    validFrom: "Valid from",
    validTo: "Valid to",
    assetList: "Access list",
    cardChanged: (cardName: string) => `${cardName} updated`,
    oldValue: (val: string) => `Old value: ${val}`,
    newValue: (val: string) => `New value: ${val}`,
    assetCount: (val: string) => `Number of assets - ${val}`
  },
  batchUpdate: {
    AlertTitle: "An error occured",
    AlertText: "An error occured while sending data",
    Cancel: "Cancel",
    Done: "Done"
  },
  admin: {
    rights: {
      WriteAccess: "Write access",
      ReadAccess: "Read access",
      PersonPlaceHolder: "Search for person to add",
      Name: "Name",
      EmptySearch: "Search results empty",
      RemoveHeader: "Remove",
      Live: "Live",
      Daily: "Daily",
      Weekly: "Weekly",
      Monthly: "Monthly"
    }
  },
  asset: {
    popup: {
      EmptyStation: "Empty station",
      AltImg: "Station image",
      NameCol: "Name",
      AreaCol: "Area",
      EntriesCountCol: "#",
      TableFooter: "Personnel in station",
      EntryText: "Entries",
      MissingInfo: "Missing info",
      ShowInfo: "Show info",
      HideInfo: "Hide info"
    },
    userpopup: {
      travelingTo: "Traveling to",
      registeredAt: "Registered at",
      lastKnownPosition: "Position",
      timestamp: "Time",
      username: "User",
      station: "asset"
    }
  },
  buttons: {
    ConfirmTitle: "Cancel request?",
    ConfirmText: "Changes will not be saved.",
    Back: "Back",
    changeSplitAlignment: "Entries/map"
  },
  dialog: {
    Yes: "Yes",
    No: "No",
    Cancel: "Cancel",
    NoComment: "No comment",
    Confirm: "Continue",
    InputText: "Comment",
    Ok: "Ok",
    DeleteRightRowTitle: "Delete row",
    DeleteRightRowText: "This will delete row and the rights on it"
  },
  editIndBar: { Required: "Required field" },
  employer: {
    Accesses: "Accesses",
    Safetycards: (cardName: string) => `Our ${cardName.toLowerCase()}s`,
    Personnel: "Our personnel",
    NewApplication: "New application",
    Applications: "Our applications",
    Profile: "Our profile",
    UserAccess: "User rights",
    OrgPlaceHolder: "Organization number",
    EmployerSearchPlaceHolder: "Search for a company",
    EmployerPlaceHolder: "Company name"
  },
  error: {
    HeaderText: "Network error.",
    Details: "Show error details",
    Retry: "Retry"
  },
  templates: {
    card: {
      card: {
        Section: "Section",
        SubSection: "Sub-section",
        Header: (cardName: string) => `${cardName} - Template`
      },
      safetyCardAttribute: {
        requiredForCard: `Required value`,
        type: "Type",
        true: "true",
        false: "false",
        dependanciesToolTip: "Dependencies",
        dependancyNotFound: attributeID => {
          return `Specified dependency with attribute ID ${attributeID} could not be found in current template.`;
        },
        required: "Required"
      }
    }
  },
  file: {
    handle: {
      DocReqOwner: "Required by",
      ColRequired: "Required",
      Expires: "Expires",
      ColFileName: "File name",
      ColAction: "Action",
      ColReference: "Reference",
      ColOrg: "Asset owner",
      ColOptComment: "Optional comment",
      ColComment: "Comment",
      ColName: "Name",
      ColDate: "Valid from",
      ColValidDays: "Days valid",
      ColDateUploaded: "Uploaded",
      ColDocumentRequirement: "Document requirement",
      ConfirmDeleteTitle: "Delete document(s)",
      ConfirmDeleteText: "This will delete document(s)",
      MyFiles: "My documentation",
      NoFile: "No file",
      DocumentsNotFound: "No documents found",
      Choose: "Select",
      FileToBigTitle: "Error",
      FileToBigText: "File is too big",
      DropZoneText: "Drag and drop a document here to upload",
      SaveDemands: "Select a file and a description before saving",
      Upload: "Upload",
      AddDocumentation: "Add documentation",
      AddNewDocumentationWithoutFile: "Add documentation without file",
      DeleteFile: "Delete file(s)",
      CannotDelete:
        "Can not delete selected file(s) as it is used in an application",
      ShowFile: "Preview file",
      DocumentRequirements: "Document requirements",
      RequirementCovered: "Requirement covered",
      SelectDocumentRequirement: "Select document type",
      SelectDate: "Select a start date for the valid period",
      SelectDocument: "Upload document for ",
      SelectDocumentRequirementHeader: "Upload documentation",
      Cancel: "Cancel",
      Select: "Choose documentation",
      SelectNoFile: "Save documentation",
      SelectDatePlaceholder: "Select date",
      UploadedBy: "Uploaded by",
      confirmationComment: "Documentation reference",
      confirmationCommentMissing: "Documentation reference is required ",
      isUploading: "Uploading...",
      requiredBy: "Required by",
      UploadInfoHeader: (reqName: string) => {
        return (
          <React.Fragment>
            Here you may upload <i>{reqName}.</i>
            <br />
            <br />
          </React.Fragment>
        );
      },
      UploadInfoText: (reqName: string, reqValid: number) => {
        let expiresText = `${reqValid} days.`;
        if (reqValid > 365) {
          const years = reqValid / 365;
          const rounded = Math.round(years * 10) / 10;
          expiresText = `${rounded} years.`;
        }

        return (
          <React.Fragment>
            <i>{reqName}</i> is valid for {expiresText} <br />
            It is required that you set the first valid date below (
            <i>this might be the date a course was completed</i>
            ).
          </React.Fragment>
        );
      },
      AllowConfirmationWithoutFileHelpText:
        "If no physical documentation exists, (common courses, etc) it is possible to register documentation without uploading documents. If this is the case, date and a reference for the course must be supplied.",
      NoFileExistsGridLabel: "-- No File -- ",
      editImageButton: "Edit",
      MissingDate: "Date is missing",
      MissingDocRef: "Missing documentation reference"
    }
  },
  header: {
    Personnel: "Personnel",
    ApproveApplication: "Approve Applications",
    IssueApplication: (cardName: string) =>
      `Issue ${cardName.toLowerCase()} from Application`,
    AdminAsset: "Admin Asset",
    ConfirmUser: "Confirm new User",
    AccessHistory: "Access History",
    MyAccessHistory: "My Access History",
    OurAccessHistory: "Our Access History",
    Accesses: "Access Map",
    MyAccess: "My Access Map",
    MyCards: (cardName: string) => `My ${cardName}s`,
    OurCards: (cardName: string) => `Our ${cardName}s`,
    OurPersonnel: "Our Personnel",
    OurProflie: "Our Profile",
    OurOtherCards: "Our HSE Cards",
    OurAccess: "Our Access Map",
    MyProfile: "My Profile",
    MyApplications: "My Applications",
    MyApplication: "My Application",
    OurApplications: "Our Applications",
    OurApplication: "Our Application",
    IssuedApplications: "Issued Applications",
    ConfirmedApplications: "Confirm Application",
    ApplicationsForApproval: "Applications Awaiting Approval",
    ApplicaitonsForIssue: (cardName: string) =>
      `${cardName}s Waiting to be Issued`,
    UserRights: "User Rights - Organization",
    GroupRights: "Our User Rights",
    MyPermitto: "My Permitto",
    ServiceProvider: (empName: string) =>
      empName ? `Service Provider - ${empName}` : `Service Provider`,
    AssetOwner: (orgName: string) => `Asset Owner - ${orgName}`,
    Owner: "Asset Owner",
    ConfirmApplication: "Approve Application for",
    Employers: "Suppliers",
    MyOtherCards: "HSE Cards",
    AdminOtherCards: "HSE Cards",
    DoorkeyApplications: "Door key applications",
    OurDoorkeyApplications: "Our door key applications",
    MyDoorkeyApplications: "My door key applications",
    Doorkeys: "Door keys",
    OurDoorkeys: "Our door keys",
    MyDoorkeys: "My door keys",
    Documentation: "Documentation",
    OurDocumentation: "Our documentation",
    Projects: "Projects",
    OrganisationTemplates: "Organisation templates",
    OrganisationDoorkeyTemplates: "Organisation Doorkey templates",
    OrganisationAssetMessages: "Asset messages"
  },
  numberLookup: {
    UserExists: (
      firstName: string,
      lastName: string,
      organizationName?: string
    ) => {
      return organizationName
        ? `${firstName} ${lastName} is already a user under ${organizationName}`
        : `${firstName} ${lastName} is already a user`;
    },
    Invitation: (firstName: string, lastName: string) => {
      return `${firstName} ${lastName} is already a user in Permitto, you may invite the user below.`;
    },
    UserNotFound: "User not found",
    InvalidPhone: "The entered phone number is not valid",
    FirstName: "First name",
    LastName: "Last name",
    Company: "Company",
    CreateUser: "Create new user",
    Invite: "Invite",
    Ok: "Ok",
    NumberPlaceHolder: "Mobile phone number",
    Cancel: "Cancel",
    SubmitMobileNr: "Fill in the mobile phone number belonging to the new user"
  },
  newApplication: {
    Warning: "Warning",
    ConfirmClose: "Are you sure you want to cancel?",
    SafetyCardApplication: (cardName: string) => `${cardName} application`,
    CloseApplicationTitle: "Cancel application",
    CloseApplicationText:
      "Are you sure you want to cancel and discard this application?",
    Cancel: "Cancel",
    DoorkeyApplicationTitle: "New Door Key Application"
  },
  newCardNoApplic: {
    CreateNewCard: `Create new card`,
    CloseApplicationTitle: "Cancel creation",
    CloseApplicationText:
      "Are you sure you want to cancel the creation of the card?",
    Cancel: "Cancel",
    Warning: "Warning",
    ConfirmClose: "Are you sure you want to cancel?"
  },
  person: {
    FirstName: "First name",
    LastName: "Last name",
    Email: "Email",
    Mobile: "Mobile",
    Company: "Company",
    Back: "Back",
    Edit: "Edit",
    EditMode: "Edit mode",
    Save: "Save",
    Cancel: "Cancel",
    ChangesMade: {
      title: "Changes made.\nDo you want to proceed?",
      text: "If you proceed changes will be lost"
    }
  },
  personDocuments: {
    Header: "Documents",
    ErrorTitle: "Error",
    ErrorMessage: "Fetching documentation failed",
    NoDocumentsMessage: (companyName: string, cardName: string) =>
      `This person has no ${cardName.toLowerCase()} connected to ${companyName} the documentation is therefore not available`
  },
  personList: {
    AlertTitle: "Error",
    AlertText: "Sending invitations failed",
    Cancel: "Cancel",
    SendInvitations: (numOfInvitations: number) => {
      return numOfInvitations === 1
        ? `Send ${numOfInvitations} invitation`
        : `Send ${numOfInvitations} invitations`;
    },
    Send: (fromOrg: boolean) =>
      fromOrg ? `Send SMS to recipient` : `Send Email to recipient`,
    Invitations: "invitations",
    InvitationsSent: "invitations sent",
    Done: "Done",
    FirstName: "First name",
    LastName: "Last name",
    FullName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    Email: "Email",
    Mobile: "Mobile",
    Company: "Company",
    Invited: (firstName: string, lastName: string) =>
      `${firstName} ${lastName} invited`,
    InvitedTo: (firstName: string, lastName: string, orgName: string) =>
      `${firstName} ${lastName} invited to ${orgName}`,
    NewPersonHeader: "Create new person",
    Save: "Create",
    CreateNew: "Create new",
    Invite: (numOfPersons: number) => {
      return numOfPersons === 1
        ? `Invite ${numOfPersons} person`
        : `Invite ${numOfPersons} persons`;
    },
    Persons: "Escorts",
    LastNamePlaceholder: "Filter by last name",
    Open: "Open",
    SelectAPerson: "Select a person",
    SelectJustOnePerson: "Select just one person",
    SendInvitationsDescription:
      "Are you sure you want to invite the selected personnel to the organization?"
  },
  safetycard: {
    Application: "Application",
    Add: "Add",
    InputPlaceholder: "Missing text",
    Shredded: "Shredded",
    Expired: "Expired",
    Sign: (cardName: string) =>
      `Click to sign - ${cardName.toLowerCase()} confirmed received`,
    NotSigned: "Receipt not received",
    SafetyCard: (cardName: string) => cardName,
    Card: (cardId: number, cardName: string) => `${cardName}: ${cardId}`,
    Access: "Access list",
    CardOwner: (cardName: string) => `${cardName} holder`,
    Issued: "Issued",
    Signed: "Receipt received",
    ValidFrom: "Valid from",
    DatePlaceholder: "Select date",
    ReceiptReceived: "Receipt received",
    ValidTo: "Valid to",
    UploadDate: "Upload Date",
    ValidFromWithColon: "Valid from: ",
    ValidToWithColon: "Valid to: ",
    UnknownDataType: "Unknown data type",
    Attestor: (name: string, email: string) => `Attestor: ${name} (${email})`,
    LoadingAssets: "Loading assets...",
    ChangedBy: "Changed by ",
    ReadyForIssuing: "Ready for issuing",
    NotFilled: "Not filled",
    EmployerName: "Employer",
    HeadOfOperations: "Head Of Operations",
    IssuedOnBehalfOf: (issuerName: string, headOfOperations: string) =>
      `Issued by ${issuerName} on behalf of Head Of Operations ${headOfOperations}`,
    postIssueSections: {
      clickToApprove: "Click to approve",
      missingTrainingHeader: "Requirements missing",
      missingTrainingHeaderForAccess: "Requirements missing for access",
      missingConditionalTrainingHeader: "Requirements related to access",
      requirmentsNeedToBeFulfilled:
        "These are requirements that must be fulfilled before the card is valid",
      requirmentsNeedToBeFulfilledForAsset:
        "These are requirements that must be fulfilled in order to register access on some of the facilities on this card",
      requirmentsNeedToBeFulfilledThisAsset:
        "These are requirement(s) that must be fulfilled in order to register access on this asset"
    }
  },
  safetyCardApplication: {
    GivesAccessConflict: `Some of the assets in your application has this field as a requirement for access. If you remove this field, you will not get access to the asset.`,
    TreeHelpFirst:
      "Here you can choose what you want to apply for access to. You can either choose a group of assets, or you can expand the tree and select a single asset.",
    TreeHelpSecond: "You choose an asset by marking it in the checkbox",
    TreeHelpThird:
      "You can browse the tree by double-clicking or clicking the arrow",
    TreeHelpFourth: "Show demo",
    TreeAttestorHelpText:
      "Here you can see which access rights the application has applied for. You may navigate the tree by clicking the arrows.",
    EqualAsset: " = Asset",
    EqualGroup: " = Group",
    WhereToApply: "Where do you want access?",
    AppliedTo: "Application requests access to",
    FileToBigTitle: "Error",
    FileToBigText: "File is too big",
    Cancel: "Cancel",
    MyFiles: "My files",
    FileOwner: "'s files",
    ApplicationApproved: "The application was attested",
    CloseWindow: "You may now close the page",
    ApplicationDenied: "The application was rejected",
    WrongNumber: "The phone number must be an 8-digit number",
    ApproverMissing: "You need to enter information about the attestor.",
    ValidEmail: "The attestor's email address must be valid",
    ValidEmailHeader: "The attestor's email address is not valid",
    CardOwner: "Card owner",
    ValidFrom: (date: string) => `Valid from ${date}`,
    SelectDatePlaceholder: "Select date",
    ValidTo: "Valid to",
    Next: "Next",
    SearchForLastName: "Filter by last name",
    SelectFile: "Please attach documentation",
    UploadFile: "Upload file",
    UploadDate: "Uploaded",
    ChangeFile: "Change file",
    NewFile: "New file",
    OpenFile: "Open file",
    NoFile: "No file",
    Loading: "Loading...",
    Missing: "Missing text",
    UnknownDataType: "Unknown data type",
    ReadyToSendWithAttestor: (cardName: string) => (
      <span>
        {`Before the application can be sent, it needs to be attested. The
            purpose of this is to confirm that the applicant is qualified to hold
            a ${cardName.toLowerCase()} with the authorizations requested. The attestor does not
            need to be a Permitto user.`}
        <br />
        <b>
          {`Attestor is seldom the same person as the ${cardName.toLowerCase()} applicant.`}
        </b>
      </span>
    ),
    ReadyToSend: "The application is valid, add a comment and click send",
    WillNotBeSetBeforeAttested: (cardName: string) =>
      `Warning: The application will not be sent to the ${cardName.toLowerCase()} issuer before it is attested. Please verify that email is correct.`,
    FillAttestorInfo: "Please provide attestor info",
    SelfAttesting: "I want to be listed as the attestor on this application",
    AddPlaceholder: "Add comment",
    AttestorNameTitle: "Attestor name",
    AttestorEmailTitle: "Attestor email",
    AttestorFirstnameTitle: "Attestor first name",
    AttestorLastnameTitle: "Attestor last name",
    AttestorTitleTitle: "Attestor title",
    AttestorPhoneTitle: "Attestor phone",
    Back: "Back",
    Done: "Done",
    Send: "Send",
    ApplyForCard: (cardName: string) =>
      `Where do you want to apply for a ${cardName.toLowerCase()}?`,
    IssueCard: (cardName: string) =>
      `Where do you want to issue a ${cardName.toLowerCase()}?`,
    ApplyForWhichCard: (cardName: string) =>
      `What type of ${cardName} are you applying for?`,
    AllMissing: "Some fields are missing",
    ForPerson: "Application information",
    AuthorizationReadOnly: "Authorizations",
    Authorization: (numAssets: number) =>
      numAssets > 0
        ? `Select authorizations (authorizations to stations selected previously are already selected)`
        : `Select authorizations`,
    ApplicationSummary: "Application info",
    Valid: "Valid:",
    ApplicationForm: "Application form",
    Access: "Access",
    AssetLowerCase: (numAssets: number) => `${numAssets} asset`,
    AuthorizationField: "Authorizations",
    Submit: "Submit",
    FillInAllMarked: "Fill in all fields marked by *",
    FillInAccessLogg: "Add stations you want access to",
    FillInDates: "Select two valid dates",
    Reject: "Reject",
    Approve: "Approve",
    AttestorInfo: "Attestor",
    ApplicantInfoIssued: "Card holder information",
    AttestorInfoMessage1: (
      fistName: string,
      lastName: string,
      orgName: string,
      cardName: string
    ) =>
      `${fistName} ${lastName} has created an application for a ${cardName.toLowerCase()} with ${orgName}.`,
    AttestorInfoMessage2: (orgName: string) =>
      `${orgName} requires that all applications are attested before being sent.`,
    AttestorInfoMessage3: (
      cardName: string
    ) => `You have been selected as an attestor for this application. The objective of this step is to obtain a confirmation from somebody associated with the applicant who can confirm that the applicant is qualified to hold a ${cardName.toLowerCase()} with the authorizations applied for.
      As attestor, you are also asked to ensure that the application has been filled in correctly and that the required documentation is valid and attached to the application.
      When attesting this application, your contact information will be added to the application so that the administrator may contact you if any questions should arise.The applicant has registered the following information about you:`,
    AttestorInfoMessage4:
      "Please verify that the information registered about you is correct, and do the necessary changes.",
    Attest: "Attest",
    AttestApplication: "Attest application",
    CompleteAttestation: "Complete attestation",
    StartAttesting: "Start attesting",
    AttestorMessage1: "You can now choose to attest or reject the application.",
    AttestorMessage2: (fistName: string, lastName: string) =>
      `If you reject the application, it will be sent back to ${fistName} ${lastName} who can make changes and reissue for attestation.`,
    AttestorMessage3: (
      orgName: string
    ) => `If you choose to attest, the application will be sent to ${orgName} for processing.
        In both cases, you can add a comment to the application.`,
    AttestorConfirmSane: (cardName: string) =>
      `By attesting this application you also confirm that the applicant is qualified to hold a ${cardName.toLowerCase()}.`,
    Applicant: "Applicant:",
    Issuer: (cardName: string) => `${cardName} issuer`,
    ValidFromDate: "Valid from:",
    ValidToDate: "Valid to:",
    Comment: "Comment",
    ContinueApplication: "Continue",
    CardNumber: (cardName: string) => `${cardName} number`,
    Previewtemplate: (cardName: string) => `Show ${cardName.toLowerCase()}`,
    ApplyFromExistingCardHeaderText: (cardName: string) =>
      `Select ${cardName.toLowerCase()} you wish to copy into this application. \nPlease note that documentation still must be filled.`,
    CreateNewSafetyCard: "Blank application",
    CopyExistingSafetyCard: (cardName: string) =>
      `Copy existing ${cardName.toLowerCase()} into this application`,
    FileValidFrom: "Valid from",
    FileValidTo: "Valid to",
    FileUploadComment: "Comment",
    FileName: "File Name",
    ExpireMessage:
      "Warning: This document expires during the period the safety card has been applied for.",
    Retry: "Retry",
    cardTypeName: (cardTemplate: CardType, createdfromApplic: boolean) =>
      createdfromApplic
        ? `${cardTemplate.cardNameEn} - Created from application`
        : cardTemplate.cardNameEn,
    ApplicantInfo: "Applicant information",
    Application: "Application",
    ApplicationAttestorInfo: (cardName: string) =>
      `Below you will find the ${cardName} that has been applied for, as well as the application associated with this card. Please see that the application seems reasonable.`,
    ApplicantName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    SafetyCard: "Safety card",
    SafetyCardStatusLog: "Application change history",
    SafetyCardStatusLogNoApplic: (cardTemplate: CardType) =>
      `${cardTemplate.cardNameEn} change history`,
    AttestorEmailSent: "Email resent",
    AttestorEmailSentInfo: "Email has been resent to attestor",
    AttestorLink: "Attestor link",
    CopyToClipboard: "Copy to clipboard",
    Status: "Status: ",
    Name: "Name: ",
    LastApplicChangeDate: "Last change: ",
    ApplicInfoNoApplic: "Fields found on application template",
    Reference: "Refernce",
    StatusExpired: "Expired",
    StatusValid: "OK",
    ExpireDate: "Expiration Date",
    SelectedDocument: "Selected Document",
    NoneFileJustRef: "No file, only reference",
    DropdownHeader: "Stored Documents",
    IssueOnBehalfOfDisabledTooltipText:
      "The asset owner has disabled the ability to apply on behalf of others"
  },
  doorkeyCardApplication: {
    SendPageText:
      "Your door key application is ready to be sent.If you wish to add a comment to your application this can be added below.",
    AddPlaceholder: "Add comment",
    MissingFeilds: "You need to enter a comment and click send.",
    Back: "Back",
    Done: "Done",
    Send: "Send",
    ForPerson: "Application information",
    Next: "Next",
    ApplyForCard: "At which asset owner do you wish to apply for doorkey?",
    ApplicationForm: "Application form",
    ApplicationSummary: "Application info",
    Submit: "Submit",
    Applicant: "Applicant:",
    Issuer: (cardName: string) => `${cardName} issuer`,
    doorkeyCardApplicationText: "Door key application"
  },
  cardIssueNoApplication: {
    CardOwner: "Card holder",
    FindByMobile:
      "Insert the mobilnumber of the person the card will be issued to",
    UserNotFound: (inPermitto: boolean) =>
      inPermitto
        ? "User exists in permitto, but in another organization. You can only issue cards to users in your own organization."
        : "User not found, fill in the fields below to create a new user. Note: Make sure phoen number and email is correct",
    UserFound: "User found",
    FirstName: "First name:",
    LastName: "Last name:",
    Mobil: "Mobile:",
    Email: "Email:",
    Next: "Next",
    Applicant: "Card holder:",
    AuthorizationField: "Authorizations",
    Issuer: (cardName: string) => `${cardName} issuer`,
    Valid: "Valid:",
    Submit: "Issuing",
    ApplicationSummary: "Card information",
    ApplicationForm: "Filling in the card",
    ReadyToSend:
      "The card is ready to be issued, add a comment and click issue",
    AddPlaceholder: "Add comment",
    Back: "Back",
    Done: "Done",
    Send: "Issue",
    Retry: "Retry",
    CreateNewPersonHeader: (
      cardholderFistName: string,
      cardholderLastName: string
    ) =>
      `${cardholderFistName} ${cardholderLastName} will with this be created as a new user in Permitto`,
    CreateNewPerson: (cardholderFistName: string, cardholderLastName: string) =>
      `${cardholderFistName} ${cardholderLastName} will now be created as a new user and you can then continue the process of issuing cards to them. Do you want to continue?`
  },
  safetyCardAssets: {
    EnterAssetLocation: "Click on map to set coordinates for the asset",
    EnterAssetName: "Enter a name",
    EnterAssetDescription: "Enter a description",
    Type: "Type",
    Description: "Description",
    AddType: (message: string) => `Add new type: "${message}"`,
    Cancel: "Cancel",
    Save: "Save",
    SelectNode: "Select node",
    Add: "Add",
    Delete: "Delete",
    Or: (message: string) => `${message} or `,
    RequireAuthorization: (message: string) =>
      `${message} is required on the safety card to get access to this station`,
    AccessList: "Access list",
    Access: "Access overview",
    SelectAll: "Select all",
    SelectNone: "Select none",
    AddAll: "Add all",
    RemoveAll: "Remove all",
    Asset: "Asset",
    AssetNode: "Group",
    SelectType: "Select type",
    SelectModelType: "Select model type",
    NotAvailable: "This selection is not available on this level"
  },
  safetyCardList: {
    Kpi: {
      Active: "Valid cards",
      Expired: "Expired Cards",
      ExpiresIn30Days: "Cards expring in 30 days",
      RecieptNotRecieved: "Cards not reciept received"
    },
    ShredButtonText: "Shred",
    ShredAndCreateButtonText: "Shred and create new",
    ShredAndCreateText: (cardName: string) =>
      `Do you want to shred and create a new ${cardName.toLowerCase()}, or just shred the ${cardName.toLowerCase()}?`,
    ShredAndCreateTitle: (cardName: string) =>
      `Shred and create new ${cardName.toLowerCase()}?`,
    ShouldIssueTitle: "Card(s) created",
    ShouldIssue: (cardName: string) =>
      `Do you want to issue the ${cardName.toLowerCase()}s now?`,
    RenewedFromCard: (
      cardId: number,
      firstName: string,
      lastName: string,
      cardName: string
    ) =>
      `Renewed from ${cardName.toLowerCase()} number ${cardId} by ${firstName} ${lastName}`,
    NamePlaceholder: "Search by name",
    CopyCardHeader: (cardName: string) =>
      `Copy ${cardName.toLowerCase()} from: `,
    CardNumberPlaceholder: (cardName: string) =>
      `Search by ${cardName.toLowerCase()} number`,
    LastNamePlaceholder: "Search by last name",
    Proceed: "Proceed",
    Warning: "Warning",
    ValidFrom: "Valid from",
    ValidTo: "Valid to",
    Missing: "Valid from need to be earlier then valid to",
    Comment: "Leave comment",
    CardTitle: (cardName: string) => `${cardName} created`,
    CardText: (cardName: string) =>
      `${cardName} created but not issued. Issuing rights are needed to issue ${cardName.toLowerCase()}s`,
    CardWarning: (cardName: string) =>
      `${cardName} not created. Not all required attributes are entered`,
    Save: "Save",
    Cancel: "Cancel",
    ErrorCreatingCardShredded: (cardId: number, cardName: string) =>
      `Creating ${cardName.toLowerCase()} ${cardId} failed. The ${cardName.toLowerCase()} has been shredded, but a new ${cardName.toLowerCase()} was not created.`,
    ErrorTitle: "Error",
    ErrorShreddingCard: (cardId: number, cardName: string) =>
      `Shredding ${cardName.toLowerCase()} ${cardId} failed`,
    SetDate: (numCards: number, cardName: string) =>
      numCards === 1
        ? `Select start and end date on ${numCards} ${cardName}`
        : numCards === 2
        ? `Select start and end date on both ${cardName}s`
        : `Select start and end date on all ${numCards} ${cardName}s`,
    SecurityCard: (cardName: string) => cardName,
    TheSecurityCard: (cardName: string) => `the ${cardName.toLowerCase()}`,
    Update: "Update",
    CardRenewed: (cardName: string) => `${cardName.toLowerCase()} renewed.`,
    NumCardRenewed: (numCards: number, issued: boolean, cardName: string) =>
      numCards === 1
        ? issued
          ? `${numCards} ${cardName.toLowerCase()} renewed and issued.`
          : `${numCards} ${cardName.toLowerCase()} renewed, but not issued`
        : issued
        ? `${numCards} ${cardName.toLowerCase()}s renewed and issued.`
        : `${numCards} ${cardName.toLowerCase()}s renewed, but not issued`,
    Done: "Done",
    Renew: "Renew",
    RenewCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? numCards === 1
          ? `Renew ${numCards} ${cardName.toLowerCase()}`
          : `Renew ${numCards} ${cardName.toLowerCase()}s`
        : `Renew ${cardName.toLowerCase()}`,
    RenewNotSelected: (cardName: string) =>
      `Select ${cardName.toLowerCase()}s you wish to renew from the list below`,
    ReceiptReceived: "Receipt received",
    IssuedBy: "Issued by",
    Issued: "Issued",
    ShreddedBy: "Shredded by",
    ShreddedTime: "Time shredded",
    CreateNewSecurityCard: (cardName: string) =>
      `Create new ${cardName.toLowerCase()}`,
    CreateNew: "Create new",
    Card: (cardName: string) => cardName,
    Shred: "Shred",
    ShredCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? numCards === 1
          ? `Shred ${numCards} ${cardName.toLowerCase()}`
          : `Shred ${numCards} ${cardName.toLowerCase()}s`
        : `Shred ${cardName.toLowerCase()}`,
    ShredCardsAllTypes: (numCards: number) =>
      numCards > 0
        ? numCards === 1
          ? `Shred ${numCards} card`
          : `Shred ${numCards} cards`
        : `Shred card`,
    ShredSelectedCards: (cardName: string) =>
      `Select ${cardName.toLowerCase()}s you wish to shred from the list below`,
    AlreadyShreddedTitle: "Already shredded",
    AlreadyShreddedText: (cardName: string) =>
      `The selected ${cardName.toLowerCase()} is already shredded`,
    AlreadyShreddedTextAllTypes: `The selected card is already shredded`,
    ShredAlertText: (multipleCards: boolean) =>
      multipleCards
        ? `This will shred the selected cards`
        : `This will shred the selected card`,

    ShredAlertTitle: (cardName: string) => `Shred ${cardName.toLowerCase()}`,
    ShredAlertTitleAllTypes: `Shred card`,
    Filter: "Show/hide columns",
    ShowValid: (cardName: string) => `Show valid ${cardName.toLowerCase()}s`,
    ShowShredded: (cardName: string) =>
      `Show shredded ${cardName.toLowerCase()}s`,
    Open: "Open",
    NeedToSelectCard: (cardName: string) =>
      `Select a ${cardName.toLowerCase()}`,
    NeedToSelectOneCard: (cardName: string) =>
      `Select just one ${cardName.toLowerCase()}`,
    RenewInfoTextWithShred: (cardName: string) =>
      `The selected ${cardName.toLowerCase()}s will be shredded and new ones created.`,
    RenewInfoTextNoShred: (cardName: string) =>
      `The selected ${cardName.toLowerCase()}s will be renewed, but the existing ones will not be shredded.`,
    RenewShouldShred: (cardName: string) => `Shred ${cardName} when renewing`,
    InfoLegendFirst: (cardName: string) =>
      `Safety ${cardName.toLowerCase()}s marked by `,
    InfoLegendLast: "have expired.",
    InfoLegendShredded: (cardName: string) =>
      `Showing shredded ${cardName.toLowerCase()}s`,
    FilterByAssets: "Filter by access",
    Actions: "Actions",
    ShredNumExpired: (num: number): string =>
      `Shred all expired cards (${num})`,
    ShredAll: "Shred All",
    ShredExpiredInProgress: "Shredding cards in progress...",
    ShredExpiredDone: "Shredding cards successful"
  },
  safetyCardEdit: {
    EditCard: (cardName: string) => `Edit ${cardName.toLowerCase()}`,
    SaveCard: (cardName: string) => `Save ${cardName.toLowerCase()}`,
    Cancel: "Cancel"
  },
  sidebar: {
    Admin: "Admin",
    AdminAsset: "Admin asset",
    UserControl: "User access control",
    Roles: "Roles",
    Templates: "Edit card templates",
    ConstructionProjects: "Projects",
    AssetMessages: "Asset messages",
    DoorKeyTemplates: "Doorkey application template"
  },
  signReceipt: {
    PinNotValid: "Pin not valid",
    ErrorRequestingPinTitle: "Error",
    ErrorRequestingPinText:
      "Request pin failed. Please try again or contact support",
    ErrorValidatingPinTitle: "Error",
    ErrorValidatingPinText: "Sending pin code failed",
    ConfirmReceivingCard: (cardId: number, orgName: string, cardName: string) =>
      `I confirm having received ${cardName} with number ${cardId} issued by ${orgName}.`,
    ConfirmReceivingCardSign: (
      cardId: number,
      orgName: string,
      cardName: string
    ) =>
      `I confirm having received ${cardName} with number ${cardId}.\nSign for safety card issued by ${orgName}.`,
    ConfirmReceivingDoorKey: "Confirm key received",
    GetPinSms: "Get PIN code via SMS",
    GetDoorKeyPinSms: "Send pin",
    VerifyDoorKeyPinSms: "Confirm",
    WritePin: "Write PIN code",
    SignSecurityCard: (cardName: string) => `Sign ${cardName.toLowerCase()}`,
    Close: "Close",
    SuccessText: "Card signed",
    DoorkeySuccessText: "Key signed",
    modalHeader: "Sign for key recieved",
    modalHeaderCard: "Sign for card recieved"
  },
  singleAsset: {
    Error: "Error",
    ErrorFetchingAsset: "Fetching asset failed",
    MissingAssetAttri: "Some attributes on asset are missing",
    ErrorPutStation: "Sending station object failed",
    ErrorPutImg: "Sending image failed",
    ErrorPutAsset: "Sending asset object failed",
    WrongFileFormat: "Wrong file format",
    DropzoneText: "Click, or drag and drop a file here to upload it",
    DeleteImage: "Delete image",
    Warning: "Delete image",
    DoYouWantToDeleteImg: "This will delete the image",
    Zones: "Zones",
    AddZone: "Add zone",
    DeleteZone: "Delete zone",
    Latitude: "Latitude *",
    SelectOnMap: "Select on map",
    ClickToSetCoordinates: "Click on map to set coordinates for the asset",
    Longitude: "Longitude *",
    Name: "Name *",
    NamePlaceholder: "Write name",
    Missing: "This field may not be empty",
    ExportId: "Integration ID",
    ExportIdPlaceholder: "Integration ID",
    AccessRequirment: "Access requirement",
    Role: (numRoles: number) =>
      numRoles === 1 ? `${numRoles} role` : `${numRoles} roles`,
    RequiredRolesForAccess:
      "One of the following roles is required to get access.",
    LeaderRequirment: "Escort requirement",
    RequiredRolesForLeader:
      "One of the following roles is required to escort others",
    maxEntries: "Max entries",
    maxEntriesPlaceholder: "Enter max entry count for asset",
    maxEntriesBlock:
      "Restriction is hard limit (users not able to register if reached)",
    maxEntriesUpdate: "Restrict number of entries in asset at the same time",
    maxEntryInputWarning: "Enter valid number as a max entry count",
    infoLabel: "Info"
  },
  singlePerson: {
    personalInformation: "Personal information",
    FirstName: "First name",
    Missing: "Cannot be empty",
    LastName: "Last name",
    Mobile: "Mobile",
    Digits: "Only digits are allowed",
    Email: "Email",
    ValidEmail: "Must be a valid email address",
    Company: "Company",
    Active: "Active",
    Shredded: "Shredded",
    SafetyCards: "Safety cards",
    Documents: "Documents"
  },
  personSafetycards: {
    SafetyCards: (cardName: string) => `${cardName}s`,
    SelectToOpen: (cardName: string) =>
      `Select a ${cardName.toLowerCase()} to open`,
    Open: "Open",
    ShowShredded: (cardName: string) =>
      `Show shredded ${cardName.toLowerCase()}s`,
    InfoLegendFirst: (cardName: string) =>
      `Safety ${cardName.toLowerCase()}s marked by `,
    InfoLegendLast: "have expired.",
    ShowValid: (cardName: string) => `Show valid ${cardName.toLowerCase()}s`
  },
  currentUser: {
    LoggedIn: "Logged in as:",
    LogOut: "Log out",
    PrivacyPolicy: "Privacy policy"
  },
  columnDefs: {
    BirthDate: "Birth date",
    PermittoId: "Permitto ID",
    CardNr: "Card number",
    FirstName: "First name",
    LastName: "Last name",
    Mobile: "Mobile",
    EmployerName: "Company",
    ValidFrom: "Valid from",
    ValidTo: "Valid to",
    Status: "Status",
    ShreddedBy: "Shredded by",
    ShreddedTime: "Time shredded",
    PersonnelId: "Id",
    Email: "Email",
    Company: "Company",
    Organisation: "Organization",
    OrganizationNo: "Organization nr.",
    InvitedDate: "Invite email sent",
    AssetName: "Asset name",
    AssetArea: "Asset area",
    AssetOwner: "Asset owner",
    CheckOut: "Check out",
    CheckIn: "Check in",
    ExpectedTimeToLeave: "Expected check out",
    EntryCount: "Number of persons",
    EntryNames: "Persons",
    EntryNote: "Note",
    RoleName: "Role",
    EntryNoteBy: "Note by",
    CheckoutBy: "Checked out by",
    IssuedBy: "Issued by",
    OtherCardType: `Card type`,
    OtherCardTypeDesc: `Card type description`,
    IsApplication: "Is application",
    ReceiptReceived: "Receipt received",
    ProjectName: "Project Name",
    ProjectConnectedToAsset: "Connected to asset(s)",
    ProjectReq: "Access Requirement",
    ProjectSupplier: "Project Supplier",
    ProjectStart: "Start Date",
    ProjectEnd: "End Date",
    ProjectReference: "Integration ID",
    Message: "Message",
    InfoType: "Message format",
    EventType: "Display message when",
    ShowMsgFrom: "Display from date",
    ShowMsgTo: "Display to date",
    EnteredBy: "Entered by",
    AllVenors: "All vendors",
    CardHolder: "Card holder",
    CardDetails: "Card details",
    CardAttribute: "Card attributes",
    CardHolderDoc: "Card holder documentation",
    BlockAutoCheckout: "Disable auto checkout",
    ProjectNumber: "Project number",
    ProjectLead: "Project manger",
    ExternalId: "external-id",
    CardType: "Card type"
  },
  columnAttachmentDetailDefs: {
    fileId: "Attachment ID",
    fileName: "Attachment Name",
    attachments: "Check-in Attachments",
    gridEmptytext: "No Attachments"
  },
  customDateComponent: { clear: "Clear filter" },
  accessLog: {
    fromDatePickerDate: "From date",
    toDatePickerDate: "To date",
    digitalProjectEntry: "Digital project entries list",
    digitalProjectEntryTitle: "Digital project entries list for",
    digitalProjectEntryAddress: "Address",
    digitalProjectEntryClient: "Construction Client",
    enableFilter: "filter by project",
    disableFilter: "reset project filter",
    from: "from",
    to: "to",
    export: "Export"
  },
  dateValidation: {
    validDates: "Selected dates are not valid",
    beforeToday:
      "Selected dates are not valid. From-date cannot be before today's date.",
    toBeforeFrom:
      "Selected dates are not valid.  To-date must be after from-date."
  },
  assetMessagesModal: {
    repeats: "Repeated",
    modalHeader: "Asset message",
    modalSave: "Save",
    modalClose: "Close",
    infoDesc: `This message will be displayed for the asset selected in the tree view. If you select an asset group, the message will be displayed for all the assets belonging to that group. You must specify for what dates the message will be active. If the to-date is empty, the message will be displayed until it is deleted. When active, the message will automatically be displayed in the Permitto App for all users visiting the selected asset(s).`,
    groupIcon: "Group",
    assetIcon: "Asset",
    selectedAsset: "Selected asset",
    selectAsset: "Select an asset",
    messageType: "Message format",
    messageText: "Message text",
    fromDate: "Display message from",
    toDate: "Display message to",
    messageEventType: "Display message when",
    messagePlaceholder: "message text...",
    datePlaceholder: "Choose a date",
    assetMessageDiabledTooltip: (asset: boolean, message: boolean) =>
      !asset ? "Asset is not set" : !message ? "Message has not been set" : ""
  },
  otherCards: {
    openCard: "Open",
    addCard: "Add card",
    deleteCard: "Delete card",
    filterPlaceHolder: "Search by last name",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    selectCardType: "Select card type",
    cardType: "Card type",
    cardNumber: "Card number",
    cardHolder: "Card holder",
    added: "Added",
    addFrontImage: "Add front image",
    addBackImage: "Add back image",
    selectDate: "Select date",
    validTo: "Valid to",
    validFrom: "Valid from",
    birthDate: "Birth date",
    editImageApplyChanges: "Apply Changes",
    editImageDiscardChanges: "Discard Changes",
    editImageButton: "Edit",
    editImageHeading: "Edit Image",
    editImageRotateLeft: "Left",
    editImageRotateRight: "Right",
    editImageZoomMinLabel: "Minimum",
    editImageZoomMaxLabel: "Maximum",
    editImageZoomControlLabel: "Zoom",
    modalEditHeader: "Edit card",
    modalNewHeader: "Add new card",
    modalViewHeader: "Card details",
    editButtonLabel: "Edit",
    deleteButtonLabel: "Delete",
    noImageFound: "No image found",
    enterCardNumber: "Enter card number",
    cardNumberNotFilled: "Card number not filled"
  },
  selectPerson: {
    proceedBtnLabel: "Proceed",
    filterPlaceholder: "Last name"
  },
  languageSelector: {
    Nor: "Norwegian",
    Eng: "English",
    Sve: "Swedish",
    Ned: "Dutch"
  },
  updateVersion: {
    preRefreshText: "A new version dedected. Please",
    refreshTextBtn: "refresh",
    postRefreshText: "the site."
  },
  maintenanceMessages: {
    title: "Title",
    author: "Author",
    message: "Message"
  },
  assetMap: {
    polygon: {
      draw: "Draw a polygon",
      cancel: "Cancel",
      finish: "Finish",
      undo: "Delete last point",
      delete: "Delete",
      deleteDisabled: "No layers to delete",
      modal: {
        delete: {
          title: "Delete polygon",
          subTitle: "This will delete the polygon"
        }
      },
      tooltip: {
        start: "Click to start drawing polygon",
        continue: "Click to add coordinate",
        stop: "Click first point to finish this polygon"
      }
    }
  },
  customToolPanel: {
    title: "Saved column filters",
    saveFilterButton: "Save as new filter",
    saveFilterModal: {
      title: "Save filter",
      subtitle: "Add name for filter",
      inputPlaceholder: "Add filter name",
      saveButton: "Save filter"
    },
    deleteFilterModal: {
      title: "Remove filter",
      subtitle: ""
    }
  },
  jungleMap: {
    firstPara: "You can find this E-Learning course ",
    linkText: "at this link",
    secondPara: "When you have completed the course, it will appear beneath",
    buttonTitle: "Refresh",
    footerText:
      "If you have completed the E-Learning course and it does not appear below automatically, you can click refresh above"
  },
  genericWords: {
    since: "since",
    from: "from",
    phone: "phone",
    change: "change"
  }
};
