import * as React from "react";
import { observer } from "mobx-react";
import { errorStore, localizationStore, userStore } from "./../../stores";
import styles from "./error-logger.css";
import { observable, action } from "mobx";
import { maintenanceMessageList } from "../../types";
import { getPreferredLanguage } from "../../stores/localization";
import { getOrganisation, getMaintenanceMessages } from "../../repo";
import { RouteProps } from "../../types";
import { withRouter } from "react-router-dom";

@observer
class BaseErrorLogger extends React.Component<RouteProps> {
  @observable
  maintanenceMessages: maintenanceMessageList[];

  constructor(props: RouteProps) {
    super(props);
    this._setMaintenanceMessages([]);
  }

  async componentDidMount() {
    window.addEventListener("error", this.onEventError);
    await this._getmaintenanceMessages();

    errorStore.urlStack.push(this.props.location);
  }

  componentDidUpdate(prevProps: RouteProps) {
    if (this.props.location !== prevProps.location) {
      errorStore.urlStack.push(this.props.location);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("error", this.onEventError);
  }

  componentDidCatch(error: Error) {
    const local = localizationStore.currentLocal.actions;
    //info contains React component stacktraces, not logged, coul'd be useful
    errorStore.reportError({
      error: error,
      text: `${local.error.unknownError}${local.contactSupport}`,
      title: local.error.title
    });
  }

  onEventError = (err: ErrorEvent) => {
    const local = localizationStore.currentLocal.actions;
    //Triggers on events and render() when React is en development mode. Production?
    if (err.error) {
      errorStore.reportError({
        error: err.error,
        text: `${local.error.unknownError}${local.contactSupport}`,
        title: local.error.title
      });
    }
  };

  async _getmaintenanceMessages() {
    const maintenanceMessages: maintenanceMessageList[] = [];
    const language = getPreferredLanguage();
    if (userStore.userIdentity && userStore.userIdentity.organisations) {
      for (const orgId of userStore.userIdentity.organisations) {
        try {
          const message = await getMaintenanceMessages(language, orgId);
          if (message) {
            const organization = await getOrganisation(orgId);
            maintenanceMessages.push({ orgId, message, organization });
          }
        } catch (e) {
          //errors will be handled silently, no action needed.
        }
      }
      maintenanceMessages && this._setMaintenanceMessages(maintenanceMessages);
    }
  }

  @action
  _setMaintenanceMessages(messages: maintenanceMessageList[]) {
    this.maintanenceMessages = messages;
  }

  _renderMaintenanceMessages() {
    const messagesByOrg = this.maintanenceMessages.map(m => {
      return (
        <div
          className={styles.organizationContainer}
          key={`${m.orgId}${Date.now().toString()}`}
        >
          <div className={styles.organizationText}>{m.organization.name}</div>
          <div>
            {m.message.map(msg => {
              return (
                <div key={m.orgId} className={styles.messageContent}>
                  <div style={{ marginRight: 5 }}>
                    <p>
                      <span className={styles.titleText}>
                        {
                          localizationStore.currentLocal.shared
                            .maintenanceMessages.title
                        }
                      </span>
                      <em>{msg.title}</em>
                    </p>
                  </div>
                  <div style={{ marginRight: 5 }}>
                    <p>
                      <span className={styles.titleText}>
                        {
                          localizationStore.currentLocal.shared
                            .maintenanceMessages.message
                        }
                        :
                      </span>
                      <em>{msg.message}</em>
                    </p>
                  </div>
                  <div style={{ marginRight: 5 }}>
                    <p>
                      <span className={styles.titleText}>
                        {
                          localizationStore.currentLocal.shared
                            .maintenanceMessages.author
                        }
                        :
                      </span>
                      <em>{msg.author}</em>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
    return messagesByOrg;
  }

  render() {
    return (
      <React.Fragment>
        {this.maintanenceMessages.length > 0 ? (
          <div className={styles.messageContainer}>
            {this._renderMaintenanceMessages()}
          </div>
        ) : null}
        {this.props.children}
      </React.Fragment>
    );
  }
}

export const ErrorLogger = withRouter(BaseErrorLogger);
