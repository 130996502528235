import styles from "./tooltip-wrapper.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

const domElement = document.getElementById("content");
let idCount = 0;

export enum TooltipType {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Info = "info",
  Light = "light"
}

export enum TooltipPosition {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left"
}

export class Tooltip extends React.Component<{
  text: string;
  type?: TooltipType;
  position?: TooltipPosition;
  disable?: boolean;
  delayed?: boolean;
}> {
  tooltipId;
  domElement;

  constructor(props) {
    super(props);
    idCount += 1;
    this.tooltipId = `tooltip-id-${idCount}`;
  }

  cloneChild = element => {
    return React.cloneElement(element, {
      "data-for": this.tooltipId,
      "data-tip": this.props.text
    });
  };

  onLoadPortal = element => {
    if (element) {
      this.domElement = element;
    }
  };

  componentWillUnmount() {}

  render() {
    const disableTooltip =
      (this.props.text && this.props.disable) || !this.props.text;
    const count = React.Children.count(this.props.children);

    if (count === 0) {
      return null;
    }
    if (disableTooltip) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }

    let children;
    const optType = this.props.type ? { type: this.props.type } : {};
    const optPos = this.props.position ? { place: this.props.position } : {};
    const optDelayed = this.props.delayed ? { delayShow: 1000 } : {};
    const optProps = { ...optType, ...optPos, ...optDelayed };

    if (count === 1) {
      children = this.cloneChild(this.props.children);
    }
    if (count > 1) {
      const childlist = React.Children.toArray(this.props.children);
      children = [this.cloneChild(childlist[0]), ...childlist.slice(1)];
    }

    //console.log(this.props.text, idCount, optProps, count);

    return (
      <React.Fragment>
        {children}
        {ReactDOM.createPortal(
          <ReactTooltip
            id={this.tooltipId}
            {...optProps}
            multiline={true}
            ref={this.onLoadPortal}
            className={styles.customClass}
            html={true}
          />,
          domElement
        )}
      </React.Fragment>
    );
  }
}
