import {
  Our,
  CardsList,
  Type,
  Card,
  PersonnelList,
  Person,
  Access,
  AccessLog,
  ApplicationList,
  Documentation,
  DoorKeys,
  ExternalCards,
  Splash,
  Profile,
  Rights,
  Application
} from "../consts";

export function GetOurApplicationListUrl(employerId: number) {
  return Our + employerId + ApplicationList;
}

export function GetOurApplicationUrl(
  employerId: number,
  applicationId: number
) {
  return Our + employerId + Application + applicationId;
}

export function GetOurCardsListUrl(employerId: number, typeId: number) {
  return Our + employerId + CardsList + Type + typeId;
}

export function GetOurCardUrl(
  employerId: number,
  typeId: number,
  cardId: number
) {
  return Our + employerId + CardsList + Type + typeId + Card + cardId;
}

export function GetOurPersonnelListUrl(employerId: number) {
  return Our + employerId + PersonnelList;
}

export function GetOurPersonUrl(employerId: number, personId: number) {
  return Our + employerId + Person + personId;
}

export function GetOurDoorKeyApplicationsUrl(employerId: number) {
  return `${Our}${employerId}${DoorKeys}${ApplicationList}`;
}

export function GetOurDoorKeysUrl(employerId: number) {
  return `${Our}${employerId}${DoorKeys}`;
}

export function GetOurRightsUrl(employerId: number) {
  return Our + employerId + Rights;
}

export function GetOurPermittoUrl(employerId: number) {
  return Our + employerId + Splash;
}

export function GetOurProfileUrl(employerId: number) {
  return Our + employerId + Profile;
}

export function GetOurExternalCardsUrl(employerId: number) {
  return Our + employerId + ExternalCards;
}

export function GetOurAccessUrl(employerId: number) {
  return Our + employerId + Access;
}

export function GetOurAccessLogUrl(employerId: number) {
  return Our + employerId + AccessLog;
}

export function GetOurDoorKeyApplicationViewIdUrl(
  employerId: number,
  doorKeyApplicId: number
) {
  return `${Our}${employerId}${DoorKeys}${ApplicationList}${doorKeyApplicId}`;
}

export function GetOurDocumentationUrl(employerId: number) {
  return `${Our}${employerId}${Documentation}`;
}
