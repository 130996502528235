// import * as React from "react";
import { Local } from "./local";
import {
  OrganisationTemplate
  // CardType
} from "../../types";
import { NorRoutes } from "./no-routes";
import { NorShared } from "./no-shared";

export const Nor: Local = {
  language: "no",
  app: {
    constants: {
      SafetyCardText: (
        organisation: OrganisationTemplate,
        currentCardTypeId: number
      ) => {
        return currentCardTypeId === -1
          ? "kort"
          : organisation &&
              organisation.cardTypes &&
              organisation.cardTypes.find(
                c => c.cardTypeId === currentCardTypeId
              ).cardNameNo;
      },
      SafetyCardTextNeutral: "kort",
      CreatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} opprettet av ${statusSetByName}`,
      CreatedApplication: (statusSetByName: string) =>
        `Søknad opprettet av ${statusSetByName}`,
      CreatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Søknad opprettet av ${statusSetByName} og avventer attestering av ${attestorName}`,
      UpdatedApplication: (statusSetByName: string) =>
        `Søknad oppdatert av ${statusSetByName}`,
      UpdatedApplicationAwaitingAttestor: (
        attestorName: string,
        statusSetByName: string
      ) =>
        `Søknad oppdatert av ${statusSetByName} og aventer attestering av ${attestorName}`,
      UpdatedCard: (statusSetByName: string, cardName: string) =>
        `${cardName} oppdatert av ${statusSetByName}`,
      Issued: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Utstedt av ${statusSetByOrgEmp} ved ${statusSetByName}`,
      IssuedOnBehalf: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Utstedt på vegne av ${statusSetByOrgEmp} ved ${statusSetByName}`,
      OrgApprovedCard: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) =>
        `${cardName} opprettet av  ${statusSetByOrgEmp} ved ${statusSetByName}`,
      OrgApprovedCardOnBehalf: (
        statusSetByOrgEmp: string,
        statusSetByName: string,
        cardName: string
      ) =>
        `${cardName} opprettet på vegne av ${statusSetByOrgEmp} ved ${statusSetByName}`,
      OrgApprovedApplication: (
        caseManager: string,
        orgName: string,
        cardName: string
      ) =>
        `${cardName} behandlet av ${orgName} ved ${caseManager} og avventer utstedelse`,
      ReceiptReceived: "Kvittert mottatt",
      DoorkeyReserved: "Nøkkel reservert",
      OrgRejected: (statusSetByOrgEmp: string, statusSetByName: string) =>
        `Avvist av ${statusSetByOrgEmp} ved ${statusSetByName}`,
      GoneStale:
        "Bruker har ikke tatt aksjon på søknad, advarsel om sletting sendt",
      ApplicationCreated: "Søknad opprettet",
      EmpApproved: "Søknad attestert",
      EmpNotApproved: "Søknad ikke attestert",
      AwaitingEmpApproval: "Avventer attestering",
      EmpRejected: "Søknad avvist i attestering",
      EmpRejectedView: "Søknad avvist i attestering - ",
      EmpApprovedView: "Søknad attestert - ",
      Shredded: "Makulert",
      ApprovedUser: "Godkjent bruker",
      Invited: "Invitert",
      InvalidUser: "Ikke bruker",
      Loading: "Laster data. Vennligst vent",
      NoRowsToShow: "Ingen data",
      NoSafetycardsToShow: `Ingen kort funnet`,
      Filter: "Filter...",
      Equal: "Er lik",
      NotEqual: "Er ikke lik",
      LessThan: "Mindre en",
      LessThanOrEqual: "Mindre eller lik",
      GreaterThan: "Større enn",
      GreaterThanOrEqual: "Større eller lik",
      InRange: "Mellom",
      ClearFilter: "Fjern filter",
      ApplyFilter: "Filtrér",
      StartsWidth: "Begynner med",
      EndsWidth: "Slutter med",
      Contains: "Inneholder",
      NotContains: "Inneholder ikke",
      RowGroupColumnsEmptyMessage: "Dra hit for å sette radgrupper",
      Groups: "Radgrupper",
      PivotColumnsEmptyMessage: "Dra hit for å sette kolonnenavn",
      Pivots: "Kolonnenavn",
      Sum: "Sum",
      Count: "Antall",
      Min: "Min",
      Max: "Max",
      Average: "Gjennomsnitt",
      PinColumn: "Fest kolonne",
      PinLeft: "Fest venstre",
      PinRight: "Fest høyre",
      NoPin: "Ingen festet",
      AutosizeThiscolumn: "Autostørrelse på denne kolonnen",
      AutosizeAllColumns: "Autostørrelse på alle kolonner",
      ResetColumns: "Tilbakestill kolonner",
      ToolPanel: "Verktøy",
      PivotMode: "Pivot modus",
      ValueColumnsEmptyMessage: "Dra hit for å aggregere",
      Values: "Verdier",
      SearchOoo: "Søk...",
      SelectAll: "Velg alle",
      Copy: "Kopier",
      CtrlC: "Ctrl+C",
      CopyWithHeaders: "Kopier med kolonnenavn",
      Paste: "Lim inn",
      CtrlV: "Ctrl+V",
      Export: "Eksporter",
      CsvExport: "Eksporter Csv",
      ExcelExport: "Eksporter Excel",
      Columns: "Kolonner",
      DoorKeyCreated: "Ny søknad om nøkkel",
      DoorKeyReciptRecieved: "Godkjenn",
      DoorKeyOrganizationApproved: "Godkjent",
      DoorKeyOrganizationRejected: "Avvist"
    }
  },
  routes: NorRoutes,
  shared: NorShared,
  actions: {
    supportTitle: "Kontakt brukerstøtte",
    supportEmail: "app-support@verico.com",
    area: {
      personell: "personell",
      rights: "brukerrettigheter",
      access: "adganger",
      keys: "dørnøkler",
      application: "søknad",
      template: "mal",
      attestor: "attestantinfo",
      company: "selskap",
      stations: "stasjoner",
      user: "bruker",
      files: "filer",
      pendingTasks: "ventende oppgaver",
      pendingScCount: "ventende sikkerhetskort telling",
      pendingSC: "ventende sikkerhetskort",
      employer: "arbeidsgiver",
      employers: "arbeidsgivere",
      termsOfService: "terms of service",
      person: "person",
      preferredLanguage: "foretrukket språk",
      maintenanceMessages: "vedlikeholdsmeldinger",
      documents: "dokumenter",
      notifications: "varsler",
      image: "bilde",
      organization: "organisasjonen",
      attributeDataType: "attribute data types",
      applications: "søknader",
      assets: "sikkerhetskort anlegg",
      accessHistory: "adgangshistorikk"
    },
    error: {
      title: "Feil",
      uploadError: (area: string) =>
        `En feil har oppstått under opplasting av ${area}.`,
      updateError: (area: string, extraText?: string) =>
        `En feil har oppstått under oppdatering av 
        ${area}${extraText ? ` for ${extraText}.` : "."}`,
      fetchError: (area: string, extraText?: string) =>
        `En feil har oppstått under innlasting av 
      ${area}${extraText ? ` for ${extraText}.` : "."}`,
      sendError: (area: string) =>
        `En feil har oppstått under innsending av ${area}.`,
      deleteError: (area: string, extraText?: string) =>
        `En feil har oppstått under innlasting av 
      ${area}${extraText ? ` for ${extraText}.` : "."}`,
      approveError: (area: string) =>
        `En feil har oppstått under attestering av ${area}.`,
      acceptError: (area: String) =>
        `En feil har oppstått under akseptering av ${area}.`,
      saveError: (area: string) =>
        `En feil har oppstått under lagring av ${area}.`,
      inviteError: (area: String) =>
        `En feil har oppstått under invitering av ${area}.`,
      checkoutError: (area: string) =>
        `En feil har oppstått under utsjekking av ${area}.`,
      changeError: (area: string) =>
        `En feil har oppstått under endring av ${area}.`,
      activateError: (area: string) =>
        `En feil har oppstått under aktivering av ${area}.`,
      createError: (area: string) =>
        `En feil har oppstått under skaping av ${area}.`,
      logoutError: "En feil har oppstått under utlogging",
      unknownError: "En ukjent feil har oppstått"
    },
    contactSupport:
      "<p> Vennligst last inn siden på nytt og prøv igjen. Hvis feilen fortsatt skjer, vennligst kontakt support via <a href='mailto:app-support@verico.com'>denne eposten.</a></p>",
    doorKeys: {
      ErrorDiscardKey: "Kunne ikke kassere nøkkel.",
      ErrorSaveDoorKey: "Kunne ikke opprette nye nøkler.",
      ErrorReceiveDoorKey: "Kunne ikke bekrefte nøkkel mottatt.",
      ErrorUpdateDoorKey: "Kunne ikke oppdatere nøkkeldata.",
      ErrorReserveDoorKey: "Kunne ikke Reserver nøkkeldata.",
      ErrorGetDoorKeys: "Kunne ikke hente nøkler.",
      ErrorReturnKey: "Kunne ikke registrere nøkkel som returnert til lager.",
      ErrorLostKeyModalMessage: "Kunne ikke registrere nøkkel som mistet.",
      ErrorHistoryMessage: "Kunne ikke hente nøkkelhistorikk.",
      ErrorSignReturnKeyModalMessage: "Kunne ikke kvittere for mottatt nøkkel.",
      ErrorRecallKeyModalMessage: "Kunne ikke tilbakekalle nøkkel.",
      ErrorDeleteKeyModalMessage: "Kunne ikke slette nøkkel.",
      ErrorFetchingPersonOrgDoorKeys: "Kunne ikke hente nøkler.",
      ErrorFetchingPersonEmployerDoorKeys: "Kunne ikke hente nøkler."
    },
    otherCards: {
      ErrorNetworkOperation: "Nettverksfeil"
    }
  }
};
