import * as repo from "./../repo";
import { errorStore, organisationStore } from "../stores/index";
import { localizationStore } from "./../stores";
import { SafetyCard, Attribute } from "../types";
import { getSafetyCardLocalizedText } from "../data-utillities/safety-card-utils";

export function getSafetyCard(cardId: number): Promise<SafetyCard> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getCard(cardId),
    local.error.title,
    `${local.error.fetchError(getSafetyCardLocalizedText([], -1))}${
      local.contactSupport
    }`
  );
}

export function getOrgSafetyCards(
  orgId: number,
  shredded: boolean,
  includeAssets: boolean,
  cardTypeId: number,
  nodeIds?: number[]
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.getOrgSafetyCardsAndApplications(
        orgId,
        shredded,
        includeAssets,
        true,
        cardTypeId,
        nodeIds
      ),
    local.error.title,
    `${local.error.fetchError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(orgId)],
        -1
      )
    )}${local.contactSupport}`
  );
}

export function deleteSafetycard(cardId: number): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteSafetycard(cardId),
    local.error.title,
    `${local.error.deleteError(getSafetyCardLocalizedText([], -1))}${
      local.contactSupport
    }`
  );
}

export function getApplicationWithToken(cardId: number, token: string) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getApplicationWithToken(cardId, token),
    local.error.title,
    `${local.error.fetchError(getSafetyCardLocalizedText([], -1))}${
      local.contactSupport
    }`
  );
}

export function getUserSaftycards(
  personnelId: number,
  includeShredded: boolean
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getUserSaftycards(personnelId, includeShredded),
    local.error.title,
    `${local.error.fetchError(getSafetyCardLocalizedText([], -1))}${
      local.contactSupport
    }`
  );
}

export function updateLiveSafetycard(card: SafetyCard): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postUpdateLiveSafetycard(card),
    local.error.title,
    `${local.error.saveError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(card.orgId)],
        card.cardTypeId
      )
    )}${local.contactSupport}`
  );
}

export function updateSafetycard(card: SafetyCard): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putSafetycard(card),
    local.error.title,
    `${local.error.saveError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(card.orgId)],
        card.cardTypeId
      )
    )}${local.contactSupport}`
  );
}

export function getOrgUserSaftycards(
  orgId: number,
  personnelId: number,
  includeShredded: boolean
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgSafetyCardsForPerson(orgId, personnelId, includeShredded),
    local.error.title,
    `${local.error.fetchError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(orgId)],
        -1
      )
    )}${local.contactSupport}`
  );
}

export function updateSafetycardAssets(card: SafetyCard): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putSafetycardAssets(card.cardId, card.nodes),
    local.error.title,
    `${local.error.saveError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(card.orgId)],
        card.cardTypeId
      )
    )}${local.contactSupport}`
  );
}

export function patchSafetycardAttribute(
  cardId: number,
  attributes: Attribute[]
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () =>
      repo.patchSafetycardAttribute(
        cardId,
        attributes.map(a => {
          return {
            attributeId: a.attributeId,
            value: a.value,
            attachmentId: a.attachmentId
          };
        })
      ),
    local.error.title,
    `${local.error.saveError(getSafetyCardLocalizedText([], -1))}${
      local.contactSupport
    }`
  );
}

export async function saveSafetyCard(
  card: SafetyCard,
  issueCard: boolean,
  comment: string
): Promise<SafetyCard> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () => {
      // Our new card
      let newCard = await repo.postSafetyCard(card.personnelId, card);
      // Now we can post assets
      if (card.cardTypeId !== 3) {
        await repo.putSafetycardAssets(newCard.cardId, card.nodes);
      }

      // We might want to issue it right awayd
      if (issueCard) {
        await repo.putOrganisationIssueApplication(
          newCard.orgId,
          newCard.cardId,
          comment
        );
        newCard = await repo.getCard(newCard.cardId);
      }

      return newCard;
    },
    local.error.title,
    `${local.error.saveError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(card.orgId)],
        card.cardTypeId
      )
    )}${local.contactSupport}`
  );
}
