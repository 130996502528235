import * as React from "react";
import { LocalShared } from "./local-shared";
import {
  // OrganisationTemplate    ,
  CardType
} from "../../types";

export const NedShared: LocalShared = {
  expertiseList: {
    greenStatus: "OK",
    yellowStatus: "Verloopt binnenkort",
    redStatus: "Verlopen",
    unknownStatus: "Onbekend",
    missingStatus: "Vermist",
    masterGridColumns: {
      status: "Toestand",
      type: "Type",
      expire: "Vervallen",
      count: "# documenten",
      requiredBy: "Vereist door"
    }
  },
  documentations: {
    documentation: "Documentatie",
    info: "Er is geen bestand aan deze documentatie gekoppeld. De documentatie bestaat uit een datum en referentie voor een mogelijke cursus",
    date: "Geldig vanaf:",
    confirmComment: "Documentatie Referentie:",
    description: "Optionele opmerking:"
  },
  statuslog: {
    header: "Geschiedenis",
    name: "Naam:",
    number: "Telefoon:",
    comment: "Commentaar:",
    title: "Titel:",
    email: "E-mail:",
    validFrom: "Geldig vanaf",
    validTo: "Geldig voor",
    assetList: "Constructie List",
    cardChanged: (cardName: string) => `${cardName} gewijzigd`,
    oldValue: (val: string) => `Oude waarde: ${val}`,
    newValue: (val: string) => `Nieuwe waarde: ${val}`,
    assetCount: (val: string) => `Aantal planten - ${val}`
  },
  batchUpdate: {
    AlertTitle: "Er is een fout opgetreden",
    AlertText: "Er is een fout opgetreden tijdens het verzenden",
    Cancel: "Annuleren",
    Done: "Gedaan"
  },
  admin: {
    rights: {
      ReadAccess: "Leestoegang",
      WriteAccess: "Schrijftoegang",
      PersonPlaceHolder: "Zoek naar persoon om toe te voegen",
      Name: "Naam",
      EmptySearch: "Geen hits",
      RemoveHeader: "Afgelegen",
      Live: "Leven",
      Daily: "Dagelijks",
      Weekly: "Wekelijks",
      Monthly: "Måntlig"
    }
  },
  asset: {
    popup: {
      EmptyStation: "Niemand in het station",
      AltImg: "Station Photo",
      NameCol: "Naam",
      AreaCol: "Gebied",
      EntriesCountCol: "#",
      TableFooter: "Overzicht van stationpersoneel",
      EntryText: "Mensen ingecheckt",
      MissingInfo: "Ontbrekende informatie",
      ShowInfo: "Informatie tonen",
      HideInfo: "Verbergen informatie"
    },
    userpopup: {
      travelingTo: "Reizen naar",
      registeredAt: "Geregistreerd bij",
      lastKnownPosition: "Laatst bekende positie",
      timestamp: "Laatst gerapporteerde positie op",
      username: "Gebruiker",
      station: "station"
    }
  },
  buttons: {
    ConfirmTitle: "Wilt u de inzending beëindigen?",
    ConfirmText: "Wijzigingen worden niet opgeslagen.",
    Back: "Terug",
    changeSplitAlignment: "Inzendingen / kaart"
  },
  dialog: {
    Cancel: "Annuleren",
    NoComment: "Geen commentaar",
    Yes: "En",
    No: "In",
    Confirm: "Voortzetten",
    InputText: "Commentaar",
    Ok: "OK",
    DeleteRightRowTitle: "Rij verwijderen",
    DeleteRightRowText:
      "Hiermee worden de rijen en machtigingen erop verwijderd"
  },
  editIndBar: {
    Required: "Je moet invullen"
  },
  employer: {
    Accesses: "Adganger",
    Safetycards: (cardName: string) => `Onze ${cardName.toLowerCase()}`,
    Personnel: "Ons personeel",
    NewApplication: "Nieuwe applicatie",
    Applications: "Onze toepassingen",
    Profile: "Ons profiel",
    UserAccess: "User management",
    OrgPlaceHolder: "Reg.",
    EmployerPlaceHolder: "Zoeken op bedrijf",
    EmployerSearchPlaceHolder: "Zoeken op bedrijf"
  },
  personDocuments: {
    Header: "",
    ErrorTitle: "Verkeerd",
    ErrorMessage: "Kan document niet ophalen",
    NoDocumentsMessage: (companyName: string, cardName: string) =>
      `Deze persoon heeft geen ${cardName.toLowerCase()} geassocieerd met ${companyName} en daarom is er geen documentatie beschikbaar`
  },
  error: {
    HeaderText: "Netwerkfout.",
    Details: "Toon gedetailleerd foutbericht",
    Retry: "Probeer het opnieuw"
  },
  templates: {
    card: {
      card: {
        Section: "Sectie",
        SubSection: "Op grond van artikel",
        Header: (cardName: string) => `${cardName} - Mal`
      },
      safetyCardAttribute: {
        requiredForCard: "Vereiste waarde",
        type: "Type",
        true: "EN",
        false: "IN",
        dependanciesToolTip: "Vordering",
        dependancyNotFound: attributeID => {
          return `Het vereiste kenmerk met ID ${attributeID} is niet gevonden in de geselecteerde sjabloon.`;
        },
        required: "Nodig"
      }
    }
  },
  file: {
    handle: {
      DocReqOwner: "Vereist door",
      ColRequired: "Nodig",
      Expires: "Vervalt",
      ColFileName: "Bestandsnaam",
      ColAction: "Acties",
      ColReference: "Referentie",
      ColOrg: "Constructie Victory",
      ColOptComment: "Optionele reactie",
      ColComment: "Commentaar",
      ColName: "Naam",
      ColDate: "Geldig vanaf",
      ColValidDays: "Dagen geldig",
      ColDateUploaded: "Geüploade",
      ColDocumentRequirement: "Dokumentkrav",
      ConfirmDeleteTitle: "Document verwijderen",
      ConfirmDeleteText: "Hiermee wordt het document verwijderd",
      MyFiles: "Mijn documentatie",
      NoFile: "Geen bestand",
      DocumentsNotFound: "Geen documenten gevonden",
      Choose: "Kiezen",
      FileToBigTitle: "Er is een fout opgetreden",
      FileToBigText: "Bestand is te groot",
      DropZoneText: "Zet hier een document neer om het te uploaden",
      SaveDemands:
        "U moet een bestand en een beschrijving selecteren om op te slaan",
      Upload: "Upload",
      AddDocumentation: "Documentatie toevoegen",
      AddNewDocumentationWithoutFile: "Voeg documentatie toe zonder bestand",
      DeleteFile: "Verwijder bestand(en)",
      CannotDelete:
        "Kan bestand niet verwijderen omdat het wordt gebruikt in een beveiligingskaart-applicatie",
      ShowFile: "Draad schroef",
      DocumentRequirements: "Dokumentkrav",
      RequirementCovered: "Vereisten gedekt",
      SelectDocumentRequirement: "Selecteer het documenttype",
      SelectDate: "Stel een startdatum in voor de geldigheidsperiode",
      SelectDocument: "Upload nog een document",
      SelectDocumentRequirementHeader: "Documentatie uploaden",
      Cancel: "Annuleren",
      Select: "Kies documentatie",
      SelectNoFile: "Bewaar documentatie",
      SelectDatePlaceholder: "Kies datum",
      UploadedBy: "Geupload door",
      confirmationComment: "Documentatie Reference",
      confirmationCommentMissing: "Documentatiereferentie is vereist",
      isUploading: "Uploaden ...",
      requiredBy: "Vereist door",
      UploadInfoHeader: (reqName: string) => {
        return React.createElement(
          React.Fragment,
          null,
          "Her kan du laste opp",
          React.createElement("i", null, reqName, "."),
          React.createElement("br", null),
          React.createElement("br", null)
        );
      },
      UploadInfoText: (reqName: string, reqValid: number) => {
        let expiresText = `${reqValid} dagen.`;
        if (reqValid > 365) {
          const years = reqValid / 365;
          const rounded = Math.round(years * 10) / 10;
          expiresText = `${rounded} jaar.`;
        }

        return React.createElement(
          React.Fragment,
          null,
          React.createElement("i", null, reqName),
          "heeft een geldigheid van \\ xE5",
          expiresText,
          React.createElement("br", null),
          "Het is daarom vereist dat u de eerste geldige datum van het document instelt (",
          React.createElement(
            "i",
            null,
            "dit kan bijvoorbeeld de datum zijn waarop de cursus is gevolgd"
          ),
          "). "
        );
      },
      AllowConfirmationWithoutFileHelpText:
        "Als er geen fysieke documentatie is (gemeenschappelijke cursus etc.), is het mogelijk om documentatie te registreren zonder een document te uploaden. Als dit het geval is, moet de datum en een referentie voor de cursus worden vermeld.",
      NoFileExistsGridLabel: "- Geen bestand -",
      editImageButton: "Uitgeven",
      MissingDate: "Datum ontbreekt",
      MissingDocRef: "Referentie documentatie ontbreekt"
    }
  },
  header: {
    Personnel: "Personeel",
    ApproveApplication: "Aanvraag goedkeuren",
    IssueApplication: (cardName: string) =>
      `Geef ${cardName.toLowerCase()} uit vanuit de toepassing`,
    AdminAsset: "Beheer voorzieningen",
    ConfirmUser: "Bevestig nieuwe gebruiker",
    AccessHistory: "Adgangshistorikk",
    MyAccessHistory: "Mijn toegangsgeschiedenis",
    OurAccessHistory: "Onze toegangsgeschiedenis",
    Accesses: "Adganger",
    MyAccess: "Mijn toegang",
    MyCards: (cardName: string) => `Mijne ${cardName.toLowerCase()}`,
    OurCards: (cardName: string) => `Onze ${cardName.toLowerCase()}`,
    OurPersonnel: "Ons personeel",
    OurProflie: "Ons profiel",
    OurOtherCards: "Onze HSE-kaart",
    OurAccess: "Onze toegang",
    MyProfile: "Mijn profiel",
    MyApplications: "Mijn toepassingen",
    MyApplication: "Mijn sollicitatie",
    OurApplications: "Onze toepassingen",
    OurApplication: "Onze applicatie",
    IssuedApplications: "Aanvraag indienen",
    ConfirmedApplications: "Aanvraag goedkeuren",
    ApplicationsForApproval: "Aanvragen voor goedkeuring",
    ApplicaitonsForIssue: (cardName: string) => `${cardName} om uit te geven`,
    UserRights: "Gebruikersrechten - organisatie",
    GroupRights: "Onze gebruikersrechten",
    MyPermitto: "Zorg min",
    ServiceProvider: (empName: string) =>
      empName ? `Leverancier - ${empName}` : `Leverancier`,
    AssetOwner: (orgName: string) => `Planteneigenaar - ${orgName}`,
    Owner: "Constructie Victory",
    ConfirmApplication: "Testimonials Aanvraag voor",
    Employers: "Leveranciers",
    MyOtherCards: "Safety cards",
    AdminOtherCards: "Safety cards",
    DoorkeyApplications: "Belangrijkste applicaties",
    OurDoorkeyApplications: "Onze belangrijkste toepassingen",
    MyDoorkeyApplications: "Mijn belangrijkste toepassingen",
    Doorkeys: "Sleutels",
    OurDoorkeys: "Onze sleutels",
    MyDoorkeys: "Mijn sleutels",
    Documentation: "Documentatie",
    OurDocumentation: "Onze documentatie",
    Projects: "Projecten",
    OrganisationTemplates: "Sjablonen bewerken",
    OrganisationDoorkeyTemplates: "Sjablonen bewerken",
    OrganisationAssetMessages: "Constructie berichten"
  },
  numberLookup: {
    UserExists: (
      firstName: string,
      lastName: string,
      organisationName?: string
    ) => {
      return organisationName
        ? `${firstName} ${lastName} is al een gebruiker onder ${organisationName}`
        : `${firstName} ${lastName} is al een gebruiker`;
    },
    Invitation: (firstName: string, lastName: string) => {
      return `${firstName} ${lastName} is al een gebruiker in Permitto, u kunt ze hieronder uitnodigen.`;
    },
    UserNotFound: "Gebruiker niet gevonden",
    InvalidPhone: "Telefoonnummer is niet geldig",
    FirstName: "Voornaam",
    LastName: "Achternaam",
    Company: "Partij",
    CreateUser: "Maak een nieuwe gebruiker aan",
    Invite: "Uitnodigen",
    Ok: "OK",
    NumberPlaceHolder: "Voer mobiel nummer in",
    Cancel: "Annuleren",
    SubmitMobileNr: "Voer het mobiele nummer van de nieuwe gebruiker in"
  },
  newApplication: {
    Warning: "Waarschuwing",
    ConfirmClose: "Weet u zeker dat u wilt annuleren?",
    SafetyCardApplication: (cardName: string) =>
      `Toepassing voor ${cardName.toLowerCase()}`,
    CloseApplicationTitle: "Toepassing annuleren",
    CloseApplicationText:
      "Weet u zeker dat u de aanvraag wilt annuleren en weigeren?",
    Cancel: "Annuleren",
    DoorkeyApplicationTitle: "Nieuwe applicatie voor sleutel"
  },
  newCardNoApplic: {
    CreateNewCard: "Maak een nieuwe kaart",
    CloseApplicationTitle: "Annuleer het maken van kaarten",
    CloseApplicationText:
      "Weet u zeker dat u het maken van een nieuwe kaart wilt annuleren?",
    Cancel: "Annuleren",
    Warning: "Waarschuwing",
    ConfirmClose: "Weet u zeker dat u wilt annuleren?"
  },
  person: {
    FirstName: "Voornaam",
    LastName: "Achternaam",
    Email: "E-mail",
    Mobile: "De auto",
    Company: "Partij",
    Back: "Terug",
    Edit: "Uitgeven",
    EditMode: "Edit Mode",
    Save: "Save",
    Cancel: "Annuleren",
    ChangesMade: {
      text: "Als u doorgaat, gaan wijzigingen verloren",
      title: "Veranderingen gemaakt.\nWil je doorgaan?"
    }
  },
  personList: {
    AlertTitle: "Er is een fout opgetreden",
    AlertText:
      "Er is een fout opgetreden tijdens het verzenden van uitnodigingen",
    Cancel: "Annuleren",
    SendInvitations: (numOfInvitations: number) => {
      return numOfInvitations === 1
        ? `Verzend ${numOfInvitations} uitnodiging`
        : ` Verzend ${numOfInvitations} uitnodigingen`;
    },
    Send: fromOrg =>
      fromOrg ? `Stuur sms naar ontvanger` : ` Stuur e-mail naar ontvanger`,
    Invitations: "Uitnodigingen",
    InvitationsSent: "Uitnodigingen verzonden",
    Done: "Gedaan",
    FirstName: "Voornaam",
    LastName: "Achternaam",
    FullName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    Email: "E-mail",
    Mobile: "De auto",
    Company: "Partij",
    Invited: (firstName: string, lastName: string) =>
      `${firstName} ${lastName} invitert`,
    InvitedTo: (firstName: string, lastName, orgName: string) =>
      `${firstName} ${lastName} invitert tot ${orgName}`,
    NewPersonHeader: "Maak een nieuwe persoon",
    Save: "Creëren",
    CreateNew: "Maak nieuw",
    Invite: (numOfPersons: number) => {
      return numOfPersons === 1
        ? `Nodig ${numOfPersons} persoon uit`
        : ` Nodig ${numOfPersons} personen uit`;
    },
    Persons: "Metgezel",
    LastNamePlaceholder: "Zoeken op achternaam",
    Open: "Open",
    SelectAPerson: "Je moet een persoon kiezen",
    SelectJustOnePerson: "U moet slechts 1 persoon selecteren",
    SendInvitationsDescription:
      "Weet u zeker dat u de geselecteerde mensen wilt uitnodigen voor de organisatie?"
  },
  safetycard: {
    Application: "Toepassing",
    Add: "Toevoegen aan",
    InputPlaceholder: "Niet ingevuld",
    Shredded: "Versnipperd",
    Expired: "Verlopen",
    Sign: (cardName: string) => `Klik om ${cardName} ontvangen te bevestigen`,
    NotSigned: "Niet bevestigd ontvangen",
    SafetyCard: (cardName: string) => cardName,
    Card: (cardId: number, cardName: string) => `${cardName}: ${cardId}`,
    Access: "Toegang List",
    LoadingAssets: "Plant wordt geladen ...",
    CardOwner: (cardName: string) => `${cardName} houder`,
    Issued: "Uitgegeven",
    Signed: "Bevestiging ontvangen",
    ValidFrom: "Geldig vanaf",
    DatePlaceholder: "Kies datum",
    ReceiptReceived: "Ontvangstbewijs ontvangen",
    ValidTo: "Geldig voor",
    UploadDate: "Upload datum",
    ValidFromWithColon: "Geldig vanaf:",
    ValidToWithColon: "Geldig voor:",
    UnknownDataType: "Onbekend gegevenstype",
    Attestor: (name: string, email: string) => `Attestant: ${name} (${email})`,
    ChangedBy: "Gewijzigd door",
    ReadyForIssuing: "Klaar om uit te geven",
    NotFilled: "Niet voltooid",
    EmployerName: "Werkgever",
    HeadOfOperations: "Operations Manager",
    IssuedOnBehalfOf: (issuerName: string, headOfOperations: string) =>
      `Uitgegeven door ${issuerName} namens Operations Manager ${headOfOperations}`,
    postIssueSections: {
      clickToApprove: "Klik om goed te keuren",
      missingTrainingHeader: "Vereisten ontbreken",
      missingTrainingHeaderForAccess: "Vereisten ontbreken voor toegang",
      missingConditionalTrainingHeader: "Voorwaardelijke documentatie",
      requirmentsNeedToBeFulfilled:
        "Dit zijn vereisten waaraan moet worden voldaan voordat de kaart geldig is",
      requirmentsNeedToBeFulfilledForAsset:
        "Dit zijn vereisten waaraan moet worden voldaan om toegang te registreren voor sommige faciliteiten op deze kaart",
      requirmentsNeedToBeFulfilledThisAsset:
        "Dit zijn vereiste (n) waaraan moet worden voldaan om toegang tot dit activum te registreren"
    }
  },
  doorkeyCardApplication: {
    SendPageText:
      "Uw belangrijkste toepassing is klaar om te worden verzonden. Als u een opmerking wilt achterlaten, kan dit hieronder.",
    AddPlaceholder: "Voeg eventuele opmerkingen toe aan de toepassing",
    MissingFeilds: "Je moet een reactie achterlaten en op Verzenden klikken.",
    Back: "Terug",
    Done: "Gedaan",
    Send: "Sturen",
    ForPerson: "Kies de persoon waarnaar u wilt zoeken namens",
    Next: "Hierin",
    ApplyForCard: "Met welke planteneigenaar wilt u een sleutel aanvragen?",
    ApplicationForm: "Toepassing",
    ApplicationSummary: "Toepassingsinfo",
    Submit: "Onderwerping",
    Applicant: "Seeking",
    Issuer: (cardName: string) => `${cardName} uitgever`,
    doorkeyCardApplicationText: "Aanvraag voor sleutel"
  },
  cardIssueNoApplication: {
    CardOwner: "Kaarthouder",
    FindByMobile:
      "Voer het mobiele nummer in van de persoon aan wie de kaart moet worden uitgegeven",
    UserNotFound: (inOrg: boolean) =>
      inOrg
        ? "Gebruiker gevonden in permitto maar in een andere organisatie. U kunt alleen kaarten uitgeven aan gebruikers in uw eigen organisatie."
        : "Gebruiker niet gevonden, vul de velden in om een nieuwe gebruiker aan te maken. Opmerking: zorg ervoor dat het telefoonnummer en e-mailadres correct zijn.",
    UserFound: "Gebruiker gevonden",
    FirstName: "Voornaam:",
    LastName: "Achternaam:",
    Mobil: "Mobile:",
    Email: "E-mail:",
    Next: "Hierin",
    Applicant: "Kaarthouder:",
    AuthorizationField: "Toelatingen",
    Valid: "Geldigheid:",
    Issuer: (cardName: string) => `${cardName} uitgever`,
    Submit: "Uitgifte",
    ApplicationSummary: "Beknopte informatie",
    ApplicationForm: "Kaart vullen",
    ReadyToSend:
      "De kaart is klaar om te worden uitgegeven. Vul eventuele opmerkingen in en klik op probleem.",
    AddPlaceholder: "Voeg eventuele opmerkingen bij het probleem toe",
    Back: "Terug",
    Done: "Gedaan",
    Send: "Uitgevende",
    Retry: "Uitgevende weer",
    CreateNewPersonHeader: (
      cardholderFistName: string,
      cardholderLastName: string
    ) =>
      `${cardholderFistName} ${cardholderLastName} wordt aangemaakt als een nieuwe gebruiker in Permitto`,
    CreateNewPerson: (cardholderFistName: string, cardholderLastName: string) =>
      `${cardholderFistName} ${cardholderLastName} zal worden aangemaakt als een nieuwe gebruiker, en u kunt dan doorgaan met het uitgeven van kaarten aan hen. Wilt u doorgaan? `
  },
  safetyCardApplication: {
    GivesAccessConflict:
      "Sommige van de voorzieningen die u zoekt, hebben dit kruis als vereiste voor toegang. Als u dit kruispunt verwijdert, hebt u geen toegang tot de faciliteit.",
    TreeHelpFirst:
      "Hier kunt u kiezen waartoe u toegang wilt.\nU kunt een 'groep' planten selecteren of u kunt door de boom bladeren om een enkele plant te selecteren.\nToegang tot een groep geeft toegang tot alles wat eronder ligt.",
    TreeHelpSecond:
      "U selecteert een plant door deze te markeren door het selectievakje aan te vinken.",
    TreeHelpThird: "U bladert door de boom door op de pijl te drukken.",
    TreeHelpFourth: "Demo weergeven",
    TreeAttestorHelpText:
      "Hier kunt u zien voor welke toepassingen een aanvraag is ingediend. U kunt door de boom navigeren door op de pijlen te drukken",
    EqualAsset: " = Plant",
    EqualGroup: " = Groep",
    WhereToApply: "Waar vraag je toegang aan?",
    AppliedTo: "Er wordt toegang gezocht",
    FileToBigTitle: "Er is een fout opgetreden",
    FileToBigText: "Bestand is te groot",
    Cancel: "Annuleren",
    MyFiles: "Mijn bestanden",
    FileOwner: " hun bestanden",
    ApplicationApproved: "De aanvraag is gecertificeerd",
    ApplicationDenied: "De aanvraag is afgewezen",
    WrongNumber: "Telefoonnummers moeten 8 nummers bevatten",
    ApproverMissing: "U moet alle informatie over het certificaat invullen",
    ValidEmail: "U moet een geldig e-mailadres in het attest-veld invoeren",
    ValidEmailHeader: "Het e-mailadres van de attestant is niet geldig",
    CardOwner: "Zoeken",
    ValidFrom: (date: string) => `Geldig vanaf ${date}`,
    SelectDatePlaceholder: "Kies datum",
    ValidTo: "Geldig voor",
    Next: "Hierin",
    SearchForLastName: "Zoeken op achternaam",
    SelectFile: "Voeg documentatie bij",
    UploadFile: "Document uploaden",
    UploadDate: "Geüpload",
    ChangeFile: "Wijzig document",
    NewFile: "Nieuw document",
    OpenFile: "Open document",
    NoFile: "Geen document",
    Loading: "Loading ..",
    Missing: "Moet worden ingevuld",
    UnknownDataType: "Onbekend gegevenstype",
    ReadyToSendWithAttestor: (cardName: string) => (
      <span>
        {" "}
        {`Voordat de aanvraag wordt ingediend, moet deze worden gecertificeerd. Het doel van deze stap is om
                      een bevestiging verkrijgen van een persoon die de aanvrager kent en wie dat kan
                      Bevestig dat de aanvrager geschikt en gekwalificeerd is om vast te houden
                      ${cardName.toLowerCase()} met de gevraagde rechten. Het certificaat zal
                      De meeste gevallen zijn de ouder van de aanvrager, maar kunnen ook andere zijn
                      wat goed kan gaan voor de aanvrager. De attestant wordt ook gevraagd om die aanvraag te controleren
                      is correct ingevuld en die vereiste documentatie is geldig en bijgevoegd
                      applicatie. Het certificaat hoeft geen gebruiker te zijn in Permitto`}
        <br />
        <b>{`Het certificaat is zelden dezelfde persoon als waarnaar ${cardName.toLowerCase()} wordt gezocht .`}</b>
      </span>
    ),
    WillNotBeSetBeforeAttested: (cardName: string) =>
      `NB. De applicatie wordt pas naar de uitgever van ${cardName.toLowerCase()} gestuurd als deze is gecertificeerd. Controleer daarom of de e-mail aan de attestant correct is.`,
    FillAttestorInfo: "Gelieve informatie over de attestant in te vullen",
    SelfAttesting:
      "Ik wil in deze toepassing worden vermeld als een certificaat",
    ReadyToSend:
      "De applicatie is klaar om te worden verzonden. Vul eventuele opmerkingen in en klik op Verzenden.",
    AddPlaceholder: "Voeg eventuele opmerkingen toe aan de toepassing",
    AttestorNameTitle: "Naam",
    AttestorEmailTitle: "Attestant e-mail",
    AttestorFirstnameTitle: "Voornaam voornaam",
    AttestorLastnameTitle: "Attestant achternaam",
    AttestorTitleTitle: "Certificaat van titel",
    AttestorPhoneTitle: "Attestant telefon",
    Back: "Terug",
    Done: "Gedaan",
    Send: "Sturen",
    ApplyForCard: (cardName: string) =>
      `Waar wilt u ${cardName.toLowerCase()} zoeken?`,
    IssueCard: (cardName: string) =>
      `Waar wilt u ${cardName.toLowerCase()} uitgeven?`,
    ApplyForWhichCard: (cardName: string) =>
      `Welk type ${cardName} vraagt u aan?`,
    AllMissing: "U moet alle velden invullen",
    ForPerson: "Kies de persoon waarnaar u wilt zoeken namens",
    AuthorizationReadOnly: "De volgende autorisaties worden aangevraagd",
    Authorization: (numAssets: number) =>
      numAssets > 0
        ? `Selecteer machtigingen die u wilt toepassen (machtigingen voor toegang tot eerder geselecteerde stations worden automatisch ingevuld)`
        : `Selecteer machtigingen die u wilt toepassen`,
    ApplicationSummary: "Toepassingsinfo",
    Valid: "Geldigheid:",
    ApplicationForm: "Toepassing",
    Access: "Toegang",
    AssetLowerCase: (numAssets: number) => `${numAssets} faciliteit`,
    AuthorizationField: "Toelatingen",
    Submit: "Onderwerping",
    FillInAllMarked: "Vul alle velden in gemarkeerd met *",
    FillInAccessLogg:
      "Selecteer de faciliteit waarvoor u toegang wilt aanvragen",
    FillInDates: "Vul alstublieft 2 geldige data in",
    Reject: "Afwijzen",
    Approve: "Attest",
    AttestorInfo: "Officieel te bevestigen",
    AttestorInfoMessage1: (
      fistName: string,
      lastName: string,
      orgName: string,
      cardName: string
    ) =>
      `${fistName} ${lastName} heeft een toepassing gemaakt voor ${cardName.toLowerCase()} op ${orgName}. `,
    AttestorInfoMessage2: (orgName: string) =>
      `${orgName} vereist dat alle applicaties worden geverifieerd voordat ze worden verzonden`,
    AttestorInfoMessage3: (cardName: string) =>
      `U bent aangewezen als een certificaat voor deze toepassing. ` +
      ` Het doel van deze stap is om een bevestiging te krijgen van een persoon die de aanvrager kent en kan bevestigen dat de aanvrager geschikt en gekwalificeerd is om ${cardName.toLowerCase()} te bezitten met de aangevraagde autorisaties. ` +
      ` Als certificaat wordt u ook gevraagd om te controleren of de aanvraag correct is ingevuld en of de vereiste documentatie geldig is en bij de aanvraag is gevoegd. ` +
      ` Door te certificeren, worden uw contactgegevens opgeslagen in de applicatie zodat de therapeut de mogelijkheid heeft om contact met u op te nemen als er vragen zijn. ` +
      ` \\ nDe volgende informatie wordt door de aanvrager van u geregistreerd: `,
    AttestorInfoMessage4:
      "Controleer of de informatie die op u is geregistreerd correct is en breng indien nodig wijzigingen aan.",
    Attest: "Certificaat",
    StartAttesting: "Start certificering",
    AttestorMessage1:
      "U kunt nu kiezen om de aanvraag te certificeren of te weigeren.",
    AttestorMessage2: (fistName: string, lastName: string) =>
      `Als u de toepassing weigert, wordt deze teruggestuurd naar ${fistName} ${lastName}, die wijzigingen kan aanbrengen en opnieuw kan indienen. `,
    AttestorMessage3: (orgName: string) =>
      `Als u Certificaten selecteert, wordt de toepassing ter verwerking naar ${orgName} verzonden.\n      In beide gevallen kunt u opmerkingen toevoegen die bij de aanvraag worden gevoegd`,
    AttestorConfirmSane: (cardName: string) =>
      `Door te bevestigen, verklaart u dat de aanvrager geschikt en gekwalificeerd is om ${cardName.toLowerCase()} te bezitten met de aangevraagde autorisaties.`,
    Applicant: "Seeking",
    Issuer: (cardName: string) => `${cardName} problemen:`,
    ValidFromDate: "Geldig vanaf:",
    ValidToDate: "Geldig voor:",
    Comment: "Commentaar",
    ContinueApplication: "Voortzetten",
    CardNumber: (cardName: string) => `${cardName} nummer`,
    Previewtemplate: (cardName: string) => `Vis ${cardName.toLowerCase()}`,
    ApplyFromExistingCardHeaderText: (cardName: string) =>
      `Selecteer de ${cardName.toLowerCase()} die u naar deze toepassing wilt kopiëren. \\ nHoud er rekening mee dat de documentatievereisten nog moeten worden ingevuld. `,
    CreateNewSafetyCard: "Lege aanvraag",
    CopyExistingSafetyCard: (cardName: string) =>
      `Kopieer bestaande ${cardName.toLowerCase()} naar deze applicatie`,
    FileValidFrom: "Geldig vanaf",
    FileValidTo: "Geldig voor",
    FileUploadComment: "Commentaar",
    FileName: "Bestandsnaam",
    ExpireMessage:
      "Waarschuwing: dit document is niet geldig voor de gehele periode waarvoor een beveiligingskaart is aangevraagd",
    Retry: "Opnieuw verzenden",
    cardTypeName: (cardTemplate: CardType, createdfromApplic: boolean) =>
      createdfromApplic
        ? `${cardTemplate.cardNameNo} - Gemaakt van applicatie`
        : cardTemplate.cardNameNo,
    ApplicantInfo: "Informatie over de aanvrager",
    ApplicantInfoIssued: "Informatie kaarthouder",
    ApplicantName: (firstName: string, lastName: string) =>
      `${firstName} ${lastName}`,
    Application: "Toepassing",
    SafetyCard: "Veiligheidskaart",
    SafetyCardStatusLog: "Wijzigingsgeschiedenis van applicaties",
    SafetyCardStatusLogNoApplic: (cardTemplate: CardType) =>
      `${cardTemplate.cardNameNo} geschiedenis van etswijziging`,
    AttestorEmailSent: "E-mail opnieuw verzonden",
    AttestorEmailSentInfo: "E-mail is opnieuw naar het attest verzonden",
    AttestorLink: "Attestorlink",
    CopyToClipboard: "Kopiëren naar klembord",
    Status: "Status:",
    Name: "Naam:",
    LastApplicChangeDate: "Laatste wijziging:",
    ApplicationAttestorInfo: (cardName: string) =>
      `Hieronder vindt u de aangevraagde ${cardName} en de bijbehorende applicatie. Zie alsjeblieft dat de applicatie redelijk lijkt.`,
    AttestApplication: "Attest-aanvraag",
    CloseWindow: "U kunt nu de pagina sluiten",
    CompleteAttestation: "Volledige verklaring",
    ApplicInfoNoApplic: "Velden uit applicatiesjabloon",
    Reference: "Referentie",
    StatusExpired: "Verlopen",
    StatusValid: "OK",
    ExpireDate: "Uiterste houdbaarheidsdatum",
    SelectedDocument: "Geselecteerd document",
    NoneFileJustRef: "Geen bestand, alleen referentie",
    DropdownHeader: "Opgeslagen documenten",
    IssueOnBehalfOfDisabledTooltipText:
      "De eigenaar van de fabriek heeft de mogelijkheid uitgeschakeld om namens anderen te zoeken"
  },
  safetyCardAssets: {
    EnterAssetLocation:
      "Klik op de kaart om coördinaten voor de faciliteit in te stellen",
    EnterAssetName: "Vul namen in",
    EnterAssetDescription: "Vul de omschrijving in",
    Type: "Type",
    Description: "Beschrijving",
    AddType: (message: string) => `Nieuw type toevoegen:" ${message} "`,
    Cancel: "Annuleren",
    Save: "Save",
    SelectNode: "Selecteer knooppunt",
    Add: "Toevoegen aan",
    Delete: "Delete",
    Or: (message: string) => `${message} of`,
    RequireAuthorization: (message: string, cardName: string) =>
      `${message} op ${cardName.toLowerCase()} is vereist voor toegang tot dit station`,
    AccessList: "Toegang List",
    Access: "Adgangsoversikt",
    SelectAll: "Selecteer alles",
    SelectNone: "Kies niets",
    AddAll: "Alles toevoegen",
    RemoveAll: "Verwijder alles",
    Asset: "Constructie",
    AssetNode: "Groep",
    SelectType: "Kies type",
    SelectModelType: "Selecteer het modeltype",
    NotAvailable: "Deze keuze is op dit niveau niet mogelijk."
  },
  safetyCardList: {
    Kpi: {
      Active: "Actieve kaarten",
      Expired: "Verlopen kaarten",
      ExpiresIn30Days: "Kaarten geldig binnen 30 dagen",
      RecieptNotRecieved: "Kaarten die niet worden erkend"
    },
    ShredButtonText: "Stukje",
    ShredAndCreateButtonText: "Versnipperen en nieuw maken",
    ShredAndCreateText: (cardName: string) =>
      `Wilt u shredden en een nieuwe ${cardName.toLowerCase()} maken, of gewoon shredden?`,
    ShredAndCreateTitle: (cardName: string) =>
      `Makuler ${cardName.toLowerCase()}`,
    ShouldIssueTitle: "Kort gemaakt",
    ShouldIssue: (cardName: string) =>
      `Wilt u nu ${cardName.toLowerCase()} (s) uitgeven?`,
    RenewedFromCard: (
      cardId: number,
      firstName: string,
      lastName,
      cardName: string
    ) =>
      `Fornyet fra ${cardName.toLowerCase()} nr. ${cardId} av ${firstName} ${lastName} `,
    CopyCardHeader: (cardName: string) =>
      `Kopieer ${cardName.toLowerCase()} van:`,
    CardNumberPlaceholder: (cardName: string) =>
      `Zoeken naar ${cardName.toLowerCase()} nummer`,
    NamePlaceholder: "Zoeken op naam",
    LastNamePlaceholder: "Zoeken op achternaam",
    Proceed: "Selecteer en ga verder",
    Warning: "Waarschuwing",
    ValidFrom: "Geldig vanaf",
    ValidTo: "Geldig voor",
    Missing: "Geldig van eerder een Geldig tot",
    Comment: "Vul eventuele opmerkingen over het probleem in",
    CardTitle: (cardName: string) => `${cardName} gemaakt`,
    CardText: (cardName: string) =>
      `${cardName} is gemaakt maar niet uitgegeven. Dit moet worden gedaan door een persoon met de rechten van de emittent. `,
    CardWarning: (cardName: string) =>
      `${cardName} is niet verzonden. Vereiste kenmerken zijn niet ingevuld bij ${cardName.toLowerCase()} `,
    Save: "Save",
    Cancel: "Annuleren",
    ErrorCreatingCardShredded: (cardId: number, cardName: string) =>
      `Er is een fout opgetreden bij het maken van ${cardName.toLowerCase()} nummer ${cardId}. ${cardName.toLowerCase()} is versnipperd, maar nieuwe ${cardName.toLowerCase()} is niet gemaakt.`,
    ErrorTitle: "Er is een fout opgetreden",
    ErrorShreddingCard: (cardId: number, cardName: string) =>
      `Er is een fout opgetreden bij het vernietigen van ${cardName.toLowerCase()} nummer ${cardId}`,
    SetDate: (numCards: number, cardName: string) =>
      numCards === 1
        ? `Stel van en tot nu toe ${numCards} ${cardName.toLowerCase()}`
        : numCards === 2
        ? `Instellen van en tot datum op beide ${cardName.toLowerCase()} one`
        : ` Instellen van en tot datum op alle ${numCards} ${cardName.toLowerCase()} `,
    SecurityCard: (cardName: string) => cardName,
    TheSecurityCard: (cardName: string) => `${cardName.toLowerCase()}et`,
    Update: "Bijwerken",
    CardRenewed: (cardName: string) => `${cardName.toLowerCase()} vernieuwd`,
    NumCardRenewed: (numCards: number, issued: boolean, cardName: string) =>
      issued
        ? `${numCards} ${cardName.toLowerCase()} vernieuwd en uitgegeven.`
        : `${numCards} ${cardName.toLowerCase()} vernieuwd maar niet uitgegeven`,
    Done: "Gedaan",
    Renew: "Vernieuwen",
    RenewCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? `Renew ${numCards} ${cardName.toLowerCase()}`
        : `Renew ${cardName.toLowerCase()}`,
    RenewNotSelected: (cardName: string) =>
      `Selecteer de ${cardName.toLowerCase()} die u wilt verlengen in de onderstaande lijst`,
    ReceiptReceived: "Bevestiging ontvangen",
    IssuedBy: "Uitgegeven door",
    Issued: "Uitgegeven",
    ShreddedBy: "Versnipperd door",
    ShreddedTime: "Versnipperde tijd",
    CreateNewSecurityCard: (cardName: string) =>
      `Maak een nieuwe ${cardName.toLowerCase()}`,
    CreateNew: "Maak nieuw",
    Card: (cardName: string) => cardName,
    ShredCards: (numCards: number, cardName: string) =>
      numCards > 0
        ? `Makuler ${numCards} ${cardName.toLowerCase()}`
        : `Makuler ${cardName.toLowerCase()}`,
    Shred: "Stukje",
    ShredSelectedCards: (cardName: string) =>
      `Selecteer de ${cardName.toLowerCase()} die u wilt vernietigen in de onderstaande lijst`,
    AlreadyShreddedTitle: "Al versnipperd",
    AlreadyShreddedText: (cardName: string) =>
      `De geselecteerde ${cardName.toLowerCase()} zijn al versnipperd`,
    ShredAlertTitle: (cardName: string) => `Makuler ${cardName.toLowerCase()}`,
    ShredAlertText: (multipleCards: boolean) =>
      multipleCards
        ? `Hiermee worden de geselecteerde kaarten vernietigd`
        : `Hiermee wordt de geselecteerde kaart vernietigd`,
    Filter: "Kolommen tonen / verbergen",
    ShowValid: (cardName: string) => `Toon geldige ${cardName}`,
    ShowShredded: (cardName: string) => `Toon versnipperde ${cardName}`,
    Open: "Open",
    NeedToSelectCard: (cardName: string) =>
      `U moet een ${cardName.toLowerCase()} selecteren`,
    NeedToSelectOneCard: (cardName: string) =>
      `U moet slechts één ${cardName.toLowerCase()} selecteren`,
    RenewInfoTextWithShred: (cardName: string) =>
      `De geselecteerde ${cardName.toLowerCase()} exemplaren worden versnipperd en er worden nieuwe gemaakt. `,
    RenewInfoTextNoShred: (cardName: string) =>
      `De geselecteerde ${cardName.toLowerCase()} worden vernieuwd, maar de oude worden niet versnipperd`,
    RenewShouldShred: (cardName: string) => `Shred ${cardName} bij verlenging`,
    InfoLegendFirst: (cardName: string) => `${cardName} gemarkeerd met`,
    InfoLegendLast: "Is verlopen.",
    InfoLegendShredded: (cardName: string) =>
      `Toont versnipperde ${cardName.toLowerCase()}`,
    FilterByAssets: "Filter op toegang",
    Actions: "Opties",
    ShredNumExpired: (num: number) =>
      `Vernietig alle verlopen kaarten (${num})`,
    ShredAll: "Versnipper iedereen",
    ShredExpiredInProgress: "Bezig met vernietigen van de kaart ...",
    ShredExpiredDone: "Kaart vernietigen voltooid",
    ShredCardsAllTypes: (numCards: number) =>
      numCards > 0
        ? numCards === 1
          ? `Stukje ${numCards} kaart`
          : `Stukje ${numCards} kaarten`
        : `Stukje kaart`,
    AlreadyShreddedTextAllTypes: `De geselecteerde kaart is al versnipperd`,
    ShredAlertTitleAllTypes: `Stukje kaart`
  },
  safetyCardEdit: {
    EditCard: (cardName: string) => `Editer ${cardName.toLowerCase()}`,
    SaveCard: (cardName: string) => `Save ${cardName.toLowerCase()}`,
    Cancel: "Annuleren"
  },
  sidebar: {
    Admin: "Beheerder",
    AdminAsset: "Beheer voorzieningen",
    UserControl: "User management",
    Roles: "Rollen",
    Templates: "Sjablonen bewerken",
    ConstructionProjects: "Projecten",
    AssetMessages: "Constructie berichten",
    DoorKeyTemplates: "Sleutel applicatiesjabloon"
  },
  signReceipt: {
    PinNotValid: "Onjuiste pincode",
    ErrorRequestingPinTitle: "Waarschuwing",
    ErrorRequestingPinText:
      "Kon pin niet bevestigen. Probeer het opnieuw of neem contact op met ondersteuning",
    ErrorValidatingPinTitle: "Er is een fout opgetreden",
    ErrorValidatingPinText:
      "Er is een fout opgetreden tijdens het verzenden van de pincode.",
    ConfirmReceivingCard: (cardId: number, orgName: string, cardName: string) =>
      `Ik bevestig hierbij dat ik ${cardName} heb ontvangen met het nummer ${cardId} uitgegeven door ${orgName}. `,
    ConfirmReceivingCardSign: (
      cardId: number,
      orgName: string,
      cardName: string
    ) =>
      `Ik bevestig hierbij de ontvangst van ${cardName} zonder ${cardId}.\nApproval voor ${cardName.toLowerCase()} uitgegeven door ${orgName}. `,
    ConfirmReceivingDoorKey: "Bevestig sleutel ontvangen",
    GetPinSms: "Stuur pin via sms",
    GetDoorKeyPinSms: "Stuur pin",
    VerifyDoorKeyPinSms: "Confirm",
    WritePin: "Voer hier de pincode in",
    SignSecurityCard: (cardName: string) => `Ontvang ${cardName.toLowerCase()}`,
    Close: "Dicht",
    SuccessText: "De kaart is ondertekend",
    DoorkeySuccessText: "Sleutel is ondertekend",
    modalHeader: "Erkenning van sleutel",
    modalHeaderCard: "Weg met kaarten"
  },
  singleAsset: {
    Error: "Verkeerd",
    ErrorFetchingAsset:
      "Er is een fout opgetreden tijdens het laden van het systeem",
    MissingAssetAttri: "Niet alle velden op de schijf zijn correct ingevuld.",
    ErrorPutStation:
      "Er is een fout opgetreden tijdens het laden van de schijf.",
    ErrorPutImg:
      "Er is een fout opgetreden tijdens het uploaden van een afbeelding.",
    ErrorPutAsset:
      "Er is een fout opgetreden tijdens het uploaden van installaties.",
    WrongFileFormat: "Het bestand heeft niet de juiste bestandsindeling",
    DropzoneText: "Klik hier of plaats een foto hier om hem te uploaden",
    DeleteImage: "Afbeelding verwijderen",
    Warning: "Afbeelding verwijderen",
    DoYouWantToDeleteImg: "Hiermee wordt de afbeelding verwijderd",
    Zones: "Soner",
    AddZone: "Zone toevoegen",
    DeleteZone: "Verwijder geselecteerde zone",
    Latitude: "Breedte",
    SelectOnMap: "Selecteer in kaart",
    ClickToSetCoordinates:
      "Klik op de kaart om coördinaten voor de faciliteit in te stellen",
    Longitude: "Lengte",
    Name: "Naam *",
    NamePlaceholder: "Vul naam in",
    Missing: "Dit veld kan niet leeg worden gelaten",
    ExportId: "Integration ID",
    ExportIdPlaceholder: "Integration ID",
    AccessRequirment: "Toelatingseisen",
    Role: (numRoles: number) =>
      numRoles === 1 ? `${numRoles} role` : ` ${numRoles} role`,
    RequiredRolesForAccess:
      "Een van de volgende rollen is vereist om toegang te krijgen.",
    LeaderRequirment: "Begeleidende Eisen",
    RequiredRolesForLeader:
      "Een van de volgende rollen is vereist als begeleider.",
    maxEntries: "Maximaal aantal gelijktijdige check-ins",
    maxEntriesPlaceholder:
      "Voer het aantal gelijktijdige check-ins voor faciliteiten in",
    maxEntriesBlock: "Blokkeren na maximaal aantal gelijktijdige check-ins",
    maxEntriesUpdate: "Werk het maximale aantal gelijktijdige check-ins bij",
    maxEntryInputWarning:
      "Voer een geldig nummer in als een maximum aantal inzendingen",
    infoLabel: "Informatie"
  },
  singlePerson: {
    personalInformation: "Persoonlijke informatie",
    FirstName: "Voornaam",
    Missing: "Dit veld kan niet leeg worden gelaten",
    LastName: "Achternaam",
    Mobile: "De auto",
    Digits: "De waarde moet een getal zijn",
    Email: "E-mail",
    ValidEmail: "De waarde moet een geldig e-mailadres zijn",
    Company: "Partij",
    Active: "Actief",
    Shredded: "Versnipperd",
    SafetyCards: "Veiligheidskaart",
    Documents: "Documenten"
  },
  personSafetycards: {
    Open: "Open",
    SafetyCards: (cardName: string) => cardName,
    SelectToOpen: (cardName: string) =>
      `Selecteer ${cardName.toLowerCase()} om te openen`,
    ShowShredded: (cardName: string) =>
      `Shredded ${cardName.toLowerCase()} weergeven`,
    InfoLegendFirst: (cardName: string) => `${cardName} gemarkeerd met`,
    InfoLegendLast: "Is verlopen.",
    ShowValid: (cardName: string) => `Toon geldige ${cardName.toLowerCase()}`
  },
  currentUser: {
    LoggedIn: "Ingelogd als:",
    LogOut: "Afmelden",
    PrivacyPolicy: "Privacy Policy"
  },
  columnDefs: {
    BirthDate: "Geboortedatum",
    PermittoId: "Vijfden ID",
    CardNr: "Afgekort",
    FirstName: "Voornaam",
    LastName: "Achternaam",
    Mobile: "De auto",
    EmployerName: "Bedrijf",
    ValidFrom: "Geldig vanaf",
    ValidTo: "Geldig voor",
    Status: "Staat",
    ShreddedBy: "Versnipperd door",
    ShreddedTime: "Versnipperde tijd",
    PersonnelId: "ID kaart",
    Email: "E-mail",
    Company: "Partij",
    Organisation: "Organisatie",
    OrganizationNo: "Reg.",
    InvitedDate: "Uitnodiging e-mail verzonden",
    AssetName: "Bouw / project",
    AssetArea: "Gebied",
    AssetOwner: "Constructie Victory",
    CheckOut: "Check-out",
    CheckIn: "Inchecken",
    ExpectedTimeToLeave: "Verwachte check-out",
    EntryCount: "Aantal mensen",
    EntryNames: "Personen",
    EntryNote: "Memo",
    EntryNoteBy: "Opmerking bij",
    CheckoutBy: "Uitgecheckt",
    IssuedBy: "Uitgegeven door",
    IsApplication: "Is toepassing",
    ReceiptReceived: "Bevestiging ontvangen",
    Message: "Archief",
    InfoType: "Message Format",
    EventType: "Verschijnt",
    ShowMsgFrom: "Weergegeven vanaf datum",
    ShowMsgTo: "Verschijnt tot op heden",
    EnteredBy: "Geregistreerd door",
    OtherCardType: "Kort type",
    OtherCardTypeDesc: "Korte beschrijving van het type",
    ProjectName: "Project Naam",
    ProjectConnectedToAsset: "Associëren met plant",
    ProjectReq: "Toelatingseisen",
    ProjectSupplier: "Beschikbaar voor leverancier (s)",
    ProjectStart: "Vanaf datum",
    ProjectEnd: "Tot op heden",
    ProjectReference: "Integration ID",
    AllVenors: "Alle leveranciers",
    CardHolder: "Kortinnehaver",
    CardDetails: "Korte Details",
    CardAttribute: "Kortattributter",
    CardHolderDoc: "Kaarthouder documentatie",
    BlockAutoCheckout: "Schakel automatisch afrekenen uit",
    RoleName: "Rol",
    ProjectNumber: "Projectnummer",
    ProjectLead: "Projectmanager",
    ExternalId: "external-id",
    CardType: "Kaarttype"
  },
  columnAttachmentDetailDefs: {
    fileId: "Bijlage ID",
    fileName: "Naam",
    attachments: "Bijlagen bij registratie",
    gridEmptytext: "Geen bijlagen"
  },
  customDateComponent: {
    clear: "Verwijder filter"
  },
  accessLog: {
    fromDatePickerDate: "Vanaf datum",
    toDatePickerDate: "Tot op heden",
    digitalProjectEntry: "",
    digitalProjectEntryTitle: "",
    digitalProjectEntryAddress: "",
    digitalProjectEntryClient: "",
    enableFilter: "",
    disableFilter: "",
    from: "",
    to: "",
    export: "Export"
  },
  dateValidation: {
    validDates: "Geselecteerde datums zijn niet geldig.",
    beforeToday:
      "Geselecteerde datums zijn niet geldig. Off date kan niet eerder zijn dan de datum van vandaag.",
    toBeforeFrom:
      "Geselecteerde datums zijn niet geldig. Datum tot datum kan niet vóór datum zijn."
  },
  assetMessagesModal: {
    repeats: "Herhaald",
    modalHeader: "Constructie Message",
    modalSave: "Save",
    modalClose: "Dicht",
    infoDesc:
      "Het bericht verschijnt voor de plant die u in de boom selecteert. Als jij\n      Als het bericht in een groep wordt geplaatst, wordt het op alle systemen weergegeven\n      onder de groep. U kiest wanneer u het bericht wilt weergeven. Het zal dan\n      verschijnt automatisch in de Permitto-app voor iedereen die bezoekt\n      plant. U kunt de aan en uit-datum instellen voor het zelf weergeven van een bericht. Als de datum leeg is, wordt het bericht weergegeven totdat het wordt verwijderd.",
    groupIcon: "Groep",
    assetIcon: "Constructie",
    selectedAsset: "Geselecteerde plant",
    selectAsset: "Selecteer een plant",
    messageType: "Message Format",
    messageText: "Archief",
    fromDate: "Verschijnt vanaf datum",
    toDate: "Verschijnt zelfs datum",
    messageEventType: "Wanneer het bericht wordt weergegeven",
    messagePlaceholder: "Voer berichttekst in",
    datePlaceholder: "Kies datum",
    assetMessageDiabledTooltip: (asset: boolean, message: boolean) =>
      !asset
        ? "Installatie / groep niet geselecteerd"
        : !message
        ? "Bericht niet ingesteld"
        : ""
  },
  otherCards: {
    openCard: "Open",
    addCard: "Kaarten toevoegen",
    deleteCard: "Kaart verwijderen",
    filterPlaceHolder: "Zoeken op achternaam",
    save: "Save",
    cancel: "Annuleren",
    close: "Dicht",
    selectCardType: "Selecteer kaarttype",
    cardType: "Kaarttype",
    cardNumber: "Kaartnummer",
    cardHolder: "Kaarthouder",
    added: "Toegevoegd",
    addFrontImage: "Voorblad toevoegen",
    addBackImage: "Achteromslag toevoegen",
    selectDate: "Kies datum",
    validTo: "Geldig voor",
    validFrom: "Geldig vanaf",
    birthDate: "Geboortedatum",
    editImageApplyChanges: "Pas wijzigingen toe",
    editImageDiscardChanges: "Wijzigingen weigeren",
    editImageRotateLeft: "Linkerzijde",
    editImageRotateRight: "Rechts",
    editImageButton: "Uitgeven",
    editImageHeading: "Afbeelding bewerken",
    editImageZoomMinLabel: "Minimum",
    editImageZoomMaxLabel: "Maximum",
    editImageZoomControlLabel: "Zoom",
    modalEditHeader: "Kaart bewerken",
    modalNewHeader: "Plaats een nieuwe kaart",
    modalViewHeader: "Korte Details",
    editButtonLabel: "Uitgeven",
    deleteButtonLabel: "Delete",
    noImageFound: "Geen afbeelding gevonden",
    enterCardNumber: "Voer kaartnummer in",
    cardNumberNotFilled: "Kaartnummer niet voltooid"
  },
  selectPerson: {
    proceedBtnLabel: "Voortzetten",
    filterPlaceholder: "Achternaam"
  },
  languageSelector: {
    Nor: "Noors",
    Eng: "Engels",
    Sve: "Zweeds",
    Ned: "Nederlands"
  },
  updateVersion: {
    preRefreshText: "Een nieuwe versie afgeleid. alsjeblieft",
    refreshTextBtn: "verversen",
    postRefreshText: "de site."
  },
  maintenanceMessages: {
    title: "titel",
    author: "auteur",
    message: "bericht"
  },
  assetMap: {
    polygon: {
      draw: "Teken een veelhoek",
      cancel: "Teken een veelhoek",
      finish: "Finish",
      undo: "Laatste punt verwijderen",
      delete: "Verwijderen",
      deleteDisabled: "Geen lagen om te verwijderen",
      modal: {
        delete: {
          title: "Veelhoek verwijderen",
          subTitle: "Dit zal de polygoon verwijderen"
        }
      },
      tooltip: {
        start: "Klik om een polygoon te tekenen",
        continue: "Klik om coördinaat toe te voegen",
        stop: "Klik op het eerste punt om deze polygoon te voltooien"
      }
    }
  },
  customToolPanel: {
    title: "Opgeslagen kolomfilters",
    saveFilterButton: "Filter opslaan",
    saveFilterModal: {
      title: "Filter opslaan",
      subtitle: "Voeg een sjabloonnaam toe",
      inputPlaceholder: "Voeg hier een sjabloonnaam toe",
      saveButton: "Filter opslaan"
    },
    deleteFilterModal: {
      title: "Filter verwijderen",
      subtitle: "Dit wordt niet teruggedraaid"
    }
  },
  jungleMap: {
    firstPara: "U kunt deze E-Learning cursus vinden",
    linkText: "op deze link",
    secondPara: "Als je de cursus hebt afgerond, verschijnt deze eronder.",
    buttonTitle: "Vernieuwen",
    footerText:
      "Als u de E-Learning cursus heeft afgerond en deze niet automatisch hieronder verschijnt, kunt u hierboven op verversen klikken"
  },
  genericWords: {
    since: "sinds",
    from: "van",
    phone: "telefoon",
    change: "veranderen"
  }
};
