import styles from "./header.css";
import * as React from "react";

interface ContextSwitcherListProps {
  onSelect: Function;
  selectedValue: string;
}

export default class ContextSwitcherList extends React.Component<
  ContextSwitcherListProps
> {
  render() {
    return (
      <div className={styles.dropdownPanelList}>
        {React.Children.toArray(this.props.children)
          .filter(c => c)
          .map((child: any) =>
            React.cloneElement(child, {
              onSelect: this.props.onSelect,
              selectedValue: this.props.selectedValue
            })
          )}
      </div>
    );
  }
}
