import styles from "./header.css";
import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import ContextMenu from "./context-menu";
import { UserIdentity } from "../../types";

interface AppButtonProps {
  currentUrl: string;
  switchContext: (url: string) => void;
  userIdentity: UserIdentity;
}

@observer
export default class AppButton extends React.Component<AppButtonProps> {
  @observable
  showDropdown: string = "";
  @observable showContextMenu: boolean = false;

  @action("ACTION: setShowDropdown app-button")
  setShowDropdown = (showDropdown: string) => {
    this.showDropdown = showDropdown;
  };

  @action("ACTION: setShowContextMenu app-button")
  setShowContextMenu = (showContextMenu: boolean) => {
    this.showContextMenu = showContextMenu;
  };

  showAppButton(): boolean {
    if (this.props.userIdentity) {
      if (
        (this.props.userIdentity.ourPermittoRights &&
          this.props.userIdentity.ourPermittoRights.length > 0) ||
        (this.props.userIdentity.issuePermissions &&
          this.props.userIdentity.issuePermissions.length > 0) ||
        (this.props.userIdentity.orgPermittoRights &&
          this.props.userIdentity.orgPermittoRights.length > 0)
      ) {
        return true;
      }
    }
    return false;
  }

  render() {
    if (!this.showAppButton()) {
      return false;
    }
    return (
      <div>
        <img
          onClick={() => {
            if (!this.showContextMenu) {
              this.setShowDropdown("");
            }
            this.setShowContextMenu(!this.showContextMenu);
          }}
          className={styles.contextButton}
          src="res/button.png"
        />
        {this.showContextMenu && (
          <ContextMenu
            showDropDown={this.showDropdown}
            setShowDropDown={this.setShowDropdown}
            currentUrl={this.props.currentUrl}
            setShowContextMenu={this.setShowContextMenu}
            switchContext={(url: string) => {
              this.setShowContextMenu(false);
              this.props.switchContext(url);
            }}
            userIdentity={this.props.userIdentity}
          />
        )}
      </div>
    );
  }
}
