import { localizationStore } from "./../../stores/index";
export const ROOT_NODE_PARENT_ID = -1;
export enum BackgroudColor {
  Blue = 1,
  White,
  Gray,
  Red
}

export function getBackgroundColor(color: BackgroudColor) {
  switch (color) {
    case BackgroudColor.Blue:
      return GlobalStyles.backgroundColorBlue;
    case BackgroudColor.Gray:
      return GlobalStyles.backgroundColorGray;
    case BackgroudColor.Red:
      return GlobalStyles.expiredItemColorRed;
    case BackgroudColor.White:
      return;
  }
}

export class GlobalStyles {
  static backgroundColorBlue: string = "backgroundColorBlue";
  static backgroundColorGray: string = "backgroundColorGray";
  static expiredItemColorRed: string = "expiredItemColorRed";
}

//TODO TRANSLATE date time format
export const dateFormat = "DD/MM/YYYY";
export const dateTimeFormat = "DD/MM/YYYY HH:mm";

export function translateGridText(
  key: string,
  defaultValue: string,
  safetyCardGrid: boolean
): string {
  switch (key) {
    case "loadingOoo":
      return localizationStore.currentLocal.app.constants.Loading;
    case "noRowsToShow":
      return safetyCardGrid
        ? localizationStore.currentLocal.app.constants.NoSafetycardsToShow
        : localizationStore.currentLocal.app.constants.NoRowsToShow;
    case "filterOoo":
      return localizationStore.currentLocal.app.constants.Filter;
    case "equals":
      return localizationStore.currentLocal.app.constants.Equal;
    case "notEqual":
      return localizationStore.currentLocal.app.constants.NotEqual;
    case "lessThan":
      return localizationStore.currentLocal.app.constants.LessThan;
    case "lessThanOrEqual":
      return localizationStore.currentLocal.app.constants.LessThanOrEqual;
    case "greaterThan":
      return localizationStore.currentLocal.app.constants.GreaterThan;
    case "greaterThanOrEqual":
      return localizationStore.currentLocal.app.constants.GreaterThanOrEqual;
    case "inRange":
      return localizationStore.currentLocal.app.constants.InRange;
    case "clearFilter":
      return localizationStore.currentLocal.app.constants.ClearFilter;
    case "applyFilter":
      return localizationStore.currentLocal.app.constants.ApplyFilter;
    case "startsWith":
      return localizationStore.currentLocal.app.constants.StartsWidth;
    case "endsWith":
      return localizationStore.currentLocal.app.constants.EndsWidth;
    case "contains":
      return localizationStore.currentLocal.app.constants.Contains;
    case "notContains":
      return localizationStore.currentLocal.app.constants.NotContains;
    case "rowGroupColumnsEmptyMessage":
      return localizationStore.currentLocal.app.constants
        .RowGroupColumnsEmptyMessage;
    case "groups":
      return localizationStore.currentLocal.app.constants.Groups;
    case "pivotColumnsEmptyMessage":
      return localizationStore.currentLocal.app.constants
        .PivotColumnsEmptyMessage;
    case "pivots":
      return localizationStore.currentLocal.app.constants.Pivots;
    case "sum":
      return localizationStore.currentLocal.app.constants.Sum;
    case "count":
      return localizationStore.currentLocal.app.constants.Count;
    case "min":
      return localizationStore.currentLocal.app.constants.Min;
    case "max":
      return localizationStore.currentLocal.app.constants.Max;
    case "average":
      return localizationStore.currentLocal.app.constants.Average;
    case "pinColumn":
      return localizationStore.currentLocal.app.constants.PinColumn;
    case "pinLeft":
      return localizationStore.currentLocal.app.constants.PinLeft;
    case "pinRight":
      return localizationStore.currentLocal.app.constants.PinRight;
    case "noPin":
      return localizationStore.currentLocal.app.constants.NoPin;
    case "autosizeThiscolumn":
      return localizationStore.currentLocal.app.constants.AutosizeThiscolumn;
    case "autosizeAllColumns":
      return localizationStore.currentLocal.app.constants.AutosizeAllColumns;
    case "resetColumns":
      return localizationStore.currentLocal.app.constants.ResetColumns;
    case "toolPanel":
      return localizationStore.currentLocal.app.constants.ToolPanel;
    case "pivotMode":
      return localizationStore.currentLocal.app.constants.PivotMode;
    case "valueColumnsEmptyMessage":
      return localizationStore.currentLocal.app.constants
        .ValueColumnsEmptyMessage;
    case "values":
      return localizationStore.currentLocal.app.constants.Values;
    case "searchOoo":
      return localizationStore.currentLocal.app.constants.SearchOoo;
    case "selectAll":
      return localizationStore.currentLocal.app.constants.SelectAll;
    case "copy":
      return localizationStore.currentLocal.app.constants.Copy;
    case "ctrlC":
      return localizationStore.currentLocal.app.constants.CtrlC;
    case "copyWithHeaders":
      return localizationStore.currentLocal.app.constants.CopyWithHeaders;
    case "paste":
      return localizationStore.currentLocal.app.constants.Paste;
    case "ctrlV":
      return localizationStore.currentLocal.app.constants.CtrlV;
    case "export":
      return localizationStore.currentLocal.app.constants.Export;
    case "csvExport":
      return localizationStore.currentLocal.app.constants.CsvExport;
    case "excelExport":
      return localizationStore.currentLocal.app.constants.ExcelExport;
    case "columns":
      return localizationStore.currentLocal.app.constants.Columns;
    default:
      // console.log(key + " : " + defaultValue);
      return defaultValue;
  }
}

export const TOKEN_NAME = "VF_TOKEN";
