import filter from "./../shared/utillities/filter-array";
import {
  SafetyCard,
  CardStatuses,
  CardLayout,
  ApplicationAttribute,
  AttributeDataType,
  Bool,
  StatusLog,
  ApplicationTemplate,
  Attribute,
  DocumentationInfo,
  DocRequirementsInfo,
  CardAndImgUrls,
  OrganisationTemplate,
  CardType,
  IssuePermission
} from "../types";
import { localizationStore, userStore } from "../stores/index";
import { StartOfDay, getMaxDate } from "../shared/utillities/date-formater";
import * as moment from "moment";
import * as consts from "./../app/view/const";
import { getDocumentDateList } from "./document-utils";

export function validateSafetycard(card: SafetyCard): boolean {
  return (
    card &&
    card.validFromDateTime &&
    card.validToDateTime &&
    card.validFromDateTime <= card.validToDateTime
  );
}

export function formattedSafetycardsForGrid(
  safetyCards: SafetyCard[],
  searchText: string,
  organisations: Map<number, OrganisationTemplate>,
  documentation?: DocumentationInfo[],
  documentRequirements?: DocRequirementsInfo[],
  isDocLoaded?: boolean
): SafetyCard[] {
  const formatedCards = [];

  if (!safetyCards) {
    return [];
  }

  const filtredCards = filter(searchText, safetyCards, s => s.lastName);

  for (const orgCard of filtredCards) {
    const org = organisations.get(orgCard.orgId);
    if (org) {
      const card = Object.assign({}, orgCard);
      for (const a of card.attributes) {
        const attribute = org.cardTypes
          .find(c => c.cardTypeId === card.cardTypeId)
          .cardTemplates.find(s => s.name === CardLayout.Card)
          .attributes.find(
            (attribute: ApplicationAttribute) =>
              attribute.attributeId === a.attributeId
          );
        if (attribute) {
          card[attribute.name] =
            attribute.dataType === AttributeDataType.Boolean
              ? a.value === Bool.True
              : a.value;
        }
      }

      if (card.statusLog.length > 0) {
        let status = getApplicationStatus(card, card.statusLog[0], org);
        if (
          card.statusLog[0].status === CardStatuses.EMP_APPROVED ||
          card.statusLog[0].status === CardStatuses.EMP_REJECTED ||
          card.statusLog[0].status === CardStatuses.ORG_APPROVED ||
          card.statusLog[0].status === CardStatuses.ORG_REJECTED
        ) {
          status += card.statusLog[0].statusSetByOrgEmp;
        }

        card["status"] = status;
      }

      for (const s of card.statusLog) {
        card[s.status] = s.statusDate;
        card[`${s.status}by`] = s.statusSetByName;
      }

      if (isDocLoaded) {
        if (card.personnelId) {
          const personelDoc = documentation.find(
            a => a.personnelId === card.personnelId
          );
          if (personelDoc) {
            for (const docReq of documentRequirements) {
              const docReqData = personelDoc.documentation.filter(
                document => document.idDocType === docReq.docTypeId
              );
              const docReqDataValidFrom = docReqData.filter(
                docReq => docReq.validFrom !== ""
              );
              if (docReq.numDaysValid > 0) {
                card[`doc_req${docReq.docTypeId}`] = moment(
                  getMaxDate(getDocumentDateList(docReqDataValidFrom)),
                  consts.dateFormat
                )
                  .add(docReq.numDaysValid, "days")
                  .toISOString();
              } else {
                card[`doc_req${docReq.docTypeId}`] =
                  docReqData && docReqData.length > 0 ? true : false;
              }
            }
          }
        }
      }
      formatedCards.push(card);
    } else {
      formatedCards.push(orgCard);
    }
  }
  return formatedCards;
}

export function getCardTemplatesForOrg(organisation: OrganisationTemplate) {
  const cardTemplates: CardType[] = [];
  for (const cardTemplate of organisation.cardTypes) {
    if (!cardTemplates.find(c => c.cardTypeId === cardTemplate.cardTypeId)) {
      cardTemplates.push(cardTemplate);
    }
  }
  return cardTemplates;
}

export function getCardTemplatesForIssuerPermissions(
  allOrgs: OrganisationTemplate[],
  issuerPermissions: IssuePermission[]
): CardType[] {
  const cardTypes: CardType[] = [];

  for (const issuerPermission of issuerPermissions) {
    const currentOrg = allOrgs.find(o => o.orgId === issuerPermission.orgId);
    cardTypes.push(
      currentOrg.cardTypes.find(
        c =>
          c.cardTypeId === issuerPermission.cardTypeId &&
          !cardTypes.find(d => d.cardTypeId === c.cardTypeId)
      )
    );
  }

  return cardTypes;
}

export function getCardTemplatesForOrgs(
  organisations: OrganisationTemplate[]
): CardType[] {
  const cardTemplates: CardType[] = [];

  for (const org of organisations) {
    for (const cardTemplate of org.cardTypes) {
      if (!cardTemplates.find(c => c.cardTypeId === cardTemplate.cardTypeId)) {
        cardTemplates.push(cardTemplate);
      }
    }
  }
  return cardTemplates;
}

export function getCardTemplatesForOrgsWihoutIssuerCards(
  organisations: OrganisationTemplate[],
  issuerPermissions: IssuePermission[]
): CardType[] {
  const cardTemplates: CardType[] = [];

  for (const org of organisations) {
    for (const cardTemplate of org.cardTypes) {
      if (!cardTemplates.find(c => c.cardTypeId === cardTemplate.cardTypeId)) {
        if (
          !issuerPermissions.find(c => c.cardTypeId === cardTemplate.cardTypeId)
        )
          cardTemplates.push(cardTemplate);
      }
    }
  }
  return cardTemplates;
}

function shouldCapitalizeFirstLetter(text: string, capitalize: boolean) {
  if (capitalize) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return text;
}

export function getSafetyCardLocalizedText(
  organisations: OrganisationTemplate[],
  currentCardTypeId: number,
  firstLetterCapitalized?: boolean
): string {
  if (organisations.length === 0) {
    return shouldCapitalizeFirstLetter(
      localizationStore.currentLocal.app.constants.SafetyCardTextNeutral,
      firstLetterCapitalized
    );
  } else if (organisations.length === 1) {
    return shouldCapitalizeFirstLetter(
      localizationStore.currentLocal.app.constants.SafetyCardText(
        organisations[0],
        currentCardTypeId
      ),
      firstLetterCapitalized
    );
  } else {
    let validOrgs = organisations;
    if (currentCardTypeId !== -1) {
      validOrgs = organisations.filter(
        o =>
          o.cardTypes.findIndex(ct => ct.cardTypeId === currentCardTypeId) > -1
      );
    }

    //Get all names for the cardtypes. If they are not identical, we use a neutral name.
    const cardNameEn = [].concat(
      validOrgs.map(o => {
        if (currentCardTypeId === -1) {
          return o.cardTypes.map(c => c.cardNameEn);
        }
        return o.cardTypes
          .filter(c => c.cardTypeId === currentCardTypeId)
          .map(c => c.cardNameEn);
      })
    );

    const cardNameNo: string[] = [].concat(
      validOrgs.map(o => {
        if (currentCardTypeId === -1) {
          return o.cardTypes.map(c => c.cardNameNo);
        }
        return o.cardTypes
          .filter(c => c.cardTypeId === currentCardTypeId)
          .map(c => c.cardNameNo);
      })
    );

    if (
      cardNameEn.every(c => c === cardNameEn[0]) &&
      cardNameNo.every(c => c === cardNameNo[0])
    )
      return shouldCapitalizeFirstLetter(
        localizationStore.currentLocal.app.constants.SafetyCardText(
          validOrgs[0],
          currentCardTypeId
        ),
        firstLetterCapitalized
      );
  }
  return shouldCapitalizeFirstLetter(
    localizationStore.currentLocal.app.constants.SafetyCardTextNeutral,
    firstLetterCapitalized
  );
}

export function getOrgPostIssueRules(
  org: OrganisationTemplate
): ApplicationAttribute[] {
  const attibs = new Map<number, ApplicationAttribute>();
  for (const cardType of org.cardTypes) {
    for (const attrib of cardType.attributePostIssueRules) {
      attibs.set(attrib.attribute.attributeId, attrib.attribute);
    }
  }
  return Array.from(attibs.values());
}

export function getApplicationCardType(
  cardTypeId: number,
  org: OrganisationTemplate
): string {
  return localizationStore.currentLocal.shared.safetyCardApplication.cardTypeName(
    org.cardTypes.find(c => c.cardTypeId === cardTypeId),
    false
  );
}

export function getApplicationStatus(
  safetyCard: SafetyCard,
  status: StatusLog,
  organisation: OrganisationTemplate
) {
  if (organisation) {
    const currentCardTemplate = organisation.cardTypes.find(
      a => a.cardTypeId === safetyCard.cardTypeId
    );

    switch (status.status) {
      case CardStatuses.CREATED: {
        // If this status is not the last one(aka the first one), this is a update
        if (
          safetyCard.statusLog &&
          safetyCard.statusLog.indexOf(status) !==
            safetyCard.statusLog.length - 1
        ) {
          if (safetyCard.hasApplication) {
            if (
              currentCardTemplate.cardRequiresAttestor &&
              safetyCard.attestor
            ) {
              return localizationStore.currentLocal.app.constants.UpdatedApplicationAwaitingAttestor(
                safetyCard.attestor.name,
                status.statusSetByName
              );
            } else {
              return localizationStore.currentLocal.app.constants.UpdatedApplication(
                status.statusSetByName
              );
            }
          } else {
            return localizationStore.currentLocal.app.constants.UpdatedCard(
              status.statusSetByName,
              getSafetyCardLocalizedText([organisation], safetyCard.cardTypeId)
            );
          }
        } else {
          if (safetyCard.hasApplication) {
            if (
              currentCardTemplate.cardRequiresAttestor &&
              safetyCard.attestor
            ) {
              return localizationStore.currentLocal.app.constants.CreatedApplicationAwaitingAttestor(
                safetyCard.attestor.name,
                status.statusSetByName
              );
            } else {
              return localizationStore.currentLocal.app.constants.CreatedApplication(
                status.statusSetByName
              );
            }
          } else {
            return localizationStore.currentLocal.app.constants.CreatedCard(
              status.statusSetByName,
              getSafetyCardLocalizedText([organisation], safetyCard.cardTypeId)
            );
          }
        }
      }

      case CardStatuses.ISSUED:
        if (safetyCard.cardTypeId === 3) {
          return localizationStore.currentLocal.app.constants.IssuedOnBehalf(
            status.statusSetByOrgEmp,
            status.statusSetByName
          );
        } else {
          return localizationStore.currentLocal.app.constants.Issued(
            status.statusSetByOrgEmp,
            status.statusSetByName
          );
        }

      case CardStatuses.ORG_APPROVED: {
        if (safetyCard.hasApplication) {
          return localizationStore.currentLocal.app.constants.OrgApprovedApplication(
            status.statusSetByName,
            status.statusSetByOrgEmp,
            getSafetyCardLocalizedText([organisation], safetyCard.cardTypeId)
          );
        } else if (safetyCard.cardTypeId === 3) {
          return localizationStore.currentLocal.app.constants.OrgApprovedCardOnBehalf(
            status.statusSetByOrgEmp,
            status.statusSetByName,
            getSafetyCardLocalizedText([organisation], safetyCard.cardTypeId)
          );
        } else {
          return localizationStore.currentLocal.app.constants.OrgApprovedCard(
            status.statusSetByOrgEmp,
            status.statusSetByName,
            getSafetyCardLocalizedText([organisation], safetyCard.cardTypeId)
          );
        }
      }
      case CardStatuses.RECEIPT_RECEIVED:
        return localizationStore.currentLocal.app.constants.ReceiptReceived;
      case CardStatuses.ORG_REJECTED:
        return localizationStore.currentLocal.app.constants.OrgRejected(
          status.statusSetByOrgEmp,
          status.statusSetByName
        );
      case CardStatuses.EMP_APPROVED:
        return localizationStore.currentLocal.app.constants.EmpApproved;
      case CardStatuses.EMP_REJECTED:
        return localizationStore.currentLocal.app.constants.EmpRejected;
      case CardStatuses.SHREDDED:
        return localizationStore.currentLocal.app.constants.Shredded;
      case CardStatuses.STALE:
        return localizationStore.currentLocal.app.constants.GoneStale;
      default:
        return;
    }
  }
}

export function generateDisabledMessage(
  card: SafetyCard | CardAndImgUrls,
  hseCard = false
): string[] {
  const missingFields = [];
  if (card) {
    if (!card.validFromDateTime) {
      missingFields.push(
        localizationStore.currentLocal.shared.dateValidation.validDates
      );
    }
    if (!card.validToDateTime) {
      missingFields.push(
        localizationStore.currentLocal.shared.dateValidation.validDates
      );
    }

    if (!hseCard) {
      if (card.validFromDateTime && card.validToDateTime) {
        if (card.validFromDateTime < StartOfDay()) {
          missingFields.push(
            localizationStore.currentLocal.shared.dateValidation.beforeToday
          );
        }
      }
    }

    if (card.validFromDateTime && card.validToDateTime) {
      if (card.validFromDateTime > card.validToDateTime) {
        missingFields.push(
          localizationStore.currentLocal.shared.dateValidation.toBeforeFrom
        );
      }
    }
  }
  return missingFields;
}

export function generateDisabledMessageHSECard(card: CardAndImgUrls): string[] {
  const missingFields = generateDisabledMessage(card, true);
  if (card) {
    if (!card.externalId || parseInt(card.externalId) === 0) {
      missingFields.push(
        localizationStore.currentLocal.shared.otherCards.cardNumberNotFilled
      );
    }
  }
  return missingFields;
}

export function createDummySafetyCard(
  cardTemplate: ApplicationTemplate,
  cardTypeId: number
) {
  const safetyCard = <SafetyCard>{
    cardTypeId: cardTypeId,
    cardId: 1004181,
    firstName: userStore.userInfo.firstName,
    lastName: userStore.userInfo.lastName,
    validFromDateTime: moment().toString(),
    attributes: cardTemplate.attributes.map(x => {
      return {
        attributeId: x.attributeId,
        value: x.info
      } as Attribute;
    }),
    validToDateTime: moment().add(365, "days").toString(),
    statusLog: [
      <StatusLog>{
        status: CardStatuses.RECEIPT_RECEIVED,
        statusDate: moment().toString()
      }
    ]
  };
  return safetyCard;
}

export function isEnableDocumentReqColumn(
  isVisible: boolean,
  documentRequirements: DocRequirementsInfo[],
  field?: string
): boolean {
  return documentRequirements.some(
    docReq => `doc_req${docReq.docTypeId}` === field && isVisible
  );
}

export function calcMaxValidDate(
  organisation: OrganisationTemplate,
  cardTypeId: number,
  validFromDate: string
): string {
  const cardType = organisation.cardTypes.find(
    t => t.cardTypeId === cardTypeId
  );
  const baseDate = validFromDate ? moment(validFromDate) : null;
  if (
    baseDate &&
    cardType &&
    cardType.maxDaysValid &&
    cardType.maxDaysValid > 0
  ) {
    return baseDate.add(cardType.maxDaysValid, "days").toISOString();
  }

  return "";
}
