import {
  Org,
  IssueList,
  Issue,
  ApprovalList,
  Approval,
  CardsList,
  Type,
  Card,
  PersonnelList,
  Person,
  Access,
  Asset,
  AccessLog,
  ApplicationList,
  AssetList,
  AssetMessages,
  ConstructionProjects,
  Documentation,
  DoorKeys,
  DoorkeyTemplates,
  Templates,
  ExternalCards,
  Employers,
  Splash,
  Profile,
  Rights,
  Roles
  //   Application,
} from "../consts";

function regExpOrgUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Templates}`);
  return regexp.test(url);
}

export function IsOrganisationIssueListUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${IssueList}`);
  return regexp.test(url);
}

export function IsOrganisationIssueUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Issue}+?\\d+`);
  return regexp.test(url);
}

export function IsOrganisationApprovalListUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${ApprovalList}`);
  return regexp.test(url);
}

export function IsOrganisationApprovalUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Approval}+?\\d+`);
  return regexp.test(url);
}

export function IsOrganisationCardsListUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${CardsList}+${Type}+?\\d`);
  return regexp.test(url);
}

export function IsOrganisationCardUrl(url: string) {
  const regexp = new RegExp(
    `^${Org}+?\\d+${CardsList}+${Type}+?\\d+${Card}+?\\d+`
  );
  return regexp.test(url);
}

export function IsOrganisationPersonnelListUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${PersonnelList}`);
  return regexp.test(url);
}

export function IsOrganisationPersonUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Person}+?\\d+`);
  return regexp.test(url);
}

export function IsOrganisationAssetListUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${AssetList}`);
  return regexp.test(url);
}

export function IsOrganisationExternalCardsUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${ExternalCards}`);
  return regexp.test(url);
}

export function IsOrganisationAssetUrl(url: string) {
  const regexp = new RegExp(`${Org}+?\\d+${Asset}+?\\d+`);
  return regexp.test(url);
}
export function IsOrganisationRightsUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Rights}`);
  return regexp.test(url);
}

export function IsOrganisationRolesUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Roles}`);
  return regexp.test(url);
}

export function IsOrganisationTemplatesUrl(url: string) {
  return regExpOrgUrl(url);
}

export function IsOrganisationDoorkeyTemplatesUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${DoorkeyTemplates}`);
  return regexp.test(url);
}

export function IsOrganisationAssetMessagesUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${AssetMessages}`);
  return regexp.test(url);
}

export function IsOrganisationPermittoUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Splash}`);
  return regexp.test(url);
}

export function IsOrganisationProfileUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Profile}`);
  return regexp.test(url);
}

export function IsOrganisationAccessUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Access}`);
  return regexp.test(url);
}

export function IsOrganisationEmployersUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Employers}`);
  return regexp.test(url);
}

export function IsOrganisationAccessLogUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${AccessLog}`);
  return regexp.test(url);
}

export function IsOrganisationDoorkeysUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${DoorKeys}`);
  return regexp.test(url);
}

export function IsOrganisationDoorkeyApplicationsUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${DoorKeys}${ApplicationList}`);
  return regexp.test(url);
}

export function IsOrganisationDocumentation(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${Documentation}`);
  return regexp.test(url);
}

export function IsOrganisationConstructionProjectsUrl(url: string) {
  const regexp = new RegExp(`^${Org}+?\\d+${ConstructionProjects}`);
  return regexp.test(url);
}
