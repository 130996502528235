import * as repo from "./../repo";
import { errorStore } from "../stores/index";
import {
  OurPendingActions,
  PersonInfo,
  SafetyCard,
  Employer,
  AssetEntry,
  OurPermittoRights,
  DoorKeysInfo,
  DocumentationInfo,
  DoorKeyApplication
} from "../types";
import { localizationStore } from "./../stores";
import { getSafetyCardLocalizedText } from "../data-utillities/safety-card-utils";

export function getEmployerSafetycardsPendingCount(
  employerId: number
): Promise<OurPendingActions> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmpSafetycardsPendingCount(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.pendingScCount)}${
      local.contactSupport
    }`
  );
}

export function getEmployerDoorkeyApplicPendingCount(
  employerId: number
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmpDoorkeyApplicPendingCount(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.pendingScCount)}${
      local.contactSupport
    }`
  );
}

export function getEmployerPersonnel(
  employerId: number
): Promise<PersonInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerPersonnel(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}
export function getEmployerSafetyCard(
  employerId: number,
  includeShredded: boolean,
  cardTypeId: number
): Promise<SafetyCard[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerSafetycards(employerId, includeShredded, cardTypeId),
    local.error.title,
    `${local.error.fetchError(getSafetyCardLocalizedText([], -1))}${
      local.contactSupport
    }`
  );
}

export function getEmployer(employerId: number): Promise<Employer> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployer(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.company)}${local.contactSupport}`
  );
}

export function getEmployerRights(employerId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerRights(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.company)}${local.contactSupport}`
  );
}

export function deleteEmployerRights(employerId: number, personnelId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteEmployerRights(employerId, personnelId),
    local.error.title,
    `${local.error.changeError(local.area.rights)}${local.contactSupport}`
  );
}

export function postEmployerRights(
  employerId: number,
  personnelId: number,
  rights: OurPermittoRights
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postEmployerRightsAdmin(employerId, personnelId, rights),
    local.error.title,
    `${local.error.changeError(local.area.rights)}${local.contactSupport}`
  );
}

export function getOrgEmployers(orgId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgEmployers(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.employers)}${local.contactSupport}`
  );
}

export function putOrgEmployer(orgId: number, employer: Employer) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgEmployer(orgId, employer),
    local.error.title,
    `${local.error.changeError(local.area.employer)}${local.contactSupport}`
  );
}

export function postOrgEmployer(
  orgId: number,
  employer: Employer,
  country: string,
  sendMail: boolean
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.postOrgEmployer(orgId, employer, employer.name, country, sendMail),
    local.error.title,
    `${local.error.uploadError(local.area.employer)}${local.contactSupport}`
  );
}

export function deleteOrgEmployer(
  orgId: number,
  employerId: number
): Promise<void> {
  return repo.deleteEmployer(orgId, employerId);
}

export function getEmployerAssetEnteries(
  employerId: number
): Promise<AssetEntry[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerAssetsEnteries(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export function getEmployerDoorKeys(
  employerId: number
): Promise<DoorKeysInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerDoorKeys(employerId),
    local.error.title,
    `${localizationStore.currentLocal.actions.doorKeys.ErrorGetDoorKeys}${
      local.contactSupport
    }`
  );
}

export function getEmployerDocumentation(
  employerId: number
): Promise<DocumentationInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerDocumentation(employerId),
    local.error.title,
    `${local.error.fetchError(local.area.documents)}${local.contactSupport}`
  );
}

export function getEmployerDoorKeyApplicationList(
  empId: number
): Promise<DoorKeyApplication[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getEmployerDoorKeyApplicationList(empId),
    local.error.title,
    `${local.error.fetchError(
      local.area.application,
      local.area.organization
    )}${local.contactSupport}`
  );
}

export function getEmployerDoorKeyApplication(
  applicId: number
): Promise<DoorKeyApplication> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getDoorKeyApplication(applicId),
    local.error.title,
    `${local.error.fetchError(
      local.area.application,
      local.area.organization
    )}${local.contactSupport}`
  );
}
