import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import styles from "./user-debug.css";
import * as stores from "./../../stores";

@observer
class UserDebug extends React.Component {
  @observable visible: boolean;
  userLoadedInitial: boolean;

  @observable text: string;
  @observable info: string;

  constructor() {
    super(undefined);
    this.text = "not yet loaded";
  }

  @action
  toggleDebug() {
    this.visible = !this.visible;
  }

  @action
  submit() {
    try {
      const jsonData = JSON.parse(this.text);
      stores.userStore.setUserRights(jsonData);
      this.text = JSON.stringify(jsonData, null, "\t");
      this.info = "OK";
    } catch (e) {
      this.info = `errpor parsing data: ${e.message}`;
    }
  }

  @action("Update user debug text")
  textChanged(e: string) {
    this.text = e;
  }

  render() {
    if (!this.userLoadedInitial && stores.userStore.userIdentity) {
      this.userLoadedInitial = true;
      const txt = JSON.stringify(stores.userStore.userIdentity, null, "\t");
      this.textChanged(txt);
    }

    return (
      <div className={styles.debugOuter}>
        <button onClick={() => this.toggleDebug()}>User debug</button>
        {this.visible ? (
          <div className={styles.debugArea}>
            <textarea
              className={styles.inputArea}
              onChange={e => this.textChanged(e.target.value)}
              value={this.text}
            />
            <button onClick={() => this.submit()}>Submit</button>
            <p>{this.info}</p>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default UserDebug;
