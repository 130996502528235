import * as repo from "./../repo";
import { Attestor } from "../types";
import { errorStore } from "../stores/index";
import { localizationStore } from "./../stores";

export async function getAttestorWithToken(token: string): Promise<Attestor> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getAttestorWithToken(token),
    local.error.title,
    `${local.error.fetchError(local.area.application)}${local.contactSupport}`
  );
}

export async function postAttestor(
  applicationId: number,
  email: string,
  name: string,
  title: string,
  phone: number
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postApprover(applicationId, email, name, title, phone, false),
    local.error.title,
    `${local.error.sendError(local.area.attestor)}${local.contactSupport}`
  );
}

export async function getAttestorLink(applicationId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getApproverLink(applicationId),
    local.error.title,
    `${local.error.sendError(local.area.attestor)}${local.contactSupport}`
  );
}

export async function replaceAttestor(
  newAttestor: Attestor,
  applicationId: number,
  skipMail: boolean
): Promise<Attestor> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    async () => {
      await repo.deleteAttestor(applicationId);
      return await repo.postApprover(
        applicationId,
        newAttestor.email,
        newAttestor.name,
        newAttestor.title,
        newAttestor.phone,
        skipMail
      );
    },
    local.error.title,
    `${local.error.sendError(local.area.attestor)}${local.contactSupport}`
  );
}

export async function resendApplicationAttestor(
  applicationId: number,
  email: string,
  name: string,
  title: string,
  phone: number
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.postApplicationResendAttestor(
        applicationId,
        email,
        name,
        title,
        phone
      ),
    local.error.title,
    `${local.error.sendError(local.area.attestor)}${local.contactSupport}`
  );
}

export async function updateAttestor(
  attestor: Attestor,
  token: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putAttestor(attestor, token),
    local.error.title,
    `${local.error.updateError(local.area.attestor)}${local.contactSupport}`
  );
}
