import styles from "./app.css";
import * as React from "react";
import Header from "./../../shared/header/header";
import { ErrorLogger } from "./../../shared/errors/error-logger";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { observable, runInAction, configure } from "mobx";
import { observer } from "mobx-react";

// import TreeTest from "./../../routes/tree-test/tree-test";

import UserDebug from "./../../shared/user-debug";
import { userStore, organisationStore } from "./../../stores";

// declares a variables attached to windows through a script file in index.html
import { appConfig } from "./../../config";
/*
ROUTES
*/
import * as Routes from "./../view/route-paths";
import * as routeComponents from "../../async-routes/async-routes";
import { LoadingSpinner } from "../../libs/loading-spinner/loading-spinner";
import { AsyncComponent } from "./../../async-routes/async-route-wrapper";
import hasRight from "./rights";
import { RouteProps } from "../../types";
import { UpdateVersion } from "../../shared/update-version/update-version";

configure({ enforceActions: "observed" });

const ROUTES_WITHOUT_AUTH = [
  Routes.IsInviteUrl,
  Routes.IsAttestUrl,
  (path: string) => Routes.GetTosRoute() === path,
  Routes.IsRegisterNewUserUrl
];

@observer
export default class App extends React.Component {
  @observable
  appInitDone: boolean;
  @observable
  authenticated: boolean;

  constructor() {
    super(undefined);
    this.appInitDone = false;
    this.authenticated = false;
  }

  componentDidMount() {
    this.initData();
  }

  async initData() {
    runInAction("App loading started", () => {
      this.appInitDone = false;
      this.authenticated = false;
    });

    // Is this a route we should skip auth on?
    let shouldBeAuthenticated = true;
    for (const isRoute of ROUTES_WITHOUT_AUTH) {
      if (isRoute(window.location.pathname)) {
        shouldBeAuthenticated = false;
        break;
      }
    }

    // If we shouldBeAuthenticated, initData on user object should be OK
    // if not we will redirect to auth pages
    if (shouldBeAuthenticated) {
      let authOk = false;
      try {
        await userStore.initData();
        authOk = true;
      } catch (e) {
        authOk = false;
      }
      runInAction("App loading done", () => {
        this.authenticated = authOk;
      });
    }

    runInAction("App loading done", () => {
      this.appInitDone = true;
    });
  }

  render() {
    // Still loading
    if (!this.appInitDone) {
      return <LoadingSpinner background={"white"} />;
    }

    // if ("1" === "1") return <TreeTest />;

    // Current route is in ROUTES_WITHOUT_AUTH OR we have failed user init.
    if (!this.authenticated) {
      return (
        <BrowserRouter basename={appConfig.basename}>
          <ErrorLogger>
            <Switch>
              <RightsRoute
                path={Routes.GetInviteRoute()}
                rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                exact={true}
                component={routeComponents.Invitation}
              />
              <RightsRoute
                path={Routes.GetAttestRoute()}
                rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                exact={true}
                component={routeComponents.AttestApplication}
              />
              <Route
                path={Routes.GetTosRoute()}
                render={(routeProps: RouteProps) => (
                  <AsyncComponent
                    routeProps={routeProps}
                    loadComponent={routeComponents.TosView}
                  />
                )}
              />
              <Route
                path={Routes.GetRegisterUserRoute()}
                render={(routeProps: RouteProps) => (
                  <AsyncComponent
                    routeProps={routeProps}
                    loadComponent={routeComponents.RegisterUser}
                  />
                )}
              />

              {/* FALLBACK TO AUTH 
              Nb!: Not the initData() callback
            
            */}
              <Route
                path={Routes.GetSsoNotAvailableRoute()}
                render={(routeProps: RouteProps) => (
                  <AsyncComponent
                    routeProps={routeProps}
                    loadComponent={routeComponents.AuthSSONotAvailable}
                  />
                )}
              ></Route>

              <Route
                path={Routes.GetAuthRoute()}
                render={(routeProps: RouteProps) => {
                  routeProps.done = () => this.initData();
                  return (
                    <AsyncComponent
                      routeProps={routeProps}
                      loadComponent={routeComponents.Auth}
                    />
                  );
                }}
              />
              <Redirect
                to={{
                  pathname: Routes.GetAuthUrl(),
                  state: { referrer: window.location.pathname }
                }}
              />
            </Switch>
          </ErrorLogger>
        </BrowserRouter>
      );
    }

    // Need approve TOS
    if (userStore.userIdentity && !userStore.userIdentity.tosApproved) {
      return (
        <BrowserRouter basename={appConfig.basename}>
          <ErrorLogger>
            <Route
              render={(routeProps: RouteProps) => {
                routeProps.done = () => this.initData();
                return (
                  <AsyncComponent
                    routeProps={routeProps}
                    loadComponent={routeComponents.Tos}
                  />
                );
              }}
            />
          </ErrorLogger>
        </BrowserRouter>
      );
    }

    // Need to update user
    if (userStore.userIdentity && userStore.userIdentity.allowUpdate) {
      return (
        <BrowserRouter basename={appConfig.basename}>
          <ErrorLogger>
            <Route
              render={(routeProps: RouteProps) => {
                routeProps.done = () => this.initData();
                return (
                  <AsyncComponent
                    routeProps={routeProps}
                    loadComponent={routeComponents.UpdateInfo}
                  />
                );
              }}
            />
          </ErrorLogger>
        </BrowserRouter>
      );
    }

    return (
      <BrowserRouter basename={appConfig.basename}>
        <ErrorLogger>
          <div className={styles.appContainer}>
            {appConfig.debug ? <UserDebug /> : false}
            <Route path={"*"} component={Header} />
            <div className={styles.contentContainer}>
              <RightsRoute
                path={Routes.GetOrganisationAssetListRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <RightsRoute
                path={Routes.GetOrganisationRightsRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <RightsRoute
                path={Routes.GetOrganisationRolesRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <RightsRoute
                path={Routes.GetOrganisationTemplateRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <RightsRoute
                path={Routes.GetOrganisationDoorkeyTemplateRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <RightsRoute
                path={Routes.GetOrganisationAssetMessagesRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <RightsRoute
                path={Routes.GetOrganisationConstructionProjectsRoute()}
                rightsIdType={RightsIdType.Org}
                component={routeComponents.SideBar}
                disableRenderNoAccess={true}
              />
              <Switch>
                <Redirect
                  from={Routes.GetAuthRoute()}
                  to={Routes.GetMyPermittoUrl()}
                />
                <Redirect
                  exact={true}
                  from="/"
                  to={Routes.GetMyPermittoUrl()}
                />
                {/* -- special case because this url is hardcode in an external app*/}
                <Redirect
                  exact={true}
                  from="/organisation/:orgId/safetycards"
                  to="/organisation/:orgId/card/type/1"
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetOrganisationPermittoRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminSplashView}
                />
                <RightsRoute
                  exact={true}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                  path={Routes.GetMyPermittoRoute()}
                  component={routeComponents.MySplashView}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetOurPermittoRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurSplashView}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyProfileRoute()}
                  component={routeComponents.MyProfile}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyExternalCardsRoute()}
                  component={routeComponents.MyExternalCards}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyAccessRoute()}
                  component={routeComponents.MyAccess}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyAccessLogRoute()}
                  component={routeComponents.MyAccessLog}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  path={Routes.GetOurAccessRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurAccess}
                />
                <RightsRoute
                  path={Routes.GetOurProfileRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurProfile}
                />
                <RightsRoute
                  path={Routes.GetOurExternalCardsRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurExternalCards}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetOurCardRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurSafetycard}
                />
                <RightsRoute
                  path={Routes.GetOurCardsListRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurSafetycardList}
                />
                <RightsRoute
                  path={Routes.GetOurPersonnelListRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurPersonList}
                />
                <RightsRoute
                  path={Routes.GetOurPersonRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurPerson}
                />
                <RightsRoute
                  path={Routes.GetMyCardsListRoute()}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                  exact={true}
                  component={routeComponents.MyCards}
                />
                <RightsRoute
                  path={Routes.GetMyCardRoute()}
                  exact={true}
                  component={routeComponents.MySafetycard}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyApplicationListRoute()}
                  component={routeComponents.MyApplicationList}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyApplicationRoute()}
                  component={routeComponents.MyApplication}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyDoorKeyApplicationViewRoute()}
                  component={routeComponents.MyDoorKeyApplicationView}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                />
                <RightsRoute
                  exact={true}
                  path={Routes.GetMyDoorKeysRoute()}
                  rightsIdType={RightsIdType.NoRightsCheckOnRoute}
                  component={routeComponents.MyDoorKeys}
                />
                <RightsRoute
                  path={Routes.GetOurApplicationListRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurApplicationList}
                />
                <RightsRoute
                  path={Routes.GetOurAccessLogRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurAccessLog}
                />
                <RightsRoute
                  path={Routes.GetOurApplicationRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurApplication}
                />
                <RightsRoute
                  path={Routes.GetOurDoorKeyApplicationViewRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurDoorKeyApplicationView}
                />
                <RightsRoute
                  path={Routes.GetOurDoorKeyApplicationsRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurDoorkeyApplication}
                />
                <RightsRoute
                  path={Routes.GetOurDoorKeysRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurDoorKeys}
                />
                <RightsRoute
                  path={Routes.GetOurDocumentationRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.OurDocumentation}
                />
                <RightsRoute
                  path={Routes.GetOrganisationApprovalListRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminApplicationListForApproval}
                />
                <RightsRoute
                  path={Routes.GetOrganisationEmployersRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminEmpoyers}
                />
                <RightsRoute
                  path={Routes.GetOrganisationIssueListRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminApplicationListForIssue}
                />
                <RightsRoute
                  path={Routes.GetOrganisationIssueRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminApplicationIssue}
                />
                <RightsRoute
                  path={Routes.GetOrganisationApprovalRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminApplicationApprove}
                />
                <RightsRoute
                  path={Routes.GetOrganisationAccessRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.OrgAssetEntries}
                />
                <RightsRoute
                  path={Routes.GetOrganisationPersonnelListRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminPersonList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationPersonRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminPerson}
                />
                <RightsRoute
                  path={Routes.GetOrganisationCardRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminSafetycard}
                />
                <RightsRoute
                  path={Routes.GetOrganisationCardsListRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminSafetycardList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationAssetListRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AssetAdminList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationExternalCardsRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminOtherCardsList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationTemplateRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminTemplates}
                />
                <RightsRoute
                  path={Routes.GetOrganisationDoorkeyTemplateRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminDoorKeyTemplates}
                />
                <RightsRoute
                  path={Routes.GetOrganisationAssetMessagesRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminAssetMessages}
                />
                <RightsRoute
                  path={Routes.GetOrganisationRightsRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminOrgRights}
                />
                <RightsRoute
                  path={Routes.GetOrganisationRolesRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminOrgRoles}
                />
                <RightsRoute
                  path={Routes.GetOurRightsRoute()}
                  rightsIdType={RightsIdType.Employer}
                  component={routeComponents.AdminEmployerRights}
                />
                <RightsRoute
                  path={Routes.GetOrganisationAccessLogRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminAccessLog}
                />
                <RightsRoute
                  path={Routes.GetOrganisationDoorKeyApplicationViewRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminDoorKeyApplicationView}
                />
                <RightsRoute
                  path={Routes.GetOrganisationDoorKeyApplicationsRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminDoorKeyApplicationsList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationDoorKeysRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminDoorKeysList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationDocumentationRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminDocumentationList}
                />
                <RightsRoute
                  path={Routes.GetOrganisationConstructionProjectsRoute()}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.AdminConstructionProjects}
                />
                <RightsRoute
                  path={Routes.GetMyDoorKeyApplicationsRoute()}
                  exact={true}
                  rightsIdType={RightsIdType.Org}
                  component={routeComponents.MyDoorkeyApplication}
                />
                <Route
                  path={Routes.NotFound}
                  render={(routeProps: RouteProps) => (
                    <AsyncComponent
                      routeProps={routeProps}
                      loadComponent={routeComponents.NotFound}
                    />
                  )}
                />
              </Switch>
            </div>
            {appConfig.debug ? (
              false
            ) : (
              <Route path={"*"} component={UpdateVersion} />
            )}
          </div>
        </ErrorLogger>
      </BrowserRouter>
    );
  }
}

enum RightsIdType {
  Org,
  Employer,
  NoRightsCheckOnRoute
}

class RightsRoute extends React.Component<
  {
    path: string;
    component: any;
    rightsIdType: RightsIdType;
    exact?: boolean;
    disableRenderNoAccess?: boolean;
  },
  {}
> {
  onRouteRender = (routeProps: RouteProps) => {
    let id;
    switch (this.props.rightsIdType) {
      case RightsIdType.Org:
        id = parseInt(routeProps.match.params.orgId, 10);
        break;
      case RightsIdType.Employer:
        id = parseInt(routeProps.match.params.employerId);
        break;
      case RightsIdType.NoRightsCheckOnRoute:
        id = -1;
        break;
    }

    if (
      hasRight(
        userStore,
        routeProps.match.path,
        organisationStore.organisations,
        id
      )
    ) {
      return (
        <AsyncComponent
          routeProps={routeProps}
          loadComponent={this.props.component}
        />
      );
    } else {
      if (this.props.disableRenderNoAccess) {
        return null;
      } else {
        return (
          <AsyncComponent
            routeProps={routeProps}
            loadComponent={routeComponents.NoAccess}
          />
        );
      }
    }
  };

  render() {
    return (
      <Route
        path={this.props.path}
        exact={this.props.exact}
        render={this.onRouteRender}
      />
    );
  }
}
