import { Local } from "./../shared/localization/local";
import { Eng } from "./../shared/localization/en";
import { Nor } from "./../shared/localization/no";
import { Swe } from "./../shared/localization/se";
import { Ned } from "./../shared/localization/nl";
import { appConfig } from "./../config";
import { userStore } from "./user-store";
import { observable, action } from "mobx";

export class Localization {
  @observable currentLocal: Local;

  getLanguageFromAppConfig(): Local {
    switch (appConfig.locale) {
      case "en":
      case "en-GB":
      case "en-US":
        return Eng;
      case "no":
      case "nb":
        return Nor;
      case "se":
        return Swe;
      case "nl":
        return Ned;
      default:
        return Eng;
    }
  }

  constructor() {
    this.currentLocal = getPreferredLanguage() === "no" ? Nor : Eng;
  }

  @action
  initData = () => {
    this.currentLocal = this.getLanguageFromAppConfig();
  };

  @action
  updateCurrentLocal(language?: string) {
    if (language) {
      switch (language) {
        case "no":
          this.currentLocal = Nor;
          break;
        case "se":
          this.currentLocal = Swe;
          break;
        case "en":
          this.currentLocal = Eng;
          break;
        case "nl":
          this.currentLocal = Ned;
          break;
        default:
          this.currentLocal = Eng;
          break;
      }
    } else if (userStore) {
      //this.currentLocal =
      if (userStore.userIdentity) {
        switch (userStore.userIdentity.preferences.preferredLanguage) {
          case "no":
            this.currentLocal = Nor;
            break;
          case "se":
            this.currentLocal = Swe;
            break;
          case "en":
            this.currentLocal = Eng;
            break;
          case "nl":
            this.currentLocal = Ned;
            break;
          default:
            this.currentLocal = Eng;
            break;
        }
      } else {
        this.currentLocal = this.getLanguageFromAppConfig();
      }
    } else {
      this.currentLocal = this.getLanguageFromAppConfig();
    }
  }
}

export function getPreferredLanguage(): string {
  if (
    userStore &&
    userStore.userIdentity &&
    userStore.userIdentity.preferences.preferredLanguage
  ) {
    return userStore.userIdentity.preferences.preferredLanguage;
  } else if (localizationStore && localizationStore.currentLocal) {
    return localizationStore.currentLocal.language;
  } else {
    if (
      (navigator as any).language.toLowerCase().indexOf("nn") > -1 ||
      (navigator as any).language.toLowerCase().indexOf("no") > -1
    )
      return "no";
    else if (
      (navigator as any).language.toLowerCase().indexOf("sv") > -1 ||
      (navigator as any).language.toLowerCase().indexOf("se") > -1
    )
      return "se";
    else if ((navigator as any).language.toLowerCase().indexOf("nl") > -1)
      return "nl";
    else {
      return "en";
    }
  }
}

export const localizationStore = new Localization();
