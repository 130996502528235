import {
  Our,
  EmployerIdParameter,
  ApplicationIdParameter,
  CardsList,
  Type,
  TypeIdParameter,
  Card,
  CardIdParameter,
  PersonnelList,
  Person,
  PersonIdParameter,
  Access,
  AccessLog,
  ApplicationList,
  Documentation,
  DoorKeys,
  ExternalCards,
  Splash,
  Profile,
  Rights,
  Application
} from "../consts";

export function GetOurCardsListRoute() {
  return Our + EmployerIdParameter + CardsList + Type + TypeIdParameter;
}

export function GetOurCardRoute() {
  return (
    Our +
    EmployerIdParameter +
    CardsList +
    Type +
    TypeIdParameter +
    Card +
    CardIdParameter
  );
}

export function GetOurApplicationListRoute() {
  return Our + EmployerIdParameter + ApplicationList;
}

export function GetOurApplicationRoute() {
  return Our + EmployerIdParameter + Application + ApplicationIdParameter;
}

export function GetOurPersonnelListRoute() {
  return Our + EmployerIdParameter + PersonnelList;
}

export function GetOurPersonRoute() {
  return Our + EmployerIdParameter + Person + PersonIdParameter;
}

export function GetOurRightsRoute() {
  return Our + EmployerIdParameter + Rights;
}

export function GetOurPermittoRoute() {
  return Our + EmployerIdParameter + Splash;
}

export function GetOurProfileRoute() {
  return Our + EmployerIdParameter + Profile;
}

export function GetOurExternalCardsRoute() {
  return Our + EmployerIdParameter + ExternalCards;
}

export function GetOurAccessRoute() {
  return Our + EmployerIdParameter + Access;
}

export function GetOurAccessLogRoute() {
  return Our + EmployerIdParameter + AccessLog;
}

export function GetOurDocumentationRoute() {
  return `${Our}${EmployerIdParameter}${Documentation}`;
}

export function GetOurDoorKeysRoute() {
  return `${Our}${EmployerIdParameter}${DoorKeys}`;
}

export function GetOurDoorKeyApplicationViewRoute() {
  return `${Our}${EmployerIdParameter}${DoorKeys}${ApplicationList}${ApplicationIdParameter}`;
}

export function GetOurDoorKeyApplicationsRoute() {
  return `${Our}${EmployerIdParameter}${DoorKeys}${ApplicationList}`;
}
