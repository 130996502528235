import styles from "./header.css";
import * as React from "react";
import { PermittoRights } from "../../types";
import { organisationStore, employerStore } from "../../stores";
import * as Routes from "./../../app/view/route-paths";
import { computed } from "mobx";
import { observer } from "mobx-react";

interface ContextSwitcherItemProps {
  value: string[];
  excludedValues?: string[];
  text: string;
  selectedValue?: string;
  onSelect?: Function;
  imgUrl: string;
  dropdownContent: PermittoRights[];
  dropdownOpen: boolean;
}

@observer
export default class ContextSwitcherItem extends React.Component<
  ContextSwitcherItemProps
> {
  isNotExcluded = (): boolean => {
    if (!this.props.excludedValues) {
      return true;
    }
    return !this.props.excludedValues.some(e =>
      this.props.selectedValue.startsWith(e)
    );
  };

  getName = (id: number): string => {
    if (organisationStore.organisations.has(id)) {
      return organisationStore.organisations.get(id).name;
    } else if (employerStore.employers.find(emp => emp.organizationNo === id)) {
      return employerStore.employers.find(emp => emp.organizationNo === id)
        .name;
    }
    return "";
  };

  @computed
  get sortedDropdownList() {
    const { dropdownContent } = this.props;
    if (
      dropdownContent &&
      dropdownContent.length &&
      dropdownContent.length > 0
    ) {
      return dropdownContent
        .map(a => ({ id: a.id, name: this.getName(a.id) }))
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
    }
    return undefined;
  }

  render() {
    const { value, selectedValue } = this.props;
    let isSelected: boolean;
    if (this.isNotExcluded()) {
      isSelected = value.some(v => selectedValue.startsWith(v));
    } else {
      isSelected = false;
    }
    const urlPart = isSelected ? "grey/" : "white/white";

    const imgUrl = `res/menu-icons/${urlPart}Permitto-icon-RGB-context-${this.props.imgUrl}.svg`;

    return (
      <div
        className={styles.itemContainer}
        onClick={() => {
          this.props.onSelect({
            target: { value: value[0] }
          });
        }}
      >
        <img
          className={isSelected ? styles.imgSelected : styles.itemImg}
          src={imgUrl}
        />
        <div className={isSelected ? styles.itemTextSelected : styles.itemText}>
          {this.props.text}
        </div>
        {this.props.dropdownContent && this.props.dropdownOpen && (
          <div className={styles.dropdown}>
            <div className={styles.dropdownMenuArrow} />

            <div className={styles.dropdownMenu}>
              {this.sortedDropdownList.map(a => {
                return (
                  <div
                    key={`header-dropdown-menu-content-${a.id}`}
                    className={styles.dropdownContentContainer}
                    onClick={() => {
                      this.props.onSelect({
                        target: {
                          value: value[0] + a.id + Routes.Splash
                        }
                      });
                    }}
                  >
                    <div className={styles.dropdownMenuContent}>{a.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
