import {
  organisationStore,
  employerStore,
  localizationStore
} from "../../stores";
import * as Routes from "./../../app/view/route-paths";
import { getSafetyCardLocalizedText } from "../../data-utillities/safety-card-utils";
import {
  getOurConnectedOrgs,
  getMyConnectedOrgs
} from "../../data-utillities/rights-utils";

export function getCurrentContextNameAndUrl(
  currentUrl: string
): { name: string; url: string } {
  let url = "";
  let name = "";

  if (currentUrl.startsWith(Routes.Org)) {
    const orgId = parseInt(currentUrl.split(Routes.Org)[1].split("/")[0]);
    url = Routes.GetOrganisationPermittoUrl(orgId);

    name = organisationStore.organisations.get(orgId)
      ? localizationStore.currentLocal.shared.header.AssetOwner(
          organisationStore.organisations.get(orgId).name
        )
      : "";
  } else if (currentUrl.startsWith(Routes.Our)) {
    const orgId = parseInt(currentUrl.split(Routes.Our)[1].split("/")[0]);
    url = Routes.GetOurPermittoUrl(orgId);
    name = employerStore.employers.find(a => a.organizationNo === orgId)
      ? localizationStore.currentLocal.shared.header.ServiceProvider(
          employerStore.employers.find(a => a.organizationNo === orgId).name
        )
      : "";
  } else {
    url = Routes.GetMyPermittoUrl();
    name = localizationStore.currentLocal.shared.header.MyPermitto;
  }
  return { name, url };
}

function extractIdFromUrl(currentUrl: string, idToExtract: string): number {
  if (currentUrl.indexOf(idToExtract) > -1) {
    const id = parseInt(currentUrl.split(idToExtract)[1].split("/")[0]);
    return id ? id : null;
  }
  return null;
}

export function routeShouldHaveSubDivider(currentUrl: string) {
  const matches = [
    Routes.IsOrganisationPersonUrl,
    Routes.IsOrganisationCardUrl,
    Routes.IsOurPersonUrl,
    Routes.IsOurCardUrl,
    Routes.IsOurApplicationUrl,
    Routes.IsOrganisationIssueUrl,
    Routes.IsOrganisationApprovalUrl,
    Routes.IsMyApplicationUrl,
    Routes.IsMyCardUrl
  ];

  return matches.some(m => m(currentUrl));
}

export function getCurrentRouteFirstLevelNameAndUrl(
  currentUrl: string
): { name: string; url: string } {
  let url = null;
  let name = "";
  if (Routes.IsOrganisationIssueListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.IssueApplication(
      getSafetyCardLocalizedText(
        extractIdFromUrl(currentUrl, Routes.Org)
          ? [
              organisationStore.organisations.get(
                extractIdFromUrl(currentUrl, Routes.Org)
              )
            ]
          : [],
        -1
      )
    );
  } else if (Routes.IsOrganisationIssueUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.IssueApplication(
      getSafetyCardLocalizedText(
        extractIdFromUrl(currentUrl, Routes.Org)
          ? [
              organisationStore.organisations.get(
                extractIdFromUrl(currentUrl, Routes.Org)
              )
            ]
          : [],
        -1
      )
    );
    url = Routes.GetOrganisationIssueListUrl(
      extractIdFromUrl(currentUrl, Routes.Org)
    );
  } else if (Routes.IsOrganisationApprovalListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.ApplicationsForApproval;
  } else if (Routes.IsOrganisationApprovalUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.ApplicationsForApproval;
    url = Routes.GetOrganisationApprovalListUrl(
      extractIdFromUrl(currentUrl, Routes.Org)
    );
  } else if (Routes.IsOurApplicationListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurApplications;
  } else if (Routes.IsMyApplicationListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyApplications;
  } else if (Routes.IsOurApplicationUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurApplications;
    url = Routes.GetOurApplicationListUrl(
      extractIdFromUrl(currentUrl, Routes.Our)
    );
  } else if (Routes.IsMyApplicationUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyApplications;
    url = Routes.GetMyApplicationListUrl();
  } else if (Routes.IsOrganisationCardsListUrl(currentUrl)) {
    name = getSafetyCardLocalizedText(
      extractIdFromUrl(currentUrl, Routes.Org)
        ? [
            organisationStore.organisations.get(
              extractIdFromUrl(currentUrl, Routes.Org)
            )
          ]
        : [],
      extractIdFromUrl(currentUrl, Routes.Type)
    );
  } else if (Routes.IsOrganisationCardUrl(currentUrl)) {
    name = getSafetyCardLocalizedText(
      extractIdFromUrl(currentUrl, Routes.Org)
        ? [
            organisationStore.organisations.get(
              extractIdFromUrl(currentUrl, Routes.Org)
            )
          ]
        : [],
      -1,
      true
    );

    url = Routes.GetOrganisationCardsListUrl(
      extractIdFromUrl(currentUrl, Routes.Org),
      extractIdFromUrl(currentUrl, Routes.Type)
    );
  } else if (Routes.IsOurCardListUrl(currentUrl)) {
    const cardTypeId = extractIdFromUrl(currentUrl, Routes.Type);
    name = localizationStore.currentLocal.shared.header.OurCards(
      getSafetyCardLocalizedText(
        extractIdFromUrl(currentUrl, Routes.Our)
          ? getOurConnectedOrgs(extractIdFromUrl(currentUrl, Routes.Our))
          : [],
        cardTypeId
      )
    );
  } else if (Routes.IsOurCardUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurCards(
      getSafetyCardLocalizedText(
        extractIdFromUrl(currentUrl, Routes.Our)
          ? getOurConnectedOrgs(extractIdFromUrl(currentUrl, Routes.Our))
          : [],
        -1
      )
    );
    url = Routes.GetOurCardsListUrl(
      extractIdFromUrl(currentUrl, Routes.Our),
      extractIdFromUrl(currentUrl, Routes.Type)
    );
  } else if (Routes.IsMyCardsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyCards(
      getSafetyCardLocalizedText(getMyConnectedOrgs(), -1)
    );
  } else if (Routes.IsMyCardsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyOtherCards;
  } else if (Routes.IsMyCardUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyCards(
      getSafetyCardLocalizedText(getMyConnectedOrgs(), -1)
    );
    url = Routes.GetMyCardsListUrl();
  } else if (Routes.IsOurPersonnelListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurPersonnel;
  } else if (Routes.IsOurPersonUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurPersonnel;
    url = Routes.GetOurPersonnelListUrl(
      extractIdFromUrl(currentUrl, Routes.Our)
    );
  } else if (Routes.IsOrganisationPersonnelListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Personnel;
  } else if (Routes.IsOrganisationPersonUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Personnel;
    url = Routes.GetOrganisationPersonnelListUrl(
      extractIdFromUrl(currentUrl, Routes.Org)
    );
  } else if (Routes.IsOrganisationExternalCardsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.AdminOtherCards;
  } else if (Routes.IsOrganisationAssetListUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.AdminAsset;
  } else if (Routes.IsOrganisationAssetUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.AdminAsset;
    url = Routes.GetOrganisationAssetListUrl(
      extractIdFromUrl(currentUrl, Routes.Org)
    );
  } else if (Routes.IsOrganisationRightsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.UserRights;
  } else if (Routes.IsOurRightsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.GroupRights;
  } else if (Routes.IsOurProfileUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurProflie;
  } else if (Routes.IsOurExternalCardsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurOtherCards;
  } else if (Routes.IsMyProfileUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyProfile;
  } else if (Routes.IsOrganisationAccessUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Accesses;
  } else if (Routes.IsOurAccessUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurAccess;
  } else if (Routes.IsMyAccessUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyAccess;
  } else if (Routes.IsOrganisationAccessLogUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.AccessHistory;
  } else if (Routes.IsOrganisationEmployersUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Employers;
  } else if (Routes.IsOurAccessLogUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurAccessHistory;
  } else if (Routes.IsMyAccessLogUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyAccessHistory;
  } else if (Routes.IsOrganisationDoorkeyApplicationsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.DoorkeyApplications;
  } else if (Routes.IsOrganisationDoorkeysUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Doorkeys;
  } else if (Routes.IsMyDoorkeyApplicationsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyDoorkeyApplications;
  } else if (Routes.IsOurDoorkeyApplicationsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurDoorkeyApplications;
  } else if (Routes.IsOurDoorkeysUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurDoorkeys;
  } else if (Routes.IsMyDoorkeysUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.MyDoorkeys;
  } else if (Routes.IsOrganisationDocumentation(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Documentation;
  } else if (Routes.IsOurDocumentation(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OurDocumentation;
  } else if (Routes.IsOrganisationConstructionProjectsUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.Projects;
  } else if (Routes.IsOrganisationTemplatesUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OrganisationTemplates;
  } else if (Routes.IsOrganisationDoorkeyTemplatesUrl(currentUrl)) {
    name = localizationStore.currentLocal.shared.header.OrganisationTemplates;
  } else if (Routes.IsOrganisationAssetMessagesUrl(currentUrl)) {
    name =
      localizationStore.currentLocal.shared.header.OrganisationAssetMessages;
  }

  return { name, url };
}

export function getHeaderIcon(currentUrl: string) {
  if (
    Routes.IsOrganisationCardsListUrl(currentUrl) ||
    Routes.IsOrganisationCardUrl(currentUrl) ||
    Routes.IsOrganisationExternalCardsUrl(currentUrl) ||
    Routes.IsOurCardListUrl(currentUrl) ||
    Routes.IsOurCardUrl(currentUrl) ||
    Routes.IsMyCardsUrl(currentUrl) ||
    Routes.IsMyCardUrl(currentUrl) ||
    Routes.IsMyCardsUrl(currentUrl) ||
    Routes.IsOurExternalCardsUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-Securitycard.svg";
  } else if (
    Routes.IsOrganisationPersonnelListUrl(currentUrl) ||
    Routes.IsOrganisationPersonUrl(currentUrl) ||
    Routes.IsOurPersonnelListUrl(currentUrl) ||
    Routes.IsOurPersonUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-Personnel.svg";
  } else if (
    Routes.IsMyAccessUrl(currentUrl) ||
    Routes.IsOurAccessUrl(currentUrl) ||
    Routes.IsOrganisationAccessUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-access-NEW.svg";
  } else if (
    Routes.IsOrganisationApprovalListUrl(currentUrl) ||
    Routes.IsOrganisationApprovalUrl(currentUrl) ||
    Routes.IsOrganisationIssueListUrl(currentUrl) ||
    Routes.IsOrganisationIssueUrl(currentUrl) ||
    Routes.IsOurApplicationListUrl(currentUrl) ||
    Routes.IsMyApplicationListUrl(currentUrl) ||
    Routes.IsOurApplicationUrl(currentUrl) ||
    Routes.IsMyApplicationUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-Permit.svg";
  } else if (
    Routes.IsOrganisationAccessLogUrl(currentUrl) ||
    Routes.IsOrganisationAssetListUrl(currentUrl) ||
    Routes.IsOrganisationAssetUrl(currentUrl) ||
    Routes.IsOurAccessLogUrl(currentUrl) ||
    Routes.IsMyAccessLogUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-Site.svg";
  } else if (
    Routes.IsOrganisationProfileUrl(currentUrl) ||
    Routes.IsOurProfileUrl(currentUrl) ||
    Routes.IsMyProfileUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-My-profile.svg";
  } else if (
    Routes.IsOrganisationRightsUrl(currentUrl) ||
    Routes.IsOurRightsUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-Personnel.svg";
  } else if (Routes.IsOrganisationEmployersUrl(currentUrl)) {
    return "Permitto-icon-RGB-suppliers.svg";
  } else if (
    Routes.IsOrganisationDoorkeysUrl(currentUrl) ||
    Routes.IsOurDoorkeysUrl(currentUrl) ||
    Routes.IsMyDoorkeysUrl(currentUrl)
  ) {
    return "Permitto-icon-RGB-Keys-module.svg";
  } else if (
    Routes.IsOrganisationDocumentation(currentUrl) ||
    Routes.IsOurDocumentation(currentUrl)
  ) {
    return "Permitto-icon-RGB-Documents.svg";
  } else if (Routes.IsOrganisationAssetMessagesUrl(currentUrl)) {
    return "Permitto-icon-RGB-Comment.svg";
  } else if (Routes.IsOrganisationTemplatesUrl(currentUrl)) {
    return "Permitto-icon-RGB-settings-resized.svg";
  } else if (Routes.IsOrganisationDoorkeyTemplatesUrl(currentUrl)) {
    return "Permitto-icon-RGB-settings-resized.svg";
  } else if (Routes.IsOrganisationConstructionProjectsUrl(currentUrl)) {
    return "Permitto-icon-RGB-settings-resized.svg";
  } else {
    return "";
  }
}
