import {
  DocumentationInfo,
  DocRequirementsInfo,
  DocumentationMasterInfo,
  DocumentRequirement
} from "../types";
import { getMaxDate, Now } from "../shared/utillities/date-formater";
import * as moment from "moment";
import filter from "./../shared/utillities/filter-array";
import * as consts from "./../app/view/const";
import { userStore, organisationStore, localizationStore } from "../stores";
import { CellClassParams } from "ag-grid-community";
import { BackgroudColor, getBackgroundColor } from "./../app/view/const";
import { toJS } from "mobx";

function getDocReqValues(
  documentation: DocumentationMasterInfo[],
  documentRequirements: DocRequirementsInfo[]
) {
  const values: [string, boolean | string][] = [];
  for (const docReq of documentRequirements) {
    const docReqData = documentation.filter(
      document => document.idDocType === docReq.docTypeId
    );

    let value: boolean | string = false;

    if (docReq.numDaysValid > 0) {
      const dateList = getDocumentDateList(docReqData);

      if (dateList.length > 0) {
        value = moment(getMaxDate(dateList), consts.dateFormat)
          .add(docReq.numDaysValid, "days")
          .toISOString();
      }
    } else {
      if (docReqData.length > 0) {
        value = true;
      }
    }

    values.push([createColField(docReq.name), value]);
  }
  return values;
}

function* getExtraColumns(
  filteredDoc: DocumentationInfo[],
  documentRequirements: DocRequirementsInfo[]
) {
  for (const doc of filteredDoc) {
    const extraCols = getDocReqValues(
      doc?.documentation ?? [],
      documentRequirements
    );
    yield { ...toJS(doc), ...Object.fromEntries(extraCols) };
  }
}

export function createColField(name: string): string {
  return name
    .replace(/\s+/g, "")
    .replace(/å+/gi, "aa")
    .replace(/ø+/gi, "oe")
    .replace(/æ+/gi, "ae");
}

export function formatDocumentsForGrid(
  documentRequirements: DocRequirementsInfo[],
  documentationInfo: DocumentationInfo[],
  documentSearchText = ""
): DocumentationInfo[] {
  const filteredDoc = filter(
    documentSearchText,
    documentationInfo,
    d => d?.lastName ?? ""
  );

  const documentList: DocumentationInfo[] = [];

  for (const doc of getExtraColumns(filteredDoc, documentRequirements)) {
    documentList.push(doc);
  }

  return documentList;
}

export function getDocumentDateList(
  docList: DocumentationMasterInfo[]
): Date[] {
  return docList
    .map(element => element.validFrom)
    .filter(validFrom => validFrom)
    .map(validFrom => new Date(validFrom));
}

export function injectValuesDocumentColumns(
  data: DocumentationMasterInfo[],
  documentRequirementList: DocRequirementsInfo[]
) {
  const reqLookup = new Map<number, DocRequirementsInfo>();

  const retval = data
    .sort((a, b) => {
      if (!reqLookup.has(a.idDocType)) {
        const documentRequirements = documentRequirementList.find(
          documentReq => documentReq.docTypeId === a.idDocType
        );
        reqLookup.set(a.idDocType, documentRequirements);
      }

      if (!reqLookup.has(b.idDocType)) {
        const documentRequirements = documentRequirementList.find(
          documentReq => documentReq.docTypeId === b.idDocType
        );
        reqLookup.set(b.idDocType, documentRequirements);
      }

      const aReq = reqLookup.get(a.idDocType);
      const bReq = reqLookup.get(b.idDocType);

      if (aReq && bReq) {
        if (aReq.name === bReq.name) {
          const aDate = moment(a.validFrom ? a.validFrom : a.uploadDate);
          const bDate = moment(b.validFrom ? b.validFrom : b.uploadDate);

          if (aDate.isValid() && bDate.isValid()) {
            return aDate.isBefore(bDate) ? -1 : 1;
          }
        }

        return aReq.name > bReq.name ? 1 : -1;
      }

      return 1;
    })
    .map(document => {
      if (!reqLookup.has(document.idDocType)) {
        const docReq = documentRequirementList.find(
          documentReq => documentReq.docTypeId === document.idDocType
        );
        reqLookup.set(document.idDocType, docReq);
      }

      const documentRequirements = reqLookup.get(document.idDocType);

      const validTo =
        documentRequirements &&
        documentRequirements.numDaysValid > 0 &&
        document.validFrom
          ? moment(document.validFrom)
              .add(documentRequirements.numDaysValid, "days")
              .toISOString()
          : "";
      const documentRequirement = documentRequirements
        ? documentRequirements.name
        : "";
      const uploadedByName = `${document.uploadedByFirstName} ${document.uploadedByLastName}`;

      if (
        document.confirmationComment &&
        document.confirmationComment.length > 0 &&
        !document.fileName
      ) {
        document.fileName =
          localizationStore.currentLocal.shared.file.handle.NoFileExistsGridLabel;
      }

      return {
        ...document,
        validTo,
        uploadedByName,
        documentRequirement
      };
    });
  return retval;
}

function mergeArraysAndRemoveDuplicates(ar1: number[], ar2: number[]) {
  const retVal = ar1.concat(ar2);
  return retVal.filter((item, pos) => retVal.indexOf(item) === pos);
}

export function getOurDocumentRequirements(
  empId: number
): DocumentRequirement[] {
  const reqDocument = new Map<number, DocumentRequirement>();
  const orgIdsList: number[] = mergeArraysAndRemoveDuplicates(
    userStore.userIdentity.ourPermittoRights.length > 0 &&
      userStore.userIdentity.ourPermittoRights.findIndex(a => a.id === empId) >
        -1
      ? userStore.userIdentity.ourPermittoRights.find(a => a.id === empId)
          .organisations
      : userStore.userIdentity.issuePermissions
          .filter(p => p.orgId === empId)
          .map(p => p.orgId),
    []
  );

  for (const orgId of orgIdsList) {
    const docRequirment = organisationStore.organisations.get(orgId);
    if (docRequirment) {
      docRequirment.docRequirements.forEach(docReq => {
        if (!reqDocument.has(docReq.docTypeId))
          reqDocument.set(docReq.docTypeId, docReq);
      });
    }
  }
  return Array.from(reqDocument.values());
}

export function HighlightExpiredDocumentRequirements(
  column: CellClassParams
): string {
  return moment(column.value).isBefore(Now())
    ? getBackgroundColor(BackgroudColor.Red)
    : "";
}
