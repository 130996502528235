import styles from "./modal.css";
import * as React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

export enum ModalSize {
  XLarge,
  Large = 1,
  Medium,
  Small,
  Xsmall
}

interface ModalProps {
  closeModal: () => void;
  closeOnClickOutside: boolean;
  width?: number;
  height?: number;
  size?: ModalSize;
  hasFooter?: boolean;
  overflow?: boolean;
  escMsg?: () => void;
}

interface HeigthWidth {
  height: number;
  width: number;
}

interface CustomStyle {
  height: string;
  width: string;
  overflow?: string;
}

@observer
export default class Modal extends React.Component<ModalProps> {
  @observable
  customStyle: CustomStyle;

  mediaQueryWidth: MediaQueryList;
  mediaQueryHeight: MediaQueryList;

  constructor(props) {
    super(props);

    const size = this.getSize(this.props);
    this.customStyle = {
      height: `${size.height}px`,
      width: `${size.width}px`,
      overflow: this.props.overflow && "auto"
    };
  }

  componentDidMount() {
    this.mediaQueryWidth = window.matchMedia("screen and (max-width: 1199px)");

    if (this.props.width && this.props.width > 1400) {
      this.mediaQueryWidth = window.matchMedia(
        "screen and (max-width: 1499px)"
      );
    }

    this.mediaQueryHeight = window.matchMedia("screen and (max-height: 799px)");

    this.mediaQueryWidth.addListener(this.onWidthChange);
    this.mediaQueryHeight.addListener(this.onHeightChange);

    this.onWidthChange(this.mediaQueryWidth);
    this.onHeightChange(this.mediaQueryHeight);
    addEventListener("keydown", this.handleClose);
  }

  componentWillUnmount() {
    this.mediaQueryWidth.removeListener(this.onWidthChange);
    this.mediaQueryHeight.removeListener(this.onHeightChange);
    removeEventListener("keydown", this.handleClose);
  }

  @action
  setCustomStyle = (newStyle: CustomStyle) => {
    this.customStyle = newStyle;
  };

  onWidthChange = evt => {
    if (evt.matches) {
      this.setCustomStyle({ ...this.customStyle, width: "calc(100% - 20px)" });
    } else {
      const size = this.getSize(this.props);
      this.setCustomStyle({ ...this.customStyle, width: `${size.width}px` });
    }
  };

  onHeightChange = evt => {
    if (evt.matches) {
      this.setCustomStyle({ ...this.customStyle, height: "calc(100% - 20px)" });
    } else {
      const size = this.getSize(this.props);
      this.setCustomStyle({ ...this.customStyle, height: `${size.height}px` });
    }
  };

  handleClose = event => {
    if (event.which === 27) {
      this.props.escMsg ? this.props.escMsg() : this.props.closeModal();
    }
  };

  getSize(props: ModalProps) {
    if (props.height && props.width) {
      return { height: props.height, width: props.width };
    } else if (props.size) {
      return this.getModalSize(props.size);
    } else {
      /* console.error(
        "Please set either width and heigth, or the size prop. Setting default size for now"
      ); */
      return this.getModalSize(ModalSize.Large);
    }
  }

  adjustFooterDimensions = (dimensions: HeigthWidth) => {
    return { width: dimensions.width, height: dimensions.height - 60 };
  };

  getModalSize = (size: ModalSize): HeigthWidth => {
    let defaultModalSize = { width: 700, height: 675 };
    switch (size) {
      case ModalSize.XLarge:
        defaultModalSize = { width: 1200, height: 750 };
        break;
      case ModalSize.Large:
        defaultModalSize = { width: 1000, height: 800 };
        break;
      case ModalSize.Medium:
        defaultModalSize = { width: 700, height: 675 };
        break;
      case ModalSize.Small:
        defaultModalSize = { width: 700, height: 300 };
        break;
      case ModalSize.Xsmall:
        defaultModalSize = { width: 300, height: 175 };
        break;
    }
    return this.props.hasFooter
      ? this.adjustFooterDimensions(defaultModalSize)
      : defaultModalSize;
  };

  _onOutsideClick(e) {
    if (
      this.props.closeOnClickOutside &&
      e.target.className.indexOf &&
      e.target.className.indexOf("ModalBackgroundTinter") === 0
    ) {
      this.props.closeModal();
    }
  }

  render() {
    const size = this.getSize(this.props);
    const style = {
      height: `${size.height}px`,
      width: `${size.width}px`,
      overflow: "none"
    };

    /*
      Check for need to adjust based on mediaQuery
    */
    let mediaQueryWidth = window.matchMedia("screen and (max-width: 999px)");
    if (this.props.width && this.props.width > 1400) {
      mediaQueryWidth = window.matchMedia("screen and (max-width: 1500px)");
    }
    const mediaQueryHeight = window.matchMedia(
      "screen and (max-height: 699px)"
    );
    if (mediaQueryWidth.matches) {
      style.width = "calc(100% - 20px)";
    }
    if (mediaQueryHeight.matches) {
      style.height = "calc(100% - 20px)";
    }

    if (this.props.overflow) {
      style.overflow = "auto";
    }

    return (
      <div
        className={styles.ModalBackgroundTinter}
        onClick={e => {
          this._onOutsideClick(e);
        }}
      >
        <div style={this.customStyle} className={styles.Modal}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class ThreeRowLayout extends React.Component {
  static Header = props => {
    return <div className={styles.ThreeRowLayoutHeader}>{props.children}</div>;
  };

  static Footer = function (props) {
    return <div className={styles.ThreeRowLayoutFooter}>{props.children}</div>;
  };

  static Content = function (props) {
    return <div className={styles.ThreeRowLayoutContent}>{props.children}</div>;
  };

  render() {
    return <div className={styles.ThreeRowLayout}>{this.props.children}</div>;
  }
}
