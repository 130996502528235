import * as repo from "./../repo";
import {
  OrganisationTemplate,
  PersonInfo,
  UserIdentity,
  OrgPendingActions,
  AssetEntry,
  OrgPermittoRights,
  Notification,
  OrgNotificationSchedules,
  DoorKeysInfo,
  DoorKeysCreatePayload,
  DoorKeyLogs,
  DoorKeysUpdatePayload,
  DocumentationInfo,
  TemplateDraft,
  KeyValueType,
  DoorKeyApplication,
  DoorkeyReserveDetails,
  DoorKeyFilters,
  PersonnelAttributeRole,
  PostIssueRule,
  OrgOnDutyPerson
} from "../types";
import { errorStore, organisationStore } from "../stores/index";
import { localizationStore } from "./../stores";
import { getSafetyCardLocalizedText } from "../data-utillities/safety-card-utils";

export function getOrganisationWithToken(
  orgId: number,
  token: string
): Promise<OrganisationTemplate> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrganisationWithToken(orgId, token),
    local.error.title,
    `${local.error.fetchError(local.area.organization)}${local.contactSupport}`
  );
}

export function getOrganisationPersonnel(orgId: number): Promise<PersonInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgPersonnel(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export function getOrganisationOnDutyOfficer(
  orgId: number
): Promise<OrgOnDutyPerson | null> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOnDutyOfficer(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export function setOrganisationOnDutyOfficer(
  orgId: number,
  personnelId: number
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.setOnDutyOfficer(orgId, personnelId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export function getOrganisationRights(orgId: number): Promise<UserIdentity[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrganisationRights(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.rights)}${local.contactSupport}`
  );
}

export function postPersonOrgRights(
  orgId: number,
  personnelId: number,
  rights: OrgPermittoRights
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postOrganisationRightsAdmin(orgId, personnelId, rights),
    local.error.title,
    `${local.error.updateError(local.area.rights, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function deleteOrganisationRights(orgId: number, personnelId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteOrganisationRights(orgId, personnelId),
    local.error.title,
    `${local.error.deleteError(local.area.rights, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function getOrganizationSafetycardsPendingCount(
  orgId: number,
  currentCardTypeId: number
): Promise<OrgPendingActions> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrganizationSafetycardsPendingCount(orgId),
    local.error.title,
    `${local.error.fetchError(
      getSafetyCardLocalizedText(
        [organisationStore.organisations.get(orgId)],
        currentCardTypeId
      )
    )}${local.contactSupport}`
  );
}

export function getOrganizationDoorkeyApplicationPendingCount(
  orgId: number
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrganizationDoorkeyApplicPendingCount(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.keys, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function createPersonOrgRelation(
  orgId: number,
  personnelId: number
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postOrgPersonRelation(orgId, personnelId),
    local.error.title,
    `${local.error.inviteError(local.area.person)}${local.contactSupport}`
  );
}

export function putInviteExistingUser(orgId: number, personnelId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putInviteExistingUser(orgId, personnelId),
    local.error.title,
    `${local.error.inviteError(local.area.person)}${local.contactSupport}`
  );
}

export function getOrgAssetEntries(orgId: number): Promise<AssetEntry[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgAssetEntries(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.personell)}${local.contactSupport}`
  );
}

export function getNotificationValues(orgId: number): Promise<Notification[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getNotificationValues(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.notifications)}${local.contactSupport}`
  );
}

export function putOrgNotificationValues(
  orgId: number,
  personnelId: number,
  notificationsValues: OrgNotificationSchedules
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.setOrgNotifications(orgId, personnelId, notificationsValues),
    local.error.title,
    `${local.error.updateError(local.area.notifications)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationCardTypeList(orgId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgCardTypeList(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationTemplateList(orgId: number, typeId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgTemplateList(orgId, typeId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationDoorkeyApplicTemplateList(orgId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDoorekyApplicTemplateList(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationDraftsList(
  orgId: number,
  typeId: number,
  templateId: number
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgTemplateDraftList(orgId, typeId, templateId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationDoorKeyTemplateDraftsList(orgId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDoorKeyTemplateDraftList(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationAttributeList(orgId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgAttributeList(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getDoorkeyOrganisationAttributeList(orgId: number) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getDoorkeyOrgAttributeList(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.template, local.area.application)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationDoorKeys(
  orgId: number
): Promise<DoorKeysInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDoorKeys(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.keys, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function getOrganisationDoorKeyApplicationList(
  orgId: number,
  archived: boolean
): Promise<DoorKeyApplication[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDoorKeyApplicationList(orgId, archived),
    local.error.title,
    `${local.error.fetchError(local.area.keys, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function getDoorKeyApplication(
  applicId: number
): Promise<DoorKeyApplication> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getDoorKeyApplication(applicId),
    local.error.title,
    `${local.error.fetchError(local.area.keys, local.area.organization)}${
      local.contactSupport
    }`
  );
}

export function approveDoorKeyApplication(
  applicId: number,
  orgId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.putOrganisationApproveDoorKeyApplication(orgId, applicId, comment),
    local.error.title,
    `${local.error.updateError(
      local.area.application,
      local.area.organization
    )}${local.contactSupport}`
  );
}

export function shredDoorKeyApplication(applicId: number): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteDoorKeyApplication(applicId),
    local.error.title,
    `${local.doorKeys.ErrorDeleteKeyModalMessage}${local.contactSupport}`
  );
}

export function rejectDoorKeyApplication(
  applicId: number,
  orgId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () =>
      repo.putOrganisationRejectDoorKeyApplication(orgId, applicId, comment),
    local.error.title,
    `${local.error.updateError(
      local.area.application,
      local.area.organization
    )}${local.contactSupport}`
  );
}

export function getOrgDoorkeysForPerson(
  orgId: number,
  personId: number
): Promise<DoorKeysInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDoorkeysForPerson(orgId, personId),
    local.error.title,
    `${local.doorKeys.ErrorFetchingPersonOrgDoorKeys}${local.contactSupport}`
  );
}

export function getOrganisationDoorKeysFilterByName(
  orgId: number,
  name: string,
  rowCount: number
): Promise<DoorKeyFilters[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrganisationDoorKeysFilterByName(orgId, name, rowCount),
    local.error.title,
    `${local.doorKeys.ErrorFetchingPersonOrgDoorKeys}${local.contactSupport}`
  );
}

export function registerKey(
  orgId: number,
  keyInfo: DoorKeysCreatePayload
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postRegisterKey(orgId, keyInfo),
    local.error.title,
    `${local.doorKeys.ErrorSaveDoorKey}${local.contactSupport}`
  );
}

export function updateKey(
  doorkeyUpdatePayload: DoorKeysUpdatePayload
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putRegisterKey(doorkeyUpdatePayload),
    local.error.title,
    `${local.doorKeys.ErrorSaveDoorKey}${local.contactSupport}`
  );
}

export function reserveDoorKey(
  doorKeyId: number,
  payload: DoorkeyReserveDetails,
  keepPersonnelId: number
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.reserveDoorKey(doorKeyId, payload, keepPersonnelId),
    local.error.title,
    `${local.doorKeys.ErrorSaveDoorKey}${local.contactSupport}`
  );
}

export function discardDoorKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putDiscardKey(doorKeyId, comment),
    local.error.title,
    `${local.doorKeys.ErrorDiscardKey}${local.contactSupport}`
  );
}

export function returnDoorKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putReturnKey(doorKeyId, comment),
    local.error.title,
    `${local.doorKeys.ErrorReturnKey}${local.contactSupport}`
  );
}

export function putOrgLostDoorKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgLostDoorKey(doorKeyId, comment),
    local.error.title,
    `${local.doorKeys.ErrorLostKeyModalMessage}${local.contactSupport}`
  );
}

export function getOrganisationDoorKeysLogsById(
  doorKeyId: number
): Promise<DoorKeyLogs[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDoorKeysLogsById(doorKeyId),
    local.error.title,
    `${local.doorKeys.ErrorHistoryMessage}${local.contactSupport}`
  );
}

export function putOrgSignReturnKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgSignReturnKey(doorKeyId, comment),
    local.error.title,
    `${local.doorKeys.ErrorSignReturnKeyModalMessage}${local.contactSupport}`
  );
}

export function putOrgRecallDoorKey(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgRecallDoorKey(doorKeyId, comment),
    local.error.title,
    `${local.doorKeys.ErrorRecallKeyModalMessage}${local.contactSupport}`
  );
}

export function deleteOrgDoorKey(doorKeyId: number): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deleteOrgDoorKey(doorKeyId),
    local.error.title,
    `${local.doorKeys.ErrorDeleteKeyModalMessage}${local.contactSupport}`
  );
}

export function getOrganisationDocumentation(
  orgId: number
): Promise<DocumentationInfo[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getOrgDocumentation(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.documents)}${local.contactSupport}`
  );
}

export function putOrganisationActiveTemplate(
  orgId: number,
  templateId: number
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgActiveTemplate(orgId, templateId),
    local.error.title,
    `${local.error.activateError(local.area.template)}${local.contactSupport}`
  );
}

export function putOrganisationActiveDoorkeyApplicTemplate(
  orgId: number,
  templateId: number
): Promise<void> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgActiveDoorkeyApplicTemplate(orgId, templateId),
    local.error.title,
    `${local.error.activateError(local.area.template)}${local.contactSupport}`
  );
}

export function putOrganisationTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgTemplate(orgId, template),
    local.error.title,
    `${local.error.saveError(local.area.template)}${local.contactSupport}`
  );
}

export function putOrganisationDoorkeyApplicationTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.putOrgDoorkeyApplicTemplate(orgId, template),
    local.error.title,
    `${local.error.saveError(local.area.template)}${local.contactSupport}`
  );
}

export function postOrganisationTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postOrgTemplate(orgId, template),
    local.error.title,
    `${local.error.saveError(local.area.template)}${local.contactSupport}`
  );
}

export function postOrganisationDoorkeyApplicationTemplate(
  orgId: number,
  template: TemplateDraft
): Promise<TemplateDraft> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postOrgDoorkeyApplicTemplate(orgId, template),
    local.error.title,
    `${local.error.saveError(local.area.template)}${local.contactSupport}`
  );
}

export function getTemplateAttributeTypes(): Promise<KeyValueType[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getTemplateAttributeTypes(),
    local.error.title,
    `${local.error.fetchError(local.area.attributeDataType)}${
      local.contactSupport
    }`
  );
}

export function getPersonnelRoles(
  orgId: number
): Promise<PersonnelAttributeRole[]> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.getPersonnelRoles(orgId),
    local.error.title,
    `${local.error.fetchError(local.area.attributeDataType)}${
      local.contactSupport
    }`
  );
}

export function updatePersonnelRoles(
  orgId: number,
  personnelId: number,
  personnelRoles: PersonnelAttributeRole[]
): Promise<number> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.updatePersonnelRoles(orgId, personnelId, personnelRoles),
    local.error.title,
    `${local.error.fetchError(local.area.attributeDataType)}${
      local.contactSupport
    }`
  );
}

export function postPostIssueRule(
  orgId: number,
  postIssueRule: PostIssueRule
): Promise<PostIssueRule> {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.postPostIssueRule(orgId, postIssueRule),
    local.error.title,
    `${local.error.fetchError(local.area.organization)}${local.contactSupport}`
  );
}

export function deletePostIssueRule(
  orgId: number,
  postIssueRule: PostIssueRule
) {
  const local = localizationStore.currentLocal.actions;
  return errorStore.wrap(
    () => repo.deletePostIssueRule(orgId, postIssueRule),
    local.error.title,
    `${local.error.fetchError(local.area.organization)}${local.contactSupport}`
  );
}
