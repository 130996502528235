import { observable, action } from "mobx";

class GlobalStore {
  @observable loadingCurrentHeader: boolean;
  @observable currentHeader: string;

  @action
  setCurrentHeader(header: string) {
    this.currentHeader = header;
  }

  @action
  setCurrentHeaderLoading(loading: boolean) {
    this.loadingCurrentHeader = loading;
  }
}

export const globalStore = new GlobalStore();
