import * as React from "react";
import { LocalRoutes } from "./local-routes";
import {
  // OrganisationTemplate,
  CardType
} from "../../types";

export const NorRoutes: LocalRoutes = {
  auth: {
    inputPIN: (byEmail: boolean) => `Tast PIN fra ${byEmail ? "epost" : "SMS"}`,
    sendByEmail: "Send pinkode på epost",
    rememberMe: "Husk meg",
    regUser: "Registrer ny bruker",
    login: "Logg inn",
    errorInputPhone: "Tast inn telefonnummer",
    errorSMSSent: "SMS allerede sent. Prøv igjen snart",
    noUserFound: "Ingen bruker funnet for gitt nummer",
    unknownError: "Ukjent feil. Prøv å last siden på nytt",
    errorInputPIN: "Tast inn PIN",
    errorWrongPIN: "Feil pinkode",
    errorMultipleWrongPIN:
      "Feil pinkode for mange ganger, vennligst prøv igjen",
    errorPinMustBeNumber: "Pin må kun være tall",
    accessedByPhone: {
      title: "Advarsel",
      description:
        "Denne nettsiden er ikke tilpasset for mobiltelefoner. Det er en egen Permitto mobil applikasjon som er tilgjengelig for nedlasting.",
      continueWithout: "Fortsett likevel"
    },
    ssoHelpText: "Eller logg inn med",
    ssoNotAvailable:
      "Single Sign On er ikke tilgjengelig for din arbeidsgiver.",
    ssoBackButton: "Tilbake til innlogging"
  },
  attest: {
    alreadyAttestedMessage: (message: string, name: string, datetime: string) =>
      `${message} ${datetime} av ${name}`,
    approvedMessage: "Søknaden ble godkjent",
    rejectMessage: "Søknaden ble avvist"
  },
  admin: {
    applicationApprove: {
      Application: "Søknad",
      ApplicantInfoHeader: "Søker",
      Approve: "Godkjenn",
      ApproveWithoutAttestation: "Godkjenn uten attestering",
      ApprovedWithoutAttestation: "Godkjent uten attestering. Kommentar: ",
      ChangeAttestor: "Endre attestant",
      ApproveCard: (cardName: string) =>
        `Godkjenn ${cardName.toLocaleLowerCase()}`,
      ApproveCardWithoutAttestation: (cardName: string) =>
        `Godkjenn ${cardName.toLocaleLowerCase()} uten attestering`,
      Issued: "Utsted",
      Reject: "Avvis",
      Delete: "Slett",
      DeleteApplicationTitle: "Slett søknad",
      DeleteApplicationText: "Dette vil permanent slette søknaden",
      RejectApplication: "Avvis søknad",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} har ingen tilknyttet søknad. Avvisning vil makulere ${cardName.toLowerCase()}.\nKommentar:`,
      EditApplication: "Editer søknad",
      ResendAttestor: "Send epost",
      GetAttestorLink: "Hent attestantlink"
    },
    applicationIssue: {
      CardNumber: (cardName: string) => `${cardName}nummer`,
      ApplicantInfoHeader: "Søker",
      Approve: "Godkjenn",
      ApproveApplication: "Godkjenn søknad",
      Dismiss: "Angre",
      Issued: "Utsted",
      IssuedCard: (cardName: string) => `Utsteder ${cardName.toLowerCase()}`,
      Reject: "Avvis",
      RejectApplication: "Avvis søknad",
      RejectApplicationNoApplicationPresentComment: (cardName: string) =>
        `${cardName} har ingen tilknyttet søknad. Avvisning vil makulere ${cardName.toLowerCase()}.\nKommentar:`,
      EditApplication: "Editer søknad"
    },
    applicationListForApproval: {
      Open: "Åpne",
      NoApplication: "Ingen søknader klare til godkjenning",
      ApproveApplications: (numApplications: number) =>
        numApplications === 1
          ? `Godkjenn ${numApplications} søknad`
          : `Godkjenn ${numApplications} søknader`,
      ApproveApplicationsDescription:
        "Er du sikker på at du vil godkjenne valgte søknader?",
      ApplicationsApproved: "søknader godkjent",
      DeleteApplications: (numApplications: number) =>
        numApplications === 1
          ? `Slett ${numApplications} søknad`
          : `Slett ${numApplications} søknader`,
      DeleteApplicationsDescription:
        "Er du sikker på at du vil slette valgte søknader?",
      ApplicationsDeleted: "søknader slettet",
      Comment: "Kommentar",
      Action: "Krever aksjon",
      CardType: "Korttype"
    },
    applicationListForIssue: {
      Open: "Åpne",
      NoApplication: (cardName: string) =>
        `Ingen ${cardName} klare til utstedelse`,
      IssueCards: (numCards: number, cardName: string) =>
        `Utsted ${numCards} ${cardName}`,
      AlertTitle: "En feil oppsto",
      AlertText: (cardName: string) =>
        `En feil oppsto under utstedelse av ${cardName.toLowerCase()}`,
      CardsIssued: (cardName: string) => `${cardName} utstedt`,
      NumCardsIssued: (numCards: number) => `${numCards} kort utstedt.`,
      Done: "Ferdig",
      Cancel: "Avbryt",
      IssueCardsDescription: (cardName: string) =>
        `Er du sikker på at du vil utstede valgte ${cardName.toLowerCase()}?`,
      Comment: "Kommentar"
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName}nummer`,
      EditMode: "Redigeringsmodus",
      Save: "Lagre",
      Cancel: "Avbryt",
      Back: "Tilbake",
      Edit: "Rediger",
      CanNotEdit: (cardName: string) =>
        `Det er ikke mulig å redigere et kvittert ${cardName.toLowerCase()}`,
      Empty: "",
      SavingCardInProgress: "Lagrer kort, vennligst vent",
      LiveEditWarning:
        "Dette kortet er allerede utstedt. Bruker vil derfor få en epost som oppsummerer de endringene du gjør. Ønsker du å fortsette? ",
      Shred: "Makuler",
      IsShredded: (cardName: string) => `${cardName} er allerede makulert`,
      AreUSure: "Er du sikker?",
      SureToShred: (cardName: string) =>
        `Er du sikker på at du vil makulere dette ${cardName.toLowerCase()}?`,
      ShredButtonText: "Makuler",
      ShredAndCreateButtonText: "Makuler og opprett ny",
      // ShredAndCreateText: (cardName: string) =>
      //   `Vil du makulere og opprette nytt ${cardName.toLowerCase()}, eller bare makulere?`,
      ShredAndCreateTitle: (cardName: string) =>
        `Makuler ${cardName.toLowerCase()}`,
      CreateNewSecurityCard: (cardName: string) =>
        `Opprett nytt ${cardName.toLowerCase()}`,
      ReNewCard: (cardName: string) => `Forny ${cardName.toLowerCase()}`,
      RenewDisable: (cardName: string) => `${cardName} er ikke makulert`,
      PrintCard: (cardName: string) => `Skriv ut ${cardName.toLowerCase()}`,
      // ShredBeforeRenew: (cardName: string) =>
      //   `Fornying av ${cardName.toLowerCase()} vil makulere det eksisterende ${cardName.toLowerCase()}`,
      ShredAlertTitle: (cardName: string) =>
        `Makuler ${cardName.toLowerCase()}`,
      ShredAlertText: (cardName: string) =>
        `Dette vil makulere ${cardName.toLowerCase()}et`,
      ShredBeforeRenewTitle: (cardName: string) =>
        `Forny ${cardName.toLowerCase()}`,
      ShredBeforeRenewText: (cardName: string) =>
        `Dette vil makulere ${cardName.toLowerCase()}et før det fornyes`
    },
    splashView: {
      Permissions: "Adganger",
      PermHistory: "Adgangshistorikk",
      SafetyCard: (cardName: string) => cardName,
      Personnel: "Personell",
      Applications: "Søknader til godkjenning",
      Issued: (cardName: string) => `${cardName} klare til utstedelse`,
      SysAdm: "Systemadministrasjon",
      Employers: "Leverandører",
      OtherCards: "HMS-kort",
      DoorKeys: "Nøkler",
      Documentation: "Dokumentasjon",
      DoorKeyApplications: "Nøkkelsøknader"
    },
    asset: {
      newModal: {
        header: (parent: string) =>
          parent ? `Opprett ny sone under ${parent}` : `Opprett anlegg`,
        Save: "Lagre",
        Cancel: "Avbryt"
      },
      admin: {
        Back: "Tilbake",
        Edit: "Rediger",
        Header: "",
        Save: "Lagre",
        Cancel: "Avbryt"
      },
      adminList: {
        Description: "Beskrivelse",
        Type: "Type",
        ShowDetails: "Vis detaljer",
        CreateNew: "Opprett ny",
        Search: "Søk",
        Asset: "Anlegg",
        AssetNode: "Gruppe",
        Edit: "Rediger",
        Delete: "Slett",
        AddChild: "Legg til",
        Save: "Lagre",
        Cancel: "Avbryt",
        AddTopNode: "Legg til toppnode",
        DeleteAssetAlertTitle: "Slett anlegg",
        DeleteAssetAlertText: "Dette vil permanent slette anlegg"
      }
    },
    employerRights: {
      Admin: "Tilgang",
      UserControl: "Brukerstyring",
      UserApprover: (cardName: string) =>
        `Attestere ${cardName.toLowerCase()}søknad`,
      UserApproverInfo: (cardName: string) =>
        `Tillater bruker å attestere ${cardName.toLowerCase()}søknad.`,
      UserControlInfo:
        "Tillater bruker å administrere brukerrettigheter for denne leverandøren \nTillater bruker å sjekke ut personell registrert som inne i et anlegg."
    },
    orgRights: {
      HeaderOrgAdmin: (cardName: string) =>
        `Personell/${cardName.toLowerCase()}`,
      HeaderOrgAdminInfo: (cardName: string) =>
        `Lesetilgang: Gir bruker tilgang til å lese data i personell/${cardName.toLowerCase()}-modulene.\nSkrivetilgang: Gir bruker tilgang til å lese og skrive i personell/${cardName.toLowerCase()}-modulene i tillegg til mulighet for å godkjenne ${cardName.toLowerCase()}søknader.`,
      HeaderOrgAccess: "Adgang/Adgangshistorikk",
      HeaderUserControl: "Brukerstyring",
      HeaderUserControlInfo:
        "Tillater bruker å administrere brukerrettigheter for denne anleggseieren. \nNB! dette er øverste rolle i systemet og burde kun tildeles til et fåtalls personer!",
      HeaderUserIssuer: (cardName: string) =>
        `Utstede ${cardName.toLowerCase()}`,
      HeaderDoorkeyAdmin: "Nøkkelstyring",
      HeaderDoorkeyAdminInfo:
        "Tillater bruker å administrere nøkler for denne anleggseieren",
      HeaderProjectAdmin: "Prosjektstyring",
      HeaderProjectAdminInfo:
        "Tillater bruker å administrere prosjekter for denne anleggseieren",
      HeaderUserIssuerInfo: (cardName: string) =>
        `Tillater bruker å utstede ${cardName.toLowerCase()}.`,
      HeaderAccessHistory: "Adgangshistorikk",
      HeaderAccessHistoryInfo:
        "Tillater bruker å se adgangshistorikken for anlegg",
      HeaderAccessView: "Adganger",
      HeaderAccessViewInfo:
        "Tillater bruker å se nåværende adganger i anleggene.\nTillater bruker å sjekke ut personell registrert som inne i et anlegg.",
      HeaderApproverSchedule: "Varsel til godkjenner",
      HeaderApproverScheduleInfo: (cardName: string) =>
        `Varslingsnivå for hendelser knyttet til nye ${cardName.toLowerCase()}søknader.`,
      HeaderIssuerSchedule: "Varsel til utsteder",
      HeaderIssuerScheduleInfo: (cardName: string) =>
        `Varslingsnivå for ${cardName.toLowerCase()} klare for utstedelse.`,
      HeaderEntrySchedule: "Varsel om ankomst",
      HeaderAssetAdminInfo:
        "Tillater bruker å administrere anlegg og anleggsmeldinger for denne anleggseieren",
      HeaderEntryScheduleInfo:
        'Denne rollen lar en bruker abonnonere på varsler ifm. innsjekk og utsjekking av anlegg. En bruker kan angi de dager og tidsintervall en ønsker å få varsel. Når en inn/ut-registrering skjer innenfor dette intervallet vil bruker få en epost som varsler om dette. I tilegg kan en velge å bli varslet når en bruker har vært inne i et anlegg i et gitt tidsrom, dette angis i feltet "Timer før varsel"',
      InfoHeader: "Info: ",
      InfoClose: "Lukk",
      EditInstuctions: "Dobbelklikk i en celle for å editere en gitt rettighet",
      HeaderExistingUser: "Bruker eksisterer allerede",
      HeaderExistingUserAlert: "Bruker eksisterer allerede i listen",
      AlertPersonAttriComboTitle: "Oppføring eksisterer allerede!",
      AlertPersonAttriComboText:
        "Denne person- og attributtkombinasjonen finnes allerede i listen.",
      AlertAllComboExistsTitle: "Duplikat oppføring!",
      AlertAllComboExistsText:
        "Alle gyldige kombinasjoner av person og attributt finnes allerede i listen.",
      EditHeaderLabel: "Dobbeltklikk på en celle for å endre attributt",
      HeaderAssetAdmin: "Anleggsstyring",
      FromTime: "Fra",
      ToTime: "Til",
      HoursBefore: "Timer før varsel",
      Monday: "Mandag",
      Thuesday: "Tirsdag",
      Wednesday: "Onsdag",
      Thursday: "Torsdag",
      Friday: "Fredag",
      Saturday: "Lørdag",
      Sunday: "Søndag",
      InvalidValueTooltip: "Ugyldig verdi",
      SearchForPerson: "Velg person"
    },
    roles: {
      columndefs: {
        Role: "Godkjenne opplæring",
        SafetyCardAttribute: "Sikkerhetskort attributt"
      },
      EditHeaderLabel: "Dobbeltklikk på en celle for å endre attributt",
      SelectAttriPlaceHolder: "Velg attributt",
      ApproveAll: "Godkjenn alle"
    },
    employers: {
      columndefs: {
        Address: "Adresse",
        Country: "Land",
        Contact: "Kontakt",
        Name: "Navn",
        OrganizationNo: "Organisasjonsnr.",
        ContactEmail: "Kontakt-epost",
        ContactPhone: "Kontakt-telefon",
        NumActiveCards: "Antall aktive kort",
        NumShreddedCards: "Antall makulerte kort",
        OrgId: "Organisasjonsid",
        PersonnelCount: "Antall personell"
      },
      UpdateBtn: "Rediger",
      CreateBtn: "Legg til",
      DeleteBtn: "Slett",
      ModalUpdateHeader: "Rediger",
      ModalCreateHeader: "Opprett ny",
      ModalSave: "Lagre",
      ModalCancel: "Avbryt",
      AlertTitle: "Advarsel",
      InvalidEmployer: "Data ble ikke sendt inn. Arbeidsgiver er ikke gyldig.",
      AutoCompleteTitle: "Selskap",
      AddEmployerHeader: "Legg til leverandør",
      AddEmployer: (emp: string, orgName: string) =>
        `${emp} er ikke en leverandør for ${orgName}. \nVil du legge ${emp} til som leverandør for ${orgName}`,
      EmployerAlreadyFound: "Leverandøren er allerede i listen",
      sendMail: "Send epost til kontaktperson"
    },
    constructionProjects: {
      delete: "Slett",
      edit: "Rediger",
      add: "Legg til prosjekt",
      deleteTitle: "Slett prosjekt",
      deleteText: "Dette vil permanent slette prosjektet",
      modalHeader: "Prosjekt",
      modalSave: "Lagre",
      modalClose: "Lukk",
      projectName: "Prosjekt navn",
      projectNumber: "Prosjektnr.",
      projectAddress: "Adresse",
      constructionClient: "Byggherre",
      projectLead: "Prosjektleder",
      namePlaceholder: "Navn",
      startDate: "Start dato",
      endDate: "Slutt dato",
      datePlaceholder: "Dato",
      accessCardType: "Korttyper for tilgang",
      lat: "Lat",
      lng: "Lng",
      selectOnMapPlaceholder: "Skriv inn kordinater eller trykk på kartet",
      clickToSetCoordinates: "Ugyldige kordinater",
      referencePlaceholder: "Integrasjons ID",
      externalReference: "Integrasjons ID",
      allVendors: "Tilgjengelig for alle leverandører",
      checkAllVendors: "Alle leverandører",
      checkSomeVendors: "Utvalgte leverandører",
      latLngRequiredMessage: "Breddegrad og lengdegrad er påkrevd.",
      latLngValidateMessage:
        "Vennligst legg til gyldig verdi for lengdegrad og breddegrad.",
      latLimitationMessage: "Breddegrad må være mellom -85 og +85",
      lngLimitationMessage: "Lengdegrad må være mellom -180 og 180",
      projectNameRequiredMessage: "Prosjektnavn påkrevd.",
      ProjectHasAssets: "Prosjektet er knyttet til anlegg",
      Ok: "Ok",
      Clear: "Fjern",
      addNewNode: "Legg til anlegg",
      projecPostionSameAsAsset: "Prosjektet har samme posisjon som anlegg",
      maxEntries: "Begrens antall oppføringer i aktiva samtidig",
      maxEntriesPlaceholder: "Angi maksimalt antall innsjekkede for prosjekt",
      maxEntriesBlock:
        "Begrensning er hard grense (brukeren kan ikke registrere seg hvis den er nådd)",
      maxEntryInputWarning:
        "Angi gyldig nummer som maksimalt antall oppføringer",
      activeProjBtnLbl: "Vis aktive prosjekter",
      finishedProjBtnLbl: "Vis avsluttede prosjekter"
    },
    entries: {
      newOperationalMsg: "Ny melding",
      operationalMsgHeader: "Driftsmeldinger",
      showOneTimeMsg: "Vis engangs meldinger",
      showRepeatingMsg: "Vis gjentagende meldinger",
      regUserBtn: "Sjekk inn bruker",
      regTravelBtn: "Registrer reise",
      modalHeader: "Sjekk inn bruker",
      modalTravelHeader: "Registrer reise",
      selectPerson: "Velg person med adgang",
      selectProject: "Velg prosjekt",
      selectAsset: "Velg anlegg",
      selectSource: "Velg prosjekter/anlegg",
      selectFollowers: "Legg til personer som ledsages",
      project: "Prosjekter",
      assetTree: "Anlegg",
      submit: "Registrer bruker",
      confirm: "Lukk",
      emptyPerson: "Ingen personer med adgang ble funnet!",
      statusText: (name: string) => `Innsjekking av ${name} fullført`,
      statusTextTraveling: (name: string) => `${name} er registrert på reise`,
      statusFailText: (name: string) =>
        `Innsjekking av ${name} ble ikke fullført`,
      back: "Tilbake",
      proceed: "Gå videre",
      choosenAsset: "Valgt anlegg",
      choosenPerson: "Valgt person",
      projectPlaceholder: "Søk blant prosjektnavn",
      followersInputLabel: 'Legg inn flere navn separert med komma ","',
      columnDefs: {
        delete: "Fjern",
        created: "Opprettet",
        enteredByName: "Opprettet av",
        assetName: "Anlegg",
        message: "Melding",
        repeats: "Gjentas"
      },
      searchAsset: "Søk etter anlegg",
      warnings: {
        maxAmount: (maxEntryAmount: number) =>
          `Kun ${maxEntryAmount} personer kan være sjekket inn samtidig på denne stasjonen. `,
        alreadyIn: (current: number) =>
          `Det er allerede ${current} som har sjekket inn.`,
        alreadyReached: "Grensen har allerede blitt nådd.",
        parentReachedMaxAmount:
          "Overordnet anlegg overstiger allerede maksimalt antall innsjekkinger.",
        reachedMaxAmount: "Maksimalt antall innsjekkinger har blitt nådd",
        note: "Merk",
        warning: "Advarsel"
      },
      selectAssetOwner: "Velg anleggseier",
      displayingRows: "Viser",
      of: "av",
      entries: "innsjekkinger",
      clearFilter: "Fjern filter",
      enterExpectedTimeToLeave: "Angi tid",
      time: "Tid",
      expectedTimeToLeaveHeader: "Angi forventet tid for å forlate",
      addAssetMsgMenuItemText: "Legg til anleggsmelding",
      chkInMenuItemText: "Sjekk inn personell",
      assetMsgDisabledTooltipText: "Anlegget tilhører annen anleggseier",
      stdDisabledTooltipText: "Deaktivert",
      gotoAsset: "Gå til anlegg",
      noAdminAssetRightsTooltipText: "Mangler rettigheter"
    },
    onDuty: {
      onDutyTitle: `Vakthavende`,
      noAppointedOnDuty: "Ingen vakthavende utpekt",
      currentOnDuty: "Nåværende vakthavende:",
      newOnDuty: "Ny vakthavende",
      seletNewOnDutyOfficer: "Velg ny vakthavende",
      changeOnDutyInfoText: `Utpek ny vakthavende ved å søke i feltet under. Hvis det ikke er
      en vakthavende på dette tidspunktet trykk "Lagre" uten å velge ny.`
    }
  },
  assetView: {
    FirstName: "Fornavn",
    LastName: "Etternavn",
    Mobile: "Mobil",
    Visitors: "Besøkende",
    NoVisitors: "Ingen besøkende i noen stasjoner",
    AssetHeader: "Navn anlegg: ",
    AltImg: "Stasjonsbilde"
  },
  notification: {
    NoNotification: "Ingen",
    Notifications: (orgName: string) =>
      orgName ? `Varslinger - ${orgName}` : `Varsler`,
    NewApplicationsForApproval: (cardName: string) =>
      `Varsel om nye ${cardName.toLowerCase()} søknader klare for behandling`,
    ReadyForIssuing: (cardName: string) =>
      `Varsel om ${cardName.toLowerCase()} klare til utstedelse`
  },
  invitation: {
    Reject: "Ikke godta",
    Accept: "Godta",
    FirstName: "Fornavn",
    EmptyFieldNotAllowed: "Dette feltet kan ikke stå tomt",
    LastName: "Etternavn",
    Mobile: "Mobil",
    AtLeast: "Må inneholde 10 tegn",
    Email: "Epost",
    ValidEmail: "Må være en gyldig epost-adresse",
    Proceed: "Bekreft",
    Welcome: "Velkommen som bruker i Permitto",
    LogInAndDownload: "Du kan nå laste ned Permitto appen og logge inn på ",
    LogIn: "Logg inn",
    VerifyData: "Verifiser at opplysningene dine er korrekte."
  },
  my: {
    access: {
      NoRegAccess: (cardName: string) =>
        `Dine ${cardName.toLowerCase()} bemyndiger ingen adgang`,
      NoAssets: (cardName: string) =>
        `Du har ingen ${cardName.toLowerCase()} som bemyndiger adgang på anlegg/prosjekter`
    },
    application: {
      Delete: "Slett søknad",
      DeleteApplicationTitle: "Slett søknad",
      DeleteApplicationText: "Dette vil permanent slette søknaden",
      ChangeAttestor: "Endre attestant",
      MissingValuesAttestor: "Du må fylle ut alle påkrevde felt",
      Cancel: "Avbryt"
    },
    applicationList: {
      ColumnText: "Krever aksjon",
      Attestor: "Attestant",
      Open: "Åpne",
      CardType: "Korttype"
    },
    profile: {
      AccessTo: (numStations: number) =>
        numStations === 1
          ? `Adgangsregistrering tillatt på ${numStations} anlegg`
          : `Adgangsregistrering tillatt på ${numStations} anlegg`,
      Holds: (numCards: number, cardName: string) =>
        numCards === 1
          ? `Innehar ${numCards} gyldig ${cardName.toLowerCase()}`
          : `Innehar ${numCards} gyldige ${cardName.toLowerCase()}`,
      InfoFieldsHeader: "Opplysninger",
      HeaderNotificationTitle: "Oppdater varsler",
      HeaderTitle: "Oppdater personopplysninger",
      HeaderSave: "Lagre",
      HeaderCancel: "Avbryt",
      DialogSuccessText: "Opplysningene er lagret",
      DialogSuccessTitle: "Ok",
      DialogConfirmText: "Er du sikker på at du vil forkaste endringene?",
      DialogConfirmTitle: "Endringer gjort",
      FooterText:
        "Ønsker du å endre andre data enn telefonnummer og epost, vennligst kontakt support via epost ",
      FooterEmail: "app support.",
      EditNotifications: "Editer varsler",
      EditInfo: "Editer opplysninger"
    },
    safetycards: {
      ShowShredded: (cardName: string) => `Makulerte ${cardName.toLowerCase()}`,
      NoCardsToShow: (cardName: string) => `Ingen ${cardName.toLowerCase()}`
    },
    splash: {
      Permissions: "Mine Adganger",
      AccessHistory: "Min Adgangshistorikk",
      Cards: (cardName: string) => `Mine ${cardName}`,
      NewApplication: "Ny Søknad",
      Applications: "Mine Søknader",
      Profile: "Min Profil",
      OtherCards: "Mine HMS-kort",
      DoorKeys: "Mine Nøkler",
      NewDoorKeyApplication: "Ny Nøkkelsøknad",
      DoorkeyApplication: "Mine Nøkkelsøknader",
      ApplicDisableTooltip: employerName =>
        `Din arbeidsgiver ${employerName} er ikke registrert som leverandør</br>
           for noen anleggseiere i Permitto.</br>
           Du vil derfor ikke kunne søke om sikkerhetskort</br>
           eller registrere adgang til prosjekter.</br>
           Kontakt anleggseieren du skal arbeide for slik at de kan</br> 
           registrere ${employerName} som leverandør i Permitto.</br>
           Trenger du ytterligere hjelp kontakt oss på epost</br>
           app-support@verico.com så hjelper vi deg videre.</br>`
    },
    otherCards: {
      AddCard: "Legg til nytt kort",
      DeleteTitle: "Slett kort",
      DeleteText: "Dette vil permanent slette kortet"
    }
  },
  noAccess: {
    NoAccess: "Din bruker har ikke adgang til denne siden",
    NoAccessHeader: "401"
  },
  notFound: {
    PageNotFound: "Denne siden ble ikke funnet",
    PageNotFoundHeader: "404"
  },
  registerView: {
    registerInfoText:
      "Her kan du registrere en ny bruker i Permitto. Når dette er gjort vil du kunne søke om og motta sikkerherhetskort fra de anleggseierne som ditt selskap er knyttet til.",
    registerPrivacyPolicyLink: (link: string) => (
      <React.Fragment>
        Informasjon om hvordan dine data blir behandlet står beskrevet i
        Permitto sin
        <a href={link}>
          <i>personvernserklæring</i>
        </a>
        .
      </React.Fragment>
    ),
    continue: "Videre",
    phoneAlreadyRegistred: "Telefonnummer er allerede registert i Permitto",
    createUserFailed: "Kunne ikke opprette bruker",
    typePhoneNumber:
      "Vennligst oppgi ditt telefonnummer. Dette vil bli brukt for å identifisere deg i Permitto:",
    companyPrompt:
      "Vennligst oppgi bedriften du er ansatt i. Dette kan du gjøre under ved å søke på navn eller ved å skrive inn organisasjonsnummer.",
    userCreated:
      "Du kan nå laste ned Permitto appen og logge inn med ditt telefonnummer",
    gotoPermitto: "Gå til Permitto",
    userCreatedHeader: "Bruker opprettet",
    userIsBeingRegistered: " Registrer ny bruker",
    firstName: "Fornavn",
    lastName: "Etternavn",
    eMail: "Epost",
    registrer: "Registrer"
  },
  our: {
    rights: {
      HeaderEmployerAdmin: "Tilgang",
      HeaderUserControl: "Brukerstyring",
      HeaderUserApprove: (cardName: string) =>
        `Attestere ${cardName.toLowerCase()}søknad`,
      HeaderAccessHistory: "Adgangshistorikk",
      HeaderAccessView: "Adganger"
    },
    access: {
      NoPersonnel:
        "Ingen personell registrert i anlegg/prosjekter du har tilgang til",
      PersonnelInField: "Personell i felt",
      menuItemGotoPerson: "Vis person",
      menuItemCheckoutPerson: "Meld person ut av anlegg",
      menuItemCancelTravel: "Avbryt reise",
      menuItemUserArrived: "Meld bruker ankommet",
      menuItemCheckoutPersonList: "Send SMS",
      menuItemGotoPersonDisabled: "Mangler rettigheter for å gå til person",
      menuItemCheckoutPersonDisabled:
        "Mangler rettigheter for å sjekke ut person",
      menuItemChangeEscortedPersonnel: "Endre ledsaget personer",
      InfoLegendCheckOutFirst: "Rad markert med ",
      InfoLegendCheckOutLast: "har vært mer enn 24 timer i anlegget.",
      InfoLegendTimeToLeaveFirst: "Rad markert med ",
      InfoLegendTimeToLeaveLast: "har forventet tid i anlegg utløpt.",
      checkoutModalHeader: "Bekreft utsjekking",
      cancelTravelHeader: "Avbryt reise",
      userArrivedHeader: "Meld bruker ankommet",
      checkoutModalConfirmBtn: "Bekreft",
      checkoutModalCancelBtn: "Avbryt",
      checkoutModalSmsText: "Send SMS varsel",
      menuItemAssetNote: "Legg inn notat",
      setAssetNoteText: "Legg inn notat på adgang",
      escortedModalHeader: "Endre ledsaget personer",
      escortedModalSubmitBtnLbl: "Endre"
    },
    applications: {
      Confirm: "Attester",
      ConfirmedApplications: "Attester søknad",
      Reject: "Avvis",
      RejectApplication: "Avvis søknad",
      Delete: "Slett søknad",
      DeleteAppTitle: "Slett søknad",
      DeleteAppText: "Dette vil permanent slette søknaden",
      ChangeAttestor: "Endre attestant",
      MissingValuesAttestor: "Vennligst fyll ut alle påkrevde felt",
      Cancel: "Avbryt",
      Application: "Søknad"
    },
    applicationList: {
      DemandsAction: "Krever aksjon",
      Open: "Åpne",
      Attestor: "Attestant"
    },
    profile: {
      ValidCards: (numCards: number, cardName: string) =>
        numCards === 1
          ? `${numCards} gyldig ${cardName.toLowerCase()}`
          : `${numCards} gyldige ${cardName.toLowerCase()}`,
      RegPersonnel: (numPersons: number) => `${numPersons} registrerte personer`
    },
    safetycard: {
      CardNumber: (cardName: string) => `${cardName}nummer`,
      Back: "Tilbake",
      Shredded: "Makulert",
      CardReceipted: (cardName: string) => `${cardName} er kvittert for `,
      EditMode: "Redigeringsmodus",
      Save: "Lagre",
      Cancel: "Avbryt",
      ValidFrom: "Gyldig fra",
      ValidTo: "Gyldig til",
      PrevValid: "Gyldig fra må være tidligere enn Gyldig Til"
    },
    splash: {
      CardName: (cardTemplate: CardType) => cardTemplate.cardNameNo,
      Accesses: "Adganger",
      AccessHistory: "Vår Adgangshistorikk",
      SafetyCard: (cardName: string) => `Våre ${cardName.toLowerCase()}`,
      Personnel: "Vårt Personell",
      NewApplication: "Ny Søknad",
      Applications: "Våre Søknader",
      NewCardNoApplic: (cardTemplate: CardType) =>
        `Nytt ${cardTemplate.cardNameNo}`,
      Profile: "Vår Profil",
      OtherCards: "Våre HMS-kort",
      UserAccess: "Brukerstyring",
      DoorKeys: "Våre Nøkler",
      Documentation: "Vår Dokumentasjon",
      NewDoorKeyApplication: "Ny Nøkkelsøknad",
      DoorkeyApplication: "Våre Nøkkelsøknader"
    },
    safetyCardList: {
      Issuer: "Utsteder",
      Open: "Åpne",
      Grid: "Tabell",
      CardUpperCase: (cardName: string) => `${cardName}`
    }
  },
  templates: {
    buttons: {
      newtemplate: "Ny",
      startedit: "Rediger",
      endedit: "Stopp redigering",
      savetemplate: "Lagre",
      saveastemplate: "Lagre som",
      previewtemplate: "Forhåndsvisning",
      applytemplate: "Bruk",
      confirmTitle: "Endringer gjort",
      confirmText: "Dette vil slette alle ulagrede endringer"
    },
    subSection: {
      ModalCancelText: "Cancel"
    },
    dropdowns: {
      cardTypeName: (cardType: CardType) => cardType.cardNameNo,
      selectCardType: "Velg korttype",
      selectTemplate: "Velg mal",
      selectDraft: "Velg utkast",
      blankDraft: "Blank mal",
      createDraft: "Lag utkast fra valgt mal",
      newDraft: "Ny mal",
      unSaved: "Ikke lagret",
      draftStatus: {
        inUse: "I bruk",
        draft: "Utkast",
        historical: "Historisk",
        unknown: "Ukjent"
      },
      redeemedAfterIssueTemplateText: "Innfries etter utstedelse"
    },
    attributes: {
      newAttribute: "Velg attribute",
      newAttributePlaceholder: "Søk etter eksisterende attribute",
      addExistingAttribute: "Legg til eksisterende attibute",
      setLocationAssetTreeButton: "Angi posisjon på anleggstre",
      dataType: "Datatype",
      lableNo: "Tekst No",
      lableEn: "Tekst En",
      hyperlink: "Hyperlink",
      sectionOrder: "Seksjon rekkefølge",
      sortOrder: "Sorteringsnummer",
      info: "Info",
      selectAttribute: "Velg attributt",
      subSectionOrder: "Underseksjon rekkefølge",
      descriptionEn: "Beskrivelse En",
      descriptionNo: "Beskrivelse No",
      SelectType: "Velg type",
      requiredValue: "Påkrevd verdi",
      requiredWhenVisible: "Påkrevd hvis synlig",
      role: "Rolle",
      sectionNo: "Seksjonsnavn - NO",
      sectionEn: "Seksjonsnavn - EN",
      subSectionNameNo: "Underseksjonnavn - NO",
      subSsectionNameEn: "Underseksjonnavn - EN",
      ok: "Ok",
      cancel: "Avbryt",
      addNewAttribute: "Legg til attributt",
      editAttribute: "Editer attributt",
      editSectionName: "Rediger seksjonsnavn",
      editSubSection: "Rediger Underseksjon",
      selectOperator: "Vis hvis",
      selectAttributes: "Velg attribute(r)",
      displayOperandOr: "OR",
      displayOperandNor: "NOT",
      attriExistOnCardTitle: "Advarsel",
      attriExistOnCardText: (cardType: string) =>
        `Attributen du legger til finnes allerede på sikkerhetskortet. Da hele ${cardType} vises på søknaden så vil dette gjøre at denne attributen blir presentert to ganger til bruker. Er du sikkert på at du ønsker dette?`,
      associatedAssets: "Knyttede anlegg"
    },
    confirmModal: {
      prompt: "Vil du lagre endringene gjort i dette utkastet?",
      ok: "Lagre",
      cancel: "Forkaste",
      title: "Endringer gjort",
      deleteAttributeModalTitle: "Slett attributt",
      deleteAttributeModalDescription: "Dette vil slette attributten"
    },
    applyModal: {
      applyMessageTitle: "Bruk mal",
      applyMessageText:
        "Er du sikker på at du vil sette denne malen som den nåværende aktive?"
    },
    saveAsModal: {
      editTemplateNameTitle: "Editer navn på mal",
      ok: "Lagre",
      cancel: "Forkast",
      editTemplateNamelabel: "Editer navn på mal",
      templateNameRequiredMessage: "Template name required."
    },
    locationAssetTreeSelectModal: {
      title: "Angi posisjon på anleggstre",
      defaultCheck: "Standard"
    },
    postIssueRule: {
      InvalidatesCard: "Ugyldiggjør kortet",
      RequiresApproval: "Krever godkjenning",
      postIssueRuleList:
        "Attributten finnes allerede i listen. Vil du fortsette?"
    }
  },
  doorKeys: {
    person: {
      GridHeaderText: "Nøkler",
      GridEmptyText: "Bruker har ingen nøkler registrert"
    },
    reserve: {
      keepPersonnel: "Reserver til",
      ModalTermsInfo: "Vilkår for utlån",
      ModalReserveText: "Reserver",
      ModalCancelText: "Avbryt",
      ModalHeaderText: "Reserve nøkler",
      ModalStatusComment: "Kommentar"
    },
    register: {
      SerialNumber: "Serienummer",
      SerialNumberStart: "Serienummer start",
      NumberOfKeys: "Antall nøkler",
      ModalHeaderText: "Registrer nye nøkler",
      ModalCancelText: "Avbryt",
      AlertLabelSingular: "Registrere nøkkel",
      AlertLabelPlural: "Registrere nøkler",
      AlertOkButtonText: "Bekreft",
      AlertCancelButtonText: "Avbryt",
      AlertMsg: (num: number) =>
        num === 1
          ? `Er du sikker på at du vil registrere ${num} ny nøkkel?`
          : `Er du sikker på at du vil registrere ${num} nye nøkler?`,
      DoorkeySystemNo: "Systemnummer",
      DoorkeyType: "Nøkkeltype",
      Description: "Beskrivelse av nøkkel",
      StatusComment: "Kommentar ved opprettelse",
      EnableKeySeries: "Registrer en ny nøkkelserie",
      DoorkeyComment: "Nøkkelkommentar",
      DoorkeyDept: "Ansvarlig"
    },
    update: {
      ModalHeaderText: "Oppdater nøkkeldata",
      ModalCancelText: "Avbryt",
      ModalUpdateText: "Oppdater",
      DoorkeySystemNo: "Systemnummer",
      SerialNumber: "Serienummer",
      DoorkeyType: "Nøkkeltype",
      Description: "Beskrivelse av nøkkel"
    },
    receive: { ReceivekeyHeader: "Kvitter nøkkel mottatt" },
    discard: {
      DiscardKeyHeader: "Kasser nøkkel",
      DiscardKeyMessage: "Er du sikker på at du vil forkaste nøkkel?",
      DiscardKeyConfirmBoxTitle: "Kassere nøkkel",
      DiscardKeyConfirmBoxText:
        "Dette vil permanent kassere nøkkelen og kan ikke omgjøres",
      DiscardKeyConfirmBoxConfirmButtonText: "Kasser",
      DiscardKeyConfirmBoxCancelButtonText: "Avbryt"
    },
    return: {
      ReturnKeyHeader: "Returner til lager",
      ReturnKeyMessage:
        "Er du sikker på at du ønsker å returnere nøkkel til lager?"
    },
    deleteKey: {
      DeleteKeyHeader: "Slett nøkkel",
      WarningText: "Dette vil permanent slette nøkkelen",
      ConfirmButtonText: "Slett",
      CancelButtonText: "Avbryt"
    },
    application: {
      ReservedLinkMessage: `Nøkkel er reservert (klikk for å vise)`
    },
    doorKeyList: {
      button: {
        DeleteKeyText: "Slett nøkkel",
        RegisterKeyText: "Registrer ny nøkkel",
        ReserveKeyText: "Reserver nøkkel",
        SignForReturnKeyText: "Kvitter nøkkel mottatt",
        RecallKeyText: "Tilbakekall nøkkel",
        LostKeyText: "Mistet nøkkel",
        ReturnToStoreText: "Returner til lager",
        DiscardKeyText: "Kasser nøkkel",
        ReceiveKeyText: "Kvitter nøkkel mottatt",
        UpdateKeyText: "Editer nøkkel",
        FilterListPlaceholder: "Søk etter person eller selskap"
      },
      columnDefs: {
        DoorkeySystemNo: "Systemnummer",
        DoorkeyType: "Nøkkeltype",
        SerialNumber: "Serienummer",
        Description: "Beskrivelse av nøkkel",
        StatusDateTime: "Status dato",
        DoorkeyStatusName: "Status",
        KeeperFirstName: "Fornavn",
        KeeperLastName: "Etternavn",
        KeeperHasSCard: "Gyldig s.kort",
        Employer: "Selskap",
        StatusComment: "Status kommentar",
        Organization: "Nøkkeleier",
        Info: "Info",
        AssetOwner: "Anleggseier",
        HolderName: "Nøkkelholder",
        ApplicationId: "Søknad",
        HolderPhone: "Nøkkelholder tlf.",
        Email: "Nøkkelholder epost",
        IsTraveling: "Reise",
        Note: "Notat",
        DoorkeyComment: "Nøkkelkommentar",
        DoorkeyDept: "Ansvarlig"
      },
      lostKey: {
        ModalTitleText: "Merk nøkkel som mistet",
        ConfirmButtonText: "Mistet"
      },
      log: {
        columnDefs: {
          ActionPersonName: "Utført av",
          ActionStatusText: "Handling",
          KeeperEmployer: "Selskap",
          KeeperFirstName: "Fornavn",
          KeeperLastName: "Etternavn",
          StatusComment: "Kommentar",
          StatusDateTime: "Dato"
        }
      },
      returnKey: {
        ModalTitleText: "Kvitter nøkkel mottatt",
        ConfirmButtonText: "Mottatt"
      },
      recallKey: {
        ModalTitleText: "Tilbakekall nøkkel",
        ConfirmButtonText: "Tilbakekall"
      }
    },
    doorKeyApplicationsList: {
      approveModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Godkjenn nøkkelsøknad",
        subHeaderText: ""
      },
      rejectModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Avvist søknad",
        subHeaderText: ""
      },
      doorkeyReserveModal: {
        doorkeyReserveHeaderText: "Resever nøkkel nå"
      },

      shredModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Slett søknad",
        subHeaderText: ""
      },
      resendModal: {
        commentHeaderText: "Kommentar",
        commentSubHeaderText: "",
        headerText: "Slett søknad",
        subHeaderText: ""
      },
      buttons: {
        approve: "Godkjenn",
        reject: "Avvis",
        open: "Åpne",
        resend: "Send på ny",
        back: "Tilbake",
        archive: "Vis aktive søknader",
        nonArchive: "Vis arkiv",
        reserve: "Reserver nøkkel",
        shred: "Slett søknad"
      },
      columnDefs: {
        applicId: "Applikasjon id",
        firstName: "Fornavn",
        lastName: "Etternavn",
        mobile: "Mobil",
        email: "Epost",
        employerName: "Selskap",
        organisationName: "Organisasjon",
        status: "Status",
        actionRequired: "Handling kreves"
      },
      statuses: {
        applicationCreated: "Søknad opprettet",
        applicationEmployerApproved: "Søknad sendt og venter behandling",
        applicationEmployerRejected: "Søknad avvist av leverandør",
        applicationOrganizationApproved:
          "Søknad godkjent, venter på å få reservert nøkkel",
        applicationOrganizationRejected: "Søknad avslått. Handling påkrevd",
        applicationEmployerApprovedORG: "Søknad klar for behandling",
        applicationOrganizationShredded: "Søknad slettet",
        applicationOrganizationRejectedORG: "Søknad avvist av anleggseier",
        applicationOrganizationReserved: "Nøkkel reservert"
      }
    },
    kpiNames: {
      loanedKeys: "Utlånte nøkler",
      reservedKeys: "Reserverte nøkler",
      recalledKeys: "Tilbakekalte nøkler",
      keyOwnersInvalidCard: "Utlånt u/gyldige kort"
    }
  },
  documentation: {
    documentationList: {
      columnDefs: {
        employerName: "Selskap",
        firstName: "Fornavn",
        lastName: "Etternavn",
        hasValidSafetyCard: (cardName: string) =>
          `Gyldig ${cardName.toLowerCase()}`
      },
      columnDocumentationDetailsDefs: {
        fileName: "Filnavn",
        documentRequirement: "Dokumentkrav",
        reference: "Referanse",
        comment: "Kommentar",
        uploaded: "Opplastet",
        validFrom: "Gyldig fra",
        expiresFrom: "Utløper",
        uploadedBy: "Opplastet av",
        actions: "Handlinger",
        gridEmptyText: "Ingen dokumenter"
      },
      lastNamePlaceholder: "Søk på etternavn",
      openButtonText: "Åpne",
      allDocumentTypes: "Alle dokumenttyper",
      editImageButton: "Redigere",
      addDocButtonText: "Legg til dokumentasjon",
      upload: {
        uploadButton: "Fortsett",
        cancelButton: "Avbryt",
        uploadDocumentHeader: "Velg personer",
        uploadProgressText: "Laster opp dokumentasjon. Vennligst vent"
      }
    }
  },
  assetMessages: {
    add: "Legg til",
    edit: "Redigere",
    delete: "Slett",
    deleteTitle: "Slett melding",
    deleteText: "Dette vil permanent slette meldingen",
    staticEventType: "Alltid",
    checkInEventType: "Ved ankomst anlegg",
    checkOutEventType: "Når anlegget forlates",
    showExpiredMessages: "Vis utgåtte meldinger",
    htmlType: "HTML",
    informationType: "Enkel tekst"
  }
};
