import styles from "./header.css";
import * as React from "react";
import ContextSwitcherList from "./context-switcher-list";
import ContextSwitcherItem from "./context-switcher-item";
import { localizationStore, userStore } from "../../stores";
import { UserIdentity, OurPermittoRights } from "../../types";
import * as Routes from "./../../app/view/route-paths";

interface ContextMenuProps {
  currentUrl: string;
  userIdentity: UserIdentity;
  showDropDown: string;
  setShowDropDown: (showDropDown: string) => void;
  setShowContextMenu: (showContextMenu: boolean) => void;
  switchContext: (url: string) => void;
}

export default class ContextMenu extends React.Component<ContextMenuProps> {
  contextMenuBtnClicked = e => {
    if (e.target.value === Routes.Org) {
      if (this.props.userIdentity.orgPermittoRights.length === 1) {
        this.props.switchContext(
          Routes.GetOrganisationPermittoUrl(
            this.props.userIdentity.orgPermittoRights[0].id
          )
        );
      } else {
        this.props.setShowDropDown(
          this.props.showDropDown !== Routes.Org ? Routes.Org : ""
        );
      }
    } else if (e.target.value === Routes.Our) {
      if (this.hasOneSupplier()) {
        this.props.switchContext(
          Routes.GetOurPermittoUrl(
            this.props.userIdentity.ourPermittoRights.length > 0
              ? this.props.userIdentity.ourPermittoRights[0].id
              : userStore.userInfo.organizationNo
          )
        );
      } else {
        this.props.setShowDropDown(
          this.props.showDropDown !== Routes.Our ? Routes.Our : ""
        );
      }
    } else {
      this.props.setShowDropDown("");
      this.props.switchContext(e.target.value);
    }
  };

  hasOneSupplier(): boolean {
    if (
      this.props.userIdentity.ourPermittoRights.length === 1 &&
      this.props.userIdentity.issuePermissions.length < 1
    ) {
      return true;
    }
    if (
      this.props.userIdentity.ourPermittoRights.length < 1 &&
      this.props.userIdentity.issuePermissions.length === 1
    ) {
      return true;
    } else if (
      this.props.userIdentity.ourPermittoRights.length === 1 &&
      this.props.userIdentity.issuePermissions.length === 1 &&
      this.props.userIdentity.ourPermittoRights[0].id ===
        userStore.userInfo.organizationNo
    ) {
      return true;
    } else {
      return false;
    }
  }

  getOurPermittoRights = (): OurPermittoRights[] => {
    const rights: OurPermittoRights[] = this.props.userIdentity.ourPermittoRights.slice();

    const issuePermissions = this.props.userIdentity.issuePermissions.filter(
      p => p.issueToOwnEmpsOnly
    );

    if (issuePermissions.length > 0) {
      if (
        !this.props.userIdentity.ourPermittoRights.find(
          r => r.id === userStore.userInfo.organizationNo
        )
      ) {
        rights.push({
          organisations: issuePermissions.map(o => o.orgId),
          accessHistory: false,
          accessView: false,
          admin: false,
          approver: false,
          id: userStore.userInfo.organizationNo,
          rights: ""
        });
      }
    }

    return rights;
  };

  render() {
    return (
      <div>
        <div className={styles.contextMenuArrow} />
        <div className={styles.contextMenu}>
          <ContextSwitcherList
            onSelect={this.contextMenuBtnClicked}
            selectedValue={this.props.currentUrl}
          >
            {this.props.userIdentity ? (
              <ContextSwitcherItem
                key={1}
                value={[Routes.GetMyPermittoUrl(), "/"]}
                excludedValues={[Routes.Our, Routes.Org]}
                text={` ${localizationStore.currentLocal.shared.header.MyPermitto}`}
                imgUrl="my permitto"
                dropdownOpen={false}
                dropdownContent={null}
              />
            ) : (
              false
            )}
            {this.props.userIdentity.ourPermittoRights.length > 0 ||
            this.props.userIdentity.issuePermissions.length > 0 ? (
              <ContextSwitcherItem
                key={2}
                value={[Routes.Our]}
                text={` ${localizationStore.currentLocal.shared.header.ServiceProvider(
                  ""
                )}`}
                imgUrl="our permitto"
                dropdownOpen={this.props.showDropDown === Routes.Our}
                dropdownContent={this.getOurPermittoRights()}
              />
            ) : (
              false
            )}
            {this.props.userIdentity.orgPermittoRights.length > 0 ? (
              <ContextSwitcherItem
                key={3}
                value={[Routes.Org]}
                text={` ${localizationStore.currentLocal.shared.header.Owner}`}
                imgUrl="admin"
                dropdownOpen={this.props.showDropDown === Routes.Org}
                dropdownContent={this.props.userIdentity.orgPermittoRights}
              />
            ) : (
              false
            )}
          </ContextSwitcherList>
        </div>
        <div
          className={styles.overlay}
          onClick={(e: any) => {
            this.props.setShowDropDown("");
            this.props.setShowContextMenu(false);
          }}
        />
      </div>
    );
  }
}
