import styles from "./header.css";
import * as React from "react";

interface HeaderTextProps {
  text: string;
  icon?: string;
  switchContext: (url: string) => void;
  currentUrl: string;
  goToUrl: string;
}

export default class HeaderText extends React.Component<HeaderTextProps> {
  render() {
    return (
      <div
        className={
          this.props.goToUrl
            ? styles.contextTextContainerLink
            : styles.contextTextContainer
        }
        onClick={() => {
          if (this.props.goToUrl) {
            this.props.switchContext(this.props.goToUrl);
          }
        }}
      >
        <span>
          {this.props.icon && (
            <img className={styles.logoHeader} src={`res/${this.props.icon}`} />
          )}
          {this.props.text}
        </span>
      </div>
    );
  }
}
