import { appConfig } from "./../config";
import {
  PersonInfo,
  SafetyCard,
  Employer,
  PostSafetycardObject,
  UserIdentity,
  Asset,
  AssetNode,
  PostApplicationObject,
  Documentation,
  MyPendingActions,
  Attestor,
  AssetEntry,
  MaintenanceMessage,
  Notification,
  NotificationSchedules,
  PreferredLanguage,
  DoorKeysInfo,
  AssetMessage,
  KeyValueType,
  DoorKeyApplication,
  PostDoorKeyApplication,
  AuthorizedPerson,
  CheckinPerson,
  ApplicationAttribute
} from "./../types";
import {
  getJson,
  postJson,
  postEmpty,
  putEmpty,
  uploadFile,
  putOrPatchJson,
  deleteCall,
  getRequestVoid,
  HTTPError
} from "./fetches";

import { TOKEN_NAME } from "./../app/view/const";
import { joinUrlParams, getAccessLogDateStr } from "./helpers";
import { Country } from "../libs/employer-bronnoysund-autocomplete/view/employer-bronnoysund-autocomplete";

export async function getValidateTlfNumber(tlf: string): Promise<boolean> {
  try {
    await getRequestVoid(
      `${appConfig.baseUrl}${appConfig.authServiceName}/personnel/validate/${tlf}`,
      "getValidateTlfNumber"
    );
    return true;
  } catch (e) {
    return false;
  }
}

//http://192.168.100.13:8085/permitto/rest/V3Auth.svc/token/personnel/validate/{tlf} GET
export async function postNewUser(
  person: PersonInfo,
  country: string
): Promise<boolean> {
  try {
    await postJson<void>(
      `${appConfig.baseUrl}${appConfig.authServiceName}/personnel?country=${country}`,
      JSON.stringify({
        email: person.email,
        firstName: person.firstName,
        lastName: person.lastName,
        mobilePhone: person.mobilePhone,
        organizationNo: person.organizationNo,
        organizationName: person.employerName,
        attributes: person.attributes,
        preferences: person.preferences
      }),
      "postInvitePerson"
    );
    return true;
  } catch {
    return false;
  }
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/nodes/{NODEIDS}/gives-access GET
export async function getGivesAccess(nodeIds: number[]): Promise<number[]> {
  return postJson<number[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/nodes/gives-access`,
    JSON.stringify(nodeIds),
    "getGivesAccess"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/attributes/node-requirements?cardType={cardType} POST
export async function getNodeAttributeReqForSubNodes(
  cardTypeId: number,
  nodeIds: number[]
): Promise<ApplicationAttribute[]> {
  return postJson<ApplicationAttribute[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/attributes/node-requirements?cardType=${cardTypeId}`,
    JSON.stringify(nodeIds),
    "getNodeAttributeReqForSubNodes"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{ID}?useTlf={USETLF} GET
export async function getLookupPerson(lookupId: string): Promise<PersonInfo> {
  return getJson<PersonInfo>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${lookupId}?useTlf=true`,
    "getLookupPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{ID}/exists?useTlf={USETLF} GET
export async function personExists(lookupId: string): Promise<void> {
  return getJson<void>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${lookupId}/exists?useTlf=true`,
    "personExists"
  );
}

export async function getPersonAccessLog(
  personnelId: number,
  fromDate: Date,
  toDate: Date
): Promise<AssetEntry[]> {
  const url = `${appConfig.baseUrl}${
    appConfig.serviceName
  }/personnel/${personnelId}/accesslog${joinUrlParams(
    getAccessLogDateStr(fromDate, toDate)
  )}`;
  return await getJson<AssetEntry[]>(url, "getPersonAccessLog");
}

export async function getBronnoysundOrgSearchResult(
  searchString: string
): Promise<Employer[]> {
  const results = await getJson<any>(
    `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${searchString}&page=0&size=40`,
    "getBronnoysundOrgSearchResult"
  );

  const retVal: Employer[] = [];

  if (results && results._embedded && results._embedded.enheter) {
    for (const result of results._embedded.enheter) {
      const adresse = result.forretningsadresse
        ? `${result.forretningsadresse.adresse}\n
           ${result.forretningsadresse.postnummer}\n
           ${result.forretningsadresse.poststed}`
        : result.postadresse
        ? `${result.postadresse.adresse}\n
           ${result.postadresse.postnummer}\n
           ${result.postadresse.poststed}`
        : "";

      retVal.push({
        address: adresse,
        contact: "",
        name: result.navn,
        organizationNo: result.organisasjonsnummer,
        country: Country.Norway
      });
    }

    return retVal;
  }
  return [];
}

export async function getLatLngFromAddress(
  street: string,
  postnumber: number,
  postplace: string
) {
  const result = await getJson(
    `https://nominatim.openstreetmap.org/search/${street}, ${postnumber} ${postplace}?format=json&addressdetails=1&limit=1&polygon_svg=1`,
    "getLatLngFromAddress"
  );

  return result
    ? { lat: parseFloat(result[0].lat), lng: parseFloat(result[0].lon) }
    : null;
}

export async function getBronnoysundOrg(
  orgNumber: number
): Promise<Employer | null> {
  try {
    const result = await getJson<any>(
      `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`,
      "getBronnoysundOrg"
    );

    const adresse = result.postadresse
      ? result.postadresse
      : result.forretningsadresse
      ? result.forretningsadresse
      : null;

    return {
      address: adresse
        ? `${adresse.adresse}\n${adresse.postnummer}\n${adresse.poststed}`
        : "",
      contact: "",
      name: result.navn,
      organizationNo: parseInt(result.organisasjonsnummer),
      country: Country.Norway
    };
  } catch (err) {
    //Handle not found status code
    if (err instanceof HTTPError && err.httpStatusCode === 404) {
      return null;
    }
    throw err;
  }
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/error/client POST
export async function PostClientError(
  userAgent: string,
  httpStack: string,
  message: string,
  mobxActions: string,
  stackTrace: string
): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/error/client`;
  const payload = JSON.stringify({
    userAgent: userAgent,
    httpStack: httpStack,
    message: message,
    mobxActions: mobxActions,
    stackTrace: stackTrace
  });

  return await postJson<void>(url, payload, "PostClientError");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards/pending GET
export async function getPersonSafetycardsPendingCount(
  personnelId: number
): Promise<MyPendingActions> {
  return await getJson<MyPendingActions>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards/pending`,
    "getPersonSafetycardsPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/doorkeys/pending GET
export async function getPersonDoorkeysPendingCount(
  personnelId: number
): Promise<number> {
  return await getJson<number>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/doorkeys/pending`,
    "getPersonDoorkeysPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/doorkeys/pending GET
export async function getPersonDoorkeysApplicPendingCount(
  personnelId: number
): Promise<number> {
  return await getJson<number>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/doorkeys/applications/pending`,
    "getPersonDoorkeysApplicPendingCount"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/invitations/tos PUT
export async function putAcceptTos(personnelId: number): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/invitations/tos`;

  return putEmpty(url, "putAcceptTos");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/tosinvitation?sendSMS={SENDSMS}&country={COUNTRY} POST
export async function postInvitePerson(
  orgId: number,
  person: PersonInfo,
  sendSms: boolean,
  country: string
): Promise<PersonInfo> {
  return await postJson<PersonInfo>(
    `${appConfig.baseUrl}${appConfig.serviceName}/organisation/${orgId}/personnel/tosinvitation?sendSMS=${sendSms}&country=${country}`,
    JSON.stringify({
      email: person.email,
      firstName: person.firstName,
      lastName: person.lastName,
      mobilePhone: person.mobilePhone,
      organizationNo: person.organizationNo,
      organizationName: person.employerName,
      attributes: person.attributes
    }),
    "postInvitePerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/application/{APPLICATIONID}/attestor DELETE
export async function deleteAttestor(applicationId) {
  return await deleteCall(
    `${appConfig.baseUrl}${appConfig.serviceName}/application/${applicationId}/attestor`,
    "deleteAttestor"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/token/application/{APPLICATIONID}/attestor/resend POST
export async function postApplicationResendAttestor(
  applicationId: number,
  email: string,
  name: string,
  title: string,
  phone: number
) {
  return await postJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/application/${applicationId}/resend/`,
    JSON.stringify({
      email,
      name,
      phone,
      title
    }),
    "postApplicationResendAttestor"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards/applications/{APPLICATIONID}/resend?selfAttesting={selfAttesting} PUT
export async function putApplicationResend(
  personnelId: number,
  applicationId: number,
  comment: string,
  selfAttesting: boolean
): Promise<void> {
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards/applications/${applicationId}/resend?selfAttesting=${selfAttesting}`,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "organisationApproveApplication"
  );
}

export async function getPersonNoOrg(personnelId: number): Promise<PersonInfo> {
  return await getJson<PersonInfo>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}`,
    "getPerson"
  );
}

export async function getPersonWithToken(
  personnelId: number,
  token: string
): Promise<PersonInfo> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}`;
  return await getJson<PersonInfo>(url, "getPersonWithToken", {
    tokenKey: token
  });
}

export async function getAttestorWithToken(token: string): Promise<Attestor> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/attestor`;
  return await getJson<Attestor>(url, "getAttestorWithToken", {
    tokenKey: token
  });
}

export async function getApplicationWithToken(
  applicationId: number,
  token: string
): Promise<SafetyCard> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${applicationId}`;
  return await getJson<SafetyCard>(url, "getApplicationWithToken", {
    tokenKey: token
  });
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID} GET
export async function getCard(cardId: number): Promise<SafetyCard> {
  return await getJson<SafetyCard>(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}`,
    "getCard"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/applications/{APPLICATIONID} GET
export async function getDoorKeyApplication(
  applicationId: number
): Promise<DoorKeyApplication> {
  return await getJson<DoorKeyApplication>(
    `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/applications/${applicationId}`,
    "getDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/doorkeys/applications/{APPLICATIONID}/resend PUT
export async function putDoorKeyApplicationResend(
  personnelId: number,
  applicationId: number,
  comment: string
): Promise<void> {
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/doorkeys/applications/${applicationId}/resend`,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putDoorKeyApplicationResend"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/token/attestor PUT
export async function putAttestor(
  attestor: Attestor,
  token: string
): Promise<void> {
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/attestor`,
    "PUT",
    JSON.stringify(attestor),
    "putOrganisationApproveApplication",
    token
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/attachment/{ID} DELETE
export async function deleteDocumentation(
  personnelId: number,
  documentationId: number
) {
  return await deleteCall(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/attachment/${documentationId}`,
    "deleteAttachment"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/attachments GET
export async function getDocumentations(
  personnelId: number
): Promise<Documentation[]> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/attachments`;

  return getJson<Documentation[]>(url, "getAttachments");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/organisation/{ORGID}/personnel/{PERSONNELID}/documentation GET
export async function getPersonDocumentationInOrg(
  orgId: number,
  personnelId: number
): Promise<Documentation[]> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/organisation/${orgId}/personnel/${personnelId}/documentation`;

  return getJson<Documentation[]>(url, "getOrganisationDocumentations");
}

export async function getDocumentationInfo(
  personnelId: number,
  documentationId: number,
  token?: string
): Promise<Documentation> {
  let url = `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/attachments/${documentationId}/info`;

  // WARNING
  // THIS IS A HACK
  // From file-row we need to call this to get attachment info(as a hack while waiting for a WS change)
  // In order to make this work when attesting, without sending token all the way down to file-row
  // we extract a potential token from here.
  if (!token) {
    const searchParams = new URLSearchParams(location.search);
    const tokenVal = searchParams.get(TOKEN_NAME);
    if (tokenVal) {
      token = tokenVal;
    }
  }

  if (token) {
    url += `?${TOKEN_NAME}=${token}`;
  }

  return getJson<Documentation>(
    url,
    "getAttachments",
    token ? { tokenKey: token } : null
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/attachments/{ATTACHMENTID} GET
export function getDocumentationUrl(
  personnelId: number,
  documentationId: number,
  token?: string
): string {
  let url = `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/attachments/${documentationId}`;
  if (token) {
    url += `?${TOKEN_NAME}=${token}`;
  }
  return url;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/attachment POST
export async function postDocumentationMetadata(
  idDocType: number,
  validFrom: string,
  personnelId: number,
  description: string,
  confirmationComment: string
): Promise<Documentation> {
  const json = JSON.stringify({
    idDocType,
    validFrom,
    description,
    confirmationComment
  });
  return await postJson<Documentation>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/attachment`,
    json,
    "postAttachment"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/attachment/{ID} POST
export async function postDocumentation(
  file: File,
  personnelId: number,
  documentationId: number
) {
  await uploadFile(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/attachment/${documentationId}`,
    "POST",
    file,
    "postAttachment"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel?sendMail={SENDMAIL}&country={COUNTRY} POST
export async function postPersonnel(
  person: PersonInfo,
  sendMail: boolean,
  country: string
): Promise<PersonInfo> {
  const json = JSON.stringify({
    attributes: person.attributes,
    email: person.email,
    organizationNo: person.organizationNo,
    firstName: person.firstName,
    lastName: person.lastName,
    mobilePhone: person.mobilePhone
  });
  return await postJson<PersonInfo>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel?sendMail=${sendMail}&country=${country}`,
    json,
    "postPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/borrowed
export async function postDoorKeySmsRequest(doorKeyId: number, status: string) {
  return await postEmpty(
    `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/${doorKeyId}/status/${status}`,
    "postReceiveDoorKeySmsRequest"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards/{CARDID}/recived POST
export async function postSmsRequest(personnelId: number, cardId: number) {
  return await postEmpty(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards/${cardId}/recived`,
    "postSmsRequest"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards/{CARDID}/recived/confirm PUT
export async function putPin(
  personnelId: number,
  cardNr: number,
  mobilePhone: string,
  pin: number
): Promise<void> {
  const json = JSON.stringify({
    cardNr: cardNr,
    mobilePhone: mobilePhone,
    pin: pin
  });
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards/${cardNr}/recived/confirm`,
    "PUT",
    json,
    "putPin"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/borrowed/confirm PUT
export async function putReceiveDoorKeyPin(
  doorKeyId: number,
  status: string,
  pin: number
): Promise<void> {
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/${doorKeyId}/status/${status}/confirm`,
    "PUT",
    pin.toString(),
    "putReceiveDoorKeyPin"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards POST
export async function postSafetyCard(
  personnelId: number,
  safetyCard: SafetyCard
): Promise<SafetyCard> {
  const postSafetycardObject: PostSafetycardObject = {
    cardTypeId: safetyCard.cardTypeId,
    validFromDateTime: safetyCard.validFromDateTime,
    validToDateTime: safetyCard.validToDateTime,
    attributes: safetyCard.attributes,
    application: false,
    orgId: safetyCard.orgId,
    responsibleAtOrg: safetyCard.responsibleAtOrg
  };
  return await postJson<SafetyCard>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards`,
    JSON.stringify(postSafetycardObject),
    "postSafetyCard"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards/applications?includeShredded={INCLUDESHREDDED} GET
export async function getPersonnelApplications(
  personnelId: number,
  includeShredded: boolean
): Promise<SafetyCard[]> {
  return await getJson<SafetyCard[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards/applications?includeShredded=${includeShredded}`,
    "getPersonnelApplications"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards/applications?demo={DEMO} POST
export async function postSafetyCardApplication(
  application: SafetyCard,
  comment: string,
  selfAttesting: boolean,
  mailTo: string
): Promise<SafetyCard> {
  const postSafetycardObject: PostApplicationObject = {
    application: true,
    attributes: application.attributes,
    orgId: application.orgId,
    responsibleAtOrg: application.responsibleAtOrg,
    validFromDateTime: application.validFromDateTime,
    validToDateTime: application.validToDateTime,
    comment: comment,
    mailTo: mailTo,
    cardTypeId: application.cardTypeId
  };
  const payload = JSON.stringify(postSafetycardObject);

  return await postJson<SafetyCard>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${application.personnelId}/safetycards/applications?selfAttesting=${selfAttesting}`,
    payload,
    "postSafetyCardApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/token/application/{APPLICATIONID}/attestor POST
export async function postApprover(
  applicationId: number,
  email: string,
  name: string,
  title: string,
  phone: number,
  skipMail: boolean
): Promise<Attestor> {
  return await postJson<Attestor>(
    `${appConfig.baseUrl}${appConfig.serviceName}/application/${applicationId}/attestor?skipMail=${skipMail}`,
    JSON.stringify({
      email,
      name,
      phone,
      title
    }),
    "postApprover"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/application/{applicationId}/attestor/link GET
export async function getApproverLink(applicationId: number) {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/application/${applicationId}/attestor/link`;
  return getJson<string>(url, "getApproverLink");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID} DELETE
export async function deleteSafetycard(cardId: number) {
  return await deleteCall(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}`,
    "deleteSafetycard"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID} PATCH
export async function patchPersonnelAttribute(
  personnelId: number,
  attributes: { attributeId: number; value: string }[]
) {
  const json = JSON.stringify(attributes);
  await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}`,
    "PATCH",
    json,
    "updatePersonnelAttribute"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID} PUT
export function putPersonnel(person: PersonInfo) {
  const json = JSON.stringify({
    email: person.email,
    organizationNo: person.organizationNo,
    firstName: person.firstName,
    lastName: person.lastName,
    mobilePhone: person.mobilePhone
  });
  return putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${person.personnelId}`,
    "PUT",
    json,
    "updatePersonnel"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID} PATCH
export async function patchSafetycardAttribute(
  cardId: number,
  toUpdate: { attributeId: number; value: string }[]
) {
  const json = JSON.stringify(toUpdate);
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}`,
    "PATCH",
    json,
    "updateSafetycardAttribute"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID} PUT
export async function postUpdateLiveSafetycard(
  card: SafetyCard
): Promise<void> {
  const payload = {
    card: card,
    attributes: card.attributes,
    nodes: card.nodes ? card.nodes : []
  };
  const json = JSON.stringify(payload);
  return await postJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${card.cardId}/live`,
    json,
    "postUpdateLiveSafetycard"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID} PUT
export async function putSafetycard(card: SafetyCard) {
  const json = JSON.stringify({
    application: card.application,
    orgId: card.orgId,
    responsibleAtOrg: card.responsibleAtOrg,
    validFromDateTime: card.validFromDateTime,
    validToDateTime: card.validToDateTime
  });
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${card.cardId}`,
    "PUT",
    json,
    "putSafetycard"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets/{assetId} GET
export async function getAsset(assetId: number): Promise<Asset> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}`;
  return getJson<Asset>(url, "getAsset");
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/assets/{assetId}/entry/authorized GET
export async function getAuthorizedPersons(
  assetId: number
): Promise<AuthorizedPerson[]> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}/entry/authorized`;
  return getJson<AuthorizedPerson[]>(url, "getAuthorizedPersons");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets POST
export async function postAsset(asset: Asset): Promise<Asset> {
  const json = await postJson<Asset>(
    `${appConfig.baseUrl}${appConfig.serviceName}/assets`,
    JSON.stringify(asset),
    "postAsset"
  );

  return json;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets/{assetId} PUT
export async function putAsset(assetId: number, asset: Asset): Promise<void> {
  await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}`,
    "PUT",
    JSON.stringify(asset),
    "putAsset"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/assets/node/{NODEID} PUT
export async function putAssetNode(
  nodeId: number,
  node: AssetNode
): Promise<void> {
  await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/assets/node/${nodeId}`,
    "PUT",
    JSON.stringify(node),
    "putAssetNode"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets/{assetId}/image GET
export function getImgUrl(assetdId: number): string {
  return `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetdId}/image`;
}

export async function getPersonInfo(id: number): Promise<PersonInfo> {
  return await getJson<PersonInfo>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${id}?useTlf=false`,
    "getUser"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/user
export async function getUserIdentity(): Promise<UserIdentity> {
  return await getJson<UserIdentity>(
    `${appConfig.baseUrl}${appConfig.serviceName}/user?includeIssuePermissions=true
    `,
    "getUserIdentity"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/preferences PUT
export async function setPreferredLanguage(
  personId: number,
  language: PreferredLanguage
) {
  await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personId}/preferences`,
    "PUT",
    JSON.stringify(language),
    "setPreferredLanguage"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/safetycards?includeShredded={INCLUDESHREDDED}GET
export async function getUserSaftycards(
  personnelId: number,
  includeShredded
): Promise<SafetyCard[]> {
  return await getJson<SafetyCard[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/safetycards?includeShredded=${includeShredded}`,
    "getUserSaftycards"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{personnelId}/assets/nodes/all GET
export async function getUserAssetNodesAll(
  personnelId: number
): Promise<AssetNode[]> {
  return await getJson<AssetNode[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/assets/nodes/all`,
    "getUserSaftycards"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{safetycardsId}/assets/nodes/all GET
export async function getSafetyCardAssetNodesAll(
  safetycardId: number
): Promise<AssetNode[]> {
  return await getJson<AssetNode[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${safetycardId}/assets/nodes/all`,
    "getUserSaftycards"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{personnelId}/assets/nodes?parentId={PARENTID} GET
export async function getUserAssetNodes(
  personnelId: number,
  parentId: number
): Promise<AssetNode[]> {
  return await getJson<AssetNode[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/assets/nodes?parentId=${parentId}`,
    "getUserSaftycards"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/safetycards/{CARDID}/assets GET
export async function getSafetycardAssets(cardId: number): Promise<Asset[]> {
  return await getJson<Asset[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}/assets`,
    "getSafetycardAssets"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/personnel/{PERSONNELID}/assets GET
export async function getUserAssets(personnelId: number): Promise<Asset[]> {
  return await getJson<Asset[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/assets`,
    "getUserAssets"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/safetycards/{CARDID}/assets/nodes/?parentId={PARENTID} GET
export async function getSafetycardAssetNodes(
  cardId: number,
  parentId: number,
  token?: string
): Promise<AssetNode[]> {
  return await getJson<AssetNode[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}/assets/nodes?parentId=${parentId}`,
    "getSafetycardAssetNodes",
    {
      tokenKey: token
    }
  );
}
///safetycards/{cardId}/assets/nodes/checked
export async function getSafetycardAssetNodesChecked(
  cardId: number
): Promise<AssetNode[]> {
  return await getJson<AssetNode[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}/assets/nodes/checked`,
    "getSafetycardAssetNodesChecked"
  );
}

///organisation/{orgId}/assets/nodes/path
export async function getNodesPath(
  orgId: number,
  nodeIds: number[]
): Promise<AssetNode[]> {
  return await postJson<AssetNode[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/organisation/${orgId}/assets/nodes/path`,
    JSON.stringify(nodeIds),
    "getNodesPath"
  );
}
//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets/{assetId}/image GET
export function getAssetImageUrl(assetId: number): string {
  return `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}/image`;
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets/{assetId}/image POST
export async function saveAssetImage(assetId: number, file: File) {
  const url = getAssetImageUrl(assetId);
  await uploadFile(url, "POST", file, "saveAssetImage");
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/schedules/cardexpiration GET
export async function getNotificationValuesCardexpiration(): Promise<
  Notification[]
> {
  return await getJson<Notification[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/schedules/cardexpiration`,
    "getNotificationValuesCardexpiration"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/schedules/docexpiration GET
export async function getNotificationValuesDocExpiration(): Promise<
  Notification[]
> {
  return await getJson<Notification[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/schedules/docexpiration`,
    "getNotificationValuesDocExpiration"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/notifications PUT
export async function setMyNotifications(
  personId: number,
  values: NotificationSchedules
): Promise<void> {
  await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personId}/notifications`,
    "PUT",
    JSON.stringify(values),
    "setMyNotifications"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/getMyDoorKeysList GET
export async function getMyDoorKeysList(
  personId: number
): Promise<DoorKeysInfo[]> {
  return await getJson<DoorKeysInfo[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personId}/doorkeys`,
    "getMyDoorKeysList"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/applications/${applicId} GET
export async function getMyDoorKeyApplication(
  applicId: number
): Promise<DoorKeyApplication> {
  return await getJson<DoorKeyApplication>(
    `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/applications/${applicId}`,
    "getMyDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/personnel/{PERSONNELID}/doorkeys/applications GET
export async function getMyDoorKeyApplicationList(
  personId: number
): Promise<DoorKeyApplication[]> {
  return await getJson<DoorKeyApplication[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personId}/doorkeys/applications`,
    "getMyDoorKeyApplicationList"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/personnel/{PERSONNELID}/doorkeys/applications POST
export async function postDoorKeyApplication(
  application: DoorKeyApplication,
  comment: string
): Promise<DoorKeyApplication> {
  const postDoorKeyObject: PostDoorKeyApplication = {
    attributes: application.attributes,
    comment: comment,
    orgId: application.orgId,
    application: true
  };
  const payload = JSON.stringify(postDoorKeyObject);
  return await postJson<DoorKeyApplication>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${application.personnelId}/doorkeys/applications`,
    payload,
    "postDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V4Internal.svc/token/doorkeys/applications/{applicationId} PUT
export async function putDoorKeyApplication(application: DoorKeyApplication) {
  const json = JSON.stringify({
    orgId: application.orgId,
    applicationId: application.doorKeyApplicId,
    personnelId: application.personnelId,
    attributes: application.attributes
  });

  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/applications/${application.doorKeyApplicId}`,
    "PUT",
    json,
    "putDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/applications/{applicationId} DELETE
export async function deleteDoorKeyApplication(applicationId: number) {
  return await deleteCall(
    `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/applications/${applicationId}`,
    "deleteDoorKeyApplication"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/personnel/{PERSONNELID}/doorkeys GET
export async function getEmployerDoorkeysForPerson(
  personnelId: number
): Promise<DoorKeysInfo[]> {
  return await getJson<DoorKeysInfo[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/personnel/${personnelId}/doorkeys`,
    "getEmployerDoorkeysForPerson"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/doorkeys/{DOORKEYID}/status/borrowed PUT
export async function putMyDoorKeyReceive(
  doorKeyId: number,
  comment: string
): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/doorkeys/${doorKeyId}/status/borrowed`;
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(comment ? comment : ""),
    "putMyDoorKeyReceive"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID}/assets PUT
export async function putSafetycardAssets(
  cardId: number,
  assets: number[]
): Promise<void> {
  const json = JSON.stringify(assets);
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}/assets`,
    "PUT",
    json,
    "putSafetycardAssets"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/safetycards/{CARDID}/attribute/{ATTRIBID}/attachment/{ATTACHMENTID} PUT
export async function sendSafetyCardDocumentations(
  cardId: number,
  documentations: Map<number, number>
): Promise<void> {
  for (const [attributeId, attachmentId] of documentations) {
    const url = `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}/attribute/${attributeId}/attachment/${attachmentId}`;
    putOrPatchJson(url, "PUT", "", "sendSafetyCardDocumentations");
  }
}

export function getSafetyCardPrintUrl(cardId: number) {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/safetycards/${cardId}/print`;
  return url;
}

//http://192.168.100.13:8085/permitto/rest/V3Auth.svc/maintenancemessages/active/{LANGUAGECODE}
export async function getMaintenanceMessages(
  languageCode: string,
  orgId: number
): Promise<MaintenanceMessage[]> {
  return await getJson<MaintenanceMessage[]>(
    `${appConfig.s3Url}/maintenence-messages/${languageCode}/${orgId}.json`,
    "getMaintenanceMessages"
  );
}

//http://192.168.100.13:8085/permitto/rest/V3Internal.svc/token/assets/{assetEntryId}/attachment/{attachmentId} GET
export function getCheckInAttachment(
  entryId: number,
  attachmentId: number
): string {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/assets/${entryId}/attachment/${attachmentId}`;
  return url;
}

export async function postUserPinRequest(
  phoneNr: string,
  deviceId: string,
  sendEmail: boolean
): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.authServiceName}/auth/${phoneNr}?deviceId=${deviceId}&sendEmail=${sendEmail}`;
  await postEmpty(url, "postPinRequest");
}

export async function putUserPin(
  phoneNr: string,
  pin: string,
  deviceId: string,
  remember: boolean
): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.authServiceName}/auth/${phoneNr}/confirm/cookie?remember=${remember}&deviceId=${deviceId}`;
  await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify({
      pin: pin
    }),
    "putUserPin()"
  );
}

export function getAadLoginUrl() {
  const url = `${appConfig.baseUrl}${appConfig.authServiceName}/aad/login`;
  return url;
}

export async function logOut() {
  const url = `${appConfig.baseUrl}${appConfig.authServiceName}/auth`;
  return deleteCall(url, "logOut");
}

export function redirectToBase() {
  if (appConfig.basename) {
    window.location.href = appConfig.basename;
  } else {
    window.location.href = "/";
  }
}

export async function deleteAssetImage(assetId: number) {
  return await deleteCall(
    `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}/image`,
    "deleteAssetImage"
  );
}

export async function checkoutOrgEntry(
  assetId: number,
  entryId: number,
  sendSms: boolean
): Promise<void> {
  const smsValue = sendSms ? "true" : "false";
  const url = `${appConfig.baseUrl}${
    appConfig.serviceName
  }/assets/${assetId}/entry/${entryId}${joinUrlParams({
    name: "sendSms",
    value: smsValue
  })}`;
  return await deleteCall(url, "checkoutOrgEntry");
}

export async function checkinPerson(
  assetId: number,
  chkIn: CheckinPerson
): Promise<number> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}/entry`;
  return await postJson(url, JSON.stringify(chkIn), "checkinPerson");
}

export async function sendSMS(
  orgId: number,
  recipients: number[],
  message: string
): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/organisation/${orgId}/notify`;
  return await postJson<void>(
    url,
    JSON.stringify({ recipients: recipients, message: message }),
    "sendSMS"
  );
}

export async function setNote(entryId: number, note: string): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/assets/${entryId}/assetNote`;
  return await postJson<void>(url, JSON.stringify({ note: note }), "setNote");
}

export async function changeEscortedPersonnel(
  assetId: number,
  entryId: number,
  escortedPersonnel: string[]
): Promise<void> {
  const url = `${appConfig.baseUrl}${appConfig.serviceName}/assets/${assetId}/entry/${entryId}/escorted`;
  return await putOrPatchJson(
    url,
    "PUT",
    JSON.stringify(escortedPersonnel),
    "changeEscortedPersonnel"
  );
}

export async function deleteAssetMessage(messageId: number) {
  return await deleteCall(
    `${appConfig.baseUrl}${appConfig.serviceName}/messages/${messageId}`,
    "deleteAssetMessage"
  );
}

export async function updateAssetMessage(
  messageId: number,
  assetMessage: AssetMessage
) {
  const payload = JSON.stringify(assetMessage);
  return await putOrPatchJson(
    `${appConfig.baseUrl}${appConfig.serviceName}/messages/${messageId}`,
    "PUT",
    payload,
    "updateAssetMessage"
  );
}

export async function getAssetMessageEventTypes(): Promise<KeyValueType[]> {
  return getJson<KeyValueType[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/typelists/assetinfo/events`,
    "getLookupPerson"
  );
}

export async function getAssetMessageInfoTypes(): Promise<KeyValueType[]> {
  return getJson<KeyValueType[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/typelists/assetinfo/types`,
    "getLookupPerson"
  );
}

export async function getTemplateAttributeTypes(): Promise<KeyValueType[]> {
  return getJson<KeyValueType[]>(
    `${appConfig.baseUrl}${appConfig.serviceName}/typelists/datatypes`,
    "getDataTypes"
  );
}

export function getGooglePlayUrl(): string {
  return "https://play.google.com/store/apps/details?id=com.verico.permitto&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
}

export function getAppleAppStoreUrl(): string {
  return "https://itunes.apple.com/no/app/permitto/id1313687181?mt=8";
}

export * from "./organization";
export * from "./employer";
export * from "./other-cards";
export * from "./personnel";
