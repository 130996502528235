import styles from "./header.css";
import * as React from "react";
import { observer } from "mobx-react";
import * as Routes from "./../../app/view/route-paths";
import { userStore } from "./../../stores";
import { History } from "history";
import { CurrentUser } from "./../current-user/current-user";
import { appConfig } from "./../../config";
import AppButton from "./app-button";
import HeaderText from "./header-text";
import {
  getCurrentContextNameAndUrl,
  getCurrentRouteFirstLevelNameAndUrl,
  getHeaderIcon
} from "./header-utils";
import SubHeaderText from "./sub-header-text";
import HeaderDivider from "./header-divider";

interface HeaderProps {
  location: Location;
  history: History;
}

@observer
export default class Header extends React.Component<HeaderProps> {
  switchContext = (url: string) => {
    this.props.history.push(url);
  };

  render() {
    return (
      <div
        className={styles.headerContainer}
        style={
          appConfig.AppEnvColor
            ? { backgroundColor: appConfig.AppEnvColor }
            : {}
        }
      >
        <div className={styles.left}>
          {appConfig.AppEnvHeader && (
            <p className={styles.devHeader}>{appConfig.AppEnvHeader}</p>
          )}
          <img
            className={styles.logo}
            src="res/logo_permitto_white.svg"
            onClick={() => {
              userStore.userIdentity &&
                this.props.history.push(Routes.GetMyPermittoUrl());
            }}
          />

          <AppButton
            currentUrl={this.props.location.pathname}
            userIdentity={userStore.userIdentity}
            switchContext={this.switchContext}
          />
          <HeaderText
            currentUrl={this.props.location.pathname}
            switchContext={this.switchContext}
            text={
              getCurrentContextNameAndUrl(this.props.location.pathname).name
            }
            goToUrl={
              getCurrentContextNameAndUrl(this.props.location.pathname).url
            }
          />
          <HeaderDivider />
          <HeaderText
            currentUrl={this.props.location.pathname}
            switchContext={this.switchContext}
            text={
              getCurrentRouteFirstLevelNameAndUrl(this.props.location.pathname)
                .name
            }
            goToUrl={
              getCurrentRouteFirstLevelNameAndUrl(this.props.location.pathname)
                .url
            }
            icon={getHeaderIcon(this.props.location.pathname)}
          />
          <SubHeaderText location={this.props.location} />
        </div>
        <div className={styles.right}>
          {userStore.userIdentity && (
            <CurrentUser className={styles.logoCustomer} />
          )}
        </div>
      </div>
    );
  }
}
