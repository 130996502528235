import * as React from "react";
import { RouteProps } from "../types";

interface AsyncComponentProps {
  loadComponent: () => Promise<any>;
  routeProps: RouteProps;
}

export class AsyncComponent extends React.Component<
  AsyncComponentProps,
  { component: any }
> {
  constructor(props) {
    super(props);
    this.state = { component: undefined };
  }

  componentDidMount() {
    this.loadChunk(this.props.loadComponent);
  }

  async loadChunk(loadComponent) {
    const retVal = await loadComponent();
    const keys = Object.keys(retVal);
    let prefferedIndex = keys.indexOf("default");
    if (prefferedIndex === -1) {
      prefferedIndex = 0;
    }
    const component = retVal[keys[prefferedIndex]];
    this.setState({ component: component });
  }

  shouldComponentUpdate(nextProps: AsyncComponentProps) {
    if (nextProps.routeProps.match.path !== this.props.routeProps.match.path) {
      this.setState({ component: undefined });
      this.loadChunk(nextProps.loadComponent);
      return false;
    }
    return true;
  }

  render() {
    if (!this.state.component) {
      return false;
    }
    return <this.state.component {...this.props.routeProps} />;
  }
}
