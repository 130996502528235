export function getAccessLogDateStr(
  fromDate: Date,
  toDate: Date
): UrlParamPair[] {
  return [
    { name: "fromDate", value: fromDate.toISOString() },
    { name: "toDate", value: toDate.toISOString() }
  ];
}

export function joinUrlParams(params: UrlParamArg): string {
  if (!params) {
    return "";
  }
  let urlParams;
  if (Array.isArray(params)) {
    urlParams = params
      .map((p: UrlParamPair) => `${p.name}=${p.value}`)
      .join("&");
  } else {
    urlParams = `${params.name}=${params.value}`;
  }
  return `?${urlParams}`;
}

export type UrlParamArg = UrlParamPair | UrlParamPair[];

export interface UrlParamPair {
  name: string;
  value: string;
}
