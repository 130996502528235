import * as React from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import onClickOutside from "react-onclickoutside";

import styles from "./portal-panel.css";

const portalRoot = document.getElementById("portal-root");

@observer
class DetachedPanel extends React.Component<{
  onClickOutside: () => void;
}> {
  handleClickOutside = evt => {
    this.props.onClickOutside();
  };

  render() {
    return createPortal(this.props.children, portalRoot);
  }
}

const DetachedPanelWrapper = onClickOutside(DetachedPanel);

@observer
export class PortalPanel extends React.Component<{
  offsetX: number;
  offsetY: number;
  onClickOutside: () => void;
}> {
  containerRef: any;

  @observable
  position: { x: number; y: number } | null;

  @computed
  get childStyles() {
    return { top: this.position.y, left: this.position.x };
  }

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.position = { x: 0, y: 0 };
  }

  @action
  setPosition = (x: number, y: number) => {
    this.position = { x, y };
  };

  componentDidMount() {
    this.updatePosition();
    window.addEventListener("resize", this.updatePosition);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePosition);
  }

  updatePosition = () => {
    if (this.containerRef && this.containerRef.current) {
      const rect = this.containerRef.current.getBoundingClientRect();

      this.setPosition(
        rect.left + document.body.scrollLeft + this.props.offsetX,
        rect.top + document.body.scrollTop + this.props.offsetY
      );
    }
  };

  render() {
    return (
      <span className={styles.container} ref={this.containerRef}>
        <DetachedPanelWrapper onClickOutside={this.props.onClickOutside}>
          <div className={styles.childContainer} style={this.childStyles}>
            {this.props.children}
          </div>
        </DetachedPanelWrapper>
      </span>
    );
  }
}
