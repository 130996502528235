import * as React from "react";

import { action, observable } from "mobx";

import { RouteProps } from "react-router-dom";
import { localizationStore } from "../../stores/index";
import { observer } from "mobx-react";
import styles from "./update-version.css";

//const ONE_HOUR = 3600000;
const ONE_MIN = 60000;

@observer
export class UpdateVersion extends React.Component<RouteProps> {
  @observable
  lastCheck: number;

  @observable
  version: string;

  @observable
  newVersionAvailable: boolean;

  constructor(props) {
    super(props);

    this.lastCheck = Date.now();
    this.version = "";
    this.newVersionAvailable = false;
  }

  @action
  setVerson = (v: string) => {
    this.version = v;
  };

  @action
  setCheckTime = (t: number) => {
    this.lastCheck = t;
  };

  @action
  setNewVersionAvailable = (n: boolean) => {
    this.newVersionAvailable = n;
  };

  componentDidMount() {
    this.setCurrentVersion();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.checkServerVersion();
    }
  }

  setCurrentVersion = () => {
    const { src } = Array.from(document.scripts).find(s =>
      s.src.includes("entry")
    );
    //  "https://map.verico.no/permitto/bundles/entry-index-6a5c5f462a84e21472a8.bundle.js"
    const [matchFilename] = /entry-index-\w{20}.bundle.js/g.exec(src);
    const [matchHash] = /\w{20}/g.exec(matchFilename);
    this.setVerson(matchHash);
  };

  checkServerVersion = async () => {
    const checkTime = Date.now();
    const timeEllapsed = checkTime - this.lastCheck;
    if (timeEllapsed > ONE_MIN) {
      const serverVersion = await this.getVersion();
      if (serverVersion !== this.version) {
        this.setNewVersionAvailable(true);
      } else {
        this.setCheckTime(checkTime);
      }
    }
  };

  getVersion = async () => {
    const respone = await fetch("./build-hash.json", { cache: "no-store" });
    const webpackBuild = await respone.json();
    return webpackBuild.hash;
  };

  onRefresh = () => {
    window.location.reload();
    //window.location.assign("/");
  };

  render() {
    if (this.newVersionAvailable) {
      const local = localizationStore.currentLocal.shared.updateVersion;
      return (
        <div className={styles.container}>
          <span>{` ${local.preRefreshText} `}</span>
          <a className={styles.refreshBtn} onClick={this.onRefresh}>
            {local.refreshTextBtn}
          </a>
          <span>{` ${local.postRefreshText} `}</span>
        </div>
      );
    }
    return null;
  }
}
