import styles from "./language-selector.css";
import * as React from "react";
import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import { localizationStore } from "../../stores/localization";

interface LanguageOption {
  value: string;
  label: string;
  src: string;
}

@observer
export class LanguageSelector extends React.Component<{
  onSelect: (lang: string) => void;
  defaultLanguage: string;
  labelCustomClass?: string;
}> {
  @observable
  isListOpen: boolean;

  constructor(props) {
    super(props);
    this.isListOpen = false;
  }

  @computed
  get languageOptions(): LanguageOption[] {
    const local = localizationStore.currentLocal.shared.languageSelector;
    return [
      {
        value: "no",
        label: local.Nor,
        src: "res/no.svg"
      },
      { value: "en", label: local.Eng, src: "res/gb.svg" },
      { value: "se", label: local.Sve, src: "res/se.svg" },
      { value: "nl", label: local.Ned, src: "res/nl.svg" }
    ];
  }

  @computed
  get selected(): LanguageOption {
    const lang = this.languageOptions.find(
      opt => opt.value === this.props.defaultLanguage
    );
    if (lang) {
      return lang;
    } else {
      throw new Error(`Unknown language code <${this.props.defaultLanguage}>`);
    }
  }

  @action
  setListOpen = (newValue: boolean) => {
    this.isListOpen = newValue;
  };

  onHeaderClick = () => {
    this.setListOpen(!this.isListOpen);
  };

  onSelect = (opt: LanguageOption) => {
    this.props.onSelect(opt.value);
    this.setListOpen(false);
  };

  render() {
    return (
      <div className={styles.flagContainer}>
        <Header
          lang={this.selected}
          onClick={this.onHeaderClick}
          customClass={this.props.labelCustomClass}
        />
        {this.isListOpen && (
          <OptionList onSelect={this.onSelect} selected={this.selected}>
            {this.languageOptions.map(lang => (
              <Option
                value={lang.value}
                label={lang.label}
                src={lang.src}
                key={`list-key-${lang.value}`}
              />
            ))}
          </OptionList>
        )}
      </div>
    );
  }
}

function Header(props: {
  lang: LanguageOption;
  onClick: () => void;
  customClass?: string;
}) {
  return (
    <div className={styles.header} onClick={props.onClick}>
      <div className={styles.headerLabelContainer}>
        <i className="fa fa-globe" aria-hidden="true" />
        <span
          className={`${styles.headerLabel} ${
            props.customClass ? props.customClass : ""
          }`}
        >
          {props.lang.label}
        </span>
      </div>
      <div>
        <i
          className={`fa fa-chevron-down ${styles.dropdownArrow}`}
          aria-hidden="true"
        />
      </div>
    </div>
  );
}

function OptionList(props: {
  onSelect: (value: LanguageOption) => void;
  children: any;
  selected: LanguageOption;
}) {
  return (
    <div className={styles.list} id="language-selector-list">
      <div className={styles.chevron} />
      {React.Children.map(props.children, child =>
        React.cloneElement(child, {
          onClick: e => props.onSelect(child.props),
          checked: props.selected.value === child.props.value
        })
      )}
    </div>
  );
}

function Option(
  props: LanguageOption & { onClick?: (e) => void; checked?: boolean }
) {
  return (
    <div className={styles.option} onClick={props.onClick}>
      <img className={styles.icon} src={props.src} />
      <span className={styles.label}>{props.label}</span>
      {props.checked && <i className="fa fa-check" aria-hidden="true" />}
    </div>
  );
}
