import styles from "./dialogs.css";
import * as React from "react";
import swal from "sweetalert2";
import { localizationStore } from "./../../stores/index";
import Modal from "./../modal/modal";
import Button from "./../buttons/button/button";
import EditIndicatorBar from "../../libs/edit-indicator-bar/edit-indicator-bar";
export enum DialogType {
  Success = "success",
  Alert = "error"
}

export interface DialogProps {
  type: DialogType;
  label: string;
  text: string;
  onOk?: Function;
  onCancel?: Function;
}

interface ConfirmDialogProps extends DialogProps {
  okButtonText?: string;
  cancelButtonText?: string;
}

interface ThreeWayDialogProps {
  faIcon: string;
  label: string;
  firstButtonText: string;
  secondButtonText: string;
  onFirstButton: () => void;
  onSecondButton: () => void;
  close: () => void;
}

const HEADER_IMAGE_URL = "res/Permitto-icon-RGB-edit-resized.svg";

export class ThreeWayDialog extends React.Component<ThreeWayDialogProps> {
  render() {
    return (
      <Modal
        closeModal={() => {
          this.props.close();
        }}
        closeOnClickOutside={true}
        width={500}
        height={200}
      >
        <div className={styles.dialogContainer}>
          <EditIndicatorBar
            header={this.props.label}
            headerImageUrl={HEADER_IMAGE_URL}
            hideSaveButton={true}
            close={() => {
              this.props.close();
            }}
          />

          <div className={styles.threeWayDialogButtons}>
            <Button
              text={this.props.firstButtonText}
              large={true}
              callback={() => {
                this.props.onFirstButton();
              }}
            />
            <Button
              text={this.props.secondButtonText}
              large={true}
              callback={() => {
                this.props.onSecondButton();
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export async function dialog(info: DialogProps): Promise<boolean> {
  const result = await swal({
    title: info.label,
    type: info.type,
    html: info.text
  });
  return result.value ? true : false;
}

export interface Info {
  title: string;
  text: string;
  allowEnterKey?: boolean;
}

export async function confirmDialog(
  info: ConfirmDialogProps
): Promise<boolean> {
  const result = await swal({
    title: info.label,
    text: info.text,
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonText: !info.cancelButtonText
      ? localizationStore.currentLocal.shared.dialog.Cancel
      : info.cancelButtonText,
    cancelButtonColor: "#d33",
    confirmButtonText: !info.okButtonText
      ? localizationStore.currentLocal.shared.dialog.Confirm
      : info.okButtonText
  });
  return result.value ? true : false;
}

export async function confirm(info: Info): Promise<boolean> {
  const result = await swal({
    title: info.title,
    text: info.text,
    type: "warning",
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonText: localizationStore.currentLocal.shared.dialog.Cancel,
    cancelButtonColor: "#d33",
    confirmButtonText: localizationStore.currentLocal.shared.dialog.Confirm
  });
  if (result.value) {
    return true;
  } else {
    return false;
  }
}

export async function alert(info: Info): Promise<boolean> {
  const result = await swal({
    title: info.title,
    text: info.text,
    type: "error",
    allowEnterKey: info.allowEnterKey !== undefined ? info.allowEnterKey : true
  });
  if (result.value) {
    return true;
  } else {
    return false;
  }
}

export async function infoDialog(info: Info): Promise<boolean> {
  const result = await swal({
    title: info.title,
    text: info.text,
    type: "info"
  });
  if (result.value) {
    return true;
  } else {
    return false;
  }
}

export async function yesNoDialog(
  title: string,
  text: string
): Promise<boolean> {
  const result = await swal({
    title: title,
    text: text,
    type: "question",
    showCancelButton: true,
    confirmButtonColor: "#428bca",
    confirmButtonText: localizationStore.currentLocal.shared.dialog.Yes,
    cancelButtonText: localizationStore.currentLocal.shared.dialog.No,
    allowOutsideClick: true
  });
  if (result.value) {
    return true;
  } else {
    return false;
  }
}

export async function inputDialog(
  header: string,
  subHeader?: string,
  noComment: boolean = false,
  confirmButtonText?: string,
  singleRow?: boolean,
  singleRowPlaceholder?: string
): Promise<{ success: boolean; text: string }> {
  const result = await swal({
    title: header,
    text: subHeader
      ? subHeader
      : localizationStore.currentLocal.shared.dialog.InputText,
    cancelButtonText: noComment
      ? localizationStore.currentLocal.shared.dialog.NoComment
      : localizationStore.currentLocal.shared.dialog.Cancel,
    input: singleRow ? "text" : "textarea",
    inputPlaceholder: singleRowPlaceholder || "",
    showCancelButton: true,
    confirmButtonColor: "#428bca",
    allowOutsideClick: true,
    confirmButtonText: confirmButtonText
      ? confirmButtonText
      : localizationStore.currentLocal.shared.dialog.Ok
  });
  if (result.value || result.value === "") {
    return { success: true, text: result.value };
  } else {
    return { success: false, text: "" };
  }
}
