import { appConfig } from "./../../config";

export * from "./route-paths/org/route-paths";
export * from "./route-paths/org/route-urls";
export * from "./route-paths/org/url-tests";

export * from "./route-paths/our/route-paths";
export * from "./route-paths/our/route-urls";
export * from "./route-paths/our/url-tests";

export * from "./route-paths/my/route-paths";
export * from "./route-paths/my/route-urls";
export * from "./route-paths/my/url-tests";

export { NotFound, Org, Our, Type, Splash } from "./route-paths/consts";

import {
  Invite,
  PersonIdParameter,
  Tos,
  RegisterUser,
  LanguageParameter,
  UpdateInfo,
  Application,
  Attest,
  Auth,
  ApplicationIdParameter,
  SSONotAvilable
} from "./route-paths/consts";

export function GetInviteRoute() {
  return Invite + PersonIdParameter;
}

export function GetTosRoute() {
  return Tos;
}

export function GetRegisterUserRoute() {
  return RegisterUser + LanguageParameter;
}

export function GetUpdateInfoRoute() {
  return UpdateInfo;
}

function GetAttestBase() {
  const attestBase =
    appConfig.basename +
    Application.substring(1, Application.length - 1) +
    Attest;
  return attestBase;
}

export function GetAttestRoute() {
  const attestUrl = GetAttestBase() + ApplicationIdParameter;
  return attestUrl;
}

export function GetAuthRoute() {
  return Auth;
}

export function GetSsoNotAvailableRoute() {
  return SSONotAvilable;
}

//////////////////////////////////////////////////////////

export function GetAuthUrl() {
  return Auth;
}

export function GetRegisterUserUrl(language: string) {
  return RegisterUser + language;
}

/////////////////////////////////////////////////////////

export function IsInviteUrl(url: string) {
  const regexp = new RegExp(`^${Invite}+?\\d+`);
  return regexp.test(url);
}

export function IsRegisterNewUserUrl(url: string) {
  const regexp = new RegExp(`^${RegisterUser}+?\\w*`);
  return regexp.test(url);
}

export function IsAttestUrl(url: string) {
  const base = GetAttestBase();
  return url.indexOf(base) > -1;
}
